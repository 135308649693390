import { Navigate, Outlet } from 'react-router-dom';

import { Cookie } from 'core/cookies';
import LanguageProvider from 'core/translations/LanguageProvider';

const ProtectedRoute = () => {
  const isTokenValid = !!Cookie.get('token');
  if (!isTokenValid) {
    return <Navigate to="/login" replace />;
  }
  return (
    <LanguageProvider>
      <Outlet />
    </LanguageProvider>
  );
};

export default ProtectedRoute;
