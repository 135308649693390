import { StringParam, useQueryParam } from 'use-query-params';

import { useControlledInput } from 'common/hooks';

const useInputUrlFilter = (name) => {
  const [query, setQuery] = useQueryParam(name, StringParam);
  const { value, onChange, setValue } = useControlledInput(query);

  const syncFilter = () => {
    setQuery(value.length ? value : undefined, 'replaceIn');
  };

  const filter = query ? query.split(', ') : [];

  const isSync = query === value;

  const clear = () => {
    setQuery(undefined, 'replaceIn');
    setValue('');
  };

  return { value, filter, isSync, syncFilter, onChange, clear };
};

export default useInputUrlFilter;
