import React from 'react';

import Image from 'assets/security-posture/not-found.png';
import Box from 'components/Box';
import Card from 'components/Card';
import Chart from 'components/Chart';
import Skeleton from 'components/Skeleton';
import Text from 'components/Text';
import { useTranslation } from 'core/translations/useTranslation';

import { getChartOptions } from './chart';

const WidgetInvolvedCases = ({ data, isLoading }) => {
  const t = useTranslation();
  const casesTimeline = data?.cases_timeline || {};

  const { series, min_date, max_date, range_days } = casesTimeline;

  const options = React.useMemo(
    () =>
      getChartOptions({
        rangeDays: range_days,
        maxDate: max_date,
        minDate: min_date,
        series: series,
      }),
    [series, min_date, max_date, range_days]
  );

  return (
    <Card className="w-full">
      {!isLoading ? (
        !!series?.length ? (
          <Chart
            options={options}
            containerProps={{ style: { width: '100%', height: '100%' } }}
          />
        ) : (
          <Box full column xcenter ycenter className="h-full">
            <Box xcenter column className="gap-3">
              <img className="w-[60px]" src={Image} alt="logo" />
              <Text bodyBold color="lightText">
                {t('details.involved_incident_timeline_widget.no_data')}
              </Text>
            </Box>
          </Box>
        )
      ) : (
        <Skeleton radius={'10px'} />
      )}
    </Card>
  );
};

export default WidgetInvolvedCases;
