import EmptyStateWidgets from 'common/components/widgets/EmptyStateWidgets';
import { usePagination } from 'common/hooks';
import Skeleton from 'components/Skeleton';
import Widget from 'components/Widget';
import '../../styles/styles.css';

import { useSignatureListQuery } from '../../services';
import TableView from './TableView';

const WidgetSignatureList = ({ id, title, isEditing, removeWidget }) => {
  const { currentPage, onPageChange } = usePagination('pageSignature');
  const { data: widgetData, isLoading } = useSignatureListQuery({
    page: currentPage,
  });

  return (
    <Widget
      isEditing={isEditing}
      iconLeft="settings"
      iconRight="dots"
      onDelete={() => removeWidget(id)}
      subtitle={<span className="title-widget">{title}</span>}
    >
      {isLoading ? (
        <Skeleton radius={'10px'} />
      ) : widgetData?.threat_signatures?.length ? (
        <TableView data={widgetData} onPageChange={onPageChange} />
      ) : (
        <EmptyStateWidgets />
      )}
    </Widget>
  );
};

export default WidgetSignatureList;
