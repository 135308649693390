import { convertBytesToMegabytes } from 'common/utils/convertBytesToMegabytes';
import Box from 'components/Box';
import Text from 'components/Text';
import { useTranslation } from 'core/translations/useTranslation';

export const useColumns = () => {
  const t = useTranslation();

  return [
    {
      title: t('soc_statistics.events_storage.table.typology'),
      dataIndex: 'name',
      key: 'name',
      width: 700,
      render: (name) => (
        <Box column>
          <Text>{name}</Text>
        </Box>
      ),
    },
    {
      title: t('soc_statistics.events_storage.table.amount'),
      dataIndex: 'amount',
      key: 'amount',
      width: 600,
      render: (amount) => <Text body>{amount}</Text>,
    },
    {
      title: t('soc_statistics.events_storage.table.size'),
      dataIndex: 'size',
      key: 'size',
      width: 300,
      render: (size) => (
        <Box column className="gap-1 w-fit items-end">
          <Text body>{convertBytesToMegabytes(size)}</Text>
        </Box>
      ),
    },
  ];
};
