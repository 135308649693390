import { useLocation } from 'react-router';

import { useToggler } from 'common/hooks';
import Box from 'components/Box';
import Icon from 'components/Icon';
import { useTranslation } from 'core/translations/useTranslation';

import { useSidebar } from '../../../hooks/useSidebar';
import { Item } from './Item';

export const ItemDropdown = ({
  icon,
  toggleCollapse,
  children,
  items,
  ...props
}) => {
  const t = useTranslation();
  const { isSidebarCollapsed } = useSidebar();

  const { pathname } = useLocation();
  const { isShown: isDropdownShown, toggle: toggleDropdown } = useToggler();
  const shouldShowDropdown = isDropdownShown && !isSidebarCollapsed;
  const isActive = !!items.find((item) => item.pathname === pathname);

  const showDropdown = () => {
    if (!isDropdownShown) {
      toggleDropdown();
    }
  };

  const handleClick = () => {
    if (isSidebarCollapsed) {
      toggleCollapse();
      showDropdown();
    } else {
      toggleDropdown();
    }
  };

  return (
    <Box onClick={handleClick} column {...props}>
      <Box between className="cursor-pointer">
        <Item isActive={isActive} icon={icon}>
          {t('common.response')}
        </Item>
        {!isSidebarCollapsed && (
          <Icon
            color="white"
            size="large"
            name={!isDropdownShown ? 'arrow-down' : 'arrow-up'}
          />
        )}
      </Box>
      {shouldShowDropdown && (
        <Box column className="pl-2">
          {items.map((item) => {
            return (
              <Item
                key={item.pathname}
                icon={item.icon}
                isActive={pathname === item.pathname}
                to={item.pathname}
              >
                {item.text}
              </Item>
            );
          })}
        </Box>
      )}
    </Box>
  );
};
