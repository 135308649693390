import React from 'react';

import { useUpdateDashboardMutation } from '../services';

const useRepositionAllTabs = () => {
  const { mutate: updateTab } = useUpdateDashboardMutation({});

  const repositionAllTabs = React.useCallback(
    (tabs) => {
      tabs.forEach((tab, i) =>
        updateTab({ id: tab.id, name: tab.name, tabPosition: i })
      );
    },
    [updateTab]
  );

  return { repositionAllTabs };
};

export default useRepositionAllTabs;
