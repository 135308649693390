import { useSelector } from 'react-redux';

import { CACHE_IDS } from 'common/constants/cache';
import { useQuery } from 'core/api/react-query';
import { selectDateRange } from 'redux/filter/selectors';

import { getEventsStorageData } from '../api';

const mapper = (data) => {
  const keys = Object.keys(data);
  return keys.map((key) => ({
    name: key,
    amount: data[key].amount,
    size: data[key].size,
  }));
};

export const useEventsStorageDataQuery = () => {
  const { dateFrom, dateTo } = useSelector(selectDateRange);

  const query = useQuery(
    [
      CACHE_IDS.EVENTS_STORAGE,
      { slug: [CACHE_IDS.EVENTS_STORAGE], dateTo, dateFrom },
    ],
    () =>
      getEventsStorageData({
        dateFrom,
        dateTo,
      }),
    {
      select: (result) => mapper(result),
    }
  );
  return query;
};

export default useEventsStorageDataQuery;
