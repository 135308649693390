import { useDashboard } from './useDashboard';

export const LOCAL_STORAGE_LAYOUT_BACKUP = 'temporaryLayoutBackup';

export const useEditMode = () => {
  const { layout, setLayout, isEditModeEnabled, setIsEditModeEnabled } =
    useDashboard();

  const enableEditMode = () => {
    // We copy the iniital layout to local storage when enabling edit mode
    localStorage[LOCAL_STORAGE_LAYOUT_BACKUP] = JSON.stringify(layout);
    setIsEditModeEnabled(!isEditModeEnabled);
  };

  const disableEditMode = () => {
    // We don't want the layout copy in storage when edit mode is disabled
    localStorage.removeItem(LOCAL_STORAGE_LAYOUT_BACKUP);
    setIsEditModeEnabled(!isEditModeEnabled);
  };

  // Discarding changes restores the local storage initial layout
  const discardChanges = () => {
    setLayout(JSON.parse(localStorage[LOCAL_STORAGE_LAYOUT_BACKUP]));
    disableEditMode();
  };

  return {
    discardChanges,
    enableEditMode,
    disableEditMode,
    isEditModeEnabled,
    setIsEditModeEnabled,
  };
};

export default useEditMode;
