import useInputUrlFilter from 'common/hooks/filters/useInputUrlFilter';
import useSelectUrlFilter from 'common/hooks/filters/useSelectUrlFilter';

export const useFilters = () => {
  const {
    value: casesValue,
    filter: casesFilter,
    isSync: isCasesFilterSync,
    syncFilter: syncUsersFilter,
    onChange: onUsersInputChange,
  } = useInputUrlFilter('caseIds');

  const {
    value: caseTypesValue,
    filter: caseTypesFilter,
    isSync: isCaseTypesFilterSync,
    syncFilter: syncCaseTypesFilter,
    onChange: onCaseTypesChange,
  } = useSelectUrlFilter('caseTypes');

  const {
    value: caseProductsValue,
    filter: productsFilter,
    isSync: isProductsFilterSync,
    syncFilter: syncProductsFilter,
    onChange: onProductsChange,
  } = useSelectUrlFilter('products');

  const {
    value: importantValue,
    filter: importantFilter,
    isSync: isImportantFilterSync,
    syncFilter: syncImportantFilter,
    onChange: onImportantChange,
  } = useSelectUrlFilter('important');

  const {
    value: criticalityValue,
    filter: criticalityFilter,
    isSync: isCriticalityFilterSync,
    syncFilter: syncCriticalityFilter,
    onChange: onCriticalityChange,
  } = useSelectUrlFilter('criticality');

  const onFiltersSubmit = (refetch) => {
    if (
      isCaseTypesFilterSync &&
      isCasesFilterSync &&
      isProductsFilterSync &&
      isImportantFilterSync &&
      isCriticalityFilterSync
    ) {
      refetch();
    } else {
      syncCriticalityFilter();
      syncCaseTypesFilter();
      syncUsersFilter();
      syncProductsFilter();
      syncImportantFilter();
    }
  };

  const transformedCases = casesFilter.map((num) => parseInt(num));

  return {
    filters: {
      caseTypes: caseTypesFilter,
      cases: transformedCases,
      products: productsFilter,
      criticality: criticalityFilter,
      important: importantFilter,
    },
    inputProps: {
      cases: {
        value: casesValue,
        defaultValue: casesValue,
        onChange: onUsersInputChange,
      },
      caseTypes: {
        value: caseTypesValue,
        defaultValue: caseTypesValue,
        onChange: onCaseTypesChange,
      },
      productsTypes: {
        value: caseProductsValue,
        defaultValue: caseProductsValue,
        onChange: onProductsChange,
      },
      important: {
        value: importantValue,
        defaultValue: importantValue,
        onChange: onImportantChange,
      },
      criticality: {
        value: criticalityValue,
        defaultValue: criticalityValue,
        onChange: onCriticalityChange,
      },
    },
    onFiltersSubmit,
  };
};
