import Box from 'components/Box';
import { cls } from 'core/styles/cls';

const ProgressBar = ({ className, completed, bg }) => {
  return (
    <div
      className={cls(
        'relative h-[10px] max-h-[20px] w-full rounded-[50px] overflow-hidden',
        className
      )}
    >
      <Box
        bg={bg}
        className={
          'absolute block h-full w-full rounded-[inherit] opacity-[0.3]'
        }
      />
      <Box
        bg={bg}
        className={`absolute block h-full rounded-[inherit] opacity-[1]`}
        style={{ width: `${completed}%` }}
      />
    </div>
  );
};

export default ProgressBar;
