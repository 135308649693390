import { AiOutlineArrowUp, AiOutlineArrowDown } from 'react-icons/ai';
import { BiGridVertical, BiLogOutCircle, BiHash } from 'react-icons/bi';
import { BsFillBugFill } from 'react-icons/bs';
import {
  FaRegFileImage,
  FaTable,
  FaFileSignature,
  FaCogs,
  FaChartLine,
} from 'react-icons/fa';
import { FiCheckCircle } from 'react-icons/fi';
import {
  HiUsers,
  HiPlus,
  HiUser,
  HiPencil,
  HiFire,
  HiDotsVertical,
} from 'react-icons/hi';
import { ImBin2 } from 'react-icons/im';
import {
  IoMdSettings,
  IoMdLock,
  IoMdKey,
  IoMdInformationCircle,
} from 'react-icons/io';
import { IoFunnel } from 'react-icons/io5';
import {
  MdOutlineCircle,
  MdDashboard,
  MdNotifications,
  MdSearch,
  MdWarning,
  MdKeyboardArrowLeft,
  MdOutlineStickyNote2,
  MdKeyboardArrowRight,
  MdLocationPin,
  MdClose,
  MdLanguage,
  MdSave,
  MdCalendarToday,
  MdAccessTime,
  MdOutlineLink,
  MdOutlineInsertDriveFile,
  MdRefresh,
} from 'react-icons/md';
import {
  RiComputerLine,
  RiSendPlaneFill,
  RiArrowDropUpLine,
  RiArrowDropDownLine,
  RiToolsFill,
  RiCheckboxCircleLine,
} from 'react-icons/ri';
import { SiAiohttp } from 'react-icons/si';
import { TbExternalLink } from 'react-icons/tb';

import { getColorHex } from 'common/constants/colors';
import { cls } from 'core/styles/cls';

const renderIcon = (name) => {
  switch (name) {
    case 'retry':
      return <MdRefresh />;
    case 'signature':
      return <FaFileSignature />;
    case 'link':
      return <MdOutlineLink />;
    case 'time':
      return <MdAccessTime />;
    case 'hash':
      return <BiHash />;
    case 'fire':
      return <HiFire />;
    case 'info':
      return <IoMdInformationCircle />;
    case 'bug':
      return <BsFillBugFill />;
    case 'file':
      return <MdOutlineInsertDriveFile />;
    case 'check':
      return <FiCheckCircle />;
    case 'calendar':
      return <MdCalendarToday />;
    case 'security-posture':
      return <MdDashboard />;
    case 'ip':
      return <MdLocationPin />;
    case 'incidents':
      return <MdNotifications />;
    case 'details':
      return <MdOutlineStickyNote2 />;
    case 'key':
      return <IoMdKey />;
    case 'domain':
      return <SiAiohttp />;
    case 'host':
      return <RiComputerLine />;
    case 'users':
      return <HiUsers />;
    case 'search':
      return <MdSearch />;
    case 'threats':
      return <MdWarning />;
    case 'response':
      return <RiSendPlaneFill />;
    case 'catalogue':
      return <FaRegFileImage />;
    case 'events':
      return <IoFunnel />;
    case 'asc':
      return <AiOutlineArrowUp />;
    case 'desc':
      return <AiOutlineArrowDown />;
    case 'arrow-up':
      return <RiArrowDropUpLine />;
    case 'arrow-down':
      return <RiArrowDropDownLine />;
    case 'arrow-left':
      return <MdKeyboardArrowLeft />;
    case 'arrow-right':
      return <MdKeyboardArrowRight />;
    case 'settings':
      return <IoMdSettings />;
    case 'redirect':
      return <TbExternalLink />;
    case 'dots':
      return <BiGridVertical />;
    case 'circle':
      return <MdOutlineCircle />;
    case 'plus':
      return <HiPlus />;
    case 'options':
      return <HiDotsVertical />;
    case 'close':
      return <MdClose />;
    case 'table':
      return <FaTable />;
    case 'account':
      return <HiUser />;
    case 'password':
      return <IoMdLock />;
    case 'logout':
      return <BiLogOutCircle />;
    case 'edit':
      return <HiPencil />;
    case 'save':
      return <MdSave />;
    case 'delete':
      return <ImBin2 />;
    case 'language':
      return <MdLanguage />;
    case 'entities':
      return <FaCogs />;
    case 'tools':
      return <RiToolsFill />;
    case 'status':
      return <RiCheckboxCircleLine />;
    case 'statistics':
      return <FaChartLine />;
    default:
      return null;
  }
};

const Icon = ({
  children,
  name,
  className,
  bg,
  color,
  isBoxed,
  isActive,
  size,
  cursor,
  isHidden,
  style,
  ...props
}) => (
  <div
    {...props}
    className={cls(
      'text-2xl flex items-center justify-center',
      {
        'text-base': size === 'small',
        'text-xl': size === 'medium',
        'text-3xl': size === 'large',
      },
      {
        'cursor-pointer': cursor === 'pointer',
        'cursor-grab': cursor === 'move',
      },
      {
        'rounded-lg p-2': isBoxed,
        'flex items-center justify-center bg-primary': isActive,
        hidden: isHidden,
      },
      className
    )}
    style={{
      ...style,
      color: getColorHex(color),
      backgroundColor: getColorHex(bg),
    }}
  >
    {renderIcon(name)} {children}
  </div>
);

export default Icon;
