import { get, post, put, remove } from 'core/api/client';

export const getAlertNotificationConfig = () =>
  get('/alert_notification_config/');

export const getAlertsMonitorConfig = ({ page }) =>
  get('/monitor_config/', { page_size: 6, requested_page: page });

export const updateMonitorConfig = async ({ id, hoursConfig }) => {
  return put(`/monitor_config/${id}`, {
    hours_config: hoursConfig,
  });
};

export const getEmailNotificationGroups = () =>
  get('/email_notification_group/');
export const getEmailNotificationGroupsById = ({ id }) =>
  get(`/email_notification_group/${id}`);

export const postEmailNotification = ({ emails, name, description }) =>
  post('/email_notification_group/', { emails, name, description }, null);

export const deleteEmailNotification = async (id) =>
  remove(`/email_notification_group/${id}`);

export const updateEmailNotification = async ({
  id,
  emails,
  name,
  description,
}) =>
  put(`/email_notification_group/${id}`, {
    emails,
    name,
    description,
  });

export const updateAlertNotificationConfig = async ({
  id,
  enabled,
  throtling,
  emailGroupId,
}) => {
  return put(`/alert_notification_config/${id}`, {
    enabled,
    throtling,
    email_group_id: emailGroupId,
  });
};

export const getCurrentUser = () => get('/users/me');
export const getCurrentLanguage = () => get('/user_profile/current_language');
export const getLanguages = () => get('/language/');
export const getCurrentTenant = () => get('/user_profile/current_tenant');

export const updateCurrentTenant = async ({ tenant }) =>
  put(`/user_profile/current_tenant`, null, {
    tenant,
  });

export const updateCurrentLanguage = async (language) =>
  put(`/user_profile/current_language`, null, {
    language,
  });

export const updateUser = async ({ email, name, lastName }) =>
  put(`/users/me`, {
    email,
    first_name: name,
    last_name: lastName,
  });

export const updateUserPassword = async ({
  currentPassword,
  newPassword,
  retypePassword,
}) =>
  put(`/users/modify_password`, {
    current_pass: currentPassword,
    new_pass: newPassword,
    confirm_new_pass: retypePassword,
  });

export const getResetPassword = async (email) =>
  get('/users/reset_password', {
    email,
  });

export const resetPassword = async ({ token, password, retypePassword }) =>
  put(`/users/reset_password_2`, {
    token,
    email: localStorage.email,
    password,
    confirm_password: retypePassword,
  });
