import React from 'react';

import Box from 'components/Box';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import Text from 'components/Text';
import { useTranslation } from 'core/translations/useTranslation';

import { useVendorsDataQuery } from '../../services';

const IntegrationDetailsModal = ({ vendor, ...props }) => {
  const t = useTranslation();
  const { data } = useVendorsDataQuery({
    vendors: [vendor],
    page: 1,
    tags: [],
  });

  const vendorData = data?.results[0];

  return (
    <Modal
      title={t('automated_response.modal.title', {
        name: vendorData?.name || '',
      })}
      {...props}
    >
      <Box column ycenter className="mt-4 pb-4 gap-3 border-gray">
        <Text subheading>{t('automated_response.modal.description')}</Text>
        <Text body>{vendorData?.description}</Text>
      </Box>
      {!!vendorData?.actions.length && (
        <Box
          column
          ycenter
          className="py-4 border-b border-t gap-3 border-gray"
        >
          <Text subheading>{t('automated_response.modal.actions')}</Text>
          <Box column className="gap-4">
            {vendorData?.actions.map((action) => (
              <Box ycenter className="gap-2">
                <Icon name="check" size="medium" color="success" />
                <Text body>{action}</Text>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Modal>
  );
};

export default IntegrationDetailsModal;
