import CalendarFilter from 'common/components/filters/CalendarFilter';
import Box from 'components/Box';
import Card from 'components/Card';
import Text from 'components/Text';
import { useTranslation } from 'core/translations/useTranslation';
import Layout from 'features/layout';
import EventsStorageTable from 'features/soc-status/components/EventsStorageTable/EventsStorageTable';
import WidgetEventsVolume from 'features/soc-status/components/WidgetEventsVolume/WidgetEventsVolume';
import WidgetEventTypologies from 'features/soc-status/components/WidgetEventTypologies/WidgetEventTypologies';

const SocStatistics = () => {
  const t = useTranslation();

  return (
    <Layout
      icon="statistics"
      title={t('sidebar.soc_sources_statistics')}
      renderHeaderRight={() => <CalendarFilter />}
    >
      <Box column full className="gap-4">
        <Box full className="gap-4 overflow-hidden h-[400px]">
          <Card className="w-full gap-2 p-5">
            <Text subheading>{t('secpos.widgets.events_volume.title')}</Text>
            <WidgetEventsVolume />
          </Card>
          <Card className="w-full gap-2 p-5">
            <Text subheading>{t('soc_status.event_typologies.title')}</Text>
            <WidgetEventTypologies />
          </Card>
        </Box>
        <Card className="w-full gap-4 p-5 !h-fit">
          <Text subheading>{t('soc_statistics.events_storage.title')}</Text>
          <EventsStorageTable />
        </Card>
      </Box>
    </Layout>
  );
};

export default SocStatistics;
