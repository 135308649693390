export const getChartOptions = ({ data }) => ({
  credits: {
    enabled: false,
  },

  chart: {
    type: 'pie',
    plotBackgroundColor: null,
    plotBorderWidth: 0,
    plotShadow: false,
  },

  plotOptions: {
    pie: {
      innerSize: '60%',
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
      cursor: 'pointer',
      allowPointSelect: true,
    },
  },

  legend: {
    enabled: true,
    labelFormatter: function () {
      return this.name + ': </span><b>' + this.y + '<br/>';
    },
    verticalAlign: 'top',
  },

  title: '',

  series: [
    {
      type: 'pie',
      name: 'Incidents',
      colorByPoint: true,
      innerSize: '60%',
      data,
    },
  ],

  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 500,
        },
      },
      {
        condition: {
          minWidth: 500,
          maxWidth: 700,
        },
      },
    ],
  },

  colors: ['#D45A66', '#fbd360', '#4CDFE8', '#a3da8d', '#ffa974', '#dddddd'],
});
