import { toast } from 'react-toastify';

export const useToast = (config) => {
  const showToast = (content, type) => {
    switch (type) {
      case 'success':
        toast.success(content, config);
        break;
      case 'error':
        toast.error(content, config);
        break;
      case 'warning':
        toast.warning(content, config);
        break;
      default:
        toast.info(content, config);
        break;
    }
  };

  return { showToast };
};

export default useToast;
