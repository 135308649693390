import React from 'react';

import { useAllWidgetTypesQuery } from '../services';

export const useSelectSidebarWidget = () => {
  const { data: widgetTypes } = useAllWidgetTypesQuery();

  const [selectedSidebarWidgetId, setSelectedSidebarWidgetId] =
    React.useState(null);

  const selectSidebarWidget = (id) => setSelectedSidebarWidgetId(id);

  const selectedSidebarWidgetType = widgetTypes?.find(
    (type) => type.id === selectedSidebarWidgetId
  );

  const selectedSidebarWidget = selectedSidebarWidgetType && {
    title: selectedSidebarWidgetType.default_title,
    description: selectedSidebarWidgetType.default_description,
    h: selectedSidebarWidgetType.default_height,
    w: selectedSidebarWidgetType.default_width,
    minH: selectedSidebarWidgetType.min_height,
    minW: selectedSidebarWidgetType.min_width,
    i: selectedSidebarWidgetType.id,
    typeId: selectedSidebarWidgetType.id,
    slug: selectedSidebarWidgetType.slug,
    tags: selectedSidebarWidgetType.tags,
    static: selectedSidebarWidgetType.extra_options?.is_static,
    isDraggable: selectedSidebarWidgetType.extra_options?.is_dragable,
    isResizable: selectedSidebarWidgetType.extra_options?.is_resizable,
  };

  return {
    selectSidebarWidget,
    selectedSidebarWidget,
  };
};

export default useSelectSidebarWidget;
