import Image from 'assets/catalogue/not-found.png';
import Box from 'components/Box';
import Card from 'components/Card';
import Icon from 'components/Icon';
import Pagination from 'components/Pagination';
import Skeleton from 'components/Skeleton';
import Tag from 'components/Tag';
import Text from 'components/Text';
import { useTranslation } from 'core/translations/useTranslation';

const CardsLoading = () => {
  return [...Array(6)].map((_, i) => (
    <Box ycenter key={`integrations-card-skeleton-${i}`}>
      <Skeleton containerClassName="w-full" height={200} />
    </Box>
  ));
};

const Integrations = ({
  currentPage,
  onPageChange,
  integrations,
  isLoading,
}) => {
  const t = useTranslation();
  const isEmptyState = !integrations?.results?.length;

  return (
    <Box column between className="h-full min-h-[650px]">
      {isEmptyState && !isLoading ? (
        <Box column xcenter className="w-[400px] self-center" full>
          <img src={Image} alt="not-found" />
          <Box column xcenter className="mt-[-24px] gap-2 text-center">
            <Text color="lightText" subtitle>
              {t('common.no_results')}
            </Text>
            <Text bodyBold className="opacity-50">
              {t('common.search_again')}
            </Text>
          </Box>
        </Box>
      ) : (
        <Box full className="gap-4 grid grid-cols-2 ">
          {isLoading ? (
            <CardsLoading />
          ) : (
            integrations?.results?.map((integration) => {
              return (
                <Card
                  className="min-h-[184px] h-full gap-3 py-4 px-3"
                  key={integration.identifier}
                >
                  <Box full between className="items-start">
                    <Box className="gap-3">
                      <img
                        src={integration.logo}
                        alt="logo"
                        className="w-[64px] h-[64px] object-contain overflow-hidden rounded-lg"
                      />
                      <Box column ycenter className="gap-2">
                        <Box ycenter className="gap-4">
                          <Text bodyBold>
                            {integration.title} {integration.version}
                          </Text>
                          <Box className="gap-2">
                            {integration.tags?.map((tag) => (
                              <>
                                <Tag className="w-fit " status="tag" ellipsis>
                                  {tag}
                                </Tag>
                              </>
                            ))}
                          </Box>
                        </Box>
                        <Text color="lightText" ellipsis>
                          {integration.identifier}
                        </Text>
                      </Box>
                    </Box>

                    <Icon
                      className="cursor-pointer"
                      color="lightText"
                      size="small"
                      name="settings"
                    />
                  </Box>
                  <Text>{integration.description}</Text>
                </Card>
              );
            })
          )}
        </Box>
      )}
      {isLoading ? (
        <Box ycenter className="gap-1 justify-end mt-3">
          <Skeleton width={24} height={24} />
          <Skeleton width={120} height={24} />
          <Skeleton width={24} height={24} />
        </Box>
      ) : (
        <Pagination
          className="justify-end"
          numPages={integrations?.total_pages}
          currentPage={currentPage}
          onPageChange={onPageChange}
        />
      )}
    </Box>
  );
};

export default Integrations;
