import { CACHE_IDS } from 'common/constants/cache';
import { useQuery } from 'core/api/react-query';

import { getAlertNotificationConfig } from '../api';

const sortDataAlphabetically = (data) => {
  return data.sort(function (a, b) {
    if (a?.alert_type?.name < b?.alert_type?.name) {
      return -1;
    }
    if (a?.alert_type?.name > b?.alert_type?.name) {
      return 1;
    }
    return 0;
  });
};

export const useGetAlertNotificationConfigQuery = () => {
  const query = useQuery(
    [CACHE_IDS.ALERT_NOTIFICATION_CONFIG],
    getAlertNotificationConfig,
    {
      select: (result) => sortDataAlphabetically(result),
    }
  );

  return query;
};

export default useGetAlertNotificationConfigQuery;
