import moment from 'moment';

import theme from 'core/styles/theme';
const INTERVAL_HOURS = 3600 * 1000;
const INTERVAL_DAYS = 24 * 3600 * 1000;
export const getChartOptions = ({ minDate, series, rangeDays }) => {
  return {
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      type: 'datetime',
      tickInterval: rangeDays === 1 ? INTERVAL_HOURS : INTERVAL_DAYS,
      min: minDate,
      labels: {
        style: {
          fontSize: theme.fontSize.small,
          color: theme.colors.text,
          fontFamily: 'roboto',
        },
      },
    },
    tooltip: {
      formatter: function () {
        return (
          moment(this.x).format('dddd, MMMM Do YYYY') +
          '<br/>' +
          this.series.name +
          ':' +
          ' ' +
          `<b>${this.point.y}</b>`
        );
      },
    },
    yAxis: {
      title: {
        text: null,
      },
    },
    legend: {
      align: 'center',
      verticalAlign: 'top',
      itemStyle: {
        fontSize: theme.fontSize.base,
        color: theme.colors.text,
        fontFamily: 'roboto',
        fontWeight: theme.fontWeight.regular,
      },
    },
    plotOptions: {
      series: {
        marker: {
          enabled: series?.length > 1 ? false : true,
        },
        pointWidth: 2000,
      },
    },
    chart: {
      type: series?.length > 1 ? 'column' : 'areaspline',
    },

    colors: ['#4CDFE8', '#ffa974', '#D45A66', '#fbd360', '#00B929', '#1f3740'],
    series,
  };
};
