import { CACHE_IDS } from 'common/constants/cache';
import { useQuery } from 'core/api/react-query';

import { getCurrentTenant } from '../api';

export const useCurrentTenantQuery = () => {
  const query = useQuery([CACHE_IDS.TENANT_USER], getCurrentTenant);

  return query;
};

export default useCurrentTenantQuery;
