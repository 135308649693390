import React from 'react';

import { useLocation } from 'react-router';

import { useToggler } from 'common/hooks';
import { usePrevious } from 'common/hooks/usePrevious';
import useWindowDimensions from 'common/hooks/useWindowDimensions';
import Account from 'components/Account';
import Box from 'components/Box';
import Icon from 'components/Icon';
import Logo from 'components/Logo';
import { cls } from 'core/styles/cls';
import { useTranslation } from 'core/translations/useTranslation';
import { useCurrentUserQuery } from 'features/profile/services';
import { useCurrentTenantQuery } from 'features/profile/services/useCurrentTenantQuery';

import { useSidebar } from '../../hooks/useSidebar';
import DropdownUser from './components/DropdownUser';
import { Item } from './components/Item';
import { ItemDropdown } from './components/ItemDropdown';
import ModalChangeTenant from './components/ModalChangeTenant';
import { SocStatusDropdown } from './components/SocStatusDropdown';

const useSidebarAutoCollapse = () => {
  const { isSidebarCollapsed, collapseSidebar: toggleSidebarSize } =
    useSidebar();
  const { width } = useWindowDimensions();
  const previousWidth = usePrevious(width);

  const hasScreenWidthChanged = width !== previousWidth;
  const shouldCollapse = !isSidebarCollapsed && width < 1024;
  const shouldExpand = isSidebarCollapsed && width >= 1024;

  React.useEffect(() => {
    if (hasScreenWidthChanged && (shouldCollapse || shouldExpand)) {
      toggleSidebarSize();
    }
  }, [hasScreenWidthChanged, shouldCollapse, shouldExpand, toggleSidebarSize]);
};

const Sidebar = ({ className }) => {
  const t = useTranslation();

  const { isShown: isAccountDetailShown, toggle: toggleAccountDetail } =
    useToggler();
  const { isShown: isModalTenantShown, toggle: toggleModalTenant } =
    useToggler();
  const { isSidebarCollapsed, collapseSidebar } = useSidebar();
  const { pathname } = useLocation();
  const { data: currentUser } = useCurrentUserQuery();
  const { data: tenant } = useCurrentTenantQuery();

  useSidebarAutoCollapse();

  return (
    <Box
      column
      className={cls(
        'h-screen sticky top-0 left-0 z-[0] min-w-[280px] justify-between bg-secondary p-4',
        {
          'w-fit min-w-[0px]': isSidebarCollapsed,
          'overflow-y-auto overflow-x-hidden': window.innerHeight <= 768,
        },
        className
      )}
    >
      <Box
        className={cls(
          'mb-5 w-full',
          isSidebarCollapsed ? 'justify-center' : 'justify-between'
        )}
      >
        <Logo small={!isSidebarCollapsed} />
        {!isSidebarCollapsed && (
          <Icon
            onClick={collapseSidebar}
            name="arrow-left"
            color="white"
            cursor="pointer"
          />
        )}
      </Box>
      <Box between column className="h-[88%]">
        <Box column>
          <Item icon="security-posture" isActive={pathname === '/'} to="/">
            {t('sidebar.security_posture')}
          </Item>
          <Item
            icon="incidents"
            isActive={pathname.includes('/incidents')}
            to="/incidents"
          >
            {t('sidebar.incidents')}
          </Item>
          <Item
            isActive={pathname.includes('/entities')}
            to="/entities"
            icon="entities"
          >
            {t('sidebar.entities')}
          </Item>
          <ItemDropdown
            icon="response"
            toggleCollapse={collapseSidebar}
            items={[
              {
                text: t('sidebar.automated_response'),
                pathname: '/automated-response',
                icon: 'settings',
              },
              {
                text: t('sidebar.catalogue'),
                pathname: '/catalogue',
                icon: 'catalogue',
              },
            ]}
          />
          <SocStatusDropdown
            icon="events"
            toggleCollapse={collapseSidebar}
            items={[
              {
                text: t('sidebar.soc_engine'),
                pathname: '/soc-engine',
                icon: 'tools',
              },
              {
                text: t('sidebar.soc_sources_statistics'),
                pathname: '/soc-statistics',
                icon: 'statistics',
              },
              {
                text: t('sidebar.soc_sources_status'),
                pathname: '/soc-status',
                icon: 'status',
              },
            ]}
          />
        </Box>
        {isSidebarCollapsed && (
          <Box
            className="absolute top-1/2 left-[88px] h-[64px] rounded-md bg-secondary -translate-y-1/2"
            ycenter
            xcenter
          >
            <Icon
              name="arrow-right"
              color="white"
              cursor="pointer"
              onClick={collapseSidebar}
            />
          </Box>
        )}
        <Box>
          {!!isModalTenantShown && (
            <ModalChangeTenant
              closeModal={toggleModalTenant}
              tenants={currentUser?.tenants}
              currentTenant={tenant}
            />
          )}
          {!!isAccountDetailShown && currentUser && (
            <DropdownUser
              toggleModalTenant={toggleModalTenant}
              closeDropdown={toggleAccountDetail}
              user={currentUser}
              tenant={tenant}
            />
          )}
          {currentUser && (
            <Account
              avatar={currentUser.avatar}
              isWhite
              name={currentUser.first_name}
              lastName={currentUser.last_name}
              company={tenant}
              isSidebarCollapsed={isSidebarCollapsed}
              onClick={toggleAccountDetail}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
