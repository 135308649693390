import Tooltip from 'components/Tooltip';

const SortIndicator = ({ tooltipAsc, tooltipDesc, orderBy, id }) => {
  return (
    <>
      {orderBy === 'asc' ? (
        <Tooltip id={id}>{tooltipAsc}</Tooltip>
      ) : (
        <Tooltip id={id}>{tooltipDesc}</Tooltip>
      )}
    </>
  );
};

export default SortIndicator;
