import { useSelector } from 'react-redux';

import { CACHE_IDS } from 'common/constants/cache';
import { WIDGET_TYPES_SLUGS } from 'common/constants/widgets';
import { useQuery } from 'core/api/react-query';
import { selectDateRange } from 'redux/filter/selectors';

import { getIncidentTimeline } from '../../api';

export const useIncidentTimelineQuery = (filters) => {
  const { dateFrom, dateTo } = useSelector(selectDateRange);

  const query = useQuery(
    [
      CACHE_IDS.WIDGET_DATA,
      { slug: WIDGET_TYPES_SLUGS.INCIDENT_TIMELINE, dateTo, dateFrom, filters },
    ],
    () =>
      getIncidentTimeline({
        dateFrom,
        dateTo,
        case_type: [],
        cases_id: filters?.cases || [],
      })
  );
  return query;
};
