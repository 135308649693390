import { useSelector } from 'react-redux';

import { CACHE_IDS } from 'common/constants/cache';
import { WIDGET_TYPES_SLUGS } from 'common/constants/widgets';
import { useQuery } from 'core/api/react-query';
import { selectDateRange } from 'redux/filter/selectors';

import { getEventsVolume } from '../api';

const mapper = (data) => {
  const keys = Object.keys(data);
  return keys
    .map((key) => ({
      name: key,
      y: data[key],
    }))
    .sort((a, b) => b.y - a.y);
};

export const useEventsVolumeQuery = () => {
  const { dateFrom, dateTo } = useSelector(selectDateRange);
  const query = useQuery(
    [
      CACHE_IDS.WIDGET_DATA,
      { slug: WIDGET_TYPES_SLUGS.EVENTS_VOLUME, dateTo, dateFrom },
    ],
    () =>
      getEventsVolume({
        dateFrom,
        dateTo,
        sort_by: 'count',
        order_by: 'desc',
      }),
    {
      select: (result) => mapper(result),
    }
  );
  return query;
};

export default useEventsVolumeQuery;
