import { useParams } from 'react-router';

import DetailsHeading from 'common/components/details/DetailsHeading';
import DetailsSection from 'common/components/details/DetailsSection';
import InvolvedCases from 'common/components/details/InvolvedCases';
import Overview from 'common/components/details/Overview';
import RelatedEntities from 'common/components/details/RelatedEntities';
import ErrorNotFound from 'common/components/errors/ErrorNotFound';
import WidgetCasesDistribution from 'common/components/widgets//WidgetCasesDistribution';
import WidgetInvolvedCases from 'common/components/widgets//WidgetInvolvedCases';
import Box from 'components/Box';
import Icon from 'components/Icon';
import Text from 'components/Text';
import { useTranslation } from 'core/translations/useTranslation';
import Layout from 'features/layout';

import { useEntitiesDetailsQuery } from '../../services/useEntitiesDetailsQuery';

const DetailsEntities = () => {
  const t = useTranslation();

  const { id, type } = useParams();
  const { data, isLoading, isError } = useEntitiesDetailsQuery({
    id,
    type,
  });
  const typeInfo = {
    ADDRESS: {
      icon: 'ip',
      title: t('sidebar.addresses'),
      color: 'danger',
    },
    HOSTNAME: {
      icon: 'host',
      title: t('sidebar.hosts'),
      color: 'primary',
    },
    DestinationURL: {
      icon: 'link',
      title: t('sidebar.destinationsURL'),
      color: 'primary',
    },
    FILENAME: {
      icon: 'file',
      title: t('sidebar.filenames'),
      color: 'darkBlue',
    },
    FILEHASH: {
      icon: 'hash',
      title: t('sidebar.filenames'),
      color: 'lightText',
    },
    THREATSIGNATURE: {
      icon: 'threats',
      title: t('sidebar.threatsignatures'),
      color: 'warning',
    },
    DOMAIN: {
      icon: 'domain',
      title: t('sidebar.domains'),
      color: 'primary',
    },
    USERUNIQNAME: {
      icon: 'users',
      title: t('sidebar.users'),
      color: 'error',
    },
  };
  const entityTypeInfo = typeInfo[type];

  return (
    <Layout
      title={entityTypeInfo.title}
      icon={entityTypeInfo.icon}
      renderHeaderLeft={() => (
        <Box className="gap-2">
          <Icon name="arrow-right" />
          <Text title>{t('details.title')}</Text>
        </Box>
      )}
    >
      <Box full column className="h-full gap-4">
        {isError ? (
          <ErrorNotFound />
        ) : (
          <>
            <DetailsHeading
              title={entityTypeInfo.title}
              icon={entityTypeInfo.icon}
              color={entityTypeInfo.color}
              value={id}
            />
            <DetailsSection title={t('details.overview.title')}>
              <Overview id={id} data={data} isLoading={isLoading} />
            </DetailsSection>
            <Box className="grid grid-cols-5 gap-4 xl:grid-cols-3">
              <DetailsSection
                title={t('details.involved_incident_widget.title')}
                className="col-span-2 xl:col-span-1"
              >
                <WidgetCasesDistribution data={data} isLoading={isLoading} />
              </DetailsSection>
              <DetailsSection
                title={t('details.involved_incident_timeline_widget.title')}
                className="col-span-3 xl:col-span-2"
              >
                <WidgetInvolvedCases data={data} isLoading={isLoading} />
              </DetailsSection>
              <DetailsSection
                title={t('details.related_entities.title')}
                className="col-span-2 xl:col-span-1"
              >
                <RelatedEntities data={data} isLoading={isLoading} />
              </DetailsSection>
              <DetailsSection
                title={t('details.involved_cases.title')}
                className="col-span-3 xl:col-span-2"
              >
                <InvolvedCases data={data} isLoading={isLoading} />
              </DetailsSection>
            </Box>
          </>
        )}
      </Box>
    </Layout>
  );
};

export default DetailsEntities;
