import { useToggler } from 'common/hooks';
import Box from 'components/Box/Box.jsx';
import { useDashboard } from 'features/dashboard/hooks';

import { TabNameInput } from './TabNameInput';
import TabNameText from './TabNameText';
import TabOptions from './TabOptions';

export const Tab = ({
  tab,
  isSelected,
  onDelete,
  totalTabs,
  onRename,
  showDeleteModal,
}) => {
  const { layout } = useDashboard();

  const { isShown: isEditingName, toggle: toggleEditName } = useToggler();
  const { name, id, tab_position: position } = tab;

  const renameTab = (newName) => onRename({ id, name: newName, position });
  const deleteTab = () => {
    if (layout?.length) return showDeleteModal();
    onDelete(id);
  };

  return (
    <Box ycenter full between className="pl-2">
      <Box full data-tip data-for={name}>
        {!isEditingName ? (
          <TabNameText isSelected={isSelected} name={name} />
        ) : (
          <TabNameInput
            name={name}
            toggleEdit={toggleEditName}
            onRename={renameTab}
          />
        )}
      </Box>
      <TabOptions
        totalTabs={totalTabs}
        onDelete={deleteTab}
        onEdit={toggleEditName}
        isSelected={isSelected}
      />
    </Box>
  );
};
