import NotFound from 'assets/security-posture/not-found.png';
import Box from 'components/Box';
import Text from 'components/Text';
import { useTranslation } from 'core/translations/useTranslation';

const ContentEmptyState = () => {
  const t = useTranslation();

  return (
    <Box className="gap-3 pt-[150px]" column xcenter ycenter>
      <img width="80px" src={NotFound} alt="sidebar__empty" />
      <Text subtitle>{t('secpos.sidebar.empty_state')}</Text>
    </Box>
  );
};

export default ContentEmptyState;
