import { useQuery } from 'react-query';

import { CACHE_IDS } from 'common/constants/cache';

import { getCaseDetailsIncidentLogs } from '../../api';

export const useIncidentLogsDataQuery = ({ id }) => {
  const query = useQuery([CACHE_IDS.INCIDENT_LOGS, { id }], () =>
    getCaseDetailsIncidentLogs({ id })
  );

  return query;
};
