import { NumberParam, useQueryParam } from 'use-query-params';

export const usePagination = (page = 'page') => {
  const [currentPage = 1, setCurrentPage] = useQueryParam(page, NumberParam);

  const onPageChange = (page, urlMethod = 'pushIn') => {
    setCurrentPage(page, urlMethod);
  };

  const cleanup = () => {
    setCurrentPage(undefined, 'replaceIn');
  };

  return { currentPage, onPageChange, cleanup };
};
