import { useQuery } from 'react-query';

import { CACHE_IDS } from 'common/constants/cache';

import { getDashboards } from '../api';

export const useDashboardsQuery = () => {
  const query = useQuery([CACHE_IDS.DASHBOARDS], getDashboards);

  return query;
};

export default useDashboardsQuery;
