import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { CACHE_IDS } from 'common/constants/cache';
import { WIDGET_TYPES_SLUGS } from 'common/constants/widgets';
import { useTranslation } from 'core/translations/useTranslation';
import { selectDateRange } from 'redux/filter/selectors';

import { getCasesByCriticityTimeline } from '../api';

const getCriticityText = (value, t) => {
  if (value === 60) {
    return t('common.medium').toUpperCase();
  } else if (value === 80) {
    return t('common.high').toUpperCase();
  } else if (value === 100) {
    return t('common.critical').toUpperCase();
  }
  return t('common.low').toUpperCase();
};

const mapperData = (data, t) => {
  const formatData = data?.series
    ?.sort((a, b) => b.priority - a.priority)
    .map((item) => {
      return { data: item.data, name: getCriticityText(item.priority, t) };
    });
  return { ...data, series: formatData };
};

export const useCasesByCriticityTimelineQuery = () => {
  const t = useTranslation();
  const { dateFrom, dateTo } = useSelector(selectDateRange);

  const query = useQuery(
    [
      CACHE_IDS.WIDGET_DATA,
      {
        slug: WIDGET_TYPES_SLUGS.CASES_BY_CRITICITY_TIMELINE,
        dateFrom,
        dateTo,
      },
    ],
    () =>
      getCasesByCriticityTimeline({
        dateFrom,
        dateTo,
      }),
    {
      select: (data) => {
        return mapperData(data, t);
      },
    }
  );
  return query;
};
