import React from 'react';

import { getColorHex } from 'common/constants/colors';
import { useToggler } from 'common/hooks';
import Icon from 'components/Icon';
import { cls } from 'core/styles/cls';

import Box from '../Box';
import Text from '../Text';

const Chip = ({
  className,
  id,
  children,
  onClick,
  disabled,
  bg,
  color,
  style,
  onDelete,
  ...props
}) => {
  const { isShown: isActive, toggle: toggleIsActive } = useToggler();

  return (
    <Box
      {...props}
      onClick={() => {
        if (disabled) return;
        if (onClick) {
          onClick({ id, isActive: !isActive });
        }

        toggleIsActive();
      }}
      className={cls(
        'cursor-pointer rounded-md border-[1px] border-gray py-1 px-2 transition-[0.3s]',
        {
          'border-[1px] border-primary bg-primary': isActive,
          'cursor-default': disabled,
        },
        className
      )}
      style={{
        ...style,
        color: getColorHex(color),
        backgroundColor: getColorHex(bg),
      }}
    >
      <Box ycenter className="gap-2">
        <Text color={isActive ? 'white' : 'primary'}>{children}</Text>
        {onDelete && (
          <Icon
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
            cursor="pointer"
            name="close"
            size="small"
            color="lightText"
          />
        )}
      </Box>
    </Box>
  );
};

export default Chip;
