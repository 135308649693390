import React from 'react';

import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import Image from 'assets/login/login-welcome.png';
import Logo from 'assets/login/vsoc-logo.png';
import { useToast } from 'common/hooks/toasts';
import Box from 'components/Box';
import Button from 'components/Button';
import Input from 'components/Input';
import Text from 'components/Text';
import { Cookie } from 'core/cookies';
import { useTranslation } from 'core/translations/useTranslation';

import { useLoginMutation } from '../../services';

const Login = () => {
  const navigate = useNavigate();
  const t = useTranslation();

  const { showToast } = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' });
  const token = Cookie.get('token');

  React.useEffect(() => {
    // if we enter this page when logged in we redirect to /
    if (!!token) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const { mutate: login, isLoading } = useLoginMutation({
    onSuccess: () => {
      showToast(t('login.success_toast'), 'success');
      navigate('/');
    },
    onError: () => {
      showToast(t('login.error_toast'), 'error');
    },
  });

  const submit = (values) => {
    const { username, password } = values;

    login({ username, password });
  };

  return (
    <Box xcenter className="h-screen gap-9 bg-[#1a2035] lg:gap-5">
      <Box className="hidden h-full lg:flex lg:items-center lg:justify-center">
        <img src={Image} className="h-full" alt="Welcome" />
      </Box>
      <form
        className="flex w-full max-w-[400px] flex-col items-center justify-center p-3"
        onSubmit={handleSubmit(submit)}
      >
        <Box column className="mb-5 gap-3">
          <Box className="gap-2 self-center lg:self-start" ycenter>
            <Box>
              <img className="w-[48px] object-contain" src={Logo} alt="logo" />
            </Box>
            <Box column>
              <Text logo color="white">
                {t('login.title')}
              </Text>
              <Text logoBold color="white">
                VSOC
              </Text>
            </Box>
          </Box>
          <Box>
            <Text body color="white" className="text-center lg:text-left">
              {t('login.description')}
            </Text>
          </Box>
        </Box>
        <Box column className="text-300 mb-3 w-full">
          <Input
            {...register('username', {
              required: t('validations.required'),
            })}
            error={errors.username?.message}
            placeholder={t('common.email')}
            iconL="account"
            iconColor="secondary"
          />

          <Input
            {...register('password', {
              required: t('validations.required'),
            })}
            error={errors.password?.message}
            type="password"
            placeholder={t('common.password')}
            iconL="password"
            iconColor="secondary"
          />
          <Box
            onClick={() => navigate('/forgot-password')}
            className="self-end mb-2 cursor-pointer"
          >
            <Text color="white">{t('login.forgot_password')}</Text>
          </Box>
        </Box>
        <Button
          loading={isLoading}
          disabled={!isValid}
          type="submit"
          className="w-full"
        >
          {t('common.continue')}
        </Button>
      </form>
    </Box>
  );
};

export default Login;
