import { CACHE_IDS } from 'common/constants/cache';
import { useQuery } from 'core/api/react-query';

import { getAllWidgetTypes } from '../api';

export const useAllWidgetTypesQuery = (activeChips) => {
  const query = useQuery([CACHE_IDS.WIDGET_TYPE, { activeChips }], () =>
    getAllWidgetTypes(activeChips)
  );

  return query;
};

export default useAllWidgetTypesQuery;
