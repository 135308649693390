import React from 'react';

import useOnOutsideClick from 'common/hooks/useOnOutsideClick';
import { cls } from 'core/styles/cls';

import Box from '../Box';
import Item from './Item';

const Dropdown = ({ closeDropdown, className, children, style }) => {
  const boxRef = React.createRef();
  useOnOutsideClick(closeDropdown, boxRef);
  return (
    <Box
      column
      ref={boxRef}
      className={cls(
        'absolute rounded-md border-[1px] border-gray bg-white p-3',
        className
      )}
      style={style}
    >
      {children}
    </Box>
  );
};

Dropdown.Item = Item;

export default Dropdown;
