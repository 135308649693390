import { post } from 'core/api';

export const getTopDetectedUsers = async ({
  dateFrom,
  dateTo,
  limit,
  case_type,
}) =>
  post('/get_widget_data/top_detected_users', {
    date_from: dateFrom,
    date_to: dateTo,
    limit,
    case_type,
  });

export const getTopDetectedHost = async ({
  dateFrom,
  dateTo,
  limit,
  case_type,
}) =>
  post('/get_widget_data/top_detected_hosts', {
    date_from: dateFrom,
    date_to: dateTo,
    limit,
    case_type,
  });

export const getIncidentTypologies = async ({
  dateFrom,
  dateTo,
  sort_by,
  order_by,
}) =>
  post(
    '/get_widget_data/incident_typologies',
    {
      date_from: dateFrom,
      date_to: dateTo,
      case_type: [],
      cases_id: [],
    },
    { sort_by, order_by }
  );

export const getIncidentTimeline = async ({ dateFrom, dateTo }) =>
  post('/get_widget_data/incident_timeline', {
    date_from: dateFrom,
    date_to: dateTo,
    case_type: [],
    cases_id: [],
  });

export const getTopReportingProducts = async ({
  dateFrom,
  dateTo,
  sort_by,
  order_by,
}) =>
  post('/get_widget_data/get_top_reporting_products', {
    date_from: dateFrom,
    date_to: dateTo,
    sort_by,
    order_by,
  });

export const getMalwareTypes = async ({
  dateFrom,
  dateTo,
  sort_by,
  order_by,
}) =>
  post(
    '/get_widget_data/get_malware_types',
    {
      date_from: dateFrom,
      date_to: dateTo,
    },
    { sort_by, order_by }
  );

export const getSummary = async ({ dateFrom, dateTo }) =>
  post('/get_widget_data/summary', {
    date_from: dateFrom,
    date_to: dateTo,
  });

export const getQuickListIncidents = async ({
  dateFrom,
  dateTo,
  page,
  sort_by,
  order_by,
}) =>
  post(
    '/get_widget_data/quick_list',
    {
      date_from: dateFrom,
      date_to: dateTo,
      page_size: 10,
      requested_page: page,
    },
    { sort_by, order_by }
  );

export const getUrlMalicious = async ({
  dateFrom,
  dateTo,
  page,
  sort_by,
  order_by,
}) =>
  post(
    '/get_widget_data/malicious_urls',
    {
      date_from: dateFrom,
      date_to: dateTo,
      page_size: 10,
      requested_page: page,
    },
    { sort_by, order_by }
  );

export const getFilenamesMalicious = async ({
  dateFrom,
  dateTo,
  page,
  sort_by,
  order_by,
}) =>
  post(
    '/get_widget_data/malicious_filenames',
    {
      date_from: dateFrom,
      date_to: dateTo,
      page_size: 10,
      requested_page: page,
    },
    { sort_by, order_by }
  );

export const getFilehashsMalicious = async ({
  dateFrom,
  dateTo,
  page,
  sort_by,
  order_by,
}) =>
  post(
    '/get_widget_data/malicious_filehashs',
    {
      date_from: dateFrom,
      date_to: dateTo,
      page_size: 10,
      requested_page: page,
    },
    { sort_by, order_by }
  );

export const getMaliciousIpsSources = async ({
  dateFrom,
  dateTo,
  limit,
  sort_by,
  order_by,
}) =>
  post(
    '/get_widget_data/malicious_ips_sources',
    {
      date_from: dateFrom,
      date_to: dateTo,
      limit,
    },
    { sort_by, order_by }
  );

export const getMaliciousIpsDestinations = async ({
  dateFrom,
  dateTo,
  limit,
  sort_by,
  order_by,
}) =>
  post(
    '/get_widget_data/malicious_ips_destinations',
    {
      date_from: dateFrom,
      date_to: dateTo,
      limit,
    },
    { sort_by, order_by }
  );

export const getSignatureList = async ({
  dateFrom,
  dateTo,
  page,
  sort_by,
  order_by,
}) =>
  post(
    '/get_widget_data/threat_signatures',
    {
      date_from: dateFrom,
      date_to: dateTo,
      page_size: 10,
      requested_page: page,
    },
    { sort_by, order_by }
  );

export const getTotalEventTimeline = async ({ dateFrom, dateTo }) =>
  post('/get_widget_data/timeline_all_events_data', {
    date_from: dateFrom,
    date_to: dateTo,
  });

export const getTotalEventsTypologiesTimeline = async ({ dateFrom, dateTo }) =>
  post('/get_widget_data/timeline_events_data_typologies', {
    date_from: dateFrom,
    date_to: dateTo,
  });

export const getTotalEventsTypologies = async ({
  dateFrom,
  dateTo,
  sort_by,
  order_by,
}) =>
  post(
    '/get_widget_data/events_data_typologies',
    {
      date_from: dateFrom,
      date_to: dateTo,
    },
    { sort_by, order_by }
  );

export const getTotalEvents = async ({ dateFrom, dateTo }) =>
  post('/get_widget_data/all_events_data', {
    date_from: dateFrom,
    date_to: dateTo,
  });

export const getNotifiedCases = async ({ dateFrom, dateTo }) =>
  post('/get_widget_data/notified_cases', {
    date_from: dateFrom,
    date_to: dateTo,
  });

export const getCasesByCriticity = async ({ dateFrom, dateTo }) =>
  post('/get_widget_data/get_cases_by_criticity', {
    date_from: dateFrom,
    date_to: dateTo,
    case_type: [],
    cases_id: [],
  });

export const getCasesByCriticityTimeline = async ({ dateFrom, dateTo }) =>
  post('/get_widget_data/get_cases_by_criticity_timeline', {
    date_from: dateFrom,
    date_to: dateTo,
    case_type: [],
    cases_id: [],
  });

export const getEventsTypologiesCount = async ({ dateFrom, dateTo, page }) =>
  post('/get_widget_data/get_events_data_typologies_count', {
    date_from: dateFrom,
    date_to: dateTo,
    page_size: 10,
    requested_page: page,
  });

export const getCriticalityOfIncidents = async ({ dateFrom, dateTo }) =>
  post('/get_widget_data/get_cases_by_criticy_spiderweb_chart', {
    date_from: dateFrom,
    date_to: dateTo,
    case_type: [],
    cases_id: [],
  });
