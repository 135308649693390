import React from 'react';

import EmptyStateWidgets from 'common/components/widgets/EmptyStateWidgets';
import { getNumberAbbreviation } from 'common/utils/getNumberAbbreviation';
import Chart from 'components/Chart';
import Skeleton from 'components/Skeleton';
import { useEventsDataTypologiesQuery } from 'features/soc-status/services';

import { useGetChartOptions } from './chart';

const WidgetEventTypologies = () => {
  const { data: widgetData, isLoading } = useEventsDataTypologiesQuery();
  const getChartOptions = useGetChartOptions();

  const totalEventsData = widgetData?.reduce((accumulator, object) => {
    return accumulator + object.y;
  }, 0);

  const options = React.useMemo(
    () =>
      getChartOptions({
        data: widgetData,
        allEventsData: getNumberAbbreviation(totalEventsData),
      }),
    [widgetData, totalEventsData, getChartOptions]
  );
  return (
    <>
      {isLoading ? (
        <Skeleton radius={'10px'} />
      ) : widgetData && totalEventsData > 0 ? (
        <Chart
          options={options}
          containerProps={{ style: { width: '100%', height: '400px' } }}
        />
      ) : (
        <EmptyStateWidgets />
      )}
    </>
  );
};

export default WidgetEventTypologies;
