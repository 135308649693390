import CalendarFilter from 'common/components/filters/CalendarFilter';
import Box from 'components/Box';
import { useTranslation } from 'core/translations/useTranslation';
import Layout from 'features/layout';

import Tabs from '../../components/Tabs';

const Entities = () => {
  const t = useTranslation();
  return (
    <Layout
      icon="entities"
      title={t('sidebar.entities')}
      renderHeaderRight={() => <CalendarFilter />}
    >
      <Box column className="gap-4 overflow-hidden">
        <Tabs />
      </Box>
    </Layout>
  );
};

export default Entities;
