import { Link } from 'react-router-dom';

import Box from 'components/Box';
import Text from 'components/Text';
import { cls } from 'core/styles/cls';

const LinkComponent = ({ to, children }) => {
  if (to) {
    return <Link to={to}>{children}</Link>;
  }
  return <>{children}</>;
};

const Item = ({ children, top, to, className, onClick }) => {
  return (
    <LinkComponent to={to}>
      <Box
        className={cls('border-b-[1px] border-gray py-2 mb-1', className, {
          'border-none pb-0': top,
        })}
        onClick={onClick}
      >
        <Text cursor className="hover:text-primary">
          {children}
        </Text>
      </Box>
    </LinkComponent>
  );
};

export default Item;
