import React from 'react';

import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import Exporting from 'highcharts/modules/exporting';
import solidGauge from 'highcharts/modules/solid-gauge';

import { useDashboard } from '../../dashboard/hooks/useDashboard';

highchartsMore(Highcharts);
solidGauge(Highcharts);
Exporting(Highcharts);

const useWidgetChart = (id) => {
  const { layout } = useDashboard();
  const widget = layout?.find((widget) => widget.i === id);
  const ref = React.useRef(null);
  const chart = ref.current?.chart;

  React.useEffect(() => {
    if (chart) {
      chart.reflow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widget?.w, widget?.h]);

  const props = {
    containerProps: { style: { width: '100%', height: '100%' } },
    highcharts: Highcharts,
    ref,
  };

  return { props, chart };
};

export default useWidgetChart;
