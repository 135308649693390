import React from 'react';

import { DateRange } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';

import Box from 'components/Box';
import Button from 'components/Button';
import { cls } from 'core/styles/cls';
import i18n from 'core/translations/init';
import { useTranslation } from 'core/translations/useTranslation';

const localeCodeByI18nLang = {
  'es-ES': 'es',
  en: 'enGB',
  it: 'it',
};

const Calendar = ({
  className,
  onSubmit,
  dateFrom,
  dateTo,
  shouldDisableFutureDates,
}) => {
  const t = useTranslation();
  const localeCode = localeCodeByI18nLang[i18n.language];

  const [range, setRange] = React.useState({
    startDate: dateFrom,
    endDate: dateTo,
  });

  return (
    <Box
      column
      className={cls(
        'absolute mt-7 right-6 z-20 rounded-lg border-gray border-[1px] overflow-hidden',
        className
      )}
    >
      <DateRange
        locale={locales[localeCode]}
        showDateDisplay={false}
        onChange={({ range1: { startDate, endDate } }) =>
          setRange({ startDate, endDate })
        }
        ranges={[range]}
        maxDate={shouldDisableFutureDates && new Date()}
        rangeColors={['#1e93be']}
      />
      <Box className="bg-white p-3 justify-end -mt-4">
        <Button small onClick={() => onSubmit(range)}>
          {t('common.apply')}
        </Button>
      </Box>
    </Box>
  );
};

export default Calendar;
