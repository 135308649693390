import React from 'react';

import { useQueryClient } from 'react-query';

import { CACHE_IDS } from 'common/constants/cache';
import { useHandleError } from 'common/hooks/errors';
import { useToast } from 'common/hooks/toasts';
import { useTranslation } from 'core/translations/useTranslation';

import { addWidget, removeWidget, updateWidget } from '../api';
import { useDashboard } from './useDashboard';
import { useEditMode, LOCAL_STORAGE_LAYOUT_BACKUP } from './useEditMode';

export const useSaveChanges = () => {
  const t = useTranslation();
  const { handleError } = useHandleError();
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  const { dashboardId, removedWidgets, addedWidgets, updatedWidgets } =
    useDashboard();
  const { disableEditMode } = useEditMode();
  const [isLoading, setIsLoading] = React.useState(false);

  const saveChanges = async () => {
    setIsLoading(true);
    const initialLayout = JSON.parse(localStorage[LOCAL_STORAGE_LAYOUT_BACKUP]);
    try {
      const addedWidgetsResponses = await Promise.all(
        addedWidgets.map((widget) =>
          addWidget({ id: widget.i, dashboardId, x: widget.x, y: widget.y })
        )
      );
      const removedWidgetsResponses = await Promise.all(
        removedWidgets.map((widget) => removeWidget(widget.i, dashboardId))
      );
      await Promise.all(
        updatedWidgets.map((widget) => {
          const addedWidget = addedWidgetsResponses?.find(
            (addedWidget) => addedWidget.widget_type?.id === widget.i
          );
          const removedWidget = removedWidgetsResponses?.find(
            (removedWidget) => removedWidget.widget_type?.id === widget.i
          );
          const initialLayoutWidget = initialLayout?.find(
            (initialLayoutWidget) => initialLayoutWidget.slug === widget.slug
          );

          const id =
            addedWidget?.id ||
            removedWidget?.id ||
            initialLayoutWidget?.i ||
            widget.i;

          return updateWidget({
            id: id,
            dashboardId,
            x: widget.x,
            y: widget.y,
            w: widget.w,
            h: widget.h,
          });
        })
      );
      queryClient.invalidateQueries([CACHE_IDS.SECURITY_POSTURE]);
      if (
        addedWidgets.length ||
        removedWidgets.length ||
        updatedWidgets.length
      ) {
        showToast(t('toast.success'), 'success');
      }
      disableEditMode();
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };
  return { saveChanges, isLoading };
};

export default useSaveChanges;
