import React, { useState } from 'react';

import { useForm } from 'react-hook-form';

import { useToast } from 'common/hooks/toasts';
import Box from 'components/Box';
import Button from 'components/Button';
import Chip from 'components/Chip';
import Input from 'components/Input';
import Modal from 'components/Modal';
import Text from 'components/Text';
import Textarea from 'components/Textarea';
import { useTranslation } from 'core/translations/useTranslation';
import {
  useGetEmailNotificationGroupById,
  useUpdateEmailNotificationMutation,
} from 'features/profile/services';

const EditGroupModal = ({ closeModal, id }) => {
  const t = useTranslation();
  const { showToast } = useToast();
  const { data: emailDataById } = useGetEmailNotificationGroupById({ id });
  const [emails, setEmails] = useState(emailDataById?.emails || []);
  const [textErrorSameEmail, setTextErrorSameEmail] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    getFieldState,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'onChange' });
  const email = watch('email');
  const errorFields = Object.keys(errors);

  const isValid =
    !(errorFields.includes('name') || errorFields.includes('description')) &&
    emails?.length;

  const { mutate: updateEmail } = useUpdateEmailNotificationMutation({
    onSuccess: () => {
      showToast(t('settings.notification.update_email_success'), 'success');
    },
    onError: () => {
      showToast(t('toast.error.error_500'), 'error');
    },
  });

  const submit = (values) => {
    const { name, description } = values;

    updateEmail({ id, emails, name, description });
    closeModal();
  };

  const onEmailEnter = (e) => {
    if (getFieldState('email').error) return;
    if (!email?.length) return;
    if (emails.includes(email)) return setTextErrorSameEmail(true);
    if (e.key === 'Enter' || e.code === 'Space') {
      e.preventDefault();
      setEmails((array) => [...array, email]);
      e.target.value = '';
      setTextErrorSameEmail(false);
    }
  };

  const onEmailClick = (e) => {
    if (getFieldState('email').error) return;
    if (!email?.length) return;
    if (emails.includes(email)) return setTextErrorSameEmail(true);
    setEmails((array) => [...array, email]);
    setValue('email', '');
    setTextErrorSameEmail(false);
  };

  const removeChip = (arr, value) => {
    setEmails([...arr].filter((ele) => ele !== value));
  };

  React.useEffect(() => {
    reset({
      name: emailDataById?.name,
      description: emailDataById?.description,
      email: '',
    });
    setEmails(emailDataById?.emails);
  }, [emailDataById, reset]);

  return (
    <Modal
      className="gap-7"
      withCancel={false}
      title={t('common.edit_group')}
      toggle={closeModal}
    >
      <form
        className="flex flex-col w-full gap-4"
        onSubmit={handleSubmit(submit)}
      >
        <Box column className="gap-1">
          <Box column className="gap-3">
            <Text bodyBold>{t('common.name')}</Text>
            <Input
              maxLength={150}
              type="text"
              defaultValue={emailDataById?.name}
              label={t('common.name')}
              {...register('name', {
                required: t('validations.required'),
              })}
              error={errors.name?.message}
            />
          </Box>
          <Box column className="gap-3">
            <Text bodyBold>{t('common.description')}</Text>
            <Textarea
              type="text"
              defaultValue={emailDataById?.description}
              label={t('common.description')}
              {...register('description', {
                required: t('validations.required'),
              })}
              error={errors.description?.message}
            />
          </Box>
          <Box column className="gap-3">
            <Box className="gap-1 items-end">
              <Text bodyBold>{t('common.emails_groups')}</Text>
              <Text badgeBold color="lightText">
                {emails?.length} emails
              </Text>
            </Box>
            <Box column className="gap-1">
              <Input
                onAdd={onEmailClick}
                textButton={t('common.add')}
                buttonAdd
                onKeyDown={onEmailEnter}
                label={t('common.email')}
                {...register('email', {
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: t('validations.email'),
                  },
                })}
                error={
                  (textErrorSameEmail && t('input.error.same_emails')) ||
                  errors.email?.message
                }
              />
              <Box className="flex-grow flex-wrap w-fit gap-2 max-h-[70px] overflow-hidden overflow-y-auto">
                {emails?.map((email) => (
                  <Chip
                    key={email}
                    disabled
                    onDelete={() => removeChip(emails, email)}
                  >
                    {email}
                  </Chip>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
        <Button disabled={!isValid} type="submit" className="w-[96px] self-end">
          {t('common.save')}
        </Button>
      </form>
    </Modal>
  );
};

export default EditGroupModal;
