import React from 'react';

import { usePrevious } from 'common/hooks/usePrevious';
import { useDashboard } from 'features/dashboard/hooks';

import { useDashboardsQuery } from '../services';
import useCreateTab from './useCreateTab';
import useDeleteTab from './useDeleteTab';
import useRenameTab from './useRenameTab';
import useRepositionAllTabs from './useRepositionAllTabs';

const useTabs = ({ selectedTabId, selectTab, preselectTab }) => {
  const { repositionAllTabs } = useRepositionAllTabs();
  const { createTab } = useCreateTab();
  const { deleteTab } = useDeleteTab();
  const { renameTab } = useRenameTab();
  const { data: backendTabs } = useDashboardsQuery();
  const { isEditModeEnabled, hasBeenEdited } = useDashboard();

  const [tabs, setTabs] = React.useState([]);

  const selectedTab = React.useMemo(
    () => tabs?.find((tab) => tab.id === selectedTabId),
    [selectedTabId, tabs]
  );

  const previousTabs = usePrevious(tabs);
  const previousSelectedTab = usePrevious(selectedTab);

  const hasAddedTab =
    previousTabs?.length && tabs?.length > previousTabs?.length;
  const hasRemovedTab = tabs?.length < previousTabs?.length;
  const removedTab = hasRemovedTab && previousSelectedTab;

  React.useEffect(() => {
    // Autoselects first tab when loading for first time
    if (backendTabs && tabs.length === 0) {
      selectTab(backendTabs[0]);
    }
    // Updates rendered tab when new data comes from backend
    if (backendTabs) {
      setTabs(backendTabs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backendTabs]);

  const onTabDragStart = (e) => {
    e.itemData = e.fromData[e.fromIndex];
  };

  const onTabDrop = (e) => {
    const newTabs = [...tabs];

    newTabs.splice(e.fromIndex, 1);
    newTabs.splice(e.toIndex, 0, e.itemData);

    repositionAllTabs(newTabs);
    setTabs(newTabs);
  };

  const onSelectionChanged = ({ addedItems }) => {
    if (isEditModeEnabled && hasBeenEdited) {
      preselectTab(addedItems[0]);
      return;
    }
    selectTab(addedItems[0]);
  };

  const onAdd = () => {
    createTab(tabs);
  };

  React.useEffect(() => {
    if (hasAddedTab) {
      const lastTab = tabs[tabs.length - 1];
      selectTab(lastTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAddedTab]);

  const onDelete = (id) => {
    deleteTab(id);
  };

  React.useEffect(() => {
    if (hasRemovedTab) {
      const removedTabIndex = previousTabs.findIndex(
        (t) => t.id === removedTab.id
      );
      selectTab(tabs[removedTabIndex] || tabs[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasRemovedTab]);

  const onRename = ({ id, name, position }) => {
    renameTab({ id, name, position });
  };

  return {
    onAdd,
    onDelete,
    onRename,
    onSelectionChanged,
    onTabDrop,
    onTabDragStart,
    selectedTab,
    tabs,
  };
};

export default useTabs;
