// converts js object to FormData data type (like json)
export const getFormData = (body) => {
  if (body) {
    const formData = new FormData();

    Object.keys(body).forEach((key) => {
      formData.append(key, body[key]);
    });
    return formData;
  }
  return undefined;
};
