import React from 'react';

import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { useToast } from 'common/hooks/toasts';
import Box from 'components/Box';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Input from 'components/Input';
import Text from 'components/Text';
import { useTranslation } from 'core/translations/useTranslation';

import { useResetPasswordQuery } from '../../../services';

const ResetPasswordForm = ({ setIsEmail }) => {
  const t = useTranslation();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' });

  const { mutate: resetPassword } = useResetPasswordQuery({
    onSuccess: () => {
      showToast(t('reset_password.success_toast'), 'success');
    },
    onError: (error) => {
      showToast(error.detail, 'error');
    },
  });

  const submit = (values) => {
    const { email } = values;

    resetPassword(email);
    localStorage.setItem('email', email);

    setIsEmail(email);
  };
  return (
    <Box column ycenter full className="bg-[#1a2035] h-[full] p-8">
      <form onSubmit={handleSubmit(submit)} className="w-[400px]">
        <Box column className="gap-3 pb-6">
          <Box
            onClick={() => navigate('/login')}
            className="cursor-pointer pb-4"
          >
            <Icon color="white" size="small" name="arrow-left" />
            <Text color="white"> {t('forgot_password.back')}</Text>
          </Box>
          <Text logoBold color="white">
            {t('forgot_password.title')}
          </Text>
          <Text body color="white">
            {t('forgot_password.description')}
          </Text>
        </Box>
        <Input
          {...register('email', {
            required: t('validations.required'),
          })}
          error={errors.email?.message}
          placeholder={t('forgot_password.email')}
          iconL="account"
          iconColor="secondary"
          width="400px"
        />
        <Button disabled={!isValid} type="submit" className="w-[150px] mt-3">
          {t('common.send')}
        </Button>
      </form>
    </Box>
  );
};

export default ResetPasswordForm;
