import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { CACHE_IDS } from 'common/constants/cache';
import { WIDGET_TYPES_SLUGS } from 'common/constants/widgets';
import { selectDateRange } from 'redux/filter/selectors';

import { getTopReportingProducts } from '../api';

const mapper = (data) => {
  const series = data?.series;

  return series?.map((item) => ({
    name: item?.name,
    y: item?.values,
  }));
};

export const useTopReportingProductsQuery = () => {
  const { dateFrom, dateTo } = useSelector(selectDateRange);

  const query = useQuery(
    [
      CACHE_IDS.WIDGET_DATA,
      {
        slug: WIDGET_TYPES_SLUGS.REPORTED_PRODUCTS,
        dateFrom,
        dateTo,
      },
    ],
    () =>
      getTopReportingProducts({
        dateFrom,
        dateTo,
        sort_by: 'count',
        order_by: 'desc',
      }),
    {
      select: (result) => mapper(result),
    }
  );
  return query;
};
