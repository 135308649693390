import { useSelector } from 'react-redux';

import { CACHE_IDS } from 'common/constants/cache';
import { useQuery } from 'core/api/react-query';
import { selectDateRange } from 'redux/filter/selectors';

import { getIncidentsTypes } from '../../api';

export const useIncidentsTypeFilters = () => {
  const { dateFrom, dateTo } = useSelector(selectDateRange);

  const query = useQuery(
    [CACHE_IDS.INCIDENTS_INCIDENTS_ALL_CASE_FILTER, { dateFrom, dateTo }],
    () =>
      getIncidentsTypes({
        dateFrom,
        dateTo,
      }),
    {
      select: (data) => data.map((item) => item[0]),
    }
  );

  return query;
};
