import Illustration from 'assets/security-posture/illustration.png';
import Box from 'components/Box';
import Card from 'components/Card';
import Text from 'components/Text';
import { useTranslation } from 'core/translations/useTranslation';

const EmptyState = () => {
  const t = useTranslation();
  return (
    <Card className="absolute w-[500px] h-[476px] self-center mt-[10px] pb-8 lg:w-[700px]">
      <Box ycenter xcenter column>
        <img src={Illustration} alt="Empty State" className="opacity-[0.5]" />
        <Box xcenter column className="gap-2 opacity-[0.8]">
          <Text subheading>{t('secpos.empty.title')}</Text>
          <Text body color="lightText" className="text-center">
            {t('secpos.empty.content')}
          </Text>
        </Box>
      </Box>
    </Card>
  );
};

export default EmptyState;
