import { useSelector } from 'react-redux';

import { CACHE_IDS } from 'common/constants/cache';
import { WIDGET_TYPES_SLUGS } from 'common/constants/widgets';
import { useQuery } from 'core/api/react-query';
import { selectDateRange } from 'redux/filter/selectors';

import { getTopDetectedUsers } from '../api';
import { TOP_DETECTED_ITEM_STATUS } from '../constants';

const getStatusByCompletion = (completion) => {
  if (completion < 25) {
    return TOP_DETECTED_ITEM_STATUS.SUCCESS;
  } else if (completion >= 25 && completion < 50) {
    return TOP_DETECTED_ITEM_STATUS.WARNING;
  } else if (completion >= 50 && completion < 75) {
    return TOP_DETECTED_ITEM_STATUS.DANGER;
  } else {
    return TOP_DETECTED_ITEM_STATUS.ERROR;
  }
};

const mapApiListToObject = (data) => {
  const listOfCases = data?.map((item) => item[2]);
  const totalCases = listOfCases?.reduce((prev, current) => prev + current);
  const listOfObjects = data?.map((item) => {
    // If this item has 20 cases of 40 total cases, the percent of completion in a bar chart is 50%
    const completionPercentage = (item[2] / totalCases) * 100;
    return {
      user: item[1],
      cases: item[2],
      status: getStatusByCompletion(completionPercentage),
      completionPercentage,
    };
  });

  return {
    totalCases,
    list: listOfObjects,
  };
};

export const useTopDetectedUsersQuery = () => {
  const { dateFrom, dateTo } = useSelector(selectDateRange);

  const query = useQuery(
    [
      CACHE_IDS.WIDGET_DATA,
      { slug: WIDGET_TYPES_SLUGS.TOP_DETECTED_USERS, dateTo, dateFrom },
    ],
    () =>
      getTopDetectedUsers({
        dateTo,
        dateFrom,
        limit: 10,
        case_type: [],
      }),
    {
      select: (result) => mapApiListToObject(result),
    }
  );
  return query;
};

export default useTopDetectedUsersQuery;
