import moment from 'moment';
import { createSearchParams, Link } from 'react-router-dom';

import IP from 'assets/svg/ip.svg';
import SortIndicator from 'common/components/tables/SortIndicator';
import Box from 'components/Box';
import Icon from 'components/Icon';
import Tag from 'components/Tag';
import Text from 'components/Text';
import Tooltip from 'components/Tooltip';
import { getDaysFromNow } from 'core/dates';
import { useTranslation } from 'core/translations/useTranslation';

const FILTERS = {
  ATTACKS: 'attacks',
  DATE: 'date',
};

export const useColumns = ({ getFiltersProps, orderBy, sortBy }) => {
  const t = useTranslation();
  const maxCharacters = 20;
  return [
    {
      title: '',
      dataIndex: '',
      key: 'avatar',
      width: 64,
      render: (data) => {
        const entityType = data?.entity_type;
        const renderTag = () => {
          if (entityType === 'HOSTNAME') {
            return (
              <Icon
                className="text-xl rounded-[100%] h-[40px] w-[40px]"
                bg="primary"
                name="host"
              />
            );
          } else if (entityType === 'USERUNIQNAME') {
            return (
              <Icon
                className="text-xl rounded-[100%] h-[40px] w-[40px]"
                bg="error"
                name="account"
              />
            );
          } else if (entityType === 'DestinationURL') {
            return (
              <Icon
                className="text-xl rounded-[100%] h-[40px] w-[40px]"
                name="link"
                bg="primary"
              />
            );
          } else if (entityType === 'ADDRESS') {
            return (
              <img
                className="rounded-[100%] h-[40px] w-[40px]"
                src={IP}
                bg="error"
                alt="ip"
              />
            );
          } else if (entityType === 'FILENAME') {
            return (
              <Icon
                className="text-xl rounded-[100%] h-[40px] w-[40px]"
                name="file"
                bg="darkBlue"
              />
            );
          } else if (entityType === 'THREATSIGNATURE') {
            return (
              <Icon
                className="text-xl rounded-[100%] h-[40px] w-[40px]"
                name="threats"
                bg="warning"
              />
            );
          } else if (entityType === 'FILEHASH') {
            return (
              <Icon
                className="text-xl rounded-[100%] h-[40px] w-[40px]"
                name="hash"
                bg="darkGray"
              />
            );
          } else if (entityType === 'DOMAIN') {
            return (
              <Icon
                className="text-xl rounded-[100%] h-[40px] w-[40px]"
                name="domain"
                bg="primary"
              />
            );
          }
          return null;
        };
        return (
          <Box xcenter className="h-[40px] w-[40px] rounded-[100%]  text-white">
            {renderTag()}
          </Box>
        );
      },
    },
    {
      title: t('entities.table.header.entity_name'),
      dataIndex: 'entity',
      key: 'entity',
      ellipsis: true,
      width: 250,
      className: 'text-clip overflow-hidden',
      render: (entity, data) => {
        const type = data?.entity_type;
        if (data?.entity_type === 'DestinationURL') {
          const entityIdentifierWithPercent = encodeURIComponent(entity);
          const shortenedDestinationURL =
            entity.length > maxCharacters
              ? `${entity.substring(0, maxCharacters)}...`
              : entity;
          const url = `/entities-details/${entityIdentifierWithPercent}/${type}`;
          return (
            <Box
              data-tip
              data-for={`tooltipDestinationURL_${entity}`}
              className="gap-1 relative w-fit overflow-visible cursor-pointer"
              ycenter
            >
              <Link to={url}>
                <Text
                  color="primary"
                  body
                  className="text-clip overflow-hidden"
                  cursor="pointer"
                >
                  {shortenedDestinationURL}
                </Text>
              </Link>
              <Tooltip
                id={`tooltipDestinationURL_${entity}`}
                content={entity}
                bg="white"
                width="auto"
                className="z-[3]"
                zIndex={99999}
                offset={{ top: -10, left: 0 }}
                place="right-start"
              >
                <Text
                  color="white"
                  body
                  className="text-clip overflow-hidden tooltip-text text-sm"
                  cursor="pointer"
                >
                  {entity}
                </Text>
              </Tooltip>
            </Box>
          );
        }
        if (data?.entity_type === 'FILENAME') {
          const entityIdentifierWithPercent = encodeURIComponent(entity);
          const shortenedFilename =
            entity.length > maxCharacters
              ? `${entity.substring(0, maxCharacters)}...`
              : entity;
          const url = `/entities-details/${entityIdentifierWithPercent}/${type}`;
          return (
            <Box
              data-tip
              data-for={`FILENAME_${entity}`}
              className="gap-1 relative w-fit overflow-visible cursor-pointer"
              ycenter
            >
              <Link to={url}>
                <Text
                  color="primary"
                  body
                  className="text-clip overflow-hidden"
                  cursor="pointer"
                >
                  {shortenedFilename}
                </Text>
              </Link>
              <Tooltip
                id={`FILENAME_${entity}`}
                content={entity}
                bg="white"
                width="auto"
                zIndex={9999}
                offset={{ top: -10, left: 0 }}
                place="right-start"
              >
                <Text
                  color="white"
                  body
                  className="text-clip overflow-hidden tooltip-text text-sm"
                  cursor="pointer"
                >
                  {entity}
                </Text>
              </Tooltip>
            </Box>
          );
        }
        if (data?.entity_type === 'FILEHASH') {
          const entityIdentifierWithPercent = encodeURIComponent(entity);
          const shortenedFilehash =
            entity.length > maxCharacters
              ? `${entity.substring(0, maxCharacters)}...`
              : entity;
          const url = `/entities-details/${entityIdentifierWithPercent}/${type}`;
          return (
            <Box
              data-tip
              data-for={`FILEHASH_${entity}`}
              className="gap-1 relative w-fit overflow-visible cursor-pointer"
              ycenter
            >
              <Link to={url}>
                <Text
                  color="primary"
                  body
                  className="text-clip overflow-hidden"
                  cursor="pointer"
                >
                  {shortenedFilehash}
                </Text>
              </Link>
              <Tooltip
                id={`FILEHASH_${entity}`}
                content={entity}
                bg="white"
                width="auto"
                zIndex={9999}
                offset={{ top: -10, left: 0 }}
                place="right-start"
              >
                <Text
                  color="white"
                  body
                  className="text-clip overflow-hidden tooltip-text text-sm"
                  cursor="pointer"
                >
                  {entity}
                </Text>
              </Tooltip>
            </Box>
          );
        }
        if (data?.entity_type === 'USERUNIQNAME') {
          const entityIdentifierWithPercent = encodeURIComponent(entity);
          const shortenedUser =
            entity.length > maxCharacters
              ? `${entity.substring(0, maxCharacters)}...`
              : entity;
          const url = `/entities-details/${entityIdentifierWithPercent}/${type}`;
          return (
            <Box
              data-tip
              data-for={`USERUNIQNAME_${entity}`}
              className="gap-1 relative w-fit overflow-visible cursor-pointer"
              ycenter
            >
              <Link to={url}>
                <Text
                  color="primary"
                  body
                  className="text-clip overflow-hidden"
                  cursor="pointer"
                >
                  {shortenedUser}
                </Text>
              </Link>
              <Tooltip
                id={`USERUNIQNAME_${entity}`}
                content={entity}
                bg="white"
                width="auto"
                zIndex={9999}
                offset={{ top: -10, left: 0 }}
                place="right-start"
              >
                <Text
                  color="white"
                  body
                  className="text-clip overflow-hidden tooltip-text text-sm"
                  cursor="pointer"
                >
                  {entity}
                </Text>
              </Tooltip>
            </Box>
          );
        }
        if (data?.entity_type === 'HOSTNAME') {
          const entityIdentifierWithPercent = encodeURIComponent(entity);
          const shortenedHostname =
            entity.length > maxCharacters
              ? `${entity.substring(0, maxCharacters)}...`
              : entity;
          const url = `/entities-details/${entityIdentifierWithPercent}/${type}`;
          return (
            <Box
              data-tip
              data-for={`HOSTNAME_${entity}`}
              className="gap-1 relative w-fit overflow-visible cursor-pointer"
              ycenter
            >
              <Link to={url}>
                <Text
                  color="primary"
                  body
                  className="text-clip overflow-hidden"
                  cursor="pointer"
                >
                  {shortenedHostname}
                </Text>
              </Link>
              <Tooltip
                id={`HOSTNAME_${entity}`}
                content={entity}
                bg="white"
                width="auto"
                zIndex={9999}
                offset={{ top: -10, left: 0 }}
                place="right-start"
              >
                <Text
                  color="white"
                  body
                  className="text-clip overflow-hidden tooltip-text text-sm"
                  cursor="pointer"
                >
                  {entity}
                </Text>
              </Tooltip>
            </Box>
          );
        }
        if (data?.entity_type === 'ADDRESS') {
          const entityIdentifierWithPercent = encodeURIComponent(entity);
          const shortenedAddress =
            entity.length > maxCharacters
              ? `${entity.substring(0, maxCharacters)}...`
              : entity;
          const url = `/entities-details/${entityIdentifierWithPercent}/${type}`;
          return (
            <Box
              data-tip
              data-for={`ADDRESS_${entity}`}
              className="gap-1 relative w-fit overflow-visible cursor-pointer"
              ycenter
            >
              <Link to={url}>
                <Text
                  color="primary"
                  body
                  className="text-clip overflow-hidden"
                  cursor="pointer"
                >
                  {shortenedAddress}
                </Text>
              </Link>
              <Tooltip
                id={`ADDRESS_${entity}`}
                content={entity}
                bg="white"
                width="auto"
                zIndex={9999}
                offset={{ top: -10, left: 0 }}
                place="right-start"
              >
                <Text
                  color="white"
                  body
                  className="text-clip overflow-hidden tooltip-text text-sm"
                  cursor="pointer"
                >
                  {entity}
                </Text>
              </Tooltip>
            </Box>
          );
        }
        if (data?.entity_type === 'THREATSIGNATURE') {
          const entityIdentifierWithPercent = encodeURIComponent(entity);
          const shortenedThreatsignature =
            entity.length > maxCharacters
              ? `${entity.substring(0, maxCharacters)}...`
              : entity;
          const url = `/entities-details/${entityIdentifierWithPercent}/${type}`;
          return (
            <Box
              data-tip
              data-for={`THREATSIGNATURE_${entity}`}
              className="gap-1 relative w-fit overflow-visible cursor-pointer"
              ycenter
            >
              <Link to={url}>
                <Text
                  color="primary"
                  body
                  className="text-clip overflow-hidden"
                  cursor="pointer"
                >
                  {shortenedThreatsignature}
                </Text>
              </Link>
              <Tooltip
                id={`THREATSIGNATURE_${entity}`}
                content={entity}
                bg="white"
                width="auto"
                zIndex={9999}
                offset={{ top: -10, left: 0 }}
                place="right-start"
              >
                <Text
                  color="white"
                  body
                  className="text-clip overflow-hidden tooltip-text text-sm"
                  cursor="pointer"
                >
                  {entity}
                </Text>
              </Tooltip>
            </Box>
          );
        }
        if (data?.entity_type === 'DOMAIN') {
          const entityIdentifierWithPercent = encodeURIComponent(entity);
          const shortenedDomain =
            entity.length > maxCharacters
              ? `${entity.substring(0, maxCharacters)}...`
              : entity;
          const url = `/entities-details/${entityIdentifierWithPercent}/${type}`;
          return (
            <Box
              data-tip
              data-for={`DOMAIN_${entity}`}
              className="gap-1 relative w-fit overflow-visible cursor-pointer"
              ycenter
            >
              <Link to={url}>
                <Text
                  color="primary"
                  body
                  className="text-clip overflow-hidden"
                  cursor="pointer"
                >
                  {shortenedDomain}
                </Text>
              </Link>
              <Tooltip
                id={`DOMAIN_${entity}`}
                content={entity}
                bg="white"
                width="auto"
                zIndex={9999}
                offset={{ top: -10, left: 0 }}
                place="right-start"
              >
                <Text
                  color="white"
                  body
                  className="text-clip overflow-hidden tooltip-text text-sm"
                  cursor="pointer"
                >
                  {entity}
                </Text>
              </Tooltip>
            </Box>
          );
        } else {
          return (
            <Link to={`/entities-details/${entity}/${type}`}>
              <Text
                color="primary"
                body
                className="text-clip overflow-hidden"
                cursor="pointer"
              >
                {entity}
              </Text>
            </Link>
          );
        }
      },
    },
    {
      title: t('entities.table.header.entity_type'),
      dataIndex: 'entity_type',
      key: 'entityType',
      width: 200,
      ellipsis: true,
      render: (entityType) => (
        <Box>
          <Text ellipsis body>
            {entityType}
          </Text>
        </Box>
      ),
    },
    {
      title: (
        <Box
          data-tip
          data-for="tooltipAttacks"
          {...getFiltersProps(FILTERS.ATTACKS)}
          ycenter
          className="gap-1 cursor-pointer"
        >
          <Text link bodyBold ellipsis>
            {t('entities.table.header.incidents')}
          </Text>
          <SortIndicator
            id="tooltipAttacks"
            orderBy={orderBy}
            tooltipAsc={t('common.tooltip.attacks.asc')}
            tooltipDesc={t('common.tooltip.attacks.desc')}
          />
          {sortBy === 'attacks' && <Icon size="small" name={orderBy} />}
        </Box>
      ),
      dataIndex: 'attacks',
      key: 'attacks',
      ellipsis: true,
      width: 200,
      render: (incidents) => <Text body>{incidents}</Text>,
    },

    {
      title: t('entities.table.header.is_suspicious'),
      dataIndex: 'is_suspicious',
      key: 'suspicious',
      width: 200,
      render: (suspicious) => {
        const renderTag = () => {
          if (suspicious === true) {
            return (
              <Tag className="h-fit min-w-[64px]" status="error">
                {t('common.yes')}
              </Tag>
            );
          }
          return (
            <Tag className="h-fit min-w-[64px]" status="success">
              {t('common.no')}
            </Tag>
          );
        };
        return renderTag();
      },
    },

    {
      title: (
        <Box
          data-tip
          data-for="tooltipDate"
          {...getFiltersProps(FILTERS.DATE)}
          ycenter
          className="gap-1 cursor-pointer"
        >
          <Text link bodyBold ellipsis>
            {t('entities.table.header.time')}
          </Text>
          <SortIndicator
            id="tooltipDate"
            orderBy={orderBy}
            tooltipAsc={t('common.tooltip.date.asc')}
            tooltipDesc={t('common.tooltip.date.desc')}
          />
          {sortBy === 'date' && <Icon size="small" name={orderBy} />}
        </Box>
      ),
      dataIndex: 'last_detected',
      key: 'last-detected',
      width: 200,
      ellipsis: true,
      render: (date) => (
        <Box column>
          <Box column className="gap-1 w-fit items-end">
            <Text>{moment(date).format('DD-MM-YYYY HH:mm:ss')}</Text>
            <Text badgeBold color="lightText">
              {getDaysFromNow(date)}
            </Text>
          </Box>
        </Box>
      ),
    },
    {
      title: t('entities.table.header.link_incidents'),
      width: 200,
      key: 'incidents',
      className: 'text-clip overflow-hidden',
      render: (data) => {
        const incidents = data.incidents
          ?.map((incident) => incident.id)
          .join(', ');

        const incidentsPathname = `/incidents?${createSearchParams({
          caseIds: incidents,
        })}`;

        return (
          <Box className="w-full gap-4">
            <Link to={incidentsPathname}>
              <Box
                data-tip
                data-for="tooltipIncidents"
                xcenter
                className="gap-1 relative w-fit overflow-visible cursor-pointer"
              >
                <Icon
                  size="small"
                  className="absolute left-[-3px] bottom-[0px] bg-white border-gray border-[1px]"
                  name="incidents"
                  color="danger"
                />
                <Icon color="lightText" name="redirect" />
              </Box>
            </Link>
          </Box>
        );
      },
    },
  ];
};
