import ReactPaginate from 'react-paginate';

import Icon from 'components/Icon';
import { cls } from 'core/styles/cls';

const Pagination = ({ numPages, currentPage, onPageChange, className }) => {
  const handlePageClick = ({ selected }) => {
    onPageChange(selected + 1);
  };

  return (
    <ReactPaginate
      breakLabel="..."
      onPageChange={handlePageClick}
      marginPagesDisplayed={1}
      forcePage={currentPage - 1}
      pageCount={numPages}
      renderOnZeroPageCount={false}
      nextLabel={<Icon name="arrow-right" />}
      previousLabel={<Icon name="arrow-left" />}
      className={cls('flex gap-4 items-center', className)}
      pageClassName="cursor-pointer"
      pageLinkClassName="hover:text-secondary"
      activeClassName="bg-primary w-[24px] h-[24px] text-white flex justify-center items-center rounded-md"
      activeLinkClassName="hover:text-white"
      previousClassName="text-secondary"
      previousLinkClassName="text-secondary hover:text-secondary"
      nextClassName="text-secondary"
      nextLinkClassName="text-secondary hover:text-secondary"
      disabledClassName="text-darkGray"
      disabledLinkClassName="cursor-default text-darkGray hover:text-darkGray"
    />
  );
};

export default Pagination;
