import { useParams } from 'react-router';

import EmptyComponent from 'common/components/tables/EmptyComponent/EmptyComponent';
import Box from 'components/Box';
import Table from 'components/Table';
import Text from 'components/Text';
import { useTranslation } from 'core/translations/useTranslation';

import { useSimilarEntitiesDataQuery } from '../../../../services/incident-details';
import { useColumns } from './table';

const SimilarCases = () => {
  const t = useTranslation();

  const { id } = useParams();

  const {
    data,
    isError: isTableError,
    isFetching: isTableFetching,
    refetch,
    isPreviousData,
  } = useSimilarEntitiesDataQuery({ id });

  return (
    <Box column className="gap-5 overflow-hidden">
      <Table
        columns={useColumns()}
        EmptyComponent={EmptyComponent({ isTableError, refetch })}
        isError={isTableError}
        isFetching={isTableFetching}
        loading={isTableFetching || isPreviousData}
        loadingRows={5}
        loadingHeight={64}
        withMinWidth
        data={data}
        expandable={{
          expandedRowRender: (record) => {
            return (
              <Box column className="gap-3 w-full">
                <Text bodyBold color="lightText">
                  {t('incidents.details.tabs.similar_cases.table.row_expanded')}
                </Text>
                <Text>{record.message}</Text>
              </Box>
            );
          },
        }}
      />
    </Box>
  );
};

export default SimilarCases;
