import React from 'react';

import 'rc-time-picker/assets/index.css';
import Select from 'components/Select';
import { formatHoursFromNumber } from 'core/dates';

const useGetOptions = () =>
  React.useMemo(
    () =>
      [...Array(24).keys()].map((hour) => ({
        label: formatHoursFromNumber({ hour }),
        value: hour,
      })),
    []
  );

const TimePicker = ({ hasError, hour, onChange, value, ...props }, ref) => {
  const options = useGetOptions();
  const defaultValue = {
    label: formatHoursFromNumber({ hour }),
    value: hour,
  };
  return (
    <Select
      hasError={hasError}
      ref={ref}
      {...props}
      value={value}
      onChange={onChange}
      optionsData={options}
      defaultValue={defaultValue}
    />
  );
};

export default React.forwardRef(TimePicker);
