import { getColorHex } from 'common/constants/colors';
import { useToggler } from 'common/hooks';
import { cls } from 'core/styles/cls';

import Box from '../Box';
import Card from '../Card';
import Dropdown from '../Dropdown';
import Icon from '../Icon';
import Text from '../Text';

export const WidgetIcon = ({ className, name, color = 'white' }) => (
  <Box
    className={cls('items-center justify-center rounded-[100%] p-2', className)}
  >
    <Icon size="small" color={color} name={name} />
  </Box>
);

const DropdownWidget = ({ closeDropdown, handleClickDeleteWidget }) => (
  <Dropdown
    closeDropdown={closeDropdown}
    className="right-[-20px] top-[40px] z-[10] w-[100px]"
  >
    <Box column>
      <Dropdown.Item>Edit</Dropdown.Item>
      <Dropdown.Item top onClick={handleClickDeleteWidget}>
        Delete
      </Dropdown.Item>
    </Box>
  </Dropdown>
);

const Widget = ({
  subtitle,
  renderTitleIcon,
  iconLeft,
  iconRight,
  children,
  className,
  onDelete,
  isEditing,
  color,
  ...props
}) => {
  const { isShown: showDropdownWidget, toggle: toggleDropdownWidget } =
    useToggler();

  const handleClickDeleteWidget = () => {
    onDelete();
  };

  return (
    <>
      {!!showDropdownWidget && (
        <DropdownWidget
          handleClickDeleteWidget={handleClickDeleteWidget}
          closeDropdown={toggleDropdownWidget}
        />
      )}
      <Card isEditing={isEditing} {...props} className={cls(className)}>
        <Box between className="mb-3">
          <Box className="items-center gap-2 text-text">
            {renderTitleIcon && renderTitleIcon()}
            <Text
              color={color}
              subtitle
              style={{
                color: getColorHex(color),
              }}
            >
              {subtitle}
            </Text>
          </Box>
          {isEditing && (
            <Box>
              <Box
                onClick={toggleDropdownWidget}
                className="p-2 cursor-pointer text-lightText hover:text-primary"
              >
                <Icon
                  cursor="pointer"
                  className="text-inherit"
                  size="small"
                  name={iconLeft}
                />
              </Box>
              <Icon
                cursor="move"
                color="lightText"
                size="small"
                name={iconRight}
              />
            </Box>
          )}
        </Box>
        {children}
      </Card>
    </>
  );
};

export default Widget;
