import React from 'react';

import Box from 'components/Box';
import { cls } from 'core/styles/cls';

import Sidebar from './components/Sidebar';
import Title from './components/Title';
import './styles.css';

const Header = ({ icon, title, renderRight, renderLeft, titlePathname }) => {
  return (
    <Box full column>
      <Box between className={cls('items-center gap-1 pb-4 min-h-[60px]')}>
        <Title
          title={title}
          titlePathname={titlePathname}
          icon={icon}
          render={renderLeft}
        />

        {!!renderRight && renderRight()}
      </Box>
    </Box>
  );
};

const Layout = ({
  children,
  title,
  icon,
  renderHeaderRight,
  renderHeaderLeft,
  toggleSidebar,
  className,
  titlePathname,
  renderContent,
  ...props
}) => (
  <Box
    className={cls('min-h-screen w-screen bg-darkBackground', className)}
    {...props}
  >
    <Sidebar />
    <div id="modals" style={{ position: 'fixed', 'z-index': '999' }}></div>
    {renderContent ? (
      renderContent()
    ) : (
      <Box
        className={cls(
          'grow-1 min-h-screen w-full flex-col overflow-y-auto px-5 py-5 lg:px-7'
        )}
      >
        <Header
          title={title}
          icon={icon}
          renderRight={renderHeaderRight}
          renderLeft={renderHeaderLeft}
          titlePathname={titlePathname}
        />

        <Box
          className={cls('h-full overflow-hidden flex-grow overflow-x-auto')}
        >
          {children}
        </Box>
      </Box>
    )}
  </Box>
);

export default Layout;
