import React from 'react';

import ReactDOM from 'react-dom';
import GridLayout, { WidthProvider } from 'react-grid-layout';

import { useToggler } from 'common/hooks';
import { useFullScreen } from 'common/providers/FullScreenProvider';
import Box from 'components/Box';
import Button from 'components/Button';
import Text from 'components/Text';
import { useTranslation } from 'core/translations/useTranslation';
import { useSidebar } from 'features/layout/hooks/useSidebar';

import Sidebar from '../components/Sidebar';
import Toolbar from '../components/Toolbar';
import { WidgetRenderer } from '../components/WidgetRenderer';
import { useEditMode, useSaveChanges } from '../hooks';
import { useDashboard } from '../hooks/useDashboard';
import { useSelectSidebarWidget } from '../hooks/useSelectSidebarWidget';
import EmptyState from './EmptyState';
import Loading from './Loading';

const ReactGridLayout = WidthProvider(GridLayout);

const Dashboard = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const t = useTranslation();
  const { enter, isFullScreen, exit } = useFullScreen();
  const { collapseSidebar: toggleSidebarSize, isSidebarCollapsed } =
    useSidebar();

  const { isShown: isShownSidebar, toggle: toggleSidebar } = useToggler();
  const { saveChanges, isLoading: isSaveChangesLoading } = useSaveChanges();
  const { selectSidebarWidget, selectedSidebarWidget } =
    useSelectSidebarWidget();
  const { isEditModeEnabled, discardChanges, enableEditMode } = useEditMode();

  React.useEffect(() => {
    // Resizes the react grid layout div when the sidebar gets collapsed / expanded
    window.dispatchEvent(new Event('resize'));
  }, [isSidebarCollapsed]);

  const {
    layout,
    dashboardId,
    addWidget,
    onLayoutChange,
    isLoading: isLayoutLoading,
  } = useDashboard();

  React.useEffect(() => {
    setIsLoading(isLayoutLoading);
  }, [isLayoutLoading]);

  const onDrop = (layout) => {
    addWidget(selectedSidebarWidget, layout);
    selectSidebarWidget(null);
    toggleSidebar(false);
  };

  const onFullScreenClick = () => {
    const shouldCollapseSidebar = !isFullScreen && !isSidebarCollapsed;
    const shouldExpandSidebar = isFullScreen && isSidebarCollapsed;
    if (!isFullScreen) {
      enter();
    } else {
      exit();
    }

    if (shouldCollapseSidebar || shouldExpandSidebar) toggleSidebarSize();
  };

  React.useEffect(() => {
    if (!isEditModeEnabled) return;
    discardChanges();
    if (isShownSidebar) {
      toggleSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardId]);

  if (isLayoutLoading) {
    return (
      <Box full column>
        <Loading />
      </Box>
    );
  }

  return (
    <Box column className="relative px-5 lg:px-7 pb-[100px]">
      {ReactDOM.createPortal(
        <Box ycenter className="gap-3" xcenter>
          <Text
            onClick={onFullScreenClick}
            bodyBold
            color="primary"
            cursor
            className="text-center"
          >
            {!isFullScreen
              ? t('common.full_screen')
              : t('common.exit_full_screen')}
          </Text>
          <Button
            secondary
            small
            onClick={enableEditMode}
            icon="edit"
            className="max-h-5"
          >
            {t('secpos.menu.edit')}
          </Button>
        </Box>,
        document.getElementById('toolbar')
      )}
      {isEditModeEnabled ? (
        <Toolbar
          className="justify-end gap-3 mt-2 right-[48px]"
          {...{
            saveChanges,
            isSaveChangesLoading,
            discardChanges,
            toggleSidebar,
          }}
        />
      ) : (
        <Box className="h-[8px]" />
      )}
      {isShownSidebar && (
        <Sidebar
          closeSidebar={toggleSidebar}
          selectSidebarWidget={selectSidebarWidget}
        />
      )}
      <Box column full>
        {!isLoading && (!layout || !layout.length) && <EmptyState />}
        {isLoading && (
          <Box full column>
            <Loading />
          </Box>
        )}
        {!isLoading && (
          <ReactGridLayout
            droppingItem={selectedSidebarWidget}
            layout={layout}
            cols={12}
            rowHeight={30}
            onLayoutChange={onLayoutChange}
            onDrop={onDrop}
            isDroppable={!!selectedSidebarWidget && isEditModeEnabled}
            isResizable={isEditModeEnabled}
            isDraggable={isEditModeEnabled}
          >
            {layout?.map((widget) => {
              const slug = widget?.slug;
              const id = widget?.i;
              const title = widget?.title;

              return (
                <div key={id} data-grid={widget}>
                  <WidgetRenderer slug={slug} id={id} title={title} />
                </div>
              );
            })}
          </ReactGridLayout>
        )}
      </Box>
    </Box>
  );
};

export default Dashboard;
