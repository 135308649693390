import React from 'react';

import { useToggler } from 'common/hooks';
import TabDrag from 'components/TabDrag';
import useTabs from 'features/sec-posture/hooks/useTabs';

import ModalChangeTab from '../ModalChangeTab';
import ModalDeleteTab from '../ModalDeleteTab';
import { Tab } from './Tab';

const Tabs = ({ selectedTabId, selectTab }) => {
  const { isShown: isDeleteModalShown, toggle: toggleDeleteModal } =
    useToggler();

  const [preselectedTab, setPreselectedTab] = React.useState(null);
  const isChangeTabModalShown = !!preselectedTab;

  const preselectTab = (tab) => {
    setPreselectedTab(tab);
  };

  const hideChangeTabModal = () => {
    if (preselectedTab) {
      setPreselectedTab(null);
    }
  };

  const commitSelection = () => {
    selectTab(preselectedTab);
  };

  const {
    onTabDragStart,
    onTabDrop,
    onSelectionChanged,
    onAdd,
    onDelete,
    onRename,
    tabs,
    selectedTab,
  } = useTabs({
    selectedTabId,
    selectTab,
    preselectTab,
  });

  return (
    <>
      {isChangeTabModalShown && (
        <ModalChangeTab
          toggle={hideChangeTabModal}
          commitSelection={commitSelection}
        />
      )}
      {isDeleteModalShown && (
        <ModalDeleteTab
          toggle={toggleDeleteModal}
          selectedTab={selectedTab}
          onDelete={onDelete}
        />
      )}
      <TabDrag
        tabs={tabs}
        onTabDragStart={onTabDragStart}
        onTabDrop={onTabDrop}
        onSelectionChanged={onSelectionChanged}
        renderTitle={(data) => {
          return (
            <Tab
              totalTabs={tabs?.length}
              tab={data}
              onDelete={onDelete}
              isSelected={selectedTabId === data.id}
              onRename={onRename}
              showDeleteModal={toggleDeleteModal}
            />
          );
        }}
        className="b-0 mb-[-1px]"
        onAdd={onAdd}
        selectedTab={preselectedTab || selectedTab}
      />
    </>
  );
};

export default Tabs;
