import React from 'react';

import { Link } from 'react-router-dom';

import EmptyStateWidgets from 'common/components/widgets/EmptyStateWidgets';
import Box from 'components/Box';
import Card from 'components/Card';
import Icon from 'components/Icon';
import Skeleton from 'components/Skeleton';
import Text from 'components/Text';
import Widget from 'components/Widget';
import { useTranslation } from 'core/translations/useTranslation';
import '../../styles/styles.css';

import { useSummaryQuery } from '../../services';

const WidgetSummary = ({
  id,
  title,
  isEditing,
  removeWidget,
  color,
  dateFrom,
  dateTo,
}) => {
  const t = useTranslation();
  const { data: widgetData, isLoading } = useSummaryQuery();
  const importantIncidentUrl = `/incidents/?dateFrom=${dateFrom}/?dateTo=${dateTo}&important=Yes&page=1`;

  return (
    <Widget
      isEditing={isEditing}
      iconLeft="settings"
      iconRight="dots"
      onDelete={() => removeWidget(id)}
      subtitle={<span className="title-widget">{title}</span>}
    >
      {isLoading ? (
        <Skeleton radius={'10px'} />
      ) : widgetData ? (
        <Box full className="h-full">
          <Card className="rounded-[0px] border-0 border-r w-full items-start flex-col pt-0">
            <Box full ycenter between>
              <Text bodyBold color="lightText" className="text-[18px]">
                {t('secpos.widgets.summary.total_cases')}
              </Text>
              <Icon name="fire" color="warning" />
            </Box>
            <Box ycenter xcenter full className="h-full">
              <Link to={`/incidents`}>
                <Text className="text-[32px] font-[600]">
                  {widgetData?.total_count}
                </Text>
              </Link>
            </Box>
          </Card>
          <Card className="rounded-[0px] border-0  w-full items-start flex-col pt-0">
            <Box full ycenter between>
              <Text bodyBold color="lightText" className="text-[18px]">
                {t('secpos.widgets.summary.important_cases')}
              </Text>
              <Icon name="threats" color="error" />
            </Box>
            <Box ycenter xcenter full className="h-full">
              <Link to={importantIncidentUrl}>
                <Text className="text-[32px] font-[600]">
                  {widgetData?.important_count}
                </Text>
              </Link>
            </Box>
          </Card>
        </Box>
      ) : (
        <EmptyStateWidgets />
      )}
    </Widget>
  );
};

export default WidgetSummary;
