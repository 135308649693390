import CalendarFilter from 'common/components/filters/CalendarFilter';
import EmptyStateWidgets from 'common/components/widgets/EmptyStateWidgets';
import Box from 'components/Box';
import Card from 'components/Card';
import Funnel from 'components/Funnel';
import Skeleton from 'components/Skeleton';
import Text from 'components/Text';
import { useTranslation } from 'core/translations/useTranslation';
import Layout from 'features/layout';
import MetricsEventsTable from 'features/soc-status/components/MetricsEventsTable/MetricsEventsTable';
import { useSocEngineQuery } from 'features/soc-status/services/useSocEngineQuery';

const SocEngine = () => {
  const t = useTranslation();
  const { data, isLoading } = useSocEngineQuery();

  return (
    <Layout
      icon="tools"
      title={t('sidebar.soc_engine')}
      renderHeaderRight={() => <CalendarFilter />}
    >
      <Box column full className="gap-4">
        <Box full className="gap-4 overflow-hidden h-[400px]">
          <Card className="w-full gap-2 p-5">
            <Text subheading>{t('soc_status.funnel.title')}</Text>
            {isLoading ? (
              <Skeleton radius={'10px'} />
            ) : data?.length ? (
              <>
                <Box full between className="pr-3 mt-4">
                  {data?.map((item) => (
                    <Box className="w-full " xcenter>
                      <Text bodyBold>{item?.label}</Text>
                    </Box>
                  ))}
                </Box>

                <Funnel data={data} />
              </>
            ) : (
              <EmptyStateWidgets />
            )}
          </Card>
        </Box>
        <Box full className="overflow-hidden">
          <Card className="w-full gap-4 p-5 !h-fit">
            <Text subheading>{t('metrics_events.title')}</Text>
            <MetricsEventsTable />
          </Card>
        </Box>
      </Box>
    </Layout>
  );
};

export default SocEngine;
