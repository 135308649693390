import Box from 'components/Box';
import Skeleton from 'components/Skeleton';

const ContentSkeleton = ({ number = 2 }) => {
  return [...Array(number)].map((i) => (
    <Box column className="gap-2" key={`sidebar-widgets-skeleton-${i}`}>
      <Box column className="gap-1">
        <Skeleton />
        <Skeleton />
      </Box>
      <Skeleton height="275px" />
    </Box>
  ));
};

export default ContentSkeleton;
