import React from 'react';

import useWindowDimensions from 'common/hooks/useWindowDimensions';
import Box from 'components/Box';
import Text from 'components/Text';
import { formatHoursFromNumber } from 'core/dates';

const DayTitleHourRange = ({ hoursConfig }) => {
  const { width } = useWindowDimensions();

  const hourRanges = React.useMemo(
    () =>
      hoursConfig
        .map((h) =>
          !isNaN(h?.hour_from) && !isNaN(h?.hour_to)
            ? {
                from: formatHoursFromNumber({ hour: h.hour_from }),
                to: formatHoursFromNumber({ hour: h.hour_to }),
              }
            : undefined
        )
        .filter((h) => h !== undefined),
    [hoursConfig]
  );

  const renderHourRange = React.useCallback(
    (hour) => (
      <Box className="gap-1">
        <Text ellipsis color="lightText" className="self-end">
          {hour.from}
        </Text>
        <Text color="lightText" className="self-end">
          -
        </Text>
        <Text ellipsis color="lightText" className="self-end">
          {hour.to}
        </Text>
      </Box>
    ),
    []
  );

  return (
    <Box ycenter className="gap-1 ">
      {hourRanges.length > 4 ? (
        <>
          {width >= 1520
            ? hourRanges.slice(0, 3).map((hour) => renderHourRange(hour))
            : hourRanges.slice(0, 2).map((hour) => renderHourRange(hour))}

          <Text color="lightText">...</Text>
        </>
      ) : (
        hourRanges.map((hour) => renderHourRange(hour))
      )}
    </Box>
  );
};

export default DayTitleHourRange;
