import React from 'react';

import { StringParam, useQueryParam } from 'use-query-params';

import PaginatedTable from 'common/components/tables/PaginatedTable';
import { usePagination } from 'common/hooks';
import Box from 'components/Box';
import Button from 'components/Button';
import Card from 'components/Card';
import Input from 'components/Input';
import Select from 'components/Select';
import { useTranslation } from 'core/translations/useTranslation';
import { useProductsTypeFilters } from 'features/incidents/services/incidents/useProductsTypeFilters';

import {
  useCriticalityTypeFilters,
  useIncidentsDataQuery,
  useIncidentsTypeFilters,
} from '../../services/incidents';
import { useFilters } from './hooks';
import { useColumns } from './table';

const TableView = () => {
  const t = useTranslation();
  const { currentPage, onPageChange } = usePagination();
  const { filters, inputProps, onFiltersSubmit } = useFilters(handleSubmit);
  const [sortBy = 'date', setSortBy] = useQueryParam('sortBy', StringParam);
  const [orderBy = 'desc', setOrderBy] = useQueryParam('orderBy', StringParam);

  const { data: caseTypes, isLoading: isCaseTypesLoading } =
    useIncidentsTypeFilters();
  const { data: caseProducts, isLoading: isCaseProductsLoading } =
    useProductsTypeFilters();
  const { data: caseCriticality, isLoading: isCaseCriticalityLoading } =
    useCriticalityTypeFilters();

  const caseTypesOptions = React.useMemo(
    () =>
      caseTypes?.map((item) => ({
        value: item,
        label: item,
      })),
    [caseTypes]
  );

  const caseProductsOptions = React.useMemo(
    () => caseProducts?.map((item) => ({ value: item, label: item })),
    [caseProducts]
  );

  const caseCriticalityOptions = React.useMemo(
    () => caseCriticality?.map((item) => ({ value: item, label: item })),
    [caseCriticality]
  );

  const {
    data,
    isFetching: isTableFetching,
    isError: isTableError,
    isPreviousData,
    refetch,
  } = useIncidentsDataQuery({
    page: currentPage,
    caseTypes: filters.caseTypes,
    casesId: filters.cases,
    products: filters.products,
    important: filters.important,
    criticality: filters.criticality,
    sortBy,
    orderBy,
  });

  function handleSubmit(e) {
    e.preventDefault();
    onFiltersSubmit(refetch);
    onPageChange(1);
  }

  const sortByDate = () => {
    setSortBy('date');
    if (orderBy === 'asc') setOrderBy('desc');
    if (orderBy === 'desc') setOrderBy('asc');
  };

  return (
    <Card className="p-4 z-0">
      <Box column className="gap-5">
        <form
          onSubmit={handleSubmit}
          className="flex gap-4 w-full justify-between flex-col"
        >
          <Box className="gap-4">
            <Input
              noValidation
              label={t('incidents.search')}
              name="search"
              iconR="search"
              {...inputProps.cases}
            />
            <Button
              type="submit"
              className="h-6 w-[200px]"
              loading={isTableFetching}
            >
              {t('common.search')}
            </Button>
          </Box>
          <Box className="gap-4">
            <Select
              placeholder={t('incidents.typology')}
              isMulti
              isLoading={isCaseTypesLoading}
              optionsData={caseTypesOptions}
              {...inputProps.caseTypes}
            />
            <Select
              placeholder={t('incidents.product')}
              isMulti
              isLoading={isCaseProductsLoading}
              optionsData={caseProductsOptions}
              {...inputProps.productsTypes}
            />
            <Select
              placeholder={t('incidents.criticality')}
              isMulti
              isLoading={isCaseCriticalityLoading}
              optionsData={caseCriticalityOptions}
              {...inputProps.criticality}
            />
          </Box>
        </form>
        <PaginatedTable
          data={data?.cases}
          columns={useColumns(sortByDate, orderBy)}
          isFetching={isTableFetching}
          isError={isTableError}
          refetch={refetch}
          isShowingPreviousData={isPreviousData}
          currentPage={currentPage}
          pageSize={data?.page_size}
          totalPages={data?.total_pages}
          totalResults={data?.total_count}
          onPageChange={onPageChange}
          withMinWidth
        />
      </Box>
    </Card>
  );
};

export default TableView;
