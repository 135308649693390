import Box from 'components/Box';
import Text from 'components/Text';
import { cls } from 'core/styles/cls';

const WidgetImage = ({ title, description, src, className, ...props }) => (
  <Box column className={cls(className)} {...props}>
    <Box column className="mb-4 gap-2">
      <Text bodyBold>{title}</Text>
      <Text body color="lightText">
        {description}
      </Text>
    </Box>
    <img width="100%" alt="widget" src={src} />
  </Box>
);

export default WidgetImage;
