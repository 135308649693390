import { useQueryClient } from 'react-query';

import { CACHE_IDS } from 'common/constants/cache';
import { useMutation } from 'core/api/react-query';

import { deleteDashboard } from '../api';

export const useDeleteDashboardMutationQuery = ({ onSuccess, onError }) => {
  const client = useQueryClient();

  const mutation = useMutation(deleteDashboard, {
    onSuccess: () => {
      client.invalidateQueries([CACHE_IDS.DASHBOARDS]);
      if (onSuccess) onSuccess();
    },
    onError,
  });

  return mutation;
};

export default useDeleteDashboardMutationQuery;
