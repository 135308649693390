import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router';

import { useTabs } from 'common/hooks';
import Card from 'components/Card';
import Tabs from 'components/Tabs';
import { useTranslation } from 'core/translations/useTranslation';

import { useHasCorrelationsDataQuery } from '../../../services/incident-details';
import Correlations from './Correlations';
import IncidentLogs from './IncidentLogs';
import Overview from './Overview';
import RawEvents from './RawEvents';
import RelatedEntities from './RelatedEntities';
import SimilarCases from './SimilarCases';

const TABS = {
  OVERVIEW: 'overview',
  RELATED_ENTITIES: 'related_entities',
  SIMILAR_CASES: 'similar_cases',
  RAW_EVENTS: 'raw_events',
  INCIDENT_LOGS: 'incident_logs',
  CORRELATIONS: 'correlations',
};

const TabsView = () => {
  const t = useTranslation();
  const { id } = useParams();

  const { data } = useHasCorrelationsDataQuery({ id });
  const [hasCorrelations, setHasCorrelations] = useState(false);

  useEffect(() => {
    if (data !== undefined) {
      setHasCorrelations(data);
    }
  }, [data]);

  const { getTabProps, activeTab } = useTabs({ initialTab: TABS.OVERVIEW });

  return (
    <Card className="p-4 z-0">
      <Tabs.Wrapper>
        <Tabs.Tab {...getTabProps(TABS.OVERVIEW)}>
          {t('incidents.details.tabs.overview.tab')}
        </Tabs.Tab>
        <Tabs.Tab {...getTabProps(TABS.RELATED_ENTITIES)}>
          {t('incidents.details.tabs.related_entities.tab')}
        </Tabs.Tab>
        <Tabs.Tab {...getTabProps(TABS.SIMILAR_CASES)}>
          {t('incidents.details.tabs.similar_cases.tab')}
        </Tabs.Tab>
        <Tabs.Tab {...getTabProps(TABS.RAW_EVENTS)}>
          {t('incidents.details.tabs.raw_events.tab')}
        </Tabs.Tab>
        <Tabs.Tab {...getTabProps(TABS.INCIDENT_LOGS)}>
          {t('incidents.details.tabs.incident_logs.tab')}
        </Tabs.Tab>
        {hasCorrelations && (
          <Tabs.Tab {...getTabProps(TABS.CORRELATIONS)}>
            {t('incidents.details.tabs.correlations.tab')}
          </Tabs.Tab>
        )}
      </Tabs.Wrapper>
      {activeTab === TABS.OVERVIEW && <Overview />}
      {activeTab === TABS.RELATED_ENTITIES && <RelatedEntities />}
      {activeTab === TABS.SIMILAR_CASES && <SimilarCases />}
      {activeTab === TABS.RAW_EVENTS && <RawEvents />}
      {activeTab === TABS.INCIDENT_LOGS && <IncidentLogs />}
      {activeTab === TABS.CORRELATIONS && hasCorrelations && <Correlations />}
    </Card>
  );
};

export default TabsView;
