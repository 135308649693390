import Box from 'components/Box';
import Icon from 'components/Icon';
import Text from 'components/Text';
import { useTranslation } from 'core/translations/useTranslation';

const FilledIcon = ({ color, icon }) => (
  <Box xcenter ycenter className="w-fit h-fit p-1 rounded-[100%]" bg={color}>
    <Box
      xcenter
      bg={color}
      className="rounded-[100%] w-fit h-fit p-3 border-4 border-white"
    >
      <Icon className="text-[50px]" color="white" name={icon} />
    </Box>
  </Box>
);

const DetailsHeading = ({ title, value, color, icon }) => {
  const t = useTranslation();
  return (
    <Box ycenter className="gap-3 pt-3">
      <FilledIcon color={color} icon={icon} />
      <Box column className="gap-2">
        <Text color="lightText" bodyBold>
          {title}
        </Text>
        <Text subtitle>{value}</Text>
        <Text body>{t('details.heading.description')}</Text>
      </Box>
    </Box>
  );
};

export default DetailsHeading;
