import Cookies from 'js-cookie';

export const Cookie = {
  set: (name, value) => {
    Cookies.set(name, value);
  },
  get: (name) => {
    return Cookies.get(name);
  },
  remove: (name) => {
    Cookies.remove(name);
  },
};
