import { CACHE_IDS } from 'common/constants/cache';
import { useQuery } from 'core/api/react-query';

import { getEntityDetailsData } from '../api';
export const useEntitiesDetailsQuery = ({ id, type }) => {
  const query = useQuery([CACHE_IDS.ENTITIES_DETAILS, { id, type }], () =>
    getEntityDetailsData({ id, type })
  );

  return query;
};
