import React from 'react';

import EmptyStateWidgets from 'common/components/widgets/EmptyStateWidgets';
import { getNumberAbbreviation } from 'common/utils/getNumberAbbreviation';
import Box from 'components/Box';
import Skeleton from 'components/Skeleton';
import Text from 'components/Text';
import Widget from 'components/Widget';
import '../../styles/styles.css';
import { useTranslation } from 'core/translations/useTranslation';
import { useNotifiedCasesQuery } from 'features/widgets/services';

const WidgetNotifiedCases = ({ isEditing, removeWidget, id, title }) => {
  const t = useTranslation();
  const { data: widgetData, isLoading } = useNotifiedCasesQuery();
  return (
    <Widget
      isEditing={isEditing}
      iconLeft="settings"
      iconRight="dots"
      onDelete={() => removeWidget(id)}
      subtitle={<span className="title-widget">{title}</span>}
    >
      {isLoading ? (
        <Skeleton radius={'10px'} />
      ) : widgetData ? (
        <Box ycenter xcenter full className="h-full">
          <Box
            ycenter
            xcenter
            column
            className="w-[150px] h-[150px] rounded-[100%] border-4 border-error p-4 text-center"
          >
            <Text className="text-[32px] font-[700]">
              {getNumberAbbreviation(widgetData)}
            </Text>
            <Text bodyBold color="lightText">
              {t('secpos.widgets.notified_cases.subtitle')}
            </Text>
          </Box>
        </Box>
      ) : (
        <EmptyStateWidgets />
      )}
    </Widget>
  );
};

export default WidgetNotifiedCases;
