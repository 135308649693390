import React from 'react';

import Skeleton from 'react-loading-skeleton';

import EmptyStateWidgets from 'common/components/widgets/EmptyStateWidgets';
import Box from 'components/Box';
import Chart from 'components/Chart';
import Widget from 'components/Widget';
import '../../styles/styles.css';

import { useWidgetChart } from '../../hooks';
import { useMalwareTypesQuery } from '../../services';
import { getChartOptions } from './chart';

const WidgetMalwareTypologies = ({ id, title, removeWidget, isEditing }) => {
  const { data: widgetData, isLoading } = useMalwareTypesQuery();
  const { props: chartProps } = useWidgetChart(id);

  const options = React.useMemo(
    () =>
      getChartOptions({
        data: widgetData,
      }),
    [widgetData]
  );

  return (
    <Widget
      isEditing={isEditing}
      iconLeft="settings"
      iconRight="dots"
      subtitle={<span className="title-widget">{title}</span>}
      onDelete={() => removeWidget(id)}
    >
      {isLoading ? (
        <Box className="block overflow-hidden rounded-[10px]">
          <Skeleton radius="10px" height={10000} />
        </Box>
      ) : widgetData?.length ? (
        <Chart options={options} {...chartProps} />
      ) : (
        <EmptyStateWidgets />
      )}
    </Widget>
  );
};

export default WidgetMalwareTypologies;
