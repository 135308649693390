import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { useToggler } from 'common/hooks';
import useDateUrlFilter from 'common/hooks/filters/useDateUrlFilter';
import Box from 'components/Box';
import Calendar from 'components/Calendar';
import Icon from 'components/Icon';
import Text from 'components/Text';
import { selectDateRange } from 'redux/filter/selectors';
import { actions } from 'redux/filter/slice';

const CalendarFilter = () => {
  useDateUrlFilter();

  const dispatch = useDispatch();
  const { dateFrom, dateTo } = useSelector(selectDateRange);
  const rangeText = `${moment(dateFrom).format('DD/MM/YYYY')} - ${moment(
    dateTo
  ).format('DD/MM/YYYY')}`;
  const { isShown, toggle } = useToggler();

  return (
    <Box>
      <Box
        onClick={toggle}
        ycenter
        className="cursor-pointer bg-white py-2 h-full px-3 gap-2 rounded-md border-gray border-[1px]"
      >
        <Text>{rangeText}</Text>
        <Icon name="calendar" color="primary" size="small" />
      </Box>

      {isShown && (
        <Calendar
          dateFrom={new Date(dateFrom)}
          dateTo={new Date(dateTo)}
          onSubmit={({ startDate, endDate }) => {
            dispatch(
              actions.updateDate({
                dateFrom: moment(startDate).valueOf(),
                dateTo: moment(endDate).valueOf(),
              })
            );
            toggle();
          }}
          shouldDisableFutureDates
        />
      )}
    </Box>
  );
};

export default CalendarFilter;
