import Box from 'components/Box';
import Chart from 'components/Chart';
import Icon from 'components/Icon';
import Text from 'components/Text';
import Widget from 'components/Widget';
import { cls } from 'core/styles/cls';
import { useTranslation } from 'core/translations/useTranslation';
import '../../styles/styles.css';

import { useWidgetChart } from '../../hooks';

const chartOptions = {
  chart: {
    type: 'solidgauge',
  },

  title: '',
  tooltip: {
    borderWidth: 0,
    backgroundColor: 'none',
    shadow: false,

    valueSuffix: '%',
    pointFormat:
      '{series.name}<br><span style="font-size:2em; color: {point.color}; font-weight: bold">{point.y}</span>',
    positioner(labelWidth) {
      return {
        x: (this.chart.chartWidth - labelWidth) / 2,
        y: this.chart.plotHeight / 2 - 20,
      };
    },
  },

  pane: {
    startAngle: 180,
    endAngle: 542,
    background: [
      {
        // Track for Move
        outerRadius: '110%',
        innerRadius: '100%',
        backgroundColor: '#F0F3FF',
        borderWidth: 0,
      },
      {
        // Track for Move
        outerRadius: '95%',
        innerRadius: '85%',
        backgroundColor: '#F0F3FF',

        borderWidth: 0,
      },
      {
        // Track for Move
        outerRadius: '80%',
        innerRadius: '70%',
        backgroundColor: '#F0F3FF',

        borderWidth: 0,
      },
      {
        // Track for Move
        outerRadius: '65%',
        innerRadius: '55%',
        backgroundColor: '#F0F3FF',

        borderWidth: 0,
      },
    ],
  },

  yAxis: {
    min: 0,
    max: 100,
    lineWidth: 0,
    tickPositions: [],
  },

  plotOptions: {
    solidgauge: {
      dataLabels: {
        enabled: true,
      },
      linecap: 'round',
      stickyTracking: false,
      rounded: true,
    },
  },

  credits: {
    enabled: false,
  },

  series: [
    {
      name: 'VPN',
      type: 'solidgauge',
      data: [
        {
          color: '#01F1E3',
          radius: '110%',
          innerRadius: '100%',
          y: 50,
        },
      ],
    },
    {
      name: 'Firewall',
      type: 'solidgauge',
      data: [
        {
          color: '#A3DA8D',
          radius: '95%',
          innerRadius: '85%',
          y: 80,
        },
      ],
    },

    {
      name: 'Endpoint',
      type: 'solidgauge',
      data: [
        {
          color: '#FFA974',
          radius: '80%',
          innerRadius: '70%',
          y: 60,
        },
      ],
    },
    {
      name: 'Active directory',
      type: 'solidgauge',
      data: [
        {
          color: '#D45A66',
          radius: '65',
          innerRadius: '55%',
          y: 40,
        },
      ],
    },
  ],
};

const Item = ({ text, amount, className }) => (
  <Box ycenter between>
    <Box ycenter className="cursor-pointer gap-2">
      <div className={cls('h-[12px] w-[12px] rounded-[100%]', className)} />
      <Text bodyBold>{text}</Text>
      <Icon size="small" name="arrow-down" />
    </Box>
    <Text bodyBold>{amount}</Text>
  </Box>
);

function WidgetEventDataSource({ id, removeWidget }) {
  const { props } = useWidgetChart(id);
  const t = useTranslation();

  return (
    <Widget
      iconLeft="settings"
      iconRight="dots"
      subtitle={
        <span className="title-widget">
          {t('secpos.widgets.event_data_source.title')}
        </span>
      }
      onDelete={() => removeWidget(id)}
    >
      <Chart options={chartOptions} {...props} />
      <Box column className="gap-3">
        <Item text="VPN" amount="500.000" className="bg-[#01f1e3]" />
        <Item text="Firewall" amount="300.000" className="bg-[#a3da8d]" />
        <Item text="Endpoint" amount="200.000" className="bg-[#ffa974]" />
        <Item
          text="Active directory"
          amount="100.000"
          className="bg-[#d45a66]"
        />
      </Box>
    </Widget>
  );
}

export default WidgetEventDataSource;
