import React from 'react';

import { useForm } from 'react-hook-form';

import { useToast } from 'common/hooks/toasts';
import Box from 'components/Box';
import Button from 'components/Button';
import Input from 'components/Input';
import Text from 'components/Text';
import { useTranslation } from 'core/translations/useTranslation';

import {
  useCurrentTenantQuery,
  useCurrentUserQuery,
  useUpdateUserMutation,
} from '../../../services';
import ContentLayout from '../../ContentLayout';

const SettingsEditProfile = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm({ mode: 'onChange' });
  const { data: currentUser } = useCurrentUserQuery();
  const { data: tenant } = useCurrentTenantQuery();
  const t = useTranslation();

  const { showToast } = useToast();
  const { mutate: updateUser, isLoading: isUpdateUserLoading } =
    useUpdateUserMutation({
      onSuccess: () => {
        showToast(t('settings.edit_profile.success_toast'), 'success');
      },
    });

  React.useEffect(() => {
    try {
      reset({
        name: currentUser.first_name,
        lastName: currentUser.last_name,
        email: currentUser.email,
      });
    } catch (error) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const submit = (values) => {
    const { name, lastName, email } = values;

    updateUser({
      name,
      lastName,
      email,
    });
  };
  return (
    <ContentLayout title={t('settings.edit_profile.title')}>
      <Box column className="w-full gap-6 lg:max-w-[550px] ">
        <Box className="gap-3" ycenter>
          <img
            src={currentUser?.avatar}
            className="w-[80px] rounded-lg"
            alt="avatar"
          />
          <Box column>
            <Text subheading>
              {currentUser?.first_name} {currentUser?.last_name}
            </Text>
            {tenant && <Text>{tenant}</Text>}
          </Box>
        </Box>
        <form
          autoComplete="off"
          onSubmit={handleSubmit(submit)}
          className="flex w-full flex-col gap-2"
        >
          <Box column className="w-full gap-2 lg:flex-row lg:gap-3">
            <Input
              maxLength={20}
              label={t('common.first_name')}
              defaultValue={currentUser?.first_name}
              type="text"
              {...register('name', {
                required: t('validations.required'),
                pattern: {
                  value: /^[^\s]+(?:$|.*[^\s]+$)/,
                  message: t('validations.blank_space'),
                },
              })}
              error={errors.name?.message}
            />
            <Input
              maxLength={50}
              label={t('common.last_name')}
              defaultValue={currentUser?.last_name}
              type="text"
              {...register('lastName', {
                required: t('validations.required'),
                pattern: {
                  value: /^[^\s]+(?:$|.*[^\s]+$)/,
                  message: t('validations.blank_space'),
                },
              })}
              error={errors.lastName?.message}
            />
          </Box>
          <Input
            disabled
            label={t('common.email')}
            type="email"
            defaultValue={currentUser?.email}
            {...register('email', {
              required: t('validations.required'),
              pattern: {
                value: /^\S+@\S+\.\S+$/,
                message: t('validations.email'),
              },
            })}
            error={errors.email?.message}
          />

          <Button
            type="submit"
            className="ml-auto w-[200px]"
            loading={isUpdateUserLoading}
            disabled={!isDirty || !isValid}
          >
            {t('common.save_changes')}
          </Button>
        </form>
      </Box>
    </ContentLayout>
  );
};

export default SettingsEditProfile;
