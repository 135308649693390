import { useToggler } from 'common/hooks';
import Box from 'components/Box';
import Button from 'components/Button';
import Skeleton from 'components/Skeleton';
import { useTranslation } from 'core/translations/useTranslation';
import ContentLayout from 'features/profile/components/ContentLayout';
import { useGetEmailNotificationGroup } from 'features/profile/services';

import CardGroup from './CardGroup';
import CreateGroupModal from './CreateGroupModal';
import EmptyState from './EmptyState';

const GroupNotifications = () => {
  const t = useTranslation();
  const {
    isShown: isShownCreateModalNotifications,
    toggle: toggleCreateModalNotifications,
  } = useToggler();

  const { data, isLoading } = useGetEmailNotificationGroup();

  if (isLoading) {
    return (
      <ContentLayout title={t('settings.tabs.group_notifications.title')}>
        <Box className="grid grid-cols-2 gap-3 mt-4">
          <Skeleton height={82} />
          <Skeleton height={82} />
          <Skeleton height={82} />
          <Skeleton height={82} />
          <Skeleton height={82} />
          <Skeleton height={82} />
        </Box>
      </ContentLayout>
    );
  }

  if (!data?.length) {
    return (
      <ContentLayout title={t('settings.tabs.group_notifications.title')}>
        {isShownCreateModalNotifications && (
          <CreateGroupModal closeModal={toggleCreateModalNotifications} />
        )}
        <EmptyState onClick={toggleCreateModalNotifications} />
      </ContentLayout>
    );
  }

  return (
    <ContentLayout
      title={t('settings.tabs.group_notifications.title')}
      renderRigth={() => (
        <Button
          onClick={toggleCreateModalNotifications}
          className="max-w-[180px]"
          icon="plus"
          iconSize="medium"
        >
          {t('common.create_group')}
        </Button>
      )}
    >
      {isShownCreateModalNotifications && (
        <CreateGroupModal closeModal={toggleCreateModalNotifications} />
      )}

      <Box className="grid grid-cols-2 gap-3 mt-4 ">
        {data?.map((group) => {
          return (
            <>
              <CardGroup
                key={group.id}
                id={group.id}
                title={group.name}
                addresses={t(
                  'settings.notificacion.group_notification.card.adresses',
                  {
                    adress: group.emails?.length || 0,
                  }
                )}
                description={group.description}
              />
            </>
          );
        })}
      </Box>
    </ContentLayout>
  );
};

export default GroupNotifications;
