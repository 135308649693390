import theme from 'core/styles/theme';

export const getChartOptions = ({ categories, data }) => ({
  chart: {
    type: 'bar',
  },
  title: {
    text: '',
  },
  credits: {
    enabled: false,
  },
  legend: {
    enabled: true,
    labelFormatter: function () {
      return 'Incidents: <b>' + data.reduce((a, b) => a + b, 0) + '</b>';
    },
  },
  xAxis: {
    categories,
    labels: {
      style: {
        fontSize: theme.fontSize.sm,
        color: theme.colors.text,
        fontFamily: 'Roboto',
      },
    },
  },
  yAxis: {
    allowDecimals: false,
    title: {
      text: null,
    },
  },
  tooltip: {
    valueSuffix: '',
  },
  series: [
    {
      name: 'Incidents',
      data,
      colorByPoint: true,
      borderRadius: '5px',
    },
  ],
});
