import { useState } from 'react';

import { useTranslation } from 'core/translations/useTranslation';
import Layout from 'features/layout';
import DataSourceAlerts from 'features/profile/components/SettingsTabs/SettingsNotifications/DataSourceAlerts';
import GroupNotifications from 'features/profile/components/SettingsTabs/SettingsNotifications/GroupNotifications';
import RuleNotifications from 'features/profile/components/SettingsTabs/SettingsNotifications/RuleNotifications';

import {
  SettingsUpdatePassword,
  SettingsSidebar,
  SettingsEditProfile,
  SettingsPersonalInformation,
  SettingsLanguage,
} from '../../components';
import { TABS } from '../../constants/constants';

const Settings = () => {
  const t = useTranslation();
  const [activeTab, setActiveTab] = useState(TABS.PERSONAL_INFORMATION);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <Layout icon="settings" title={t('sidebar.settings')}>
      <SettingsSidebar
        activeTab={activeTab}
        handleTabClick={handleTabClick}
        className="pt-6 min-h-[600px]"
      />
      {activeTab === TABS.PROFILE && <SettingsEditProfile />}
      {activeTab === TABS.PASSWORD && <SettingsUpdatePassword />}
      {activeTab === TABS.PERSONAL_INFORMATION && (
        <SettingsPersonalInformation />
      )}
      {activeTab === TABS.RULE_NOTIFICATIONS && <RuleNotifications />}
      {activeTab === TABS.GROUP_NOTIFICATIONS && <GroupNotifications />}
      {activeTab === TABS.DATA_SOURCE_ALERTS && <DataSourceAlerts />}
      {activeTab === TABS.CHANGE_LANGUAGE && <SettingsLanguage />}
    </Layout>
  );
};

export default Settings;
