import { useSelector } from 'react-redux';

import { CACHE_IDS } from 'common/constants/cache';
import { WIDGET_TYPES_SLUGS } from 'common/constants/widgets';
import { useQuery } from 'core/api/react-query';
import { selectDateRange } from 'redux/filter/selectors';

import { getMaliciousIpsSources } from '../api';

const mapper = (data) => {
  const series = data?.source_ips;
  return [...series].reduce(
    (accumulator, item) => {
      const name = Object.keys(item)[0];
      const y = item[name];
      accumulator.categories.push(name);
      accumulator.data.push(y);
      return accumulator;
    },

    {
      categories: [],
      data: [],
    }
  );
};
export const useMaliciousIpsSources = () => {
  const { dateFrom, dateTo } = useSelector(selectDateRange);

  const query = useQuery(
    [
      CACHE_IDS.WIDGET_DATA,
      { slug: WIDGET_TYPES_SLUGS.MALICIOUS_IPS_SOURCES, dateTo, dateFrom },
    ],
    () =>
      getMaliciousIpsSources({
        dateFrom,
        dateTo,
        limit: 20,
        sort_by: 'count',
        order_by: 'desc',
      }),
    {
      select: (result) => mapper(result),
    }
  );
  return query;
};

export default useMaliciousIpsSources;
