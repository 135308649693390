import { cls } from 'core/styles/cls';

import Box from '../Box';
import Text from '../Text';

const Account = ({
  isWhite,
  className,
  isSidebarCollapsed,
  name,
  lastName,
  avatar,
  company,
  isLoading,
  ...props
}) => (
  <Box
    className={cls('w-full cursor-pointer gap-2', className)}
    ycenter
    {...props}
  >
    <Box className="overflow:hidden h-[56px] min-w-[56px] items-center justify-center rounded-lg bg-primary p-0">
      <img src={avatar} className="w-[48px]" alt="avatar" />
    </Box>
    {!isSidebarCollapsed && (
      <Box column>
        <Text color={isWhite && 'white'} bodyBold>
          {name} {lastName}
        </Text>
        <Text color={isWhite && 'white'}>{company}</Text>
      </Box>
    )}
  </Box>
);

export default Account;
