import React from 'react';

import { StringParam, useQueryParam } from 'use-query-params';

import PaginatedTable from 'common/components/tables/PaginatedTable';
import { usePagination } from 'common/hooks';
import Box from 'components/Box';
import Button from 'components/Button';
import Input from 'components/Input';
import Select from 'components/Select';
import { useTranslation } from 'core/translations/useTranslation';
import {
  useCaseTypeFilters,
  useEntityTypeFilters,
  useEntitiesDataQuery,
} from 'features/entities/services';

import { useFilters } from '../../hooks/useFilters';
import { useColumns } from './table';

const AllEntitiesView = () => {
  const t = useTranslation();
  const { currentPage, onPageChange } = usePagination();

  const { filters, inputProps, onFiltersSubmit } = useFilters();

  const { data: caseTypes, isLoading: isCaseTypesLoading } =
    useCaseTypeFilters();

  const { data: entityTypes, isLoading: isEntityTypesLoading } =
    useEntityTypeFilters();

  const [sortBy = 'date', setSortBy] = useQueryParam('sortBy', StringParam);
  const [orderBy = 'desc', setOrderBy] = useQueryParam('orderBy', StringParam);

  const getFiltersProps = (id) => {
    return {
      onClick: () => {
        setSortBy(id);
        if (orderBy === 'asc') setOrderBy('desc');
        if (orderBy === 'desc') setOrderBy('asc');
      },
    };
  };

  const caseTypesOptions = React.useMemo(
    () =>
      caseTypes?.map((item) => ({
        value: item,
        label: item,
      })),
    [caseTypes]
  );

  const entityTypesOptions = React.useMemo(
    () =>
      entityTypes?.map((item) => ({
        value: item,
        label: item,
      })),
    [entityTypes]
  );

  const {
    data,
    isLoading: isTableFetching,
    isError: isTableError,
    isPreviousData,
    refetch,
  } = useEntitiesDataQuery({
    page: currentPage,
    caseTypes: filters.caseTypes,
    entityTypes: filters.entityTypes,
    entities: filters.entities,
    sortBy: sortBy,
    orderBy: orderBy,
    isSuspicious: filters.suspicious,
  });

  function handleSubmit(e) {
    e.preventDefault();
    onFiltersSubmit(refetch);
    onPageChange(1);
  }

  return (
    <Box column className="gap-4 overflow-hidden">
      <form
        onSubmit={handleSubmit}
        className="flex gap-4 w-full overflow-x-auto justify-between pt-2 pb-2"
      >
        <Input
          noValidation
          label={t('entities.label_input')}
          name="search"
          iconR="search"
          {...inputProps.entities}
        />
        <Select
          placeholder={t('entities.select_case')}
          isMulti
          isLoading={isCaseTypesLoading}
          optionsData={caseTypesOptions}
          {...inputProps.caseTypes}
          className="min-w-[130px]"
        />
        <Select
          placeholder={t('entities.select_type')}
          isMulti
          isLoading={isEntityTypesLoading}
          optionsData={entityTypesOptions}
          {...inputProps.entityTypes}
          className="min-w-[130px]"
        />
        <Select
          isMulti
          isLoading={isCaseTypesLoading}
          placeholder={t('select.suspicious')}
          optionsData={[
            {
              value: 'Yes',
              chipLabel: t('common.suspicious'),
              label: t('common.suspicious'),
            },
            {
              value: 'No',
              chipLabel: t('common.not_suspicious'),
              label: t('common.not_suspicious'),
            },
          ]}
          {...inputProps.suspicious}
          className="min-w-[130px]"
        />

        <Button type="submit" className="h-6" loading={isTableFetching}>
          {t('common.search')}
        </Button>
      </form>
      <PaginatedTable
        data={data?.results}
        columns={useColumns({
          getFiltersProps,
          orderBy,
          sortBy,
        })}
        isFetching={isTableFetching}
        isError={isTableError}
        refetch={refetch}
        isShowingPreviousData={isPreviousData}
        currentPage={currentPage}
        pageSize={data?.page_size}
        totalPages={data?.total_pages}
        totalResults={data?.total_count}
        onPageChange={onPageChange}
        withMinWidth
      />
    </Box>
  );
};

export default AllEntitiesView;
