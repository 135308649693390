import React, { useState } from 'react';

import OutsideClickHandler from 'react-outside-click-handler';

import { WIDGET_IMAGES } from 'common/constants/widgets';
import { useChipList, useControlledInput } from 'common/hooks';
import Box from 'components/Box';
import Chip from 'components/Chip';
import Icon from 'components/Icon';
import Input from 'components/Input';
import Text from 'components/Text';
import { useTranslation } from 'core/translations/useTranslation';

import { useDashboard } from '../../../dashboard/hooks/useDashboard';
import {
  useAllWidgetTypesQuery,
  useGetWidgetTypeTagsQuery,
} from '../../services';
import ContentEmptyState from './ContentEmptyState';
import ContentSkeleton from './ContentSkeleton';
import WidgetImage from './WidgetImage';

const Sidebar = ({ closeSidebar, selectSidebarWidget }) => {
  const t = useTranslation();
  const { layout } = useDashboard();
  const { activeChips, onClick: handleChipClick } = useChipList();

  const [onDragStart, setOnDragStart] = useState(false);
  const { value: searchValue, onChange: onSearchChange } = useControlledInput();
  const { data: widgetTypes, isLoading: isWidgetTypesLoading } =
    useAllWidgetTypesQuery(activeChips);
  const { data: tags } = useGetWidgetTypeTagsQuery();
  const [shownWidgetTypes, setShownWidgetTypes] = React.useState(widgetTypes);
  const [filteredWidgetTypes, setFilteredWidgetTypes] =
    React.useState(shownWidgetTypes);

  const onWidgetDragStart = (id) => {
    selectSidebarWidget(id);
    setOnDragStart(true);
  };

  React.useEffect(() => {
    if (onDragStart) {
      closeSidebar();
    }
  }, [closeSidebar, onDragStart]);

  // Filters the widget types based on the search
  React.useEffect(() => {
    const widgetsToShow = shownWidgetTypes?.filter((widget) =>
      widget.default_title?.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredWidgetTypes(widgetsToShow);
  }, [searchValue, shownWidgetTypes]);

  // Remove the widgets that are being used in the layout
  React.useEffect(() => {
    const unusedWidgets = widgetTypes?.filter((widgetType) => {
      const isInLayout = layout?.find(
        (layoutWidget) => layoutWidget.typeId === widgetType.id
      );
      return !isInLayout;
    });
    setShownWidgetTypes(unusedWidgets);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout?.length, widgetTypes]);
  return (
    <OutsideClickHandler onOutsideClick={closeSidebar}>
      <Box
        full
        className="fixed top-0 right-0 z-[3] h-screen w-[412px] overflow-y-scroll border-[1px] border-gray bg-white py-3 px-5"
        column
      >
        <Icon
          cursor="pointer"
          onClick={closeSidebar}
          color="secondary"
          className="absolute top-1 left-2"
          name="close"
        />

        <Box column className="mt-6 gap-3">
          <Box column className="pb-24 gap-3 border-b-[1px] border-gray">
            <Text bodyBold>{t('secpos.sidebar.title')}</Text>

            <Input
              value={searchValue}
              onChange={onSearchChange}
              noValidation
              placeholder={t('secpos.sidebar.input')}
              className="w-full border-[1px] border-gray p-1 text-lightText"
              iconR="search"
            />

            <Box className="flex-wrap gap-2 pb-3">
              {tags?.results?.map((tag) => {
                return (
                  <Chip onClick={handleChipClick} id={tag}>
                    {tag}
                  </Chip>
                );
              })}
            </Box>
          </Box>
          {isWidgetTypesLoading ? (
            <ContentSkeleton />
          ) : filteredWidgetTypes && filteredWidgetTypes.length ? (
            filteredWidgetTypes.map((widgetType) => (
              <WidgetImage
                onDragStart={() => {
                  onWidgetDragStart(widgetType.id);
                }}
                key={widgetType.id}
                src={WIDGET_IMAGES[widgetType.slug]}
                title={widgetType.default_title}
                description={widgetType.default_description}
              />
            ))
          ) : (
            <ContentEmptyState />
          )}
        </Box>
      </Box>
    </OutsideClickHandler>
  );
};

export default Sidebar;
