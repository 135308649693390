import React from 'react';

import Box from 'components/Box';
import Button from 'components/Button';
import Input from 'components/Input';
import Select from 'components/Select';
import Text from 'components/Text';
import { useTranslation } from 'core/translations/useTranslation';

import { useIntegrationsTagsQuery } from '../../services/useIntegrationsTagsQuery';

const Search = ({
  isLoading,
  onFiltersSubmit,
  refetch,
  inputProps,
  onPageChange,
}) => {
  const t = useTranslation();
  const { data: tags, isLoading: isTagsLoading } = useIntegrationsTagsQuery();
  const tagsOptions = React.useMemo(
    () =>
      tags?.results?.map((item) => ({
        value: item,
        label: item,
      })),
    [tags]
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    onFiltersSubmit(refetch);
    onPageChange(1);
  };

  return (
    <Box
      full
      column
      bg="white"
      className="p-5 rounded-md border-[1px] border-gray gap-4"
    >
      <Box className="gap-4">
        <Text subtitle>{t('catalogue.filters.title')}</Text>
      </Box>
      <form onSubmit={handleSubmit}>
        <Box ycenter between className="gap-3 ">
          <Input
            noValidation
            iconR="search"
            placeholder={t('catalogue.filters.search')}
            {...inputProps.integrations}
          />
          <Select
            isMulti
            isLoading={isTagsLoading}
            optionsData={tagsOptions}
            className="w-1/2"
            placeholder={t('catalogue.filters.select')}
            {...inputProps.tags}
          />
          <Button type="submit" loading={isLoading}>
            {t('common.search')}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default Search;
