import React from 'react';

import { useToggler } from 'common/hooks';
const SidebarContext = React.createContext();

export const SidebarProvider = ({ children }) => {
  const { isShown: isSidebarCollapsed, toggle: collapseSidebar } = useToggler();

  return (
    <SidebarContext.Provider value={{ isSidebarCollapsed, collapseSidebar }}>
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = () => {
  const { isSidebarCollapsed, collapseSidebar } =
    React.useContext(SidebarContext);

  return {
    isSidebarCollapsed,
    collapseSidebar,
  };
};
