import { get, post } from 'core/api/client';

export const getIntegrations = async ({ page, integrations, tags }) => {
  return post('/integrations/', {
    requested_page: page,
    page_size: 6,
    integrations_name: integrations,
    tags_name: tags,
  });
};

export const getIntegrationsTags = async () => get('/integrations_tags');

export const getVendors = async ({ vendors, page, tags }) => {
  return post('/vendors/', {
    vendors,
    requested_page: page,
    page_size: 6,
    tags,
  });
};
