import Box from 'components/Box';
import Text from 'components/Text';
import { cls } from 'core/styles/cls';

const Tag = ({ children, status, className, ellipsis }) => {
  return (
    <Box
      xcenter
      className={cls(
        'w-[64px] rounded-md py-[6px] px-[12px]',
        {
          'bg-lightGreen  text-darkGreen':
            status === 'success' || status === 'informational',
          'bg-lightYellow text-darkYellow': status === 'warning',
          'bg-lightOrange text-darkOrange':
            status === 'danger' || status === 'high',
          'bg-veryLightRed text-error':
            status === 'error' || status === 'critical',
          'bg-lightBlue text-primary': status === 'tag',
        },
        className
      )}
    >
      <Text badgeBold ellipsis={ellipsis} className="text-inherit">
        {children}
      </Text>
    </Box>
  );
};

export default Tag;
