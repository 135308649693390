import React from 'react';

import Box from 'components/Box';
import Chip from 'components/Chip';
import Modal from 'components/Modal';
import Tag from 'components/Tag';
import Text from 'components/Text';
import i18n from 'core/translations/init';
import { useTranslation } from 'core/translations/useTranslation';

import { Separator } from '../TabsView/Overview/Overview';

const formatData = (data) => {
  const get = (name) => data?.find((item) => item.name === name || undefined);
  const transform = (item, renderValue) =>
    item
      ? {
          name: item.name,
          value: renderValue(item.value),
        }
      : undefined;

  return [
    get('Device Vendor'),
    transform(get('Product'), (productValue) => (
      <Chip disabled>{productValue}</Chip>
    )),
    get('Device'),
    get('VIP User'),
    get('User'),
    get('Source IP'),
    get('Destination IP'),
    get('URL'),
    get('file_hash'),
    get('Signature'),
    transform(get('Severity'), (severityValue) => (
      <Tag className="capitalize w-fit" status={severityValue}>
        {i18n.t(`common.${severityValue}`)}
      </Tag>
    )),
    get('Action'),
    get('Type'),
  ].filter((item) => item !== undefined);
};

const RawEventsModal = ({ data, toggle }) => {
  const t = useTranslation();
  // sorts data based on specifications and converts Severity and Product to components
  const formattedData = React.useMemo(() => formatData(data), [data]);

  const halfLength = formattedData?.length / 2 + 1;
  const firstPartdata = formattedData?.slice(0, halfLength);
  const secondPartdata = formattedData?.slice(
    halfLength,
    formattedData?.length
  );

  return (
    <Modal
      toggle={toggle}
      title={t('incidents.details.tabs.raw_events.modal.title')}
    >
      <Box full between className="px-4 py-4">
        <Box column className="mt-3 gap-2">
          {firstPartdata?.map((item) => (
            <Box key={item.name} className="w-[270px]" between>
              <Text bodyBold color="lightText">
                {t(`incidents.details.tabs.raw_events.types.${item.name}`)}
              </Text>
              <Box className="w-[110px] break-all">
                <Text body>{item.value}</Text>
              </Box>
            </Box>
          ))}
        </Box>
        <Separator className="w-[1px] bg-gray" />
        <Box column className="mt-3 gap-2">
          {secondPartdata?.map((item) => (
            <Box key={item.name} className="w-[270px] h-fit" between>
              <Text bodyBold color="lightText">
                {t(`incidents.details.tabs.raw_events.types.${item.name}`)}
              </Text>
              <Box className="w-[150px] break-all">
                <Text body>{item.value}</Text>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Modal>
  );
};

export default RawEventsModal;
