import moment from 'moment';

import theme from 'core/styles/theme';
const INTERVAL_HOURS = 3600 * 1000;
const INTERVAL_DAYS = 24 * 3600 * 1000;
export const getChartOptions = ({ minDate, series, rangeDays }) => {
  return {
    title: {
      text: '',
    },
    chart: {
      zoomType: 'x',
    },
    credits: {
      enabled: false,
    },
    legend: {
      align: 'center',
      verticalAlign: 'top',
      itemStyle: {
        fontSize: theme.fontSize.base,
        color: theme.colors.text,
        fontFamily: 'roboto',
        fontWeight: theme.fontWeight.regular,
      },
    },
    xAxis: {
      type: 'datetime',
      tickInterval: rangeDays === 1 ? INTERVAL_HOURS : INTERVAL_DAYS,
      min: minDate,
      labels: {
        style: {
          fontSize: theme.fontSize.small,
          color: theme.colors.text,
          fontFamily: 'roboto',
        },
      },
    },
    tooltip: {
      formatter: function () {
        return (
          moment(this.x).format('dddd, MMMM Do YYYY') +
          '<br/>' +
          this.series.name +
          ':' +
          ' ' +
          `<b>${this.point.y}</b>`
        );
      },
    },
    yAxis: {
      title: {
        text: null,
      },
    },

    colors: ['#dc0000', '#fd8c00', '#fdc500', '#00ac46'],
    series,
  };
};
