import React from 'react';

import { useControlledInput } from 'common/hooks';
import Box from 'components/Box';
import Icon from 'components/Icon';
import Input from 'components/Input';
import Text from 'components/Text';
import TimePicker from 'components/TimePicker';
import { useTranslation } from 'core/translations/useTranslation';

const DayTimePickerRow = ({ hour, selectHour, removePicker, hasError }) => {
  const t = useTranslation();
  const { value: inputValueMinutes, onChange: onInputChange } =
    useControlledInput();

  React.useEffect(() => {
    selectHour({ idHour: hour.id, inputValueMinutes });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValueMinutes]);

  return (
    <Box full ycenter className="gap-4">
      <Box full ycenter className="gap-3">
        <TimePicker
          hasError={hasError}
          hour={hour?.hour_from}
          onChange={(valueFrom) => {
            selectHour({
              idHour: hour.id,
              valueFrom,
            });
          }}
        />
        <Text body color="lightText">
          {t('common.to')}
        </Text>
        <TimePicker
          hasError={hasError}
          hour={hour?.hour_to}
          onChange={(valueTo) => {
            selectHour({
              idHour: hour.id,
              valueTo,
            });
          }}
        />
        <Input
          min={0}
          type="number"
          onChange={onInputChange}
          defaultValue={hour?.max_elapsed_minutes / 60}
          noValidation
          label={t('common.minutes')}
        />
      </Box>
      <Icon
        cursor="pointer"
        color="lightText"
        onClick={() => removePicker({ idHour: hour.id })}
        name="delete"
        size="small"
      />
    </Box>
  );
};
export default DayTimePickerRow;
