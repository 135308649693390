import { useMutation } from 'core/api/react-query';
import { Cookie } from 'core/cookies';
import { changeLanguage } from 'core/translations/changeLanguage';
import { getLanguageCode } from 'core/translations/utils';
import { getCurrentLanguage } from 'features/profile/api';

import { postLogin } from '../api';

export const useLoginMutation = ({ onSuccess, onError }) => {
  const mutation = useMutation(postLogin, {
    onSuccess: async (data) => {
      Cookie.set('token', data?.access_token);
      if (onSuccess) {
        const lng = await getCurrentLanguage();
        changeLanguage(getLanguageCode(lng));
        setTimeout(() => {
          onSuccess();
        }, [200]);
      }
    },
    onError,
  });

  return mutation;
};

export default useLoginMutation;
