import { useState } from 'react';

import { Link } from 'react-router-dom';

import Image from 'assets/security-posture/not-found.png';
import Box from 'components/Box';
import Card from 'components/Card';
import Icon from 'components/Icon';
import Skeleton from 'components/Skeleton';
import Tag from 'components/Tag';
import Text from 'components/Text';
import Tooltip from 'components/Tooltip';
import { cls } from 'core/styles/cls';
import { useTranslation } from 'core/translations/useTranslation';

import Pagination from './Pagination';

const ENTITY_TYPES = {
  ADDRESS: 'ADDRESS',
  HOSTNAME: 'HOSTNAME',
  DestinationURL: 'DestinationURL',
  FILENAME: 'FILENAME',
  FILEHASH: 'FILEHASH',
  THREATSIGNATURE: 'THREATSIGNATURE',
  DOMAIN: 'DOMAIN',
  USER: 'USERUNIQNAME',
};

const IsSuspiciousBadge = ({ isSuspicious }) => {
  const t = useTranslation();
  return !isSuspicious ? (
    <Tag className="w-fit" status="success">
      {t('common.no')}
    </Tag>
  ) : (
    <Tag className="w-fit" status="error">
      {t('common.yes')}
    </Tag>
  );
};

const Entity = ({ data, setLink }) => {
  const type = data?.entityType;
  const identifier = data?.identifier;

  const icon_by_type = {
    [ENTITY_TYPES.ADDRESS]: 'ip',
    [ENTITY_TYPES.HOSTNAME]: 'host',
    [ENTITY_TYPES.DestinationURL]: 'link',
    [ENTITY_TYPES.FILENAME]: 'file',
    [ENTITY_TYPES.FILEHASH]: 'hash',
    [ENTITY_TYPES.THREATSIGNATURE]: 'threats',
    [ENTITY_TYPES.DOMAIN]: 'domain',
    [ENTITY_TYPES.USER]: 'users',
  };

  const color_by_type = {
    [ENTITY_TYPES.ADDRESS]: 'danger',
    [ENTITY_TYPES.HOSTNAME]: 'primary',
    [ENTITY_TYPES.DestinationURL]: 'primary',
    [ENTITY_TYPES.FILENAME]: 'darkBlue',
    [ENTITY_TYPES.FILEHASH]: 'lightText',
    [ENTITY_TYPES.THREATSIGNATURE]: 'warning',
    [ENTITY_TYPES.DOMAIN]: 'primary',
    [ENTITY_TYPES.USER]: 'error',
  };

  const handleClick = () => {
    setLink(`/entities-details/${encodeURIComponent(identifier)}/${type}`);
  };

  const maxCharacters = 35;

  const truncatedIdentifier =
    type === 'ADDRESS' ||
    type === 'HOSTNAME' ||
    type === 'DestinationURL' ||
    type === 'FILENAME' ||
    type === 'FILEHASH' ||
    type === 'THREATSIGNATURE' ||
    type === 'DOMAIN' ||
    type === 'USERUNIQNAME'
      ? identifier.length > maxCharacters
        ? identifier.substring(0, maxCharacters) + '...'
        : identifier.replaceAll('/', '%')
      : identifier;

  return (
    <Box
      key={data.identifier}
      between
      className="cursor-pointer"
      onMouseDown={handleClick}
    >
      <Box ycenter className="gap-2">
        <Box
          ycenter
          xcenter
          bg={color_by_type[data.entityType]}
          className="h-[32px] w-[32px] rounded-[100%] text-white"
        >
          <Icon
            color="white"
            className="text-lg"
            name={icon_by_type[data.entityType]}
          />
        </Box>
        <Box
          data-tip
          data-for={`ENTITIES_${identifier}`}
          className="gap-1 relative w-fit overflow-visible cursor-pointer"
          ycenter
        >
          <Text link body style={{ maxWidth: '100px' }}>
            {truncatedIdentifier}
          </Text>
          <Tooltip
            id={`ENTITIES_${identifier}`}
            content={identifier}
            bg="white"
            width="auto"
            zIndex={9999}
            offset={{ top: -10, left: 0 }}
            place="right-start"
          >
            <Text
              color="white"
              body
              className="text-clip overflow-hidden tooltip-text text-sm"
              cursor="pointer"
            >
              {identifier}
            </Text>
          </Tooltip>
        </Box>
      </Box>
      <IsSuspiciousBadge isSuspicious={data.isSuspicious} />
    </Box>
  );
};

const RelatedEntities = ({ data, isLoading }) => {
  const ENTITIESPERPAGE = 5;
  const t = useTranslation();
  const entities = data?.linked_entities;
  const [link, setLink] = useState('');

  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastPost = currentPage * ENTITIESPERPAGE;
  const indexOfFirstPost = indexOfLastPost - ENTITIESPERPAGE;
  const currentEntities = entities?.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <Card size>
      <Box
        column
        className={cls('gap-3 h-full', {
          hidden: !entities?.length,
        })}
      >
        <Box between>
          <Text body color="lightText">
            {t('details.related_entities.table.header.identifier')}
          </Text>
          <Text body color="lightText">
            {t('details.related_entities.table.header.suspicious')}
          </Text>
        </Box>
        {isLoading ? (
          <Skeleton />
        ) : (
          !!currentEntities?.length &&
          currentEntities?.map((entity) => (
            <Link key={entity.id} to={link}>
              <Entity setLink={setLink} data={entity} />
            </Link>
          ))
        )}
        <Pagination
          currentPage={currentPage}
          className="self-end"
          entitiesPerPage={ENTITIESPERPAGE}
          totalEntities={entities?.length}
          paginate={paginate}
        />
      </Box>
      {!!isLoading ? (
        <Skeleton />
      ) : (
        !entities?.length && (
          <Box full column xcenter ycenter className="h-full">
            <Box xcenter column className="gap-3 ">
              <img className="w-[60px]" src={Image} alt="logo" />
              <Text bodyBold color="lightText">
                {t('details.related_entities.table.no_data')}
              </Text>
            </Box>
          </Box>
        )
      )}
    </Card>
  );
};

export default RelatedEntities;
