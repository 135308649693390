import React from 'react';

import { ArrayParam, useQueryParam } from 'use-query-params';

const useSelectUrlFilter = (name) => {
  const [query, setQuery] = useQueryParam(name, ArrayParam);
  const [value, setValue] = React.useState(query);

  const onChange = (selectedOptions) => {
    const result = selectedOptions.map((option) => {
      return option.value;
    });
    setValue(result);
  };

  const isSync = query === value;

  const syncFilter = () => {
    setQuery(value, 'replaceIn');
  };

  const clear = () => {
    setQuery([], 'replaceIn');
    setValue([]);
  };

  const selectValue = React.useMemo(() => {
    return value?.map((optionValue) => ({
      value: optionValue,
      label: optionValue,
    }));
  }, [value]);

  const filter = query ? query : [];

  return {
    onChange,
    syncFilter,
    filter,
    value: selectValue,
    isSync,
    clear,
  };
};

export default useSelectUrlFilter;
