import React from 'react';

import { useWidgetsQuery } from '../services';
import { LOCAL_STORAGE_LAYOUT_BACKUP } from './useEditMode';

const DashboardContext = React.createContext({
  layout: null,
  setLayout: () => {},
  isLoading: true,
  isEditModeEnabled: false,
  setIsEditModeEnabled: () => {},
});

export const DashboardProvider = ({ dashboardId, children }) => {
  const [layout, setLayout] = React.useState();
  const [isEditModeEnabled, setIsEditModeEnabled] = React.useState(false);

  const { data: backendLayout, isLoading } = useWidgetsQuery({ dashboardId });

  // Updates the layout with the backend data
  React.useEffect(() => {
    if (backendLayout) {
      setLayout(backendLayout);
    }
  }, [backendLayout, setLayout]);

  return (
    <DashboardContext.Provider
      value={{
        layout,
        setLayout,
        isLoading,
        isEditModeEnabled,
        setIsEditModeEnabled,
        dashboardId,
      }}
    >
      {children && children}
    </DashboardContext.Provider>
  );
};

// Utils
const getLayoutWidgetById = (id, layout) => {
  const currentLayoutWidget = layout?.find(
    (currentWidget) => currentWidget.i === id
  );
  return currentLayoutWidget;
};

export const useDashboard = () => {
  const {
    layout,
    setLayout,
    isLoading,
    setIsEditModeEnabled,
    isEditModeEnabled,
    dashboardId,
  } = React.useContext(DashboardContext);

  const onLayoutChange = (newLayout) => {
    if (layout) {
      const mapped = newLayout.map((newWidget) => {
        const currentLayoutWidget = getLayoutWidgetById(newWidget.i, layout);
        return { ...currentLayoutWidget, ...newWidget };
      });

      setLayout(mapped);
    }
  };

  const removeWidget = (id) => {
    const filteredLayout = layout?.filter((widget) => widget.i !== id);
    setLayout(filteredLayout);
  };

  const addWidget = (widget, baseLayout) => {
    const mappedLayout = baseLayout.map((layoutWidget) => {
      if (layoutWidget.i === widget.i) {
        return {
          ...layoutWidget,
          ...widget,
        };
      }
      return layoutWidget;
    });
    onLayoutChange(mappedLayout);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialLayout = localStorage[LOCAL_STORAGE_LAYOUT_BACKUP]
    ? JSON.parse(localStorage[LOCAL_STORAGE_LAYOUT_BACKUP])
    : [];

  const removedWidgets = React.useMemo(() => {
    return isEditModeEnabled
      ? initialLayout.filter(
          ({ slug: id1 }) => !layout.some(({ slug: id2 }) => id2 === id1)
        )
      : [];
  }, [isEditModeEnabled, initialLayout, layout]);

  const addedWidgets = React.useMemo(
    () =>
      isEditModeEnabled
        ? layout.filter(
            ({ slug: id1 }) =>
              !initialLayout?.some(({ slug: id2 }) => id2 === id1)
          )
        : [],
    [isEditModeEnabled, initialLayout, layout]
  );

  const updatedWidgets = React.useMemo(
    () =>
      isEditModeEnabled
        ? layout.filter(
            ({ slug: id1, x: x1, y: y1, w: w1, h: h1 }) =>
              !initialLayout?.some(
                ({ slug: id2, x: x2, y: y2, w: w2, h: h2 }) =>
                  id2 === id1 &&
                  x1 === x2 &&
                  y1 === y2 &&
                  w1 === w2 &&
                  h1 === h2
              )
          )
        : [],
    [isEditModeEnabled, initialLayout, layout]
  );

  const hasBeenEdited = React.useMemo(
    () =>
      !!updatedWidgets?.length ||
      !!removedWidgets?.length ||
      !!addedWidgets?.length,
    [addedWidgets?.length, removedWidgets?.length, updatedWidgets?.length]
  );

  return {
    layout,
    setLayout,
    isLoading,
    onLayoutChange,
    setIsEditModeEnabled,
    isEditModeEnabled,
    removeWidget,
    addWidget,
    dashboardId,
    removedWidgets,
    addedWidgets,
    updatedWidgets,
    hasBeenEdited,
  };
};
