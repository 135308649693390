import { StringParam, useQueryParam } from 'use-query-params';

export const useTabs = ({ initialTab }) => {
  const [activeTab = initialTab, setActiveTab] = useQueryParam(
    'tab',
    StringParam
  );

  const getTabProps = (id) => {
    return {
      onClick: () => {
        setActiveTab(id);
      },
      isActive: activeTab === id,
    };
  };

  return { getTabProps, activeTab };
};
