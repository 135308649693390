import React from 'react';

import { changeLanguage } from 'i18next';

import { useCurrentLanguageQuery } from 'features/profile/services';

import { getLanguageCode } from './utils';

const LanguageProvider = ({ children }) => {
  const { data: language } = useCurrentLanguageQuery();
  React.useEffect(() => {
    if (language) changeLanguage(getLanguageCode(language));
  }, [language]);
  return <>{children}</>;
};

export default LanguageProvider;
