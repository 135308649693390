import { CACHE_IDS } from 'common/constants/cache';
import { useQuery } from 'core/api/react-query';

import { getLanguages } from '../api';

export const useLanguageQuery = () => {
  const query = useQuery([CACHE_IDS.LANGUAGE], getLanguages);

  return query;
};

export default useLanguageQuery;
