import React from 'react';

import moment from 'moment';
import { Link } from 'react-router-dom';

import Box from 'components/Box';
import Pagination from 'components/Pagination';
import Text from 'components/Text';
import { getDaysFromNow } from 'core/dates';
import { useTranslation } from 'core/translations/useTranslation';

const TableView = ({ data, onPageChange }) => {
  const t = useTranslation();

  return (
    <Box full column className="gap-3  overflow-hidden">
      <div className="overflow-y-auto overflow-x-hidden">
        <table className="table-auto  border-spacing-1 w-full">
          <thead className="sticky top-0 bg-white pb-1">
            <tr>
              <th>
                <Box className="text-start">
                  <Text body color="lightText">
                    {t('secpos.widgets.quick_list.table.headers.identifier')}
                  </Text>
                </Box>
              </th>
              <th>
                <Box className="text-start">
                  <Text body color="lightText">
                    {t('secpos.widgets.quick_list.table.headers.typology')}
                  </Text>
                </Box>
              </th>
              <th>
                <Box className="text-start">
                  <Text body color="lightText">
                    {t('secpos.widgets.quick_list.table.headers.time')}
                  </Text>
                </Box>
              </th>
            </tr>
          </thead>
          <tbody className="mt-4">
            {data?.cases?.map((value) => (
              <tr>
                <td className="align-top">
                  <Link to={`/incidents/${value.id}`}>
                    <Text cursor="pointer" color="primary" className="sm:mr-6">
                      {value.id}
                    </Text>
                  </Link>
                </td>
                <td className="align-top">
                  <Text className="text-left break-normal md:break-all">
                    {value.event_typology}
                  </Text>
                </td>
                <td className="lg:w-[155px] align-top">
                  <Box column className="gap-1 items-end mr-4">
                    <Box className="gap-1 flex-col lg:flex-row items-end">
                      <Text className="w-[54px] break-all lg:w-fit">
                        {moment(value.date).format('DD-MM-YYYY')}
                      </Text>
                      <Text>{moment(value.date).format('HH:mm:ss')}</Text>
                    </Box>
                    <Text badgeBold color="lightText">
                      {getDaysFromNow(value.date)}
                    </Text>
                  </Box>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        numPages={data?.total_pages}
        currentPage={data?.page_number}
        onPageChange={onPageChange}
      />
    </Box>
  );
};

export default TableView;
