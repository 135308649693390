import moment from 'moment';

import theme from 'core/styles/theme';
const INTERVAL_HOURS = 3600 * 1000;
const INTERVAL_DAYS = 24 * 3600 * 1000;
export const getChartOptions = ({ minDate, series, rangeDays }) => ({
  title: {
    text: '',
  },
  credits: {
    enabled: false,
  },
  legend: {
    align: 'center',
    verticalAlign: 'top',
    itemStyle: {
      fontSize: theme.fontSize.base,
      color: theme.colors.text,
      fontFamily: 'roboto',
      fontWeight: theme.fontWeight.regular,
    },
  },
  tooltip: {
    formatter: function () {
      return (
        moment(this.x).format('dddd, MMMM Do YYYY') +
        '<br/>' +
        this.series.name +
        ':' +
        ' ' +
        `<b>${this.point.y}</b>`
      );
    },
  },
  xAxis: {
    type: 'datetime',
    tickInterval: rangeDays === 1 ? INTERVAL_HOURS : INTERVAL_DAYS,
    min: minDate,

    labels: {
      style: {
        fontSize: theme.fontSize.small,
        color: theme.colors.text,
        fontFamily: 'roboto',
      },
    },
  },
  yAxis: {
    title: {
      text: null,
    },
  },
  colors: ['#D45A66', '#00B929', '#4CDFE8', '#fbd360', '#ffa974', '#1f3740'],
  series,
});
