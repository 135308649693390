import React from 'react';

import { motion, AnimatePresence } from 'framer-motion';

import { useToggler } from 'common/hooks';
import Box from 'components/Box';
import Icon from 'components/Icon';
import { cls } from 'core/styles/cls';

const Accordion = ({
  children,
  title,
  status,
  id,
  noStatus,
  className,
  onClick,
  render,
  ...props
}) => {
  const { isShown: isOpen, toggle } = useToggler();
  const handleClick = (e) => {
    e.stopPropagation();
    if (onClick && id) onClick(id);
    toggle();
  };

  return (
    <Box
      full
      column
      className={cls(
        'border-b border-gray last:border-b-0 overflow-hidden',
        className
      )}
      {...props}
    >
      <Box
        between
        xcenter
        ycenter
        className={cls('py-3 px-3 cursor-pointer bg-white', className)}
        onClick={(e) => handleClick(e)}
      >
        <Box className="gap-2" ycenter>
          {!noStatus && (
            <Box
              bg={status ? 'success' : 'gray'}
              className="w-[12px] h-[12px] rounded-[100%]"
            />
          )}
          {render && render()}
        </Box>

        <Icon
          name={isOpen ? 'arrow-down' : 'arrow-up'}
          color={isOpen ? 'primary' : 'lightText'}
        />
      </Box>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { height: 'auto' },
              collapsed: { height: 0 },
            }}
          >
            <Box
              column
              full
              bg="white"
              className="border-gray border-b last:border-0 overflow-hidden"
            >
              <Box column className="p-5 gap-3 border-t border-gray">
                {children}
              </Box>
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
};

export default Accordion;
