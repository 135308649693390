import Box from 'components/Box';
import Text from 'components/Text';
import { cls } from 'core/styles/cls';

const ContentLayout = ({
  children,
  title,
  xcenter,
  className,
  renderRigth,
}) => {
  return (
    <Box xcenter className="h-full w-full pt-6 pl-6" column>
      <Box column className={cls('w-full max-w-[900px]', className)}>
        <Box between>
          <Text subtitle className="mb-4">
            {title}
          </Text>
          {!!renderRigth && renderRigth()}
        </Box>
        <Box xcenter={xcenter} className="flex flex-col h-full">
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default ContentLayout;
