import React from 'react';

import { useToast } from 'common/hooks/toasts';
import Box from 'components/Box';
import Icon from 'components/Icon';
import Pagination from 'components/Pagination';
import Select from 'components/Select';
import SelectorCard from 'components/SelectorCard';
import Skeleton from 'components/Skeleton';
import Text from 'components/Text';
import { weekdayNames } from 'core/dates';
import { cls } from 'core/styles/cls';
import { useTranslation } from 'core/translations/useTranslation';
import ContentLayout from 'features/profile/components/ContentLayout';
import {
  useGetAlertsMonitorConfigQuery,
  useUpdateMonitorConfigMutation,
  useGetEmailNotificationGroup,
} from 'features/profile/services';

import DayAccordion from '../components/DayAccordion';

const WeekDays = ({ selectedAlert, onSubmit }) => {
  return (
    <Box full column>
      {weekdayNames?.map((day, i) => {
        return (
          <DayAccordion
            day={day}
            key={i}
            idDay={i}
            idAlert={selectedAlert?.id}
            initialHoursConfig={selectedAlert?.hours_config?.filter(
              (h) => h.day === i
            )}
            onSubmit={onSubmit}
            style={{ zIndex: 0 }}
          />
        );
      })}
    </Box>
  );
};

export const DataSourceAlerts = () => {
  const t = useTranslation();

  const { data: emailGroupList, isLoading: isEmailGroupListLoading } =
    useGetEmailNotificationGroup();
  const [emailGroupId, setEmailGroupId] = React.useState(emailGroupList);

  const [currentPage, setCurrentPage] = React.useState(1);
  const { data: alerts, isLoading } = useGetAlertsMonitorConfigQuery({
    page: currentPage,
  });

  const [selectedAlertId, setSelectedAlertId] = React.useState();

  const selectedAlert = React.useMemo(
    () => alerts?.configs?.find((config) => config.id === selectedAlertId),
    [alerts, selectedAlertId]
  );

  const { showToast } = useToast();
  const { mutate: updateMonitorConfig } = useUpdateMonitorConfigMutation({
    onSuccess: () => {
      showToast(t('toast.notification.success'), 'success');
    },
    onError: () => {
      showToast(t('toast.error.error_500'), 'error');
    },
  });

  const emailOptions = React.useMemo(() => {
    if (emailGroupList && emailGroupList.length) {
      return emailGroupList.map((e) => ({
        value: e.id,
        label: e.name,
      }));
    }
  }, [emailGroupList]);

  React.useEffect(() => {
    if (!!alerts?.configs.length && !selectedAlertId)
      setSelectedAlertId(alerts?.configs[0].id);
  }, [alerts?.configs, selectedAlertId, currentPage]);

  const handleClick = (alert) => {
    setSelectedAlertId(alert.id);
  };

  if (isLoading) {
    return (
      <ContentLayout title={t('settings.tabs.data_source_alerts.tab')}>
        <Box full className="gap-5">
          <Box className="w-[900px]" column>
            <Skeleton height={60} />
            <Skeleton height={60} />
            <Skeleton height={60} />
            <Skeleton height={60} />
            <Skeleton height={60} />
            <Skeleton height={60} />
          </Box>
        </Box>
      </ContentLayout>
    );
  }

  const onSubmit = ({ idAlert, hours, day }) => {
    const newHours = alerts.configs
      .find((a) => a.id === idAlert)
      .hours_config?.filter((h) => h.day !== day);
    updateMonitorConfig({
      id: idAlert,
      hoursConfig: [...newHours, ...hours],
      email_group_id: emailGroupId,
    });
  };

  const Alert = ({ selectedAlert, handleClick, alert }) => {
    const [expanded, setExpanded] = React.useState(
      selectedAlert?.id === alert.id
    );

    const handleCardClick = () => {
      if (selectedAlert?.id === alert.id) {
        setExpanded(!expanded);
      } else {
        handleClick(alert);
        setExpanded(true);
      }
    };

    return (
      <React.Fragment>
        <SelectorCard
          onClick={handleCardClick}
          noCheck
          className={cls(
            'border-b-gray border-b border-t-gray border-t last:border-none max-h-[64px]',
            {
              'bg-lightBlue   border-lightBlue': selectedAlert?.id === alert.id,
            }
          )}
          render={() => (
            <Box ycenter className="w-full">
              <Box column ycenter flex className="flex-auto">
                <Box ycenter>
                  <Text bodyBold>{alert?.product}</Text>
                </Box>
                <Box row ycenter>
                  <Text ellipsis badgeBold color="lightText">
                    {alert?.sources && alert?.sources?.value}
                  </Text>
                </Box>
                <Box row ycenter>
                  <Text ellipsis badgeBold color="lightText">
                    {alert?.date}
                  </Text>
                </Box>
              </Box>
              <Box ycenter flex className="justify-end">
                {expanded ? (
                  <Icon name="arrow-down" color="primary" />
                ) : (
                  <Icon name="arrow-up" color="lightText" />
                )}
              </Box>
            </Box>
          )}
          id={alert?.id}
        />
        {expanded && (
          <Box
            column
            className="overflow-hidden h-fit w-full gap-4 p-4 bg-white"
          >
            <Text className="text-lg" color="lightText" bodyBold>
              {t('common.target_group')}
            </Text>
            <Select
              isLoading={isEmailGroupListLoading}
              value={
                selectedAlert?.email_group?.name && {
                  value: selectedAlert?.email_group?.id,
                  label: selectedAlert?.email_group?.name,
                }
              }
              isClearable
              onChange={(emailGroup) => {
                if (selectedAlert?.email_group?.id === emailGroup?.value)
                  return;
                setEmailGroupId(emailGroup?.value);
                updateMonitorConfig({
                  id: selectedAlert.id,
                  hoursConfig: selectedAlert?.hours_config,
                  email_group_id: emailGroup?.value,
                });
              }}
              optionsData={emailOptions}
              className="w-[200px]"
              placeholder={t('settings.notificacion.select')}
              style={{ zIndex: 9999 }}
            />
            <Text color="lightText" bodyBold className="text-lg">
              {t('common.time_ranges')}
            </Text>
            <WeekDays selectedAlert={selectedAlert} onSubmit={onSubmit} />
          </Box>
        )}
      </React.Fragment>
    );
  };

  return (
    <ContentLayout title={t('settings.tabs.data_source_alerts.tab')}>
      <Box full className="flex-col justify-between gap-4 2xl:flex-row">
        <Box
          column
          between
          className="2xl:min-h-[512px] gap-2 2xl:gap-0 w-full"
        >
          <Box
            column
            className="rounded-lg overflow-hidden border border-gray h-fit"
          >
            {alerts?.configs?.map((alert) => (
              <Alert
                key={alert.id}
                selectedAlert={selectedAlert}
                handleClick={handleClick}
                alert={alert}
              />
            ))}
          </Box>
          <Pagination
            className="self-end pt-2"
            numPages={alerts?.total_pages || 1}
            currentPage={currentPage}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </Box>
      </Box>
    </ContentLayout>
  );
};

export default DataSourceAlerts;
