import moment from 'moment';

import Box from 'components/Box';
import Text from 'components/Text';
import { useTranslation } from 'core/translations/useTranslation';

export const useColumns = () => {
  const t = useTranslation();
  return [
    {
      title: t('incidents.details.tabs.raw_events.table.header.time'),
      dataIndex: 'time',
      key: 'time',
      width: 250,
      className: 'flex',
      render: (date) => (
        <Text body>
          {moment(parseInt(date)).format('DD-MM-YYYY HH:mm:ss:SS')}
        </Text>
      ),
    },
    {
      title: t('incidents.details.tabs.raw_events.table.header.raw_event'),
      dataIndex: 'raw_event',
      key: 'raw_event',
      flex: 1,
      render: (raw_event) => (
        <Box>
          {raw_event ? (
            <Text className="break-words text-ellipsis max-w-[1200px] break-all">
              {raw_event}
            </Text>
          ) : (
            <Text body>{t('common.unknown')}</Text>
          )}
        </Box>
      ),
    },
  ];
};
