import { useQuery } from 'react-query';

import { CACHE_IDS } from 'common/constants/cache';

import { getVersionAndBuildNumber } from '../api';

export const useVersionAndBuildNumberQuery = () => {
  const query = useQuery(
    [CACHE_IDS.VERSION_AND_BUILD],
    getVersionAndBuildNumber
  );

  return query;
};

export default useVersionAndBuildNumberQuery;
