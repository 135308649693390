import ReactHtmlParser from 'react-html-parser';

import { getColorHex } from 'common/constants/colors';
import Box from 'components/Box';
import { cls } from 'core/styles/cls';

const TextWrapper = ({ children, ellipsis }) =>
  ellipsis ? (
    <Box className="block overflow-hidden text-ellipsis whitespace-nowrap">
      {children}
    </Box>
  ) : (
    <>{children}</>
  );

const Text = ({
  className,
  base = true,
  title,
  subtitle,
  subheading,
  body,
  bodyBold,
  input,
  logo,
  logoBold,
  badge,
  button,
  badgeBold,
  link,
  cursor,
  color,
  ellipsis,
  children,
  nowrap,
  isHtml,
  ...props
}) => {
  return (
    <TextWrapper ellipsis={ellipsis}>
      <span
        {...props}
        className={cls(
          'text-text',
          {
            'text-base font-light leading-[18px]': base,
            'text-md font-light leading-[20px]': body,
            'text-2xl font-regular leading-[33px]': title,
            'text-xl font-regular leading-[26px]': subtitle,
            'text-lg font-regular leading-[24px]': subheading,
            'text-md font-regular leading-[20px]': bodyBold,
            'text-md font-light leading-[16px]': input,
            'text-[14px] font-regular leading-[14px]': button,
            'text-sm font-light leading-[18px]': badge,
            'text-sm font-[500] leading-[18px]': badgeBold,
            'text-primary border-primary': link,
            "text-2xl font-['Poppins'] font-light leading-[113%] text-white":
              logo,
            "text-3xl font-['Poppins'] font-black leading-[113%] text-white":
              logoBold,
            'cursor-pointer': cursor,
            'whitespace-pre-line': !nowrap,
            'whitespace-nowrap': nowrap,
          },
          className
        )}
        style={{
          color: getColorHex(color),
        }}
      >
        {!isHtml ? children : ReactHtmlParser(children)}
      </span>
    </TextWrapper>
  );
};

export default Text;
