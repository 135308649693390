import Box from 'components/Box';
import { cls } from 'core/styles/cls';
import { useTranslation } from 'core/translations/useTranslation';
import { useLogout } from 'features/auth/hooks/useLogout';

import { TABS } from '../../constants/constants';
import Item from '../Item';
import ItemDropdown from '../ItemDropdown';

const SettingsSidebar = ({ className, activeTab, handleTabClick }) => {
  const t = useTranslation();
  const { logout } = useLogout();
  return (
    <Box
      between
      column
      className={cls(
        'h-full w-[88px] min-w-[88px] gap-4 border-r border-gray pl-1 xl:w-[240px] xl:min-w-[280px] xl:pl-0',
        className
      )}
    >
      <Box column>
        <Item
          icon="account"
          onClick={() => handleTabClick(TABS.PERSONAL_INFORMATION)}
          isActive={activeTab === TABS.PERSONAL_INFORMATION}
        >
          {t('settings.tabs.personal_information.tab')}
        </Item>
        <Item
          onClick={() => handleTabClick(TABS.PROFILE)}
          isActive={activeTab === TABS.PROFILE}
          icon="edit"
        >
          {t('settings.tabs.edit_profile.tab')}
        </Item>
        <Item
          onClick={() => handleTabClick(TABS.PASSWORD)}
          isActive={activeTab === TABS.PASSWORD}
          icon="password"
        >
          {t('settings.tabs.password_security.tab')}
        </Item>
        <ItemDropdown
          isActive={activeTab}
          handleTabClick={handleTabClick}
          icon="incidents"
          items={[
            {
              text: t('settings.tabs.rule_notifications.tab'),
              icon: 'settings',
              id: 'rule_notifications',
            },
            {
              text: t('settings.tabs.data_source_alerts.tab'),
              icon: 'threats',
              id: 'data_source_alerts',
            },
            {
              text: t('settings.tabs.group_notifications.tab'),
              icon: 'users',
              id: 'group_notifications',
            },
          ]}
        ></ItemDropdown>
        <Item
          onClick={() => handleTabClick(TABS.CHANGE_LANGUAGE)}
          isActive={activeTab === TABS.CHANGE_LANGUAGE}
          icon="language"
        >
          {t('settings.tabs.language.tab')}
        </Item>
      </Box>
      <Box column>
        <Item onClick={logout} icon="language" color="error">
          {t('common.log_out')}
        </Item>
      </Box>
    </Box>
  );
};

export default SettingsSidebar;
