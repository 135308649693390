import React from 'react';

import { FullScreen, useFullScreenHandle } from 'react-full-screen';

export const FullScreenContext = React.createContext();

export const FullScreenProvider = ({ children }) => {
  const handle = useFullScreenHandle();

  return (
    <FullScreenContext.Provider value={{ handle }}>
      <FullScreen handle={handle}>{children}</FullScreen>
    </FullScreenContext.Provider>
  );
};

export const useFullScreen = () => {
  const { handle } = React.useContext(FullScreenContext);
  return {
    enter: handle.enter,
    exit: handle.exit,
    isFullScreen: handle.active,
  };
};
