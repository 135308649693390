import moment from 'moment';
import { Link } from 'react-router-dom';

import Box from 'components/Box';
import Text from 'components/Text';
import { getDaysFromNow } from 'core/dates';

const Cases = ({ cases }) => {
  return (
    !!cases?.length &&
    cases?.map((entity) => (
      <>
        <Box key={entity.id} between>
          <Link to={`/incidents/${entity.id}`}>
            <Text className="cursor-pointer" link body>
              {entity.id}
            </Text>
          </Link>
          <Box className="xl:w-fit">
            <Text ellipsis body>
              {entity.title}
            </Text>
          </Box>
          <Box column className="gap-1 w-fit items-end">
            <Box className="gap-1 flex-col xl:flex-row items-end">
              <Text className="w-[54px] break-all xl:w-fit">
                {moment(entity.creationTime).format('DD-MM-YYYY')}
              </Text>
              <Text>{moment(entity.creationTime).format('HH:mm:ss')}</Text>
            </Box>
            <Text badgeBold color="lightText">
              {getDaysFromNow(entity.creationTime)}
            </Text>
          </Box>
        </Box>
      </>
    ))
  );
};

export default Cases;
