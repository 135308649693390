import React from 'react';

import SelectRE, { components } from 'react-select';
import makeAnimated from 'react-select/animated';

import { cls } from 'core/styles/cls';
import theme from 'core/styles/theme';

const customStyles = ({ hasError }) => ({
  control: (provided, state) => ({
    ...provided,
    borderColor: !hasError
      ? state.isFocused
        ? theme.colors.primary
        : theme.colors.gray
      : theme.colors.error,
    boxShadow: 'none',
    '&:hover': {
      borderColor: state.isFocused
        ? theme.colors.primary
        : theme.colors.darkGray,
    },
  }),
});

const MultiValueComponent = (props) => (
  <components.MultiValue {...props}>
    {props.data.chipLabel}
  </components.MultiValue>
);

const Select = (
  {
    optionsData,
    onChange,
    isMulti,
    isSearchable,
    isClearable,
    className,
    isLoading,
    hasError,
    ...props
  },
  ref
) => {
  const animatedComponents = makeAnimated();

  return (
    <SelectRE
      {...props}
      ref={ref}
      menuPlacement="auto"
      menuPortalTarget={document.body}
      classNamePrefix="react-select"
      closeMenuOnSelect={false}
      components={{ ...animatedComponents, MultiValueComponent }}
      isMulti={isMulti}
      isLoading={isLoading}
      isSearchable={isSearchable}
      isClearable={isClearable}
      onChange={onChange}
      options={optionsData}
      styles={customStyles({ hasError })}
      className={cls('w-full z-100', className)}
    />
  );
};

export default React.forwardRef(Select);
