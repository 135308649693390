import Box from 'components/Box';
import Text from 'components/Text';
import Tooltip from 'components/Tooltip';

const TabNameText = ({ isSelected, name }) => {
  const shouldShowTooltip = name.length > 22;

  return (
    <Box className="max-w-[180px] overflow-hidden">
      <Text
        ellipsis
        bodyBold
        color={isSelected ? 'primary' : 'text'}
        nowrap
        inline="mr-1"
      >
        {name}
      </Text>
      {shouldShowTooltip && <Tooltip id={name}>{name}</Tooltip>}
    </Box>
  );
};

export default TabNameText;
