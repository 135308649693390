import BlueLogo from 'assets/security-posture/header-logo-blue.png';
import SmallLogo from 'assets/security-posture/header-logo.png';
import { cls } from 'core/styles/cls';

const Logo = ({ alt, small, className }) => (
  <a href={'/'}>
    <img
      src={small ? SmallLogo : BlueLogo}
      alt={alt}
      className={cls('h-[48px]', className)}
    />
  </a>
);

export default Logo;
