import EmptyStateWidgets from 'common/components/widgets/EmptyStateWidgets';
import WidgetProgressBars from 'components/WidgetProgressBars';
import { useTranslation } from 'core/translations/useTranslation';
import { useTopDetectedUsersQuery } from 'features/widgets/services';
import '../../styles/styles.css';

import { TOP_DETECTED_ITEM_STATUS } from '../../constants';

const getProgressBarColorByStatus = (itemStatus) => {
  switch (itemStatus) {
    case TOP_DETECTED_ITEM_STATUS.ERROR:
      return 'error';
    case TOP_DETECTED_ITEM_STATUS.DANGER:
      return 'danger';
    case TOP_DETECTED_ITEM_STATUS.WARNING:
      return 'warning';
    case TOP_DETECTED_ITEM_STATUS.SUCCESS:
      return 'success';
    default:
      return null;
  }
};

const getColorByStatus = (itemStatus) => {
  switch (itemStatus) {
    case TOP_DETECTED_ITEM_STATUS.ERROR:
      return 'error';
    case TOP_DETECTED_ITEM_STATUS.DANGER:
      return 'darkOrange';
    case TOP_DETECTED_ITEM_STATUS.WARNING:
      return 'darkYellow';
    case TOP_DETECTED_ITEM_STATUS.SUCCESS:
      return 'darkGreen';
    default:
      return null;
  }
};

const WidgetTopDetectedUsers = ({ id, title, isEditing, removeWidget }) => {
  const t = useTranslation();
  const { data: widgetData, isLoading } = useTopDetectedUsersQuery();
  return (
    <WidgetProgressBars.Widget
      isEditing={isEditing}
      iconLeft="settings"
      iconRight="dots"
      subtitle={<span className="title-widget">{title}</span>}
      onDelete={() => removeWidget(id)}
    >
      {isLoading ? (
        <WidgetProgressBars.Skeleton />
      ) : widgetData?.list ? (
        widgetData?.list?.map((item) => {
          const entityIdentifierWithPercent = encodeURIComponent(item.user);
          return (
            <WidgetProgressBars.Item
              name={item.user}
              completion={item.completionPercentage}
              cases={item.cases}
              totalCases={widgetData.totalCases}
              textColor={getColorByStatus(item.status)}
              barColor={getProgressBarColorByStatus(item.status)}
              key={item.user}
              incidents={t('secpos.widgets.top_detected_users.counter')}
              linkTo={`/entities-details/${entityIdentifierWithPercent}/USERUNIQNAME`}
            />
          );
        })
      ) : (
        <EmptyStateWidgets className="mt-[3px] mb-[50px]" />
      )}
    </WidgetProgressBars.Widget>
  );
};

export default WidgetTopDetectedUsers;
