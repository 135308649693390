import { useToast } from 'common/hooks/toasts';
import { useTranslation } from 'core/translations/useTranslation';

import { useCreateDashboardMutation } from '../services';

const extractNumberFromString = (str) => {
  const match = str.match(/\d+$/);
  return match ? parseInt(match[0]) : 0;
};

const useCreateTab = () => {
  const t = useTranslation();

  const { showToast } = useToast();

  const { mutate: newTab } = useCreateDashboardMutation({
    onError: (error) => {
      showToast(error, 'error');
    },
  });

  const createTab = (tabs) => {
    const newTabString = t('secpos.dashboard.new_tab');
    const numbers = tabs
      .map((t) =>
        t.name.includes(newTabString)
          ? extractNumberFromString(t.name)
          : undefined
      )
      .filter((n) => n !== undefined);
    const max = numbers.length ? Math.max(...numbers) : 0;
    newTab(`${newTabString} ${max + 1}`);
  };

  return { createTab };
};

export default useCreateTab;
