import React from 'react';

import EmptyStateWidgets from 'common/components/widgets/EmptyStateWidgets';
import Chart from 'components/Chart';
import Skeleton from 'components/Skeleton';
import Widget from 'components/Widget';
import '../../styles/styles.css';

import { useWidgetChart } from '../../hooks';
import { useIncidentTimelineQuery } from '../../services';
import { getChartOptions } from './chart';

const WidgetIncidentTimeline = ({ id, title, removeWidget, isEditing }) => {
  const { data: widgetData, isLoading } = useIncidentTimelineQuery();
  const { props: chartProps } = useWidgetChart(id);

  const options = React.useMemo(
    () =>
      getChartOptions({
        rangeDays: widgetData?.range_days,

        minDate: widgetData?.min_date,
        series: widgetData?.series,
      }),
    [widgetData]
  );

  return (
    <Widget
      isEditing={isEditing}
      iconLeft="settings"
      iconRight="dots"
      subtitle={<span className="title-widget">{title}</span>}
      onDelete={() => removeWidget(id)}
    >
      {isLoading ? (
        <Skeleton radius={'10px'} />
      ) : widgetData?.series?.length ? (
        <Chart options={options} {...chartProps} />
      ) : (
        <EmptyStateWidgets />
      )}
    </Widget>
  );
};

export default WidgetIncidentTimeline;
