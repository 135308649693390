import Box from 'components/Box';
import Text from 'components/Text';
import { cls } from 'core/styles/cls';

const DetailsSection = ({ title, children, className }) => {
  return (
    <Box column className={cls('w-full gap-2', className)}>
      <Text subheading>{title}</Text>
      {children}
    </Box>
  );
};

export default DetailsSection;
