import { cls } from 'core/styles/cls';

import Box from '../Box';

const Card = ({ className, children, isEditing, size, ...props }) => (
  <Box
    {...props}
    column
    className={cls(
      'rounded-lg border-[1px] border-gray bg-white p-3',
      { 'border-dashed border-2': isEditing },
      { 'h-full w-full': !size },
      size,
      className
    )}
  >
    {children}
  </Box>
);

export default Card;
