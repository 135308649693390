import { useToggler } from 'common/hooks';
import Box from 'components/Box';
import Card from 'components/Card';
import Icon from 'components/Icon';
import Text from 'components/Text';

import DeleteModal from '../DeleteModal';
import DetailsGroupModal from '../DetailsGroupModal';
import DropdownCard from '../DropdownCard/DropdownCard';
import EditGroupModal from '../EditGroupModal';

const CardGroup = ({ title, addresses, description, id, ...props }) => {
  const { isShown: isShownDeleteModal, toggle: toggleDeleteModal } =
    useToggler();
  const { isShown: isShownEditModal, toggle: toggleEditModal } = useToggler();
  const { isShown: isShownDetailsModal, toggle: toggleDetailsModal } =
    useToggler();
  const { isShown: isShownDropdownCard, toggle: toggleDropdownCard } =
    useToggler();

  return (
    <>
      {isShownDeleteModal && (
        <DeleteModal id={id} closeModal={toggleDeleteModal} />
      )}
      {isShownEditModal && (
        <EditGroupModal id={id} closeModal={toggleEditModal} />
      )}

      {isShownDetailsModal && (
        <DetailsGroupModal id={id} closeModal={toggleDetailsModal} />
      )}
      <Card
        {...props}
        onClick={toggleDetailsModal}
        className="gap-2 cursor-pointer "
      >
        <Box between>
          <Box ycenter xcenter className="gap-2 items-end">
            <Text bodyBold>{title}</Text>
            <Text badgeBold color="lightText">
              {addresses}
            </Text>
          </Box>
          <Box color="lightText" className="gap-2 relative">
            {isShownDropdownCard && (
              <DropdownCard
                className="top-[-17px] right-[-17px]"
                handleClickEditGroup={toggleEditModal}
                handleClickDeleteGroup={toggleDeleteModal}
                closeDropdown={toggleDropdownCard}
              />
            )}
            <Icon
              cursor="pointer"
              className="text-[18px] "
              name="options"
              onClick={(e) => {
                e.stopPropagation();
                toggleDropdownCard();
              }}
            />
          </Box>
        </Box>

        <Text body color="lightText">
          {description}
        </Text>
      </Card>
    </>
  );
};

export default CardGroup;
