import { createSelector } from '@reduxjs/toolkit';

const selectFilter = (state) => state.filter;

export const selectDateRange = createSelector(selectFilter, (filter) => {
  return {
    dateFrom: filter.dateFrom,
    dateTo: filter.dateTo,
  };
});
