import { getFormData } from './utils';

class Request {
  url = '';
  options = {};

  constructor(baseUrl, { headers, body, method }) {
    this.url = baseUrl;
    this.options = { headers, body, method };
  }

  setToken(token) {
    const { headers } = this.options;
    if (token) {
      this.options.headers = { ...headers, Authorization: `Bearer ${token}` };
    } else {
      delete this.options.headers.Authorization;
    }
    return this;
  }

  addUrlParams(params) {
    if (params) {
      const urlParams = new URLSearchParams(params).toString();
      this.url = `${this.url}?${urlParams}`;
    }
    return this;
  }

  // Adds current hour to the timestamps before sending them in any server request
  syncTimestamps() {
    const { body } = this.options;
    if (body && body.date_from && body.date_to) {
      this.options.body = {
        ...body,
        date_from: body.date_from,
        date_to: new Date(body.date_to).setHours(23, 59, 59, 999),
      };
    }

    return this;
  }

  build({ isJson }) {
    const { body, headers } = this.options;
    if (body) {
      this.options.body = isJson ? JSON.stringify(body) : getFormData(body);
    }
    if (isJson) {
      this.options.headers = {
        ...headers,
        'content-type': 'application/json',
      };
    }

    return this;
  }
}

export default Request;
