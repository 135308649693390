import { useLocation, useNavigate } from 'react-router';

import { useToast } from 'common/hooks/toasts';
import { Cookie } from 'core/cookies';
import { useTranslation } from 'core/translations/useTranslation';

const useHandleError = () => {
  const t = useTranslation();
  const { showToast } = useToast({ toastId: 'generic-error' });
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const handleError = (error) => {
    // Not authenticated or token expired
    if (error.status === 401 && pathname !== '/login') {
      showToast(t('toast.error.error_401'), 'error');
      Cookie.remove('token');
      navigate('/login');
    } else if (error.status === 500 && pathname !== '/login') {
      showToast(t('toast.error.error_500'), 'error');
    }
  };

  return { handleError };
};

export default useHandleError;
