import React from 'react';

import { twMerge } from 'tailwind-merge';

import { getColorHex } from 'common/constants/colors';
import { cls } from 'core/styles/cls';

const Box = (
  {
    children,
    between,
    className,
    xcenter,
    ycenter,
    column,
    full,
    bg,
    color,
    style,
    block,
    ...props
  },
  ref
) => (
  <div
    ref={ref}
    className={twMerge(
      cls(
        {
          flex: !block,
          'justify-center': column ? ycenter : xcenter,
          'items-center': column ? xcenter : ycenter,
          'flex-col': column,
          'justify-between': between,
          'w-full': full,
        },
        className
      )
    )}
    style={{
      ...style,
      backgroundColor: getColorHex(bg),
      color: getColorHex(color),
    }}
    {...props}
  >
    {children}
  </div>
);

export default React.forwardRef(Box);
