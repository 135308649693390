import Box from 'components/Box';
import Text from 'components/Text';
import { cls } from 'core/styles/cls';
import { useTranslation } from 'core/translations/useTranslation';

const EmptyStateWidgets = ({ className }) => {
  const t = useTranslation();

  return (
    <Box
      column
      xcenter
      ycenter
      className={cls('w-full h-full pb-7 gap-1', className)}
    >
      <Text subtitle className="mt-5">
        {t('common.no_data')}
      </Text>
      <Text bodyBold color="lightText" className="text-center">
        {t('widgets.empty_state')}
      </Text>
    </Box>
  );
};

export default EmptyStateWidgets;
