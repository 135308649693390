import React from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import useWindowDimensions from 'common/hooks/useWindowDimensions';
import { useSidebar } from 'features/layout/hooks/useSidebar';

const Chart = ({ options, containerProps }, outerRef) => {
  const { isSidebarCollapsed } = useSidebar();
  const { width } = useWindowDimensions();

  const innerRef = React.useRef();
  const ref = outerRef || innerRef;
  const chart = ref?.current?.chart;

  React.useEffect(() => {
    chart?.reflow();
  }, [isSidebarCollapsed, width, chart]);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={ref}
      containerProps={containerProps}
    />
  );
};

export default React.forwardRef(Chart);
