import React from 'react';

import { useQueryClient } from 'react-query';

import { CACHE_IDS } from 'common/constants/cache';
import { useQuery } from 'core/api/react-query';

import { getEmailNotificationGroupsById } from '../api';

export const useGetEmailNotificationGroupById = ({ id }) => {
  const queryClient = useQueryClient();

  const query = useQuery([CACHE_IDS.EMAIL_NOTIFICATION_GROUP, { id }], () =>
    getEmailNotificationGroupsById({ id })
  );

  React.useEffect(() => {
    if (query?.data) {
      queryClient.invalidateQueries([CACHE_IDS.EMAIL_NOTIFICATION_GROUP]);
    }
  }, [query?.data, queryClient]);

  return query;
};

export default useGetEmailNotificationGroupById;
