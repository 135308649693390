import { parse, stringify } from 'query-string';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import Error from 'common/pages/Error';
import ProtectedRoute from 'features/auth/components/ProtectedRoute';
import ForgotPassword from 'features/auth/pages/ForgotPassword';
import Login from 'features/auth/pages/Login';
import PasswordReset from 'features/auth/pages/PasswordReset';
import AutomatedResponse from 'features/automated-response/pages/AutomatedResponse';
import Catalogue from 'features/catalogue/pages/Catalogue';
import DetailsEntities from 'features/entities/pages/DetailsEntities/DetailsEntities';
import Entities from 'features/entities/pages/Entities/Entities';
import DetailsIncident from 'features/incidents/pages/DetailsIncident';
import Incidents from 'features/incidents/pages/Incidents';
import Settings from 'features/profile/pages/Settings';
import SecurityPosture from 'features/sec-posture/pages/SecurityPosture';
import SocEngine from 'features/soc-status/pages/SocEngine';
import SocStatistics from 'features/soc-status/pages/SocStatistics';
import SocStatus from 'features/soc-status/pages/SocStatus';

function Router() {
  return (
    <BrowserRouter>
      <QueryParamProvider
        adapter={ReactRouter6Adapter}
        options={{
          searchStringToObject: parse,
          objectToSearchString: stringify,
        }}
      >
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/password-reset" element={<PasswordReset />} />
          <Route path="*" element={<Error />} />

          <Route element={<ProtectedRoute />}>
            <Route path="/settings" element={<Settings />} />
            <Route path="/entities" element={<Entities />} />
            <Route
              path="/entities-details/:id/:type"
              element={<DetailsEntities />}
            />
            <Route path="/incidents" element={<Incidents />} />
            <Route path="/incidents/:id" element={<DetailsIncident />} />
            <Route path="/catalogue" element={<Catalogue />} />
            <Route path="/soc-status" element={<SocStatus />} />
            <Route path="/soc-statistics" element={<SocStatistics />} />
            <Route path="/soc-engine" element={<SocEngine />} />
            <Route path="/automated-response" element={<AutomatedResponse />} />
            <Route path="/" element={<SecurityPosture />} />
          </Route>
        </Routes>
      </QueryParamProvider>
    </BrowserRouter>
  );
}

export default Router;
