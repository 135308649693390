import CalendarFilter from 'common/components/filters/CalendarFilter';
import Box from 'components/Box';
import { useTranslation } from 'core/translations/useTranslation';
import Layout from 'features/layout';

import TableView from '../../components/TableView';
import WidgetIncidentDetection from '../../components/Widgets/WidgetIncidentDetection';
import WidgetIncidentTimeline from '../../components/Widgets/WidgetIncidentTimeline';

const Incidents = () => {
  const t = useTranslation();
  return (
    <Layout
      icon="incidents"
      title={t('sidebar.incidents')}
      renderHeaderRight={() => <CalendarFilter />}
    >
      <Box column className="gap-4 overflow-hidden">
        <Box className="gap-3 grid grid-cols-2">
          <WidgetIncidentDetection />
          <WidgetIncidentTimeline />
        </Box>
        <TableView />
      </Box>
    </Layout>
  );
};

export default Incidents;
