import { get, post } from 'core/api';

export const getSocEngineData = async ({ dateFrom, dateTo }) =>
  post('/get_widget_data/soc_engine', {
    date_from: dateFrom,
    date_to: dateTo,
  });

export const getEventsDataTypologies = async ({ dateFrom, dateTo }) =>
  post('/get_widget_data/events_data_typologies', {
    date_from: dateFrom,
    date_to: dateTo,
  });

export const getReportingProducts = async () => get('/data_source/');
export const getReportingProductsByID = async ({ id }) =>
  get(`/data_source/${id}`);

export const getEventsVolume = async ({ dateFrom, dateTo }) =>
  post('/get_widget_data/events_size', {
    date_from: dateFrom,
    date_to: dateTo,
  });

export const getMetricsEvents = async ({ dateFrom, dateTo }) =>
  post('/get_widget_data/events_metrics_table_data', {
    date_from: dateFrom,
    date_to: dateTo,
  });

export const getEventsStorageData = async ({ dateFrom, dateTo }) =>
  post('/get_widget_data/events_table_data', {
    date_from: dateFrom,
    date_to: dateTo,
  });
