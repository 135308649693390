export const getChartOptions = ({ data }) => ({
  credits: {
    enabled: false,
  },

  chart: {
    type: 'pie',
    plotBackgroundColor: null,
    plotBorderWidth: 0,
    plotShadow: false,
  },

  plotOptions: {
    pie: {
      innerSize: '60%',
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
      cursor: 'pointer',
      allowPointSelect: true,
    },
  },

  legend: {
    align: 'center',
    verticalAlign: 'top',

    itemStyle: {
      lineHeight: '14px',
    },
  },
  title: {
    text: `Total Cases ${data.y}`,
    align: 'center',
    verticalAlign: 'middle',
    style: {
      fontFamily: "'roboto', sans-serif",
      lineHeight: '18px',
      fontSize: '20px',
    },
    y: 35,
  },

  series: [
    {
      type: 'pie',
      colorByPoint: true,
      innerSize: '80%',
      name: 'Cases',
      data: [data],
    },
  ],

  colors: ['#D45A66', '#fbd360', '#4CDFE8', '#a3da8d', '#ffa974', '#dddddd'],
});
