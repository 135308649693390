import SkeletonRL from 'react-loading-skeleton';

import { cls } from 'core/styles/cls';

const Skeleton = ({
  lines,
  height,
  width,
  radius,
  className,
  circle,
  containerClassName,
}) => {
  return (
    <SkeletonRL
      className={cls('h-full', className)}
      count={lines}
      height={height}
      width={width}
      containerClassName={cls('h-full', containerClassName)}
      circle={circle}
      style={{ borderRadius: radius }}
    />
  );
};

export default Skeleton;
