import { get, post, remove, put } from 'core/api';

export const addWidget = async ({ id, dashboardId, x, y }) =>
  post(
    '/widget/',
    {
      widget_type_id: id,
      axis_x: x,
      axis_y: y,
      page_id: 'security_posture',
    },
    { dashboard_id: dashboardId }
  );

export const removeWidget = async (id, dashboardId) =>
  remove(`/widget/${id}`, { dashboard_id: dashboardId });

export const getWidgets = async ({ dashboardId }) =>
  get('/widget/page_id', {
    dashboard_id: dashboardId,
    page_id: 'security_posture',
  });

export const updateWidget = async ({ id, dashboardId, x, y, w, h }) =>
  put(
    `/widget/${id}`,
    {
      height: h,
      width: w,
      axis_x: x,
      axis_y: y,
      page_id: 'security_posture',
    },
    { dashboard_id: dashboardId }
  );

export const getAllWidgetTypes = async (activeChips = []) => {
  return post('/widget_type/', {
    widget_types: [],
    tags: activeChips,
  });
};

export const getWidgetTypesTags = async () => get('/widget_type/tags');

export const getDashboards = async () => get('/dashboards/');
export const postDashboard = async () => post('/dashboards/', { name: 'New' });
export const deleteDashboard = async (id) =>
  remove(`/dashboards/{id}?dashboard_id=${id}`);
