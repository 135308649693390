import Box from 'components/Box';
import Button from 'components/Button';
import { useTranslation } from 'core/translations/useTranslation';

const Toolbar = ({
  saveChanges,
  isSaveChangesLoading,
  discardChanges,
  toggleSidebar,
  className,
}) => {
  const t = useTranslation();

  return (
    <Box full className={className}>
      <Button
        onClick={saveChanges}
        loading={isSaveChangesLoading}
        small
        icon="save"
      >
        {t('common.save')}
      </Button>
      <Button
        onClick={discardChanges}
        loading={isSaveChangesLoading}
        small
        icon="close"
        bg="red"
      >
        {t('secpos.menu.discard')}
      </Button>
      <Button
        onClick={toggleSidebar}
        loading={isSaveChangesLoading}
        small
        icon="plus"
        bg="white"
        color="primary"
      >
        {t('secpos.menu.add')}
      </Button>
    </Box>
  );
};

export default Toolbar;
