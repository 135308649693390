import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { CACHE_IDS } from 'common/constants/cache';
import { WIDGET_TYPES_SLUGS } from 'common/constants/widgets';
import { selectDateRange } from 'redux/filter/selectors';

import { getCriticalityOfIncidents } from '../api';

const criticities = [
  {
    value: 100,
    name: 'CRITICAL',
    color: '#dc0000',
  },
  {
    value: 80,
    name: 'HIGH',
    color: '#fd8c00',
  },
  {
    value: 60,
    name: 'MEDIUM',
    color: '#fdc500',
  },
];

const useGetCriticityText = (value) => {
  let criticity = criticities.find((c) => c.value === value);
  if (criticity) return criticity.name;
  return 'LOW';
};
const useGetCriticityColor = (value) => {
  let criticity = criticities.find((c) => c.value === value);
  if (criticity) return criticity.color;
  return '#00ac46';
};

const mapperData = (data) => {
  const formatData = data?.series
    ?.sort((a, b) => b.name - a.name)
    .map((item) => {
      return {
        data: item.data,
        color: useGetCriticityColor(item.name),
        name: useGetCriticityText(item.name),
        pointPlacement: 'on',
      };
    })
    .filter((item) => item.data.some((value) => value > 0))
    .reduce((acc, item) => {
      const findIndex = acc.findIndex((i) => i.name === item.name);
      if (findIndex >= 0) {
        item.data.forEach((value, index) => {
          acc[findIndex].data[index] += value;
        });
        return acc;
      }

      acc.push(item);
      return acc;
    }, []);

  return { ...data, series: formatData };
};

export const useCriticalityOfIncidentsQuery = () => {
  const { dateFrom, dateTo } = useSelector(selectDateRange);

  const query = useQuery(
    [
      CACHE_IDS.WIDGET_DATA,
      {
        slug: WIDGET_TYPES_SLUGS.CRITICALITY_INCIDENTS,
        dateFrom,
        dateTo,
      },
    ],
    () =>
      getCriticalityOfIncidents({
        dateFrom,
        dateTo,
      }),
    {
      select: (data) => {
        return mapperData(data);
      },
    }
  );
  return query;
};
