import Box from 'components/Box';
import Chip from 'components/Chip';
import Icon from 'components/Icon';
import Text from 'components/Text';
import { useTranslation } from 'core/translations/useTranslation';

export const useColumns = ({ openVendorModal }) => {
  const t = useTranslation();
  return [
    {
      title: '',
      dataIndex: 'logo',
      key: 'logo',
      width: 72,
      render: (logo) => {
        return (
          <Box className="h-8">
            <img
              className=" object-contain overflow-hidden rounded-lg"
              src={logo}
              alt="logo"
            />
          </Box>
        );
      },
    },
    {
      title: t('automated_response.table.heading.vendor'),
      dataIndex: 'name',
      key: 'vendor',
      render: (vendor) => <Text body>{vendor}</Text>,
    },
    {
      title: t('automated_response.table.heading.integrations'),
      dataIndex: 'integrations',
      key: 'integrations',
      ellipsis: true,
      render: (items) => (
        <Box className="gap-2 flex-wrap flex-grow">
          {items?.map((item) => (
            <Chip disabled className="w-fit">
              {item}
            </Chip>
          ))}
        </Box>
      ),
    },
    {
      title: '',
      dataIndex: '',
      key: 'modal',
      width: 100,
      render: (item) => {
        return (
          <Icon
            onClick={() => openVendorModal(item.name)}
            cursor="pointer"
            color="lightText"
            name="details"
          />
        );
      },
    },
  ];
};
