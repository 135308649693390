import React from 'react';

import { useQueryClient } from 'react-query';

import { CACHE_IDS } from 'common/constants/cache';
import { useQuery } from 'core/api/react-query';

import { getAlertsMonitorConfig } from '../api';

const sortDataAlphabetically = (data) => {
  const configsSorted = data?.configs?.sort(function (a, b) {
    if (a?.id < b?.id) {
      return -1;
    }
    if (a?.id > b?.id) {
      return 1;
    }
    return 0;
  });

  return { ...data, configs: configsSorted };
};

export const useGetAlertsMonitorConfigQuery = ({ page }) => {
  const queryClient = useQueryClient();
  const query = useQuery(
    [CACHE_IDS.ALERT_MONITOR_CONFIG, { page }],
    () => getAlertsMonitorConfig({ page }),
    {
      select: (result) => sortDataAlphabetically(result),
    }
  );
  React.useEffect(() => {
    if (query?.data?.total_pages || query?.data?.page_number) {
      queryClient.invalidateQueries([CACHE_IDS.ALERT_MONITOR_CONFIG]);
    }
  }, [query?.data?.page_number, query?.data?.total_pages, queryClient]);

  return query;
};

export default useGetAlertsMonitorConfigQuery;
