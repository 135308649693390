import React from 'react';

export const useControlledInput = (initialValue = '') => {
  const [value, setValue] = React.useState(initialValue);
  const onChange = (event) => {
    setValue(event.target.value);
  };
  return { value, onChange, setValue };
};

export default useControlledInput;
