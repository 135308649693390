import EmptyComponent from 'common/components/tables/EmptyComponent';
import Box from 'components/Box';
import Table from 'components/Table';

import { useMetricsEventsQuery } from '../../services/useMetricsEventsQuery';
import { useColumns } from './table';

const MetricsEventsTable = () => {
  const {
    data,
    isError: isTableError,
    isFetching: isTableFetching,
    refetch,
    isPreviousData,
  } = useMetricsEventsQuery();

  return (
    <Box column className="flex gap-5 overflow-x-auto">
      <Table
        data={data}
        columns={useColumns()}
        EmptyComponent={EmptyComponent({ isTableError, refetch })}
        isError={isTableError}
        isFetching={isTableFetching}
        loading={isTableFetching || isPreviousData}
        loadingRows={5}
        loadingHeight={64}
        withMinWidth
      />
    </Box>
  );
};

export default MetricsEventsTable;
