import { useSelector } from 'react-redux';

import { CACHE_IDS } from 'common/constants/cache';
import { WIDGET_TYPES_SLUGS } from 'common/constants/widgets';
import { useQuery } from 'core/api/react-query';
import { useTranslation } from 'core/translations/useTranslation';
import { selectDateRange } from 'redux/filter/selectors';

import { getTotalEventsTypologiesTimeline } from '../api';

const mapperData = (data, t) => {
  const series = data?.series;
  const formatSeries = data?.series?.map((serie) => {
    return { ...serie, type: 'column' };
  });

  let averageArray = [];

  const arrayDate = series[0]?.data;

  if (series !== []) {
    for (const [index, element] of arrayDate?.entries()) {
      const numEvents = series?.length;
      const newArray = [element[0]];
      let averageEvents = 0;
      for (let i = 0; i < numEvents; i++) {
        averageEvents += series[i]['data'][index][1];
      }
      newArray.push(Math.round(averageEvents / numEvents));
      averageArray.push(newArray);
    }
  }

  formatSeries.push({
    name: t('common.average'),
    data: averageArray,
    type: 'spline',
    color: '#1e93be',
    marker: {
      lineWidth: 2,
      lineColor: '#1e93be',
      fillColor: 'white',
    },
  });

  return { data, series: formatSeries };
};

export const useTotalEventsTypologiesTimelineQuery = () => {
  const { dateFrom, dateTo } = useSelector(selectDateRange);
  const t = useTranslation();

  const query = useQuery(
    [
      CACHE_IDS.WIDGET_DATA,
      {
        slug: WIDGET_TYPES_SLUGS.TOTAL_EVENTS_TYPOLOGIES_TIMELINE,
        dateFrom,
        dateTo,
      },
    ],
    () =>
      getTotalEventsTypologiesTimeline({
        dateFrom,
        dateTo,
      }),
    {
      select: (data) => {
        return mapperData(data, t);
      },
    }
  );
  return query;
};
