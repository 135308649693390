import SwitchRe from 'react-switch';

import { useToggler } from 'common/hooks';

export const Switch = ({ isEnabled, updateSwitch, id, disabled }) => {
  const { isShown: isChecked, toggle } = useToggler(isEnabled);

  return (
    <SwitchRe
      disabled={disabled}
      onColor="#1e93be"
      onHandleColor="#fff"
      handleDiameter={14}
      height={20}
      width={40}
      uncheckedIcon={false}
      checkedIcon={false}
      className="react-switch"
      onChange={() => {
        toggle();
        updateSwitch(id, isChecked);
      }}
      checked={isChecked || isEnabled}
    />
  );
};

export default Switch;
