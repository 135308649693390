import { Link } from 'react-router-dom';

import Box from 'components/Box';
import ProgressBar from 'components/ProgressBar';
import Skeleton from 'components/Skeleton/Skeleton';
import Text from 'components/Text';
import Widget from 'components/Widget';

const WidgetProgressBarsWrapper = ({
  children,
  iconLeft = 'settings',
  renderTitleIcon,
  subtitle,
  onDelete,
  iconRight = 'dots',
  isEditing,
}) => {
  return (
    <Widget
      className="pb-4"
      iconLeft={iconLeft}
      iconRight={iconRight}
      subtitle={subtitle}
      onDelete={onDelete}
      renderTitleIcon={renderTitleIcon}
      isEditing={isEditing}
    >
      <Box column className="h-full overflow-x-auto pr-3 pl-2">
        {children}
      </Box>
    </Widget>
  );
};

const WidgetProgressBarsItem = ({
  name,
  cases,
  totalCases,
  completion,
  textColor,
  barColor,
  incidents,
  linkTo,
}) => {
  return (
    <Box column className="gap-2 mt-4 mr-2" key={name}>
      <Box between>
        {linkTo ? (
          <Link to={linkTo} target="_blank">
            <Text cursor="pointer" color="primary" bodyBold>
              {name}
            </Text>
          </Link>
        ) : (
          <Text bodyBold>{name}</Text>
        )}
        <Box ycenter className="gap-1">
          <Text badgeBold color={textColor}>
            {cases} {incidents}
          </Text>
          <Text badge color="lightText">
            /{totalCases}
          </Text>
        </Box>
      </Box>
      <ProgressBar completed={completion} bg={barColor} />
    </Box>
  );
};

const WidgetProgressBarsSkeleton = ({ number = 10 }) => {
  return [...Array(number)].map((_, i) => (
    <Box column className="gap-2" key={`widgetProgressBarsSkeleton-${i}`}>
      <Skeleton height={20} />
      <Skeleton height={10} />
    </Box>
  ));
};

const WidgetProgressBars = {
  Widget: WidgetProgressBarsWrapper,
  Item: WidgetProgressBarsItem,
  Skeleton: WidgetProgressBarsSkeleton,
};

export default WidgetProgressBars;
