import { useEffect, useState } from 'react';

import { useQueryClient } from 'react-query';

import EmptyComponent from 'common/components/tables/EmptyComponent';
import { CACHE_IDS } from 'common/constants/cache';
import Box from 'components/Box';
import Text from 'components/Text';
import { useTranslation } from 'core/translations/useTranslation';
import { getReportingProductsByID } from 'features/soc-status/api';
import { useReportingDeviceQuery } from 'features/soc-status/services';

import Table from '../Table/Table';
import { useColumns } from './table';

const TableView = () => {
  const t = useTranslation();
  const queryClient = useQueryClient();

  const {
    data,
    refetch,
    isError: isTableError,
    isFetching: isTableFetching,
  } = useReportingDeviceQuery();

  const [newDevices, setDevices] = useState([]);
  const [isLoading, setIsLoading] = useState({});
  const [isLoadingNewDevices, setIsLoadingNewDevices] = useState(false);
  const [processedData, setProcessedData] = useState([]);

  const batchSize = 2;

  async function makeApiCalls(devices) {
    const numBatches = Math.ceil(devices.length / batchSize);
    for (let i = 0; i < numBatches; i++) {
      setIsLoadingNewDevices(true);
      const start = i * batchSize;
      const end = (i + 1) * batchSize;
      const batchIds = devices.slice(start, end);

      const batchPromises = batchIds.map((item) =>
        getReportingProductsByID({ id: item.id })
      );

      const data = await Promise.all(
        batchPromises.map((promise) =>
          promise
            .catch((e) => {
              return {
                error: e,
              };
            })
            .then((data) => data)
        )
      );

      data?.forEach((item, i) => {
        if (!item.error) {
          const deviceIndex = devices.findIndex(
            (device) => device.id === item.id
          );
          if (deviceIndex !== -1) {
            devices[deviceIndex].last_report = item.last_report;
            devices[deviceIndex].avg_receipt_time = item.avg_receipt_time;
            setDevices([...devices, devices[deviceIndex]]);
          }
        }
      });
      setIsLoadingNewDevices(false);
    }
  }

  useEffect(() => {
    if (isTableFetching) return;
    setProcessedData(
      data?.map((item) => {
        item.key = item.id;
        return item;
      })
    );
    makeApiCalls(data);
    return () => {
      setDevices([]);
      setProcessedData([]);
      queryClient.invalidateQueries([CACHE_IDS.REPORTING_PRODUCTS]);
    };
  }, [isTableFetching]);

  useEffect(() => {
    console.log();
  }, [newDevices]);

  return (
    <Box column className="gap-5 overflow-hidden">
      <Table
        columns={useColumns(newDevices, isLoadingNewDevices)}
        EmptyComponent={EmptyComponent({ isTableError, refetch })}
        isError={isTableError}
        isFetching={isTableFetching}
        loading={isLoading}
        loadingRows={3}
        loadingHeight={64}
        withMinWidth
        data={processedData}
        expandable={{
          expandedRowRender: (record) => {
            return (
              <Box column className="gap-3 w-full p-4">
                <Text bodyBold color="lightText">
                  {t('soc_status.list_of_devices.expanded_row.details')}
                </Text>
                <Box between>
                  <Box column className="gap-2">
                    <Text caption color="lightText">
                      {t('common.name')}
                    </Text>
                    {record.device_name ? (
                      <Text body>{record.device_name}</Text>
                    ) : (
                      <Text body>{t('common.unknown')}</Text>
                    )}
                  </Box>
                  <Box column className="gap-2">
                    <Text caption color="lightText">
                      {t('soc_status.list_of_devices.table.product')}
                    </Text>
                    {record.product ? (
                      <Text body>{record.product}</Text>
                    ) : (
                      <Text body>{t('common.unknown')}</Text>
                    )}
                  </Box>
                  <Box column className="gap-2">
                    <Text caption color="lightText">
                      IP
                    </Text>
                    {record.ip_address ? (
                      <Text body>{record.ip_address}</Text>
                    ) : (
                      <Text body>{t('common.unknown')}</Text>
                    )}
                  </Box>
                  <Box column className="gap-2">
                    <Text caption color="lightText">
                      {t('common.version')}
                    </Text>
                    {record.version ? (
                      <Text body>{record.version}</Text>
                    ) : (
                      <Text body>{t('common.unknown')}</Text>
                    )}
                  </Box>
                  <Box column className="gap-2">
                    <Text caption color="lightText">
                      {t('common.vendor')}
                    </Text>
                    {record.manufacturer ? (
                      <Text body>{record.manufacturer}</Text>
                    ) : (
                      <Text body>{t('common.unknown')}</Text>
                    )}
                  </Box>
                  <Box column className="gap-2">
                    <Text caption color="lightText">
                      {t('common.average_reporting_time')}
                    </Text>
                    {record.avg_receipt_time ? (
                      <Text body>{record.avg_receipt_time / 60} min</Text>
                    ) : (
                      <Text body>{t('common.unknown')}</Text>
                    )}
                  </Box>
                </Box>
              </Box>
            );
          },
        }}
      />
    </Box>
  );
};

export default TableView;
