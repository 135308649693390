import { getColorHex } from 'common/constants/colors';
import { cls } from 'core/styles/cls';
import { useTranslation } from 'core/translations/useTranslation';

import Icon from '../Icon';
import Text from '../Text';

const Button = ({
  children,
  className,
  onClick,
  disabled,
  icon,
  small,
  bg,
  color,
  secondary,
  style,
  loading,
  iconSize = 'small',
  ...props
}) => {
  const t = useTranslation();

  return (
    <button
      type="button"
      className={cls(
        'flex cursor-pointer items-center justify-center gap-1 rounded-lg border-0 h-fit bg-primary px-3 py-[12px] text-white',
        {
          'py-2 px-3': small,
          'cursor-auto opacity-30': disabled || loading,
          'bg-white text-primary border border-primary': secondary,
        },
        className
      )}
      style={{
        ...style,
        color: getColorHex(color),
        backgroundColor: getColorHex(bg),
      }}
      disabled={disabled || loading}
      onClick={onClick}
      {...props}
    >
      {loading && <Icon size="small" name="settings" />}
      {!loading ? (
        <Text
          className={cls('text-white', {
            'text-primary': secondary,
          })}
          color={color}
          bodyBold={!small}
          button={small}
        >
          {children}
        </Text>
      ) : (
        <Text
          className={cls('text-white', {
            'text-primary': secondary,
          })}
          color={color}
          bodyBold={!small}
          button={small}
        >
          {t('common.loading')}
        </Text>
      )}
      {icon && <Icon size={iconSize} name={icon} color={color} />}
    </button>
  );
};

export default Button;
