import { WIDGET_TYPES_SLUGS } from 'common/constants/widgets';
import { useTranslation } from 'core/translations/useTranslation';
import { useEditMode, useDashboard } from 'features/dashboard/hooks';
import {
  WidgetTopDetectedUsers,
  WidgetTopDetectedHosts,
  WidgetIncidentTypologies,
  WidgetIncidentTimeline,
  WidgetMalwareTypologies,
  WidgetQuickList,
  WidgetUrlMalicious,
  WidgetFilenamesMalicious,
  WidgetFilehashsMalicious,
  WidgetSignatureList,
  WidgetTotalEvents,
  WidgetTotalEventTimeline,
  WidgetTotalEventsTypologiesTimeline,
  WidgetReportedProducts,
  WidgetSummary,
  WidgetMaliciousIpsSources,
  WidgetMaliciousIpsDestinations,
} from 'features/widgets/components';
import WidgetCasesByCriticity from 'features/widgets/components/WidgetCasesByCriticity';
import WidgetCasesByCriticityTimeline from 'features/widgets/components/WidgetCasesByCriticityTimeline';
import WidgetCriticalityOfIncidents from 'features/widgets/components/WidgetCriticalityOfIncidents';
import WidgetEventsTypologiesCount from 'features/widgets/components/WidgetEventsTypologiesCount';
import WidgetNotifiedCases from 'features/widgets/components/WidgetNotifiedCases';
import WidgetTotalEventsTypologies from 'features/widgets/components/WidgetTotalEventsTypologies';

const WidgetRenderer = ({ slug, id }) => {
  const t = useTranslation();
  const { removeWidget } = useDashboard();
  const { isEditModeEnabled } = useEditMode();

  switch (slug) {
    case WIDGET_TYPES_SLUGS.TOP_DETECTED_USERS:
      return (
        <WidgetTopDetectedUsers
          id={id}
          title={t('secpos.widgets.top_detected_users.title')}
          removeWidget={removeWidget}
          isEditing={isEditModeEnabled}
        />
      );
    case WIDGET_TYPES_SLUGS.TOP_DETECTED_HOSTS:
      return (
        <WidgetTopDetectedHosts
          id={id}
          title={t('secpos.widgets.top_detected_hosts.title')}
          removeWidget={removeWidget}
          isEditing={isEditModeEnabled}
        />
      );
    case WIDGET_TYPES_SLUGS.INCIDENT_TYPOLOGIES:
      return (
        <WidgetIncidentTypologies
          id={id}
          title={t('secpos.widgets.incident_typologies.title')}
          removeWidget={removeWidget}
          isEditing={isEditModeEnabled}
        />
      );
    case WIDGET_TYPES_SLUGS.INCIDENT_TIMELINE:
      return (
        <WidgetIncidentTimeline
          id={id}
          title={t('secpos.widgets.incident_timeline.title')}
          removeWidget={removeWidget}
          isEditing={isEditModeEnabled}
        />
      );
    case WIDGET_TYPES_SLUGS.MALWARE_TYPOLOGIES:
      return (
        <WidgetMalwareTypologies
          id={id}
          title={t('secpos.widgets.malware_typologies.title')}
          removeWidget={removeWidget}
          isEditing={isEditModeEnabled}
        />
      );
    case WIDGET_TYPES_SLUGS.REPORTED_PRODUCTS:
      return (
        <WidgetReportedProducts
          id={id}
          title={t('secpos.widgets.top_reporting_products.title')}
          removeWidget={removeWidget}
          isEditing={isEditModeEnabled}
        />
      );

    case WIDGET_TYPES_SLUGS.SUMMARY:
      return (
        <WidgetSummary
          id={id}
          title={t('secpos.widgets.summary.title')}
          removeWidget={removeWidget}
          isEditing={isEditModeEnabled}
        />
      );
    case WIDGET_TYPES_SLUGS.QUICK_LIST:
      return (
        <WidgetQuickList
          id={id}
          title={t('secpos.widgets.quick_list.title')}
          removeWidget={removeWidget}
          isEditing={isEditModeEnabled}
        />
      );

    case WIDGET_TYPES_SLUGS.URL_MALICIOUS:
      return (
        <WidgetUrlMalicious
          id={id}
          title={t('secpos.widgets.malicious-url.title')}
          removeWidget={removeWidget}
          isEditing={isEditModeEnabled}
        />
      );

    case WIDGET_TYPES_SLUGS.FILENAMES_MALICIOUS:
      return (
        <WidgetFilenamesMalicious
          id={id}
          title={t('secpos.widgets.malicious-filenames.title')}
          removeWidget={removeWidget}
          isEditing={isEditModeEnabled}
        />
      );

    case WIDGET_TYPES_SLUGS.FILEHASHS_MALICIOUS:
      return (
        <WidgetFilehashsMalicious
          id={id}
          title={t('secpos.widgets.malicious-filehashs.title')}
          removeWidget={removeWidget}
          isEditing={isEditModeEnabled}
        />
      );
    case WIDGET_TYPES_SLUGS.MALICIOUS_IPS_SOURCES:
      return (
        <WidgetMaliciousIpsSources
          id={id}
          title={t('secpos.widgets.malicious_ips_source.title')}
          removeWidget={removeWidget}
          isEditing={isEditModeEnabled}
        />
      );
    case WIDGET_TYPES_SLUGS.MALICIOUS_IPS_DESTINATIONS:
      return (
        <WidgetMaliciousIpsDestinations
          id={id}
          title={t('secpos.widgets.malicious_ips_destinations.title')}
          removeWidget={removeWidget}
          isEditing={isEditModeEnabled}
        />
      );
    case WIDGET_TYPES_SLUGS.SIGNATURE_LIST:
      return (
        <WidgetSignatureList
          id={id}
          title={t('secpos.widgets.signature_list.title')}
          removeWidget={removeWidget}
          isEditing={isEditModeEnabled}
        />
      );
    case WIDGET_TYPES_SLUGS.TOTAL_EVENT_TIMELINE:
      return (
        <WidgetTotalEventTimeline
          id={id}
          title={t('secpos.widgets.total_event_timeline.title')}
          removeWidget={removeWidget}
          isEditing={isEditModeEnabled}
        />
      );

    case WIDGET_TYPES_SLUGS.TOTAL_EVENTS_TYPOLOGIES_TIMELINE:
      return (
        <WidgetTotalEventsTypologiesTimeline
          id={id}
          title={t('secpos.widgets.total_events_typologies_timeline.title')}
          removeWidget={removeWidget}
          isEditing={isEditModeEnabled}
        />
      );

    case WIDGET_TYPES_SLUGS.TOTAL_EVENTS_TYPOLOGIES:
      return (
        <WidgetTotalEventsTypologies
          id={id}
          title={t('secpos.widgets.total_events_typologies.title')}
          removeWidget={removeWidget}
          isEditing={isEditModeEnabled}
        />
      );

    case WIDGET_TYPES_SLUGS.TOTAL_EVENTS:
      return (
        <WidgetTotalEvents
          id={id}
          title={t('secpos.widgets.total_events.title')}
          removeWidget={removeWidget}
          isEditing={isEditModeEnabled}
        />
      );
    case WIDGET_TYPES_SLUGS.NOTIFIED_CASES:
      return (
        <WidgetNotifiedCases
          id={id}
          title={t('secpos.widgets.notified_cases.title')}
          removeWidget={removeWidget}
          isEditing={isEditModeEnabled}
        />
      );

    case WIDGET_TYPES_SLUGS.CASES_BY_CRITICITY:
      return (
        <WidgetCasesByCriticity
          id={id}
          title={t('secpos.widgets.cases_by_criticity.title')}
          removeWidget={removeWidget}
          isEditing={isEditModeEnabled}
        />
      );

    case WIDGET_TYPES_SLUGS.CASES_BY_CRITICITY_TIMELINE:
      return (
        <WidgetCasesByCriticityTimeline
          id={id}
          title={t('secpos.widgets.cases_by_criticity_timeline.title')}
          removeWidget={removeWidget}
          isEditing={isEditModeEnabled}
        />
      );

    case WIDGET_TYPES_SLUGS.EVENTS_TYPOLOGIES_COUNT:
      return (
        <WidgetEventsTypologiesCount
          id={id}
          title={t('secpos.widgets.events_typologies_count.title')}
          removeWidget={removeWidget}
          isEditing={isEditModeEnabled}
        />
      );

    case WIDGET_TYPES_SLUGS.CRITICALITY_INCIDENTS:
      return (
        <WidgetCriticalityOfIncidents
          id={id}
          title={t('secpos.widgets.criticality_incidents.title')}
          removeWidget={removeWidget}
          isEditing={isEditModeEnabled}
        />
      );

    default:
      return null;
  }
};

export default WidgetRenderer;
