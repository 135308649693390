import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { NumberParam, useQueryParams } from 'use-query-params';

import { selectDateRange } from 'redux/filter/selectors';
import { actions } from 'redux/filter/slice';

const getIsValidDate = (date) => {
  if (
    date.dateFrom !== date.dateTo &&
    date.dateFrom !== undefined &&
    date.dateTo !== undefined
  ) {
    return true;
  }
  return false;
};

const useDateUrlFilter = () => {
  const dispatch = useDispatch();
  const dateRedux = useSelector(selectDateRange);
  const [date, setDate] = useQueryParams({
    dateFrom: NumberParam,
    dateTo: NumberParam,
  });

  React.useLayoutEffect(() => {
    if (
      date.dateTo === undefined ||
      date.dateFrom === undefined ||
      date.dateTo !== dateRedux.dateTo ||
      date.dateFrom !== dateRedux.dateFrom
    ) {
      setDate(
        {
          dateFrom: dateRedux.dateFrom,
          dateTo: dateRedux.dateTo,
        },
        'replaceIn'
      );
    }
  }, [setDate, dateRedux, date]);

  React.useLayoutEffect(() => {
    if (
      getIsValidDate(date) &&
      (date.dateFrom !== dateRedux.dateFrom || date.dateTo !== dateRedux.dateTo)
    ) {
      dispatch(actions.updateDate(date));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, date]);
};

export default useDateUrlFilter;
