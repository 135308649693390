import { useSelector } from 'react-redux';

import { CACHE_IDS } from 'common/constants/cache';
import { useQuery } from 'core/api/react-query';
import { getCriticalityTypes } from 'features/incidents/api';
import { selectDateRange } from 'redux/filter/selectors';

export const useCriticalityTypeFilters = () => {
  const { dateFrom, dateTo } = useSelector(selectDateRange);

  const query = useQuery(
    [CACHE_IDS.INCIDENTS_CRITICALITY, { dateFrom, dateTo }],
    () =>
      getCriticalityTypes({
        dateFrom,
        dateTo,
      }),
    {
      select: (data) => data.map((item) => item[0]),
    }
  );

  return query;
};
