import { post } from 'core/api/client';

export const getEntitiesData = async ({
  dateFrom,
  dateTo,
  page,
  caseTypes,
  entityTypes,
  entities,
  sortBy,
  orderBy,
  isSuspicious,
}) => {
  return post('/entity_details/get_entities', {
    date_from: dateFrom,
    date_to: dateTo,
    entities,
    is_suspicious: isSuspicious,
    requested_page: page,
    case_types: caseTypes,
    entity_types: entityTypes || [],
    page_size: 10,
    cases_per_entity: 10,
    sort_by: sortBy,
    order_by: orderBy,
  });
};

export const getEntityDetailsData = async ({ id, type }) => {
  return post('/entity_details/get_entity_details', {
    entity_identifier: id,
    entity_type: type,
  });
};

export const getCaseTypes = async ({ dateFrom, dateTo }) => {
  return post('/entity_details/get_case_types', {
    date_from: dateFrom,
    date_to: dateTo,
  });
};

export const getEntityTypes = async ({ dateFrom, dateTo }) => {
  return post('/entity_details/get_entity_types', {
    date_from: dateFrom,
    date_to: dateTo,
  });
};
