import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { CACHE_IDS } from 'common/constants/cache';
import { WIDGET_TYPES_SLUGS } from 'common/constants/widgets';
import { selectDateRange } from 'redux/filter/selectors';

import { getCasesByCriticity } from '../api';

const CRITICAL = 100;
const HIGH = 80;
const MEDIUM = 60;

const percentage = (value, total) => {
  return (value * 100) / total;
};

const mapper = (data) => {
  if (!data?.results?.length) return null;
  const total = data.results.length;

  const critical = data.results.filter(
    (criticity) => criticity.priority === CRITICAL
  ).length;
  const high = data.results.filter(
    (criticity) => criticity.priority === HIGH
  ).length;
  const medium = data.results.filter(
    (criticity) => criticity.priority === MEDIUM
  ).length;
  const low = data.results.filter(
    (criticity) => ![CRITICAL, HIGH, MEDIUM].includes(criticity.priority)
  ).length;

  const output = {
    CRITICAL:
      critical > 0 ? parseFloat(percentage(critical, total).toFixed(2)) : 0,
    HIGH: high > 0 ? parseFloat(percentage(high, total).toFixed(2)) : 0,
    MEDIUM: medium > 0 ? parseFloat(percentage(medium, total).toFixed(2)) : 0,
    LOW: low > 0 ? parseFloat(percentage(low, total).toFixed(2)) : 0,
  };

  return Object.keys(output).map((itemName) => ({
    name: itemName,
    y: output[itemName],
  }));
};

export const useCasesByCriticityQuery = () => {
  const { dateFrom, dateTo } = useSelector(selectDateRange);

  const query = useQuery(
    [
      CACHE_IDS.WIDGET_DATA,
      {
        slug: WIDGET_TYPES_SLUGS.CASES_BY_CRITICITY,
        dateFrom,
        dateTo,
      },
    ],
    () =>
      getCasesByCriticity({
        dateFrom,
        dateTo,
      }),
    {
      select: (result) => mapper(result),
    }
  );
  return query;
};
