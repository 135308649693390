import React from 'react';

import { useTranslation } from 'core/translations/useTranslation';

const priorityColors = {
  Critical: 'error',
  High: 'danger',
  Medium: 'warning',
  Low: 'success',
  Informative: 'success',
};

export const getColorByPriorityName = (color) => {
  return priorityColors[color];
};

export const getColorByPriorityValue = (value) => {
  switch (value) {
    case -1:
      return priorityColors.Informative;
    case 60:
      return priorityColors.Medium;
    case 80:
      return priorityColors.High;
    case 100:
      return priorityColors.Critical;
    default:
      return priorityColors.Low;
  }
};

const useGetPriorityText = () => {
  const t = useTranslation();

  const getPriorityText = React.useCallback(
    (value) => {
      if (value === -1) {
        return t('common.informative');
      } else if (value === 60) {
        return t('common.medium');
      } else if (value === 80) {
        return t('common.high');
      } else if (value === 100) {
        return t('common.critical');
      }
      return t('common.low');
    },
    [t]
  );

  return getPriorityText;
};

export default useGetPriorityText;
