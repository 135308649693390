import { useToast } from 'common/hooks/toasts';
import Box from 'components/Box';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Text from 'components/Text';
import { useTranslation } from 'core/translations/useTranslation';
import {
  useDeleteEmailNotificationMutation,
  useGetAlertNotificationConfigQuery,
} from 'features/profile/services';

const DeleteModal = ({ closeModal, id }) => {
  const t = useTranslation();
  const { showToast } = useToast();
  const { data: alerts } = useGetAlertNotificationConfigQuery();
  const { mutate: deleteEmail } = useDeleteEmailNotificationMutation({
    onSuccess: () => {
      showToast(t('settings.notification.delete_email_success'), 'success');
    },
    onError: () => {
      showToast(t('toast.error.error_500'), 'error');
    },
  });

  const arrayIdsAlertsNotifications = alerts?.map(
    (alert) => alert.email_group_id
  );

  const isIdFoundInAlertsNotification =
    arrayIdsAlertsNotifications?.includes(id);

  const handleOnClick = () => {
    deleteEmail(id);
    closeModal();
  };

  return (
    <Modal withCancel={isIdFoundInAlertsNotification} toggle={closeModal}>
      {!isIdFoundInAlertsNotification ? (
        <Box column between className="gap-6">
          <Box full xcenter column className="gap-3">
            <Text subtitle>{t('settings.tabs.modal.delete.title')}</Text>
            <Box column xcenter className="gap-1 text-center">
              <Text body isHtml>
                {t('settings.tabs.modal.delete.decription')}
              </Text>
            </Box>
          </Box>
          <Box full xcenter className="gap-5 pb-3">
            <Button bg="gray" color="lightText" onClick={closeModal}>
              {t('common.cancel')}
            </Button>
            <Button onClick={handleOnClick} bg="error">
              {t('common.delete')}
            </Button>
          </Box>
        </Box>
      ) : (
        <Box full xcenter column className="gap-3 pb-3">
          <Text subtitle>
            {t(
              'settings.notificacion.group_notification.modal.not_delete.tile'
            )}
          </Text>
          <Box column xcenter className="gap-1 text-center">
            <Text body>{t('secpos.dashboard.modal.description__bottom')}</Text>
          </Box>
        </Box>
      )}
    </Modal>
  );
};

export default DeleteModal;
