import React from 'react';

import Box from 'components/Box';
import Chip from 'components/Chip';
import Input from 'components/Input';
import Modal from 'components/Modal';
import Text from 'components/Text';
import Textarea from 'components/Textarea';
import { useTranslation } from 'core/translations/useTranslation';
import { useGetEmailNotificationGroupById } from 'features/profile/services';

const DetailsGroupModal = ({ closeModal, id }) => {
  const { data: emailDataById } = useGetEmailNotificationGroupById({ id });

  const t = useTranslation();
  return (
    <Modal
      className="gap-7"
      withCancel={false}
      title={t('common.details_group')}
      toggle={closeModal}
    >
      <Box full column className="gap-4">
        <Box column className="gap-1">
          <Box column className="gap-3">
            <Text bodyBold>{t('common.name')}</Text>
            <Input
              value={emailDataById?.name}
              defaultValue={emailDataById?.name}
              maxLength={30}
              type="text"
              label={t('common.name')}
            />
          </Box>
          <Box column className="gap-3">
            <Text bodyBold>{t('common.description')}</Text>
            <Textarea
              type="text"
              value={emailDataById?.description}
              defaultValue={emailDataById?.description}
              label={t('common.description')}
            />
          </Box>
          <Box column className="gap-3">
            <Box className="gap-1 items-end">
              <Text bodyBold>{t('common.emails_groups')}</Text>
              <Text badgeBold color="lightText">
                {emailDataById?.emails?.length} emails
              </Text>
            </Box>
            <Box className="flex-grow flex-wrap w-fit gap-2 max-h-[70px] overflow-hidden overflow-y-auto">
              {emailDataById?.emails?.map((email) => (
                <Chip key={email} disabled>
                  {email}
                </Chip>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default DetailsGroupModal;
