import React from 'react';

import 'devextreme/data/odata/store';

import { useControlledInput } from 'common/hooks';
import useOnOutsideClick from 'common/hooks/useOnOutsideClick';
import Input from 'components/Input';

const useInputAutoFocus = (inputRef) => {
  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);
};

export const TabNameInput = ({ onRename, name, toggleEdit }) => {
  const { value: inputName, onChange: onInputChange } = useControlledInput();

  const ref = React.createRef();
  useInputAutoFocus(ref);

  const updateName = async () => {
    const newName = inputName?.trim();
    toggleEdit();
    if (!newName || newName === '' || newName === name) return;
    await onRename(newName);
  };

  const onEnter = (e) => {
    if (e.key === 'Enter') updateName();
  };

  useOnOutsideClick(updateName, ref);

  return (
    <Input
      maxLength={30}
      name="title"
      noValidation
      ref={ref}
      defaultValue={inputName || name}
      className="max-h-[20px] text-md ml-[-4px] w-full overflow-hidden rounded-sm active:border-primary"
      inputClassname="px-[4px] py-[4px] w-full max-w-[180px]"
      onChange={onInputChange}
      onKeyDown={onEnter}
    />
  );
};
