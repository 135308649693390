import { CACHE_IDS } from 'common/constants/cache';
import { useQuery } from 'core/api/react-query';

import { getReportingProducts } from '../api';

export const useReportingDeviceQuery = () => {
  const query = useQuery([CACHE_IDS.REPORTING_PRODUCTS], getReportingProducts);

  return query;
};

export default useReportingDeviceQuery;
