import { useQuery } from 'react-query';

import { CACHE_IDS } from 'common/constants/cache';

import { getCaseDetailsRawEvents } from '../../api';

export const useRawEventsDataQuery = ({ id }) => {
  const query = useQuery([CACHE_IDS.RAW_EVENTS, { id }], () =>
    getCaseDetailsRawEvents({ id })
  );

  return query;
};
