import { useSelector } from 'react-redux';

import { CACHE_IDS } from 'common/constants/cache';
import { WIDGET_TYPES_SLUGS } from 'common/constants/widgets';
import { useQuery } from 'core/api/react-query';
import { selectDateRange } from 'redux/filter/selectors';

import { getNotifiedCases } from '../api';

export const useNotifiedCasesQuery = () => {
  const { dateFrom, dateTo } = useSelector(selectDateRange);

  const query = useQuery(
    [
      CACHE_IDS.WIDGET_DATA,
      { slug: WIDGET_TYPES_SLUGS.NOTIFIED_CASES, dateTo, dateFrom },
    ],
    () =>
      getNotifiedCases({
        dateFrom,
        dateTo,
      })
  );
  return query;
};

export default useNotifiedCasesQuery;
