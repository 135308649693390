import React, { useEffect } from 'react';

import { useParams } from 'react-router';

import EmptyComponent from 'common/components/tables/EmptyComponent';
import Box from 'components/Box';

import { useCorrelationsDataQuery } from '../../../../services/incident-details';
import { useColumns } from './table';
import Table from './Table/Table';

const Correlations = () => {
  const { id } = useParams();

  const {
    data,
    isError: isTableError,
    isFetching: isTableFetching,
    refetch,
    isPreviousData,
  } = useCorrelationsDataQuery({ id });

  useEffect(() => {
    refetch();
  }, [id]);

  return (
    <Box column className="gap-5 overflow-x-auto ">
      <Table
        data={data?.correlations}
        columns={useColumns()}
        EmptyComponent={EmptyComponent({ isTableError, refetch })}
        isError={isTableError}
        isFetching={isTableFetching}
        loading={isTableFetching || isPreviousData}
        loadingRows={5}
        loadingHeight={64}
        withMinWidth
      />
    </Box>
  );
};

export default Correlations;
