import React from 'react';

import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

import { CACHE_IDS } from 'common/constants/cache';
import { useQuery } from 'core/api/react-query';
import { selectDateRange } from 'redux/filter/selectors';

import { getEntitiesData } from '../api';

export const useEntitiesDataQuery = ({
  page,
  caseTypes,
  entityTypes,
  entities,
  sortBy,
  orderBy,
  isSuspicious,
}) => {
  const queryClient = useQueryClient();
  const { dateFrom, dateTo } = useSelector(selectDateRange);

  const query = useQuery(
    [
      CACHE_IDS.ENTITIES_ALL,
      {
        page,
        dateFrom,
        dateTo,
        caseTypes,
        entityTypes,
        entities,
        sortBy,
        orderBy,
        isSuspicious,
      },
    ],
    () =>
      getEntitiesData({
        dateFrom,
        dateTo,
        page,
        caseTypes,
        entityTypes,
        entities,
        sortBy,
        orderBy,
        isSuspicious,
      }),
    {
      keepPreviousData: true,
    }
  );

  React.useEffect(() => {
    if (query?.data?.num_entity || query?.data?.num_pages) {
      queryClient.invalidateQueries([CACHE_IDS.ENTITIES_ALL]);
    }
  }, [query?.data?.num_entity, query?.data?.num_pages, queryClient]);
  return query;
};
