module.exports = {
  screens: {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1200px',
    '2xl': '1320px',
  },
  colors: {
    blackOpacity: ' rgba(0, 0, 0, 0.60)',
    black: '#000',
    primary: '#1e93be',
    secondary: '#1f3740',
    red: '#d45a66',
    text: '#1f3740',
    lightText: '#8294a1',
    darkBackground: '#f4f4fa',
    white: '#fff',
    gray: '#dddddd',
    darkGray: '#bbb',
    error: '#d45a66',
    danger: '#ffa974',
    warning: '#fbd360',
    success: '#a3da8d',
    darkOrange: '#b16130',
    darkYellow: '#8d6e16',
    darkGreen: '#426933',
    lightRed: '#EDACB2',
    lightOrange: '#FFDDC8',
    lightYellow: '#FFF2CE',
    lightGreen: '#e2ffd6',
    lightBlue: '#D9EEF0',
    veryLightRed: '#FFD9DC',
    darkBlue: '#3846EC',
  },
  spacing: {
    0: '0px',
    1: '4px',
    2: '8px',
    3: '16px',
    4: '24px',
    5: '32px',
    6: '40px',
    7: '48px',
    8: '64px',
    9: '80px',
  },
  fontSize: {
    xs: '8px',
    sm: '12px',
    base: '14px',
    md: '16px',
    lg: '18px',
    xl: '22px',
    '2xl': '28px',
    '3xl': '32px',
    '4xl': '36px',
    '5xl': '48px',
  },
  fontWeight: {
    light: '400',
    regular: '500',
    bold: '600',
    black: '700',
  },
  borderRadius: {
    sm: '2px',
    md: '4px',
    lg: '6px',
  },
};
