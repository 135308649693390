import { useSelector } from 'react-redux';

import { CACHE_IDS } from 'common/constants/cache';
import { WIDGET_TYPES_SLUGS } from 'common/constants/widgets';
import { useQuery } from 'core/api/react-query';
import { selectDateRange } from 'redux/filter/selectors';

import { getTopDetectedHost } from '../api';

const mapApiListToObject = (data) => {
  if (!data.length) {
    return {
      totalCases: 0,
      list: [],
    };
  }
  const listOfCases = data?.map((item) => item[2]);
  const totalCases = listOfCases?.reduce((prev, current) => prev + current);
  const listOfObjects = data?.map((item) => ({
    host: item[1],
    cases: item[2],
    // If this item has 20 cases of 40 total cases, the percent of completion in a bar chart is 50%
    completion: (item[2] / totalCases) * 100,
  }));

  return {
    totalCases,
    list: listOfObjects,
  };
};

export const useTopDetectedHostsQuery = () => {
  const { dateFrom, dateTo } = useSelector(selectDateRange);

  const query = useQuery(
    [
      CACHE_IDS.WIDGET_DATA,
      { slug: WIDGET_TYPES_SLUGS.TOP_DETECTED_HOSTS, dateFrom, dateTo },
    ],
    () =>
      getTopDetectedHost({
        dateFrom,
        dateTo,
        limit: 10,
        case_type: [],
      }),
    {
      select: (result) => mapApiListToObject(result),
    }
  );
  return query;
};
