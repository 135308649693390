import moment from 'moment';

import Box from 'components/Box';
import Skeleton from 'components/Skeleton';
import Tag from 'components/Tag';
import Text from 'components/Text';
import { cls } from 'core/styles/cls';
import useGetPriorityText, {
  getColorByPriorityValue,
} from 'features/incidents/utils/priorities';

const MetadataBase = ({ children, isLoading }) => {
  return (
    <Box column className="gap-2 px-4 overflow-hidden xl:w-[50%]">
      {isLoading
        ? [...Array(5)].map(() => (
            <Box className="gap-4 w-full overflow-hidden">
              <Skeleton width="180px" height="24px" />
              <Skeleton width="1000px" height="24px" />
            </Box>
          ))
        : children}
    </Box>
  );
};

const isDate = (date) => {
  return (
    new Date(parseInt(date)) !== 'Invalid Date' &&
    !isNaN(new Date(parseInt(date)))
  );
};

const MetadataRow = ({ title, value, className }) => {
  const getPriorityText = useGetPriorityText();

  const formatValue = (value) => {
    if (value === 60 || value === -1 || value === 80 || value === 100)
      return (
        <Tag className="w-fit" status={getColorByPriorityValue(value)}>
          <Text badgeBold>{getPriorityText(value)}</Text>
        </Tag>
      );
    if (isDate(value / 1000) && title !== 'DestinationPort') {
      const newDate = new Date(parseInt(value));
      return (
        <Box className="gap-1">
          <Text body>{moment(newDate).format('DD-MM-YYYY')} -</Text>
          <Text body>{moment(newDate).format('HH:mm:ss')}</Text>
        </Box>
      );
    }
    return value;
  };

  return (
    <Box full className={cls('gap-4 items-center', className)}>
      <Box className="min-w-[180px] max-w-[180px] mb-auto">
        <Text ellipsis subheading color="lightText">
          {title}
        </Text>
      </Box>
      <Text body className="break-all">
        {formatValue(value)}
      </Text>
    </Box>
  );
};

const Metadata = {
  Base: MetadataBase,
  Row: MetadataRow,
};

export default Metadata;
