import Box from 'components/Box';
import Icon from 'components/Icon';
import Text from 'components/Text';
import { cls } from 'core/styles/cls';

const TabsWrapper = ({ children, className }) => {
  return (
    <Box className={cls('gap-4 mb-4 border-b-gray border-b', className)}>
      {children}
    </Box>
  );
};

const Tab = ({
  children,
  isActive,
  onClick,
  className,
  counter,
  icon,
  toggle,
}) => {
  return (
    <Box
      onClick={onClick}
      className={cls('cursor-pointer pb-3 px-2 gap-4 relative', className, {
        'border-b-[2px] border-b-primary': isActive,
      })}
    >
      <Text subheading color={isActive ? 'primary' : 'lightText'}>
        {children}
      </Text>

      {!isNaN(counter) && (
        <Box xcenter className="bg-darkBackground rounded-md px-1 ml-2">
          <Text color={isActive ? 'primary' : 'lightText'} subheading>
            {counter}
          </Text>
        </Box>
      )}

      {icon && (
        <Icon
          onClick={toggle}
          name={icon}
          color="primary"
          className={cls('text-md', { 'opacity-0': !isActive })}
        />
      )}
    </Box>
  );
};

const Tabs = {
  Wrapper: TabsWrapper,
  Tab: Tab,
};

export default Tabs;
