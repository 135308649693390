import CalendarFilter from 'common/components/filters/CalendarFilter';
import Box from 'components/Box';
import { useTranslation } from 'core/translations/useTranslation';
import CommonLayout from 'features/layout';
import Title from 'features/layout/components/Title';

const Layout = ({ children, renderTabs }) => {
  const t = useTranslation();

  return (
    <CommonLayout
      className="p-0"
      renderContent={() => (
        <Box className="grow-1 min-h-screen w-full flex-col overflow-y-auto">
          <Box
            full
            column
            className="gap-1 h-fit bg-white px-5 pt-5 lg:px-7 border-b-gray border-b"
          >
            <Box between full className="pb-2">
              <Title
                title={t('sidebar.security_posture')}
                icon="security-posture"
              />
              <Box className="gap-3 min-h-[36px]" xcenter ycenter>
                <CalendarFilter />
              </Box>
            </Box>
            {renderTabs && renderTabs()}
          </Box>
          <Box className="block ">{children}</Box>
        </Box>
      )}
    />
  );
};

export default Layout;
