import { post, get } from 'core/api/client';

export const getIncidentsData = async ({
  dateFrom,
  dateTo,
  page,
  caseTypes,
  casesId,
  sortBy,
  orderBy,
  products,
  important,
  incident = [],
  criticality,
}) => {
  return post('/cases_incidents/incidents_data', {
    date_to: dateTo,
    date_from: dateFrom,
    cases_id: casesId,
    requested_page: page,
    case_type: caseTypes,
    page_size: 10,
    sort_by: sortBy,
    order_by: orderBy,
    products,
    important,
    incident,
    criticality,
  });
};

export const getIncidentsTypes = async ({ dateFrom, dateTo }) => {
  return post('/cases_incidents/cases_filter_types', {
    date_from: dateFrom,
    date_to: dateTo,
  });
};

export const getProductsTypes = async ({ dateFrom, dateTo }) => {
  return post('/cases_incidents/products_filter_types', {
    date_from: dateFrom,
    date_to: dateTo,
  });
};

export const getCriticalityTypes = async ({ dateFrom, dateTo }) => {
  return post('/cases_incidents/criticality_filter_types', {
    date_from: dateFrom,
    date_to: dateTo,
  });
};

export const getCaseDetailsBaseDetails = async ({ id }) =>
  get(`/case_details/${id}/basic_details`);

export const getCaseDetailsOverview = async ({ id }) =>
  get(`/case_details/${id}/overview`);

export const getCaseDetailsRelatedEntities = async ({ id }) =>
  get(`/case_details/${id}/related_entities`);

export const getCaseDetailSimilarCases = async ({ id }) =>
  get(`/case_details/${id}/similar_cases`);

export const getCaseDetailsRawEvents = async ({ id }) =>
  get(`/case_details/${id}/raw_events`);

export const getCaseDetailsIncidentLogs = async ({ id }) =>
  get(`/case_details/${id}/incident_logs`);

export const getCaseDetailsCorrelations = async ({ id }) =>
  get(`/case_details/${id}/correlations`);

export const getCaseDetailsHasCorrelations = async ({ id }) =>
  get(`/case_details/${id}/has_correlations`);

export const getIncidentTypologies = async ({
  dateFrom,
  dateTo,
  case_type,
  cases_id,
}) =>
  post('/get_widget_data/incident_typologies', {
    date_from: dateFrom,
    date_to: dateTo,
    case_type,
    cases_id,
  });

export const getIncidentTimeline = async ({
  dateFrom,
  dateTo,
  case_type,
  cases_id,
}) =>
  post('/get_widget_data/incident_timeline', {
    date_from: dateFrom,
    date_to: dateTo,
    case_type,
    cases_id,
  });
