import React from 'react';

export const useToggler = (initialState = false) => {
  const [isShown, setIsShown] = React.useState(initialState);

  const toggle = React.useCallback(() => {
    setIsShown((prev) => !prev);
  }, [setIsShown]);

  return { isShown, toggle, setIsShown };
};

export default useToggler;
