import { Cookie } from 'core/cookies';

import Request from './request-builder';

const API_URL = process.env.REACT_APP_API_URL;

const client = async (path, method, { body, params, isNotJson = false }) => {
  const token = Cookie.get('token');

  const defaultUrl = `${API_URL}${path}`;
  const defaultOptions = {
    method,
    body,
    headers: {
      Accept: 'application/json', // api sends response in JSON
    },
  };

  const { url, options } = new Request(defaultUrl, defaultOptions)
    .addUrlParams(params)
    .setToken(token)
    .syncTimestamps()
    .build({ isJson: !isNotJson });

  const res = await fetch(url, options);
  if (res.status !== 200) {
    const error = await res.json();
    // eslint-disable-next-line no-throw-literal
    throw { ...error, status: res.status };
  }
  return res.json();
};

export const get = async (path, params) => {
  return client(path, 'GET', { params });
};

export const post = async (path, body, params, isNotJson) => {
  return client(path, 'POST', { body, params, isNotJson });
};

export const put = async (path, body, params, isNotJson) => {
  return client(path, 'PUT', { body, params, isNotJson });
};

export const remove = async (path, params, isNotJson) => {
  return client(path, 'DELETE', { params, isNotJson });
};
