import IP from 'assets/svg/ip.svg';
import Box from 'components/Box';
import Icon from 'components/Icon';
import Text from 'components/Text';
import { useTranslation } from 'core/translations/useTranslation';

export const useColumns = () => {
  const t = useTranslation();

  return [
    {
      title: t('incidents.details.tabs.correlations.table.header.entity_type'),
      dataIndex: 'entity_type',
      key: 'entity_type',
      render: (entity_type) => {
        const type = entity_type;
        const renderTag = () => {
          if (type === 'HOSTNAME' || type === 'DestinationURL') {
            return <Icon className="text-xl" color="primary" name="host" />;
          } else if (type === 'USERUNIQNAME') {
            return <Icon className="text-xl" color="error" name="account" />;
          } else if (type === 'ADDRESS') {
            return <img className="w-4" src={IP} alt="ip" />;
          } else if (type === 'FILENAME') {
            return <Icon className="text-xl" name="file" color="7B61FF" />;
          } else if (type === 'THREATSIGNATURE') {
            return <Icon className="text-xl" name="threats" color="warning" />;
          } else if (type === 'FILEHASH') {
            return <Icon className="text-xl" name="hash" color="lightText" />;
          } else if (type === 'DOMAIN') {
            return <Icon className="text-xl" name="domain" color="primary" />;
          }
          return <Icon className="text-xl " name="info" color="warning" />;
        };
        return (
          <Box className="flex">
            {entity_type ? (
              <>
                {renderTag()}
                <Text
                  className="break-words text-ellipsis 
               ml-1"
                >
                  {entity_type}
                </Text>
              </>
            ) : (
              <Text body>{t('common.unknown')}</Text>
            )}
          </Box>
        );
      },
    },
    {
      title: t('incidents.details.tabs.correlations.table.header.entity'),
      dataIndex: 'entity',
      key: 'entity',
      render: (entity) => (
        <Box>
          {entity ? (
            <Text className="break-words text-ellipsis">{entity}</Text>
          ) : (
            <Text body>{t('common.unknown')}</Text>
          )}
        </Box>
      ),
    },
    {
      title: t('incidents.details.tabs.correlations.table.header.source'),
      dataIndex: 'source',
      key: 'source',
      render: (source) => (
        <Box>
          <Text className="break-words text-ellipsis">{source}</Text>
        </Box>
      ),
    },
    {
      title: t('incidents.details.tabs.correlations.table.header.content'),
      dataIndex: 'content',
      key: 'content',
      render: (content) => (
        <Box>
          <Text
            className="break-words text-ellipsis"
            dangerouslySetInnerHTML={{ __html: content }}
          ></Text>
        </Box>
      ),
    },
  ];
};
