export const CACHE_IDS = {
  LOGIN: 'login',
  CURRENT_USER: 'user',
  CURRENT_TENANT: 'tenant',
  CURRENT_LANGUAGE: 'current_language',
  LANGUAGE: 'language',
  SECURITY_POSTURE: 'sec-posture',
  WIDGET_TYPE: 'widget-type',
  WIDGET_TYPE_TAGS: 'widget-type-tags',
  WIDGET_DATA: 'widget-data',

  DASHBOARDS: 'dashboards',

  USERS_INCIDENTS_ALL: 'users-incidents-all',
  USERS_INCIDENTS_TOP: 'users-incidents-top',
  USERS_INCIDENTS_ALL_CASE_FILTER: 'users-incidents-users-filters',

  HOSTS_INCIDENTS_ALL: 'hosts-incidents-all',
  HOSTS_INCIDENTS_TOP: 'hosts-incidents-top',
  HOSTS_INCIDENTS_ALL_CASE_FILTER: 'hosts-incidents-users-filters',

  ENTITIES_ALL: 'entities-all',
  ENTITIES_CASE_FILTER: 'entities-case-filters',
  ENTITIES_TYPE_FILTER: 'entities-type-filters',
  ENTITIES_DETAILS: 'entities_details',

  INCIDENTS_INCIDENTS_ALL: 'incidents-incidents-all',
  INCIDENTS_INCIDENTS_ALL_CASE_FILTER: 'incidents-incidents-users-filters',
  INCIDENTS_PRODUCTS_ALL_CASE_FILTER: 'incidents-incidents-case-filters',
  INCIDENTS_CRITICALITY: 'incidents-incidents-criticality',

  INTEGRATIONS_ALL: 'integrations-all',
  INTEGRATIONS_TAGS: 'integrations-tags',

  AUTOMATED_RESPONSE: 'automated_response',

  ENTITY_DETAILS_HOSTS: 'entity_details_hosts',
  ENTITY_DETAILS_USERS: 'entity_details_users',

  VERSION_AND_BUILD: 'version_and_build',
  TENANT_USER: 'tenant_user',

  REPORTING_PRODUCTS: 'reporting_products',
  REPORTING_PRODUCTS_ID: 'reporting_products_id',

  ALERT_NOTIFICATION_CONFIG: 'alert_notification_config',
  ALERT_MONITOR_CONFIG: 'alert_monitor_config',
  EMAIL_NOTIFICATION_GROUP: 'email_notification_group',
  EMAIL_NOTIFICATION_GROUP_BY_ID: 'email_notification_group_by_id',

  BASIC_DETAILS: 'basic_details',
  OVERVIEW: 'overview',
  RELATED_ENTITIES: 'related_entities',
  SIMILAR_CASES: 'similar_cases',
  RAW_EVENTS: 'raw_events',
  INCIDENT_LOGS: 'incident_logs',
  CORRELATIONS: 'correlations',
  EVENTS_STORAGE: 'events_storage',
  METRICS: 'metrics',
};
