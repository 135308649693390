import { get, post } from 'core/api/client';

export const getIntegrationsTags = async () => get('/integrations_tags');

export const getVendors = async ({ vendors, page, tags }) => {
  return post('/vendors/', {
    vendors,
    requested_page: page,
    page_size: 6,
    tags,
  });
};
