export const getChartOptions = ({ data }) => ({
  credits: {
    enabled: false,
  },
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie',
    events: {
      load: function () {
        updateLegend(this);
      },
      redraw: function () {
        updateLegend(this);
      },
    },
  },
  title: '',

  accessibility: {
    point: {
      valueSuffix: '%',
    },
  },
  plotOptions: {
    pie: {
      colors: ['#dc0000', '#fd8c00', '#fdc500', '#00ac46'],
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
    },
  },
  legend: {
    labelFormatter: function () {
      return this.name + ': </span><b>' + this.y + '%<br/>';
    },
    align: 'right',
    verticalAlign: 'middle',
    layout: 'vertical',
  },
  tooltip: {
    formatter: function () {
      return `${this.point.name} : <b>${this.point.y}%</b>`;
    },
  },
  series: [
    {
      name: 'Cases',
      colorByPoint: true,
      data,
    },
  ],
});

const updateLegend = function (chart) {
  var center = chart.series[0].center;
  chart.legend.group.attr({
    translateX: center[0] + center[2] / 1.5,
  });
};
