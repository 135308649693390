import React from 'react';

import { useForm } from 'react-hook-form';

import { useToast } from 'common/hooks/toasts';
import Button from 'components/Button';
import Input from 'components/Input';
import { useTranslation } from 'core/translations/useTranslation';

import { useUpdateUserPasswordMutation } from '../../../services';
import ContentLayout from '../../ContentLayout';

export const SettingsUpdatePassword = () => {
  const { showToast } = useToast();
  const t = useTranslation();
  const {
    register,
    getValues,
    handleSubmit,
    trigger,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' });

  const { mutate: updateUserPassword, isLoading: isUpdatePasswordLoading } =
    useUpdateUserPasswordMutation({
      onSuccess: () => {
        showToast(t('settings.update_password.success_toast'), 'success');
      },
      onError: (error) => {
        showToast(error.detail, 'error');
      },
    });

  const submit = (values) => {
    const { currentPassword, newPassword, retypePassword } = values;

    updateUserPassword({
      currentPassword,
      newPassword,
      retypePassword,
    });
  };

  const retypePassword = watch('retypePassword');
  const newPassword = watch('newPassword');
  const currentPassword = watch('currentPassword');

  React.useEffect(() => {
    if (newPassword?.length && retypePassword?.length) {
      trigger('newPassword');
      trigger('retypePassword');
    }
  }, [retypePassword, newPassword, trigger]);

  React.useEffect(() => {
    if (newPassword?.length && currentPassword?.length) {
      trigger('newPassword');
      trigger('currentPassword');
    }
  }, [newPassword, currentPassword, trigger]);

  return (
    <ContentLayout title={t('settings.update_password.title')}>
      <form
        autoComplete="off"
        onSubmit={handleSubmit(submit)}
        className="flex w-full flex-col gap-2 max-w-[550px]"
      >
        <Input
          label={t('common.current_password')}
          type="password"
          {...register('currentPassword', {
            required: t('validations.required'),
            pattern: {
              value: /^[^\s]+(?:$|.*[^\s]+$)/,
              message: t('validations.blank_space'),
            },
          })}
          error={errors.currentPassword?.message}
        />
        <Input
          label={t('common.new_password')}
          type="password"
          {...register('newPassword', {
            required: t('validations.required'),
            minLength: {
              value: 6,
              message: t('validations.password_length'),
            },
            pattern: {
              value: /^[^\s]+(?:$|.*[^\s]+$)/,
              message: t('validations.blank_space'),
            },
            validate: {
              differentThanPassword: (value) => {
                const { currentPassword } = getValues();

                if (
                  value?.length &&
                  currentPassword?.length &&
                  value === currentPassword
                ) {
                  return t('validations.password_same');
                }
              },
            },
          })}
          error={errors.newPassword?.message}
        />

        <Input
          label={t('common.retype_password')}
          type="password"
          {...register('retypePassword', {
            required: t('validations.required'),
            pattern: {
              value: /^[^\s]+(?:$|.*[^\s]+$)/,
              message: t('validations.blank_space'),
            },

            validate: {
              sameThanNewPassword: async (value) => {
                const { newPassword } = getValues();
                if (newPassword.length && value !== newPassword) {
                  return t('validations.password_match');
                }
              },
            },
          })}
          error={errors.retypePassword?.message}
        />
        <Button
          loading={isUpdatePasswordLoading}
          disabled={!isValid}
          type="submit"
          className="ml-auto w-[200px]"
        >
          {t('common.save_changes')}
        </Button>
      </form>
    </ContentLayout>
  );
};

export default SettingsUpdatePassword;
