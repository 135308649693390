import React from 'react';

import Button from 'components/Button';
import Text from 'components/Text';
import { cls } from 'core/styles/cls';

import Box from '../Box';
import Icon from '../Icon';

const Input = (
  {
    className,
    iconColor = 'lightText',
    iconL,
    iconR,
    size,
    error,
    onAdd,
    noValidation,
    label,
    name,
    buttonAdd,
    textButton,
    width,
    onChange,
    onBlur,
    onFocus,
    disabled,
    inputClassname,
    ...props
  },
  ref
) => {
  const [isFocused, setIsFocused] = React.useState(false);
  const [innerValue, setInnerValue] = React.useState(props.value);

  React.useLayoutEffect(() => {
    setInnerValue(props.value);
  }, [props.value]);

  React.useLayoutEffect(() => {
    setInnerValue(props.defaultValue);
  }, [props.defaultValue]);

  const hasContent = !!innerValue || innerValue + '' === '0';

  const handleClickClearInput = () => {
    if (onChange) onChange({ target: { value: '' } });
  };
  return (
    <Box column style={{ maxWidth: width }} className="w-full gap-1">
      <Box
        ycenter
        className={cls(
          'bg-white relative rounded-md border-[1px] border-gray text-text focus-within:border-primary flex-shrink: 0 min-w-[220px]',
          {
            'bg-darkBackground hover:border-gray': disabled,
            'hover:border-darkGray': !isFocused,
            'border-error focus-within:border-error hover:border-error': error,
          },
          className
        )}
      >
        {iconL && (
          <Icon size={size} name={iconL} color={iconColor} className="ml-1" />
        )}
        <input
          disabled={disabled}
          ref={ref}
          onFocus={(e) => {
            setIsFocused(true);
            if (onFocus) onFocus(e);
          }}
          onBlur={(e) => {
            setIsFocused(false);
            if (onBlur) onBlur(e);
          }}
          onChange={(e) => {
            setInnerValue(e.target.value);
            if (onChange) onChange(e);
          }}
          className={cls(
            'z-10 w-full bg-[transparent] py-2 placeholder-lightText focus:outline-none',
            {
              'px-[12px]': !iconL,
              'pl-2 pr-[12px]': iconL,
            },
            inputClassname
          )}
          name={name}
          {...props}
        />
        <label
          htmlFor={name}
          className={cls(
            'absolute top-2 left-[12px] origin-left text-lightText transition duration-300 ease',
            {
              '-translate-y-[18px] scale-[75%] transform z-10 ':
                isFocused || hasContent,
              'bg-white': !disabled,
              'bg-darkBackground': disabled,
              'text-primary': isFocused,
              'text-error': !!error,
              'text-lightText': !!disabled,
            }
          )}
        >
          {label}
        </label>
        {iconR && (
          <>
            {hasContent && (
              <Icon
                cursor="pointer"
                name="close"
                color="lightText"
                onClick={handleClickClearInput}
                className="text-lg"
              />
            )}
            <Icon
              size={size}
              name={iconR}
              color={iconColor}
              className="mx-1 border-l pl-1 border-darkGray h-4"
            />
          </>
        )}
        {buttonAdd && (
          <Button
            onClick={onAdd}
            color="text"
            className="h-[37px] border-l border-gray rounded-l-[0px] bg-white hover:bg-darkBackground hover:rounded-[0px]"
          >
            {textButton}
          </Button>
        )}
      </Box>
      {!noValidation && (
        <Box block className="h-[24px] w-full">
          {error && (
            <Text color="error" className="ml-2">
              {error}
            </Text>
          )}
        </Box>
      )}
    </Box>
  );
};

export default React.forwardRef(Input);
