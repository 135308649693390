import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';

import { useToast } from 'common/hooks/toasts';
import { Cookie } from 'core/cookies';
import { changeLanguage } from 'core/translations/changeLanguage';
import { useTranslation } from 'core/translations/useTranslation';

export const useLogout = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const t = useTranslation();
  const { showToast } = useToast();

  const logout = () => {
    Cookie.remove('token');
    navigate('/login');
    showToast(t('toast.logout'), 'success');
    changeLanguage(navigator.language);
    queryClient.resetQueries();
  };

  return { logout };
};
