import Box from 'components/Box';
import Button from 'components/Button';
import Text from 'components/Text';
import { useTranslation } from 'core/translations/useTranslation';

const EmptyComponent = ({ isError, refetch, noDataText }) => {
  const t = useTranslation();

  return (
    <Box className="w-full py-4" xcenter>
      {isError ? (
        <Box column className="gap-3" xcenter>
          <Text body>{t('table.empty_state.error')}</Text>
          {refetch && (
            <Button onClick={refetch} className="w-[180px]">
              {t('common.retry')}
            </Button>
          )}
        </Box>
      ) : noDataText ? (
        <Text body> {noDataText}</Text>
      ) : (
        <Text body> {t('common.no_data')}</Text>
      )}
    </Box>
  );
};

export default EmptyComponent;
