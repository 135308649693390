import React from 'react';

import EmptyStateWidgets from 'common/components/widgets/EmptyStateWidgets';
import Box from 'components/Box';
import Chart from 'components/Chart';
import Skeleton from 'components/Skeleton';
import Widget from 'components/Widget';
import { useTranslation } from 'core/translations/useTranslation';

import { useIncidentTypologiesQuery } from '../../../services/widget-data';
import { useFilters } from '../../TableView/hooks';
import { useGetChartOptions } from './chart';

const WidgetIncidentDetection = () => {
  const t = useTranslation();
  const { filters } = useFilters();
  const { data: widgetData, isLoading } = useIncidentTypologiesQuery(filters);
  const getChartOptions = useGetChartOptions();

  const totalIncidents = React.useMemo(
    () =>
      widgetData?.data.reduce((accumulator, value) => {
        return accumulator + value;
      }, 0),
    [widgetData?.data]
  );

  const dataTransformed = React.useMemo(
    () =>
      widgetData?.categories.map((name, index) => ({
        name,
        y: widgetData?.data[index],
      })),
    [widgetData?.categories, widgetData?.data]
  );

  const options = React.useMemo(
    () =>
      getChartOptions({
        totalIncidents,
        data: dataTransformed,
      }),
    [totalIncidents, dataTransformed, getChartOptions]
  );

  return (
    <Widget
      iconLeft="settings"
      iconRight="dots"
      subtitle={t('incidents.widgets.incident_detection.title')}
      className="h-fit"
    >
      {isLoading ? (
        <Box xcenter column className="gap-5">
          <Skeleton width={278} height={278} circle />
          <Skeleton width={500} />
        </Box>
      ) : widgetData?.data?.length ? (
        <Chart
          options={options}
          containerProps={{ style: { width: '100%', height: '330px' } }}
        />
      ) : (
        <EmptyStateWidgets className="min-h-[400px]" />
      )}
    </Widget>
  );
};

export default WidgetIncidentDetection;
