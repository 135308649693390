import { createRoot } from 'react-dom/client';
import { ToastContainer } from 'react-toastify';

import { config as toastConfig } from 'core/toasts/config';

import GlobalProviders from './Providers';
import Router from './Router';

import 'core/styles/index.scss';
import 'core/translations/init';
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <GlobalProviders>
    <ToastContainer {...toastConfig} />
    <Router />
  </GlobalProviders>
);
