import CasesByCriticityTimelineImage from 'assets/security-posture/cases-by-criticity-timeline.png';
import CasesByCriticityImage from 'assets/security-posture/cases-by-criticity.png';
import CriticalityIncidentsImage from 'assets/security-posture/criticality-of-incidents.png';
import EventsTypologiesCount from 'assets/security-posture/events-tipologies-count.png';
import IncidentTimeline from 'assets/security-posture/incident-timeline.png';
import IncidentTypologies from 'assets/security-posture/incident-typologies.png';
import MaliciousFilehashsImage from 'assets/security-posture/malicious-filehashs.png';
import MaliciousFilenamesImage from 'assets/security-posture/malicious-filenames.png';
import MaliciousIpsDestinations from 'assets/security-posture/malicious-ips-destinations.png';
import MaliciousIpsSources from 'assets/security-posture/malicious-ips-sources.png';
import MaliciousUrlImage from 'assets/security-posture/malicious-url.png';
import MalwareTypologies from 'assets/security-posture/malware-typologies.png';
import NotifiedCasesImage from 'assets/security-posture/notified-cases.png';
import QuickList from 'assets/security-posture/quick-list-incidents.png';
import ReportedProducts from 'assets/security-posture/reported-cases.png';
import SignatureListImage from 'assets/security-posture/signature-list.png';
import Summary from 'assets/security-posture/summary.png';
import TopDetectedHostsImage from 'assets/security-posture/top-detected-hosts.png';
import TopDetectedUsersImage from 'assets/security-posture/top-detected-users.png';
import TotalEventsTimelineImage from 'assets/security-posture/total-events-timeline.png';
import TotalEventTypologiesTimelineImage from 'assets/security-posture/total-events-typologies-timeline.png';
import TotalEventsTypologiesImage from 'assets/security-posture/total-events-typologies.png';
import TotalEventsImage from 'assets/security-posture/total-events.png';

export const WIDGET_TYPES_SLUGS = {
  TOP_DETECTED_USERS: 'top_useruniqname_entities',
  TOP_DETECTED_HOSTS: 'top_detected_hosts',
  INCIDENT_TYPOLOGIES: 'incident-typologies',
  INCIDENT_TIMELINE: 'incident-timeline',
  SOC_ENGINE: 'soc-engine',
  EVENTS_TYPOLOGIES: 'events_typologies',
  MALWARE_TYPOLOGIES: 'malware-typologies',
  REPORTED_PRODUCTS: 'reporting-products',
  SUMMARY: 'summary',
  QUICK_LIST: 'quick-list',
  URL_MALICIOUS: 'malicious-url',
  FILENAMES_MALICIOUS: 'malicious-filenames',
  FILEHASHS_MALICIOUS: 'malicious-filehashs',
  MALICIOUS_IPS_SOURCES: 'malicious-ips-sources',
  MALICIOUS_IPS_DESTINATIONS: 'malicious-ips-destinations',
  SIGNATURE_LIST: 'signature-list',
  TOTAL_EVENTS_TYPOLOGIES: 'total-events-typologies',
  TOTAL_EVENTS: 'total-events',
  TOTAL_EVENT_TIMELINE: 'total-events-timeline',
  TOTAL_EVENTS_TYPOLOGIES_TIMELINE: 'total-events-typologies-timeline',
  NOTIFIED_CASES: 'notified-cases',
  CASES_BY_CRITICITY: 'cases-by-criticity',
  CASES_BY_CRITICITY_TIMELINE: 'cases-by-criticity-timeline',
  EVENTS_TYPOLOGIES_COUNT: 'events-typologies-count',
  CRITICALITY_INCIDENTS: 'criticality-incidents',
};

export const WIDGET_IMAGES = {
  [WIDGET_TYPES_SLUGS.TOP_DETECTED_USERS]: TopDetectedUsersImage,
  [WIDGET_TYPES_SLUGS.TOP_DETECTED_HOSTS]: TopDetectedHostsImage,
  [WIDGET_TYPES_SLUGS.INCIDENT_TYPOLOGIES]: IncidentTypologies,
  [WIDGET_TYPES_SLUGS.INCIDENT_TIMELINE]: IncidentTimeline,
  [WIDGET_TYPES_SLUGS.REPORTED_PRODUCTS]: ReportedProducts,
  [WIDGET_TYPES_SLUGS.MALWARE_TYPOLOGIES]: MalwareTypologies,
  [WIDGET_TYPES_SLUGS.SUMMARY]: Summary,
  [WIDGET_TYPES_SLUGS.QUICK_LIST]: QuickList,
  [WIDGET_TYPES_SLUGS.URL_MALICIOUS]: MaliciousUrlImage,
  [WIDGET_TYPES_SLUGS.FILENAMES_MALICIOUS]: MaliciousFilenamesImage,
  [WIDGET_TYPES_SLUGS.FILEHASHS_MALICIOUS]: MaliciousFilehashsImage,
  [WIDGET_TYPES_SLUGS.MALICIOUS_IPS_SOURCES]: MaliciousIpsSources,
  [WIDGET_TYPES_SLUGS.MALICIOUS_IPS_DESTINATIONS]: MaliciousIpsDestinations,
  [WIDGET_TYPES_SLUGS.SIGNATURE_LIST]: SignatureListImage,
  [WIDGET_TYPES_SLUGS.TOTAL_EVENTS]: TotalEventsImage,
  [WIDGET_TYPES_SLUGS.TOTAL_EVENT_TIMELINE]: TotalEventsTimelineImage,
  [WIDGET_TYPES_SLUGS.TOTAL_EVENTS_TYPOLOGIES]: TotalEventsTypologiesImage,
  [WIDGET_TYPES_SLUGS.TOTAL_EVENTS_TYPOLOGIES_TIMELINE]:
    TotalEventTypologiesTimelineImage,
  [WIDGET_TYPES_SLUGS.NOTIFIED_CASES]: NotifiedCasesImage,
  [WIDGET_TYPES_SLUGS.CASES_BY_CRITICITY]: CasesByCriticityImage,
  [WIDGET_TYPES_SLUGS.CASES_BY_CRITICITY_TIMELINE]:
    CasesByCriticityTimelineImage,
  [WIDGET_TYPES_SLUGS.EVENTS_TYPOLOGIES_COUNT]: EventsTypologiesCount,
  [WIDGET_TYPES_SLUGS.CRITICALITY_INCIDENTS]: CriticalityIncidentsImage,
};
