import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const initialState = {
  dateFrom: moment().subtract(1, 'day').startOf('day').valueOf(),
  dateTo: moment().endOf('day').valueOf(),
};

//startOf('day')
//moment().add('days', 1).startOf('day').valueOf(),
const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    updateDate: (state, { payload }) => {
      const { dateFrom, dateTo } = payload;
      state.dateFrom = dateFrom;
      state.dateTo = dateTo;
    },
  },
});

export const actions = filterSlice.actions;

export default filterSlice.reducer;
