import { ResponsiveFunnel } from '@nivo/funnel';

import Box from 'components/Box';
import Text from 'components/Text';

const Funnel = ({ data }) =>
  data ? (
    <ResponsiveFunnel
      data={data}
      theme={{
        fontSize: 24,
      }}
      margin={{ top: 40, right: 20, bottom: 40, left: 20 }}
      direction="horizontal"
      shapeBlending={0.5}
      colors={['#abe1f5', '#68bede', '#1e93be']}
      colorBy="index"
      borderWidth={40}
      borderColor={{ from: 'color' }}
      borderOpacity={0.6}
      labelColor={{
        from: 'color',
        modifiers: [['darker', 9]],
      }}
      tooltip={({ part }) => (
        <Box className="rounded-lg bg-black p-3">
          <Text color="white">{part.data.label}</Text>
        </Box>
      )}
      currentPartSizeExtension={10}
      currentBorderWidth={40}
      motionConfig="wobbly"
    />
  ) : null;

export default Funnel;
