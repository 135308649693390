import { useQuery } from 'react-query';

import { CACHE_IDS } from 'common/constants/cache';

import { getCaseDetailsRelatedEntities } from '../../api';

export const useRelatedEntitiesDataQuery = ({ id }) => {
  const query = useQuery([CACHE_IDS.RELATED_ENTITIES, { id }], () =>
    getCaseDetailsRelatedEntities({ id })
  );

  return query;
};
