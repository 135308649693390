import { useQueryClient } from 'react-query';

import { useMutation } from 'core/api/react-query';

import { updateCurrentTenant } from '../api';

export const useUpdateCurrentTenantMutation = ({ onSuccess, onError }) => {
  const client = useQueryClient();

  const mutation = useMutation(updateCurrentTenant, {
    onSuccess: () => {
      client.resetQueries();
      if (onSuccess) onSuccess();
    },
    onError,
  });

  return mutation;
};

export default useUpdateCurrentTenantMutation;
