import { Link } from 'react-router-dom';

import Box from 'components/Box';
import Icon from 'components/Icon';
import Text from 'components/Text';
import { cls } from 'core/styles/cls';

import { useSidebar } from '../../../hooks/useSidebar';

const LinkComponent = ({ to, children }) => {
  if (to) {
    return <Link to={to}>{children}</Link>;
  }
  return <>{children}</>;
};

export const Item = ({ icon, children, isActive, className, to, ...props }) => {
  const { isSidebarCollapsed } = useSidebar();
  return (
    <LinkComponent to={to}>
      <Box
        className={cls(
          'h-[56px] cursor-pointer justify-center gap-3',
          className
        )}
        ycenter
        {...props}
      >
        <Icon color="white" isBoxed isActive={isActive} name={icon} />
        {!isSidebarCollapsed && (
          <Text className="grow" color="white" bodyBold>
            {children}
          </Text>
        )}
      </Box>
    </LinkComponent>
  );
};
