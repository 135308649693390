import moment from 'moment';
import 'locales/it.json';

export const getDaysFromNow = (date) => {
  moment.updateLocale('es', {
    relativeTime: {
      future: (diff) => (diff === 'Today' ? diff : `in ${diff}`),
      past: (diff) => (diff === 'Today' ? diff : `${diff} ago`),
      s: 'Today',
      ss: 'Today',
    },
  });
  const normalizedDate = new Date(date).setHours(0, 0, 0, 0);
  const today = new Date().setHours(0, 0, 0, 0);

  return moment(normalizedDate)
    .from(today)
    .replace(/\b./, (match) => match.toUpperCase());
};

export const sortDateArray = (array) => {
  return array?.sort((a, b) => b.creationTime - a.creationTime);
};

export const weekdayNames = Array.from({ length: 7 }, (_, i) =>
  moment().startOf('isoWeek').day('Monday').add(i, 'days').format('dddd')
);

export const formatHoursFromNumber = ({ hour }) => {
  return hour > 9 ? `${hour}:00` : `0${hour}:00`;
};

/* export const addCurrentTime = (date) => {
  const initialDate = moment(date);
  const time = moment();
  initialDate.set({
    hour: time.get('hour'),
    minute: time.get('minute'),
    second: time.get('second'),
  });
  return initialDate.valueOf();
};
 */
