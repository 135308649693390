import Box from 'components/Box';
import Card from 'components/Card';
import Text from 'components/Text';
import { useTranslation } from 'core/translations/useTranslation';
import Layout from 'features/layout';
import TableView from 'features/soc-status/components/TableView';
import { useSocEngineQuery } from 'features/soc-status/services/useSocEngineQuery';

const SocStatus = () => {
  const t = useTranslation();
  const { data, isLoading } = useSocEngineQuery();

  return (
    <Layout icon="status" title={t('sidebar.soc_status')}>
      <Box column full className="gap-4">
        <Card className="w-full gap-4 p-5 !h-fit">
          <Text subheading>{t('soc_status.list_of_devices.title')}</Text>
          {!isLoading && <TableView />}
        </Card>
      </Box>
    </Layout>
  );
};

export default SocStatus;
