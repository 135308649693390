import { useState } from 'react';

import PaginatedTable from 'common/components/tables/PaginatedTable';
import { useToggler } from 'common/hooks';

import IntegrationDetailsModal from '../IntegrationDetailsModal';
import { useColumns } from './table';

const AutomatedResponse = ({
  vendors,
  currentPage,
  isLoading,
  isError,
  refetch,
  isPreviousData,
  onPageChange,
}) => {
  const { isShown: isShownModal, toggle: toggleModal } = useToggler();
  const [vendor, setVendor] = useState('');

  const openVendorModal = (vendorName) => {
    setVendor(vendorName);
    toggleModal();
  };

  return (
    <>
      {!!isShownModal && (
        <IntegrationDetailsModal vendor={vendor} toggle={toggleModal} />
      )}

      <PaginatedTable
        data={vendors?.results}
        columns={useColumns({ openVendorModal })}
        isFetching={isLoading}
        refetch={refetch}
        isError={isError}
        isShowingPreviousData={isPreviousData}
        currentPage={currentPage}
        pageSize={vendors?.page_size}
        totalPages={vendors?.total_pages}
        totalResults={vendors?.total_count}
        onPageChange={onPageChange}
      />
    </>
  );
};

export default AutomatedResponse;
