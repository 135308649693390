import useInputUrlFilter from 'common/hooks/filters/useInputUrlFilter';
import useSelectUrlFilter from 'common/hooks/filters/useSelectUrlFilter';

export const useFilters = () => {
  const {
    value: entitiesInput,
    filter: entities,
    syncFilter: syncEntitiesFilter,
    onChange: onEntitiesInputChange,
    isSync: isEntitiesFilterSync,
  } = useInputUrlFilter('entities');
  const {
    value: caseTypesSelect,
    filter: caseTypes,
    syncFilter: syncCaseTypesFilter,
    onChange: onCaseTypesChange,
    isSync: isCaseTypesFilterSync,
  } = useSelectUrlFilter('caseTypes');

  const {
    value: entityTypesSelect,
    filter: entityTypes,
    syncFilter: syncEntityTypesFilter,
    onChange: onEntityTypesChange,
    isSync: isEntityTypesFilterSync,
  } = useSelectUrlFilter('entityTypes');

  const {
    value: suspiciousSelect,
    filter: suspicious,
    syncFilter: syncSuspiciousFilter,
    onChange: onSuspiciousChange,
    isSync: isSuspiciousFilterSync,
  } = useSelectUrlFilter('suspicious');

  const onFiltersSubmit = (refetch) => {
    if (
      isCaseTypesFilterSync &&
      isEntityTypesFilterSync &&
      isEntitiesFilterSync &&
      isSuspiciousFilterSync
    ) {
      refetch();
    } else {
      syncCaseTypesFilter();
      syncEntityTypesFilter();
      syncEntitiesFilter();
      syncSuspiciousFilter();
    }
  };

  return {
    filters: {
      entityTypes,
      caseTypes,
      entities,
      suspicious,
    },
    inputProps: {
      entities: {
        value: entitiesInput,
        defaultValue: entitiesInput,
        onChange: onEntitiesInputChange,
      },
      caseTypes: {
        value: caseTypesSelect,
        defaultValue: caseTypesSelect,
        onChange: onCaseTypesChange,
      },
      entityTypes: {
        value: entityTypesSelect,
        defaultValue: entityTypesSelect,
        onChange: onEntityTypesChange,
      },
      suspicious: {
        value: suspiciousSelect,
        defaultValue: suspiciousSelect,
        onChange: onSuspiciousChange,
      },
    },
    onFiltersSubmit,
  };
};
