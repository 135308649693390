import React from 'react';

import { getNumberAbbreviation } from 'common/utils/getNumberAbbreviation';
import { useTranslation } from 'core/translations/useTranslation';

export const useGetChartOptions = () => {
  const t = useTranslation();
  return React.useCallback(
    ({ data, allEventsData }) => ({
      credits: {
        enabled: false,
      },

      chart: {
        type: 'pie',
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
        events: {
          render: (event) => {
            redrawTitle(event.target, allEventsData);
            redrawSubtitle(
              event.target,
              t('soc_status.event_typologies.subtitle')
            );
          },
        },
      },

      plotOptions: {
        pie: {
          innerSize: '60%',
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
          cursor: 'pointer',
          allowPointSelect: true,
        },
      },

      tooltip: {
        enabled: true,
        formatter: function () {
          return (
            this.point.name +
            ': </span><b>' +
            getNumberAbbreviation(this.point.y)
          );
        },
      },

      legend: {
        enabled: true,
        labelFormatter: function () {
          return (
            this.name + ': </span><b>' + getNumberAbbreviation(this.y) + '<br/>'
          );
        },
        verticalAlign: 'top',
      },

      title: allEventsData,

      series: [
        {
          type: 'pie',
          name: 'Events',
          colorByPoint: true,
          innerSize: '60%',
          data,
        },
      ],

      exporting: {
        enabled: false,
      },

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
          },
          {
            condition: {
              minWidth: 500,
              maxWidth: 700,
            },
          },
        ],
      },

      colors: [
        '#D45A66',
        '#fbd360',
        '#4CDFE8',
        '#a3da8d',
        '#ffa974',
        '#dddddd',
      ],
    }),
    [t]
  );
};

const redrawTitle = (chart, text) => {
  if (chart.title) {
    chart.title.destroy();
  }
  let r = chart.renderer,
    x = chart.series[0].center[0] + chart.plotLeft,
    y = chart.series[0].center[1] + chart.plotTop;
  chart.title = r
    .text(text, 0, 0)
    .css({
      fontFamily: "'roboto', sans-serif",
      fontSize: '20px',
      fontWeight: 500,
    })
    .hide()
    .add();

  let bbox = chart.title.getBBox();
  chart.title
    .attr({
      x: x - bbox.width / 2,
      y: y,
    })
    .show();
};

const redrawSubtitle = (chart, text) => {
  if (chart.subtitle) {
    chart.subtitle.destroy();
  }
  let r = chart.renderer,
    x = chart.series[0].center[0] + chart.plotLeft,
    y = chart.series[0].center[1] + chart.plotTop;
  chart.subtitle = r
    .text(text, 0, 0)
    .css({
      color: '#1f3740',
      fontFamily: "'roboto', sans-serif",
      fontSize: '14px',
    })
    .hide()
    .add();

  var bbox = chart.subtitle.getBBox();
  chart.subtitle
    .attr({
      x: x - bbox.width / 2,
      y: y + 15,
    })
    .show();
};
