import React from 'react';

import EmptyStateWidgets from 'common/components/widgets/EmptyStateWidgets';
import Chart from 'components/Chart';
import Skeleton from 'components/Skeleton';
import Widget from 'components/Widget';
import '../../styles/styles.css';
import { useTranslation } from 'core/translations/useTranslation';

import { useWidgetChart } from '../../hooks';
import { useTotalEventTimelineQuery } from '../../services';
import { getChartOptions } from './chart';

const WidgetTotalEventTimeline = ({ id, title, removeWidget, isEditing }) => {
  const { data: widgetData, isLoading } = useTotalEventTimelineQuery();
  const { props: chartProps } = useWidgetChart(id);
  const t = useTranslation();

  const options = React.useMemo(() => {
    widgetData?.series?.map((serie) => {
      if (serie.name === 'ALL') serie.name = t('common.events');
      return serie;
    });
    return getChartOptions({
      rangeDays: widgetData?.range_days,
      minDate: widgetData?.min_date,
      series: widgetData?.series,
    });
  }, [widgetData, t]);

  return (
    <Widget
      isEditing={isEditing}
      iconLeft="settings"
      iconRight="dots"
      subtitle={<span className="title-widget">{title}</span>}
      onDelete={() => removeWidget(id)}
    >
      {isLoading ? (
        <Skeleton radius={'10px'} />
      ) : widgetData?.series?.length ? (
        <Chart options={options} {...chartProps} />
      ) : (
        <EmptyStateWidgets />
      )}
    </Widget>
  );
};

export default WidgetTotalEventTimeline;
