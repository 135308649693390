import useInputUrlFilter from 'common/hooks/filters/useInputUrlFilter';
import useSelectUrlFilter from 'common/hooks/filters/useSelectUrlFilter';

export const useFilters = () => {
  const {
    value: integrationsInput,
    filter: integrations,
    syncFilter: syncIntegrationsFilter,
    onChange: onIntegrationsInputChange,
    isSync: isHostsFilterSync,
  } = useInputUrlFilter('integrations');
  const {
    value: tagsSelect,
    filter: tags,
    syncFilter: syncTagsFilter,
    onChange: onTagsChange,
    isSync: isTagsFilterSync,
  } = useSelectUrlFilter('tags');

  const onFiltersSubmit = (refetch) => {
    if (isTagsFilterSync && isHostsFilterSync) {
      refetch();
    } else {
      syncTagsFilter();
      syncIntegrationsFilter();
    }
  };

  return {
    filters: {
      tags,
      integrations,
    },
    inputProps: {
      integrations: {
        value: integrationsInput,
        defaultValue: integrationsInput,
        onChange: onIntegrationsInputChange,
      },
      tags: {
        value: tagsSelect,
        defaultValue: tagsSelect,
        onChange: onTagsChange,
      },
    },
    onFiltersSubmit,
  };
};
