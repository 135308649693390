import { CACHE_IDS } from 'common/constants/cache';
import { useQuery } from 'core/api/react-query';

import { getWidgetTypesTags } from '../api';

export const useGetWidgetTypeTagsQuery = () => {
  const query = useQuery([CACHE_IDS.WIDGET_TYPE_TAGS], getWidgetTypesTags);

  return query;
};

export default useGetWidgetTypeTagsQuery;
