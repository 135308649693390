import { useSelector } from 'react-redux';

import { CACHE_IDS } from 'common/constants/cache';
import { WIDGET_TYPES_SLUGS } from 'common/constants/widgets';
import { useQuery } from 'core/api/react-query';
import { selectDateRange } from 'redux/filter/selectors';

import { getUrlMalicious } from '../api';

export const useUrlMaliciousQuery = ({ page }) => {
  const { dateFrom, dateTo } = useSelector(selectDateRange);

  const query = useQuery(
    [
      CACHE_IDS.WIDGET_DATA,
      { slug: WIDGET_TYPES_SLUGS.URL_MALICIOUS, dateTo, dateFrom, page },
    ],
    () =>
      getUrlMalicious({
        dateFrom,
        dateTo,
        page,
        sort_by: 'suspicious',
        order_by: 'true',
      })
  );
  return query;
};

export default useUrlMaliciousQuery;
