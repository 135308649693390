import moment from 'moment';
import { Link } from 'react-router-dom';

import Tag from 'components/Tag';
import Text from 'components/Text';
import { useTranslation } from 'core/translations/useTranslation';

export const useColumns = () => {
  const t = useTranslation();
  return [
    {
      title: t('incidents.details.tabs.similar_cases.table.header.id'),
      dataIndex: 'id',
      key: 'id',
      render: (id) => (
        <Link to={`/incidents/${id}`}>
          <Text cursor="pointer" color="primary" body>
            {id}
          </Text>
        </Link>
      ),
    },
    {
      title: t('incidents.details.tabs.similar_cases.table.header.typology'),
      dataIndex: 'name',
      key: 'name',
      render: (name) => <Text body>{name}</Text>,
    },
    {
      title: t(
        'incidents.details.tabs.similar_cases.table.header.similar_percentage'
      ),
      dataIndex: 'similar_percentage',
      key: 'similar_percentage',
      render: (data) => <Text body>{data}%</Text>,
    },
    {
      title: t('incidents.details.tabs.similar_cases.table.header.criticity'),
      dataIndex: 'criticality',
      key: 'criticality',
      render: (incident) => {
        if (incident === -1)
          return <Tag status="success">{t('common.informative')}</Tag>;
        if (incident === 60)
          return <Tag status="warning">{t('common.medium')}</Tag>;
        if (incident === 80) return <Tag status="high">{t('common.high')}</Tag>;
        if (incident === 100)
          return <Tag status="critical">{t('common.critical')}</Tag>;

        return <Tag status="success">Low</Tag>;
      },
    },
    {
      title: t('incidents.details.tabs.similar_cases.table.header.time'),
      dataIndex: 'creation_time',
      key: 'creationTime',
      render: (date) => (
        <Text body>{moment(date).format('DD-MM-YYYY HH:mm:ss')}</Text>
      ),
    },
  ];
};
