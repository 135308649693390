import { useState } from 'react';

import { useForm } from 'react-hook-form';

import { useToast } from 'common/hooks/toasts';
import Box from 'components/Box';
import Button from 'components/Button';
import Chip from 'components/Chip';
import Input from 'components/Input';
import Modal from 'components/Modal';
import Text from 'components/Text';
import Textarea from 'components/Textarea';
import { useTranslation } from 'core/translations/useTranslation';
import { useCreateEmailNotificationGroupMutation } from 'features/profile/services';

const CreateGroupModal = ({ closeModal }) => {
  const t = useTranslation();
  const { showToast } = useToast();
  const [emails, setEmails] = useState([]);
  const [textErrorSameEmail, setTextErrorSameEmail] = useState(false);

  const {
    register,
    handleSubmit,
    getFieldState,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'onChange' });

  const email = watch('email');
  const errorFields = Object.keys(errors);

  const isValid =
    !(errorFields.includes('name') || errorFields.includes('description')) &&
    emails?.length;

  const { mutate: createGroup } = useCreateEmailNotificationGroupMutation({
    onSuccess: () => {
      showToast(
        t(
          'settings.notificacion.group_notification.toast.create_group.success'
        ),
        'success'
      );
    },
    onError: () => {
      showToast(t('toast.error.error_500'), 'error');
    },
  });

  const submit = (values) => {
    const { name, description } = values;

    createGroup({ emails, name, description });
    closeModal();
  };

  const onEmailEnter = (e) => {
    if (getFieldState('email').error) return;
    if (!email?.length) return;
    if (emails.includes(email)) return setTextErrorSameEmail(true);
    if (e.key === 'Enter' || e.code === 'Space') {
      e.preventDefault();
      setEmails((array) => [...array, email]);
      e.target.value = '';
      setTextErrorSameEmail(false);
    }
  };

  const onEmailClick = () => {
    if (getFieldState('email').error) return;
    if (!email?.length) return;
    if (emails.includes(email)) return setTextErrorSameEmail(true);
    setEmails((array) => [...array, email]);
    setValue('email', '');
    setTextErrorSameEmail(false);
  };

  const removeChip = (arr, value) => {
    setEmails([...arr].filter((ele) => ele !== value));
  };

  return (
    <Modal
      className="gap-7"
      withCancel={false}
      title={t('common.create_group')}
      toggle={closeModal}
    >
      <form
        autoComplete={false}
        className="flex flex-col w-full gap-4"
        onSubmit={handleSubmit(submit)}
      >
        <Box column className="gap-1">
          <Box column className="gap-3">
            <Text bodyBold>{t('common.name')}</Text>
            <Input
              maxLength={150}
              label={t('common.name')}
              {...register('name', {
                required: t('validations.required'),
              })}
              error={errors.name?.message}
            />
          </Box>
          <Box column className="gap-3">
            <Text bodyBold>{t('common.description')}</Text>
            <Textarea
              label={t('common.description')}
              {...register('description', {
                required: t('validations.required'),
              })}
              error={errors.description?.message}
            />
          </Box>
          <Box column className="gap-3">
            <Box className="gap-1 items-end">
              <Text bodyBold>{t('common.emails_groups')}</Text>
              <Text badgeBold color="lightText">
                {emails?.length} emails
              </Text>
            </Box>
            <Input
              onAdd={onEmailClick}
              textButton={t('common.add')}
              buttonAdd
              onKeyDown={onEmailEnter}
              label={t('common.email')}
              {...register('email', {
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: t('validations.email'),
                },
              })}
              error={
                (textErrorSameEmail && t('input.error.same_emails')) ||
                errors.email?.message
              }
            />

            <Box className="flex-grow flex-wrap w-fit gap-2 max-h-[70px] overflow-hidden overflow-y-auto">
              {emails?.map((email) => (
                <Chip
                  key={email}
                  disabled
                  onDelete={() => removeChip(emails, email)}
                >
                  {email}
                </Chip>
              ))}
            </Box>
          </Box>
        </Box>
        <Button disabled={!isValid} type="submit" className="w-[96px] self-end">
          {t('common.save')}
        </Button>
      </form>
    </Modal>
  );
};

export default CreateGroupModal;
