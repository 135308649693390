import React from 'react';

import { useQueryClient } from 'react-query';

import { CACHE_IDS } from 'common/constants/cache';
import { useQuery } from 'core/api/react-query';

import { getIntegrations } from '../api';

export const useIntegrationsDataQuery = ({ page, integrations, tags }) => {
  const queryClient = useQueryClient();
  const query = useQuery(
    [CACHE_IDS.INTEGRATIONS_ALL, { page, integrations, tags }],
    () =>
      getIntegrations({
        page,
        integrations,
        tags,
      }),
    {
      keepPreviousData: true,
    }
  );

  React.useEffect(() => {
    if (query?.data?.num_pages) {
      queryClient.invalidateQueries([CACHE_IDS.INTEGRATIONS_ALL]);
    }
  }, [query?.data?.num_pages, queryClient]);

  return query;
};
