import { useToast } from 'common/hooks/toasts';
import Box from 'components/Box';
import SelectorCard from 'components/SelectorCard';
import { changeLanguage } from 'core/translations/changeLanguage';
import i18n from 'core/translations/init';
import { useTranslation } from 'core/translations/useTranslation';
import { getLanguageCode } from 'core/translations/utils';
import {
  useLanguageQuery,
  useUpdateCurrentLanguageMutation,
} from 'features/profile/services';

import ContentLayout from '../../ContentLayout';

const LanguageCards = ({ handleClickCard, languages }) => {
  return (
    <Box column full>
      {languages?.map((lng) => (
        <SelectorCard
          className="border-b border-gray last:border-b-[0px]"
          key={lng.name}
          isActive={i18n.language === getLanguageCode(lng.name)}
          title={lng.name}
          onClick={() => {
            handleClickCard(lng.name);
          }}
        ></SelectorCard>
      ))}
    </Box>
  );
};

const SettingsLanguage = () => {
  const t = useTranslation();
  const { showToast } = useToast();
  const { data: languages } = useLanguageQuery();
  const { mutate: updateLanguage } = useUpdateCurrentLanguageMutation({
    onSuccess: (lng) => {
      changeLanguage(getLanguageCode(lng));
      setTimeout(() => {
        showToast(t('toast.language.success'), 'success');
      }, 100);
    },
    onError: (error) => {
      showToast(error.detail, 'error');
    },
  });

  const handleClickCard = (lng) => {
    updateLanguage(lng);
  };

  return (
    <ContentLayout title={t('settings.tabs.language.title')}>
      <Box
        column
        className="2xl:w-[400px] border border-gray rounded-[10px] overflow-hidden "
      >
        <LanguageCards {...{ handleClickCard, languages }} />
      </Box>
    </ContentLayout>
  );
};

export default SettingsLanguage;
