import { CACHE_IDS } from 'common/constants/cache';
import { useQuery } from 'core/api/react-query';

import { getWidgets } from '../api';

export const useWidgetsQuery = ({ dashboardId }) => {
  const query = useQuery(
    [CACHE_IDS.SECURITY_POSTURE, { dashboardId }],
    () =>
      getWidgets({
        dashboardId,
      }),
    {
      select: (data) => {
        return data.map((widget) => ({
          h: widget.height,
          w: widget.width,
          i: widget.id,
          y: widget.axis_y,
          x: widget.axis_x,
          typeId: widget.widget_type?.id,
          minH: widget.widget_type?.min_height,
          minW: widget.widget_type?.min_width,
          slug: widget.widget_type?.slug,
          static: widget.widget_type?.extra_options?.is_static,
          title: widget.title,
          description: widget.description,
        }));
      },
    }
  );
  return query;
};

export default useWidgetsQuery;
