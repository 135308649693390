import Box from 'components/Box';
import Button from 'components/Button';
import { useTranslation } from 'core/translations/useTranslation';

const DayActionButtons = ({
  hasHours,
  isButtonDisabled,
  submit,
  addPicker,
}) => {
  const t = useTranslation();
  return hasHours ? (
    <Box full className="gap-3 mt-3 items-center justify-end">
      <Button
        small
        disabled={isButtonDisabled}
        className="w-[100px]"
        onClick={submit}
      >
        {t('common.save')}
      </Button>

      <Button
        small
        onClick={addPicker}
        icon="plus"
        size="small"
        className="w-fit"
        secondary
      >
        {t('common.add_hour')}
      </Button>
    </Box>
  ) : (
    <Box className="justify-center w-full mt-[-16px]">
      <Button small onClick={addPicker} icon="plus" className="w-fit" secondary>
        {t('common.add_hour')}
      </Button>
    </Box>
  );
};

export default DayActionButtons;
