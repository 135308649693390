import React from 'react';

import { Sortable } from 'devextreme-react/sortable';
import TabPanel from 'devextreme-react/tab-panel';
import 'devextreme/data/odata/store';

import Box from 'components/Box';
import Icon from 'components/Icon';

const TabDrag = ({
  tabs,
  selectedTab,
  onTabDragStart,
  onTabDrop,
  renderTitle,
  onSelectionChanged,
  onAdd,
}) => {
  const toolbarRef = React.useRef();
  const selectedTabIndex = tabs?.findIndex(
    (tab) => tab?.id === selectedTab?.id
  );
  const toolbarWidth = toolbarRef.current?.clientWidth || 240;

  return (
    <Box full between ycenter className="pt-3 relative">
      <Sortable
        filter=".dx-tab"
        className={`overflow-visible relative pr-[32px]`}
        style={{
          maxWidth: `calc(100% - ${toolbarWidth + 32}px)`,
        }}
        data={tabs}
        itemOrientation="horizontal"
        dragDirection="horizontal"
        onDragStart={onTabDragStart}
        onReorder={onTabDrop}
      >
        <TabPanel
          itemTitleRender={renderTitle}
          deferRendering={true}
          showNavButtons={true}
          selectedIndex={selectedTabIndex || 0}
          defaultSelectedIndex={0}
          id="tabpanel-container"
          items={tabs}
          onSelectionChanged={onSelectionChanged}
        />
        {!!tabs?.length && (
          <Icon
            cursor="pointer"
            name="plus"
            onClick={onAdd}
            color="lightText"
            className="text-lg absolute right-0 top-[50%] translate-y-[-50%]"
          />
        )}
      </Sortable>
      <Box id="toolbar" ref={toolbarRef}></Box>
    </Box>
  );
};

export default TabDrag;
