import { useLayoutEffect, useRef, useState } from 'react';

import useWindowDimensions from 'common/hooks/useWindowDimensions';
import Box from 'components/Box';
import Icon from 'components/Icon';
import ProgressBar from 'components/ProgressBar';
import Text from 'components/Text';
import { cls } from 'core/styles/cls';
import { useTranslation } from 'core/translations/useTranslation';

const TableView = ({ data }) => {
  const [changeTable, setChangeTable] = useState();
  const { width } = useWindowDimensions();
  const ref = useRef(null);
  const widgetBox = ref.current;
  const t = useTranslation();
  const tranformData = data?.map((item) => {
    return item.total;
  });

  const totalData = tranformData?.reduce((a, b) => a + b, 0);

  const getPercentage = (value, total) => {
    return (value * 100) / total;
  };

  const hasScrollBar = widgetBox?.clientHeight < widgetBox?.scrollHeight;
  const widgetWidth = widgetBox?.clientWidth;
  const isRow = widgetWidth > 400;

  useLayoutEffect(() => {
    setChangeTable(isRow);
  }, [widgetWidth, isRow, width]);

  return (
    <Box full between column className="gap-3 overflow-hidden pr-3 pl-2 ">
      <Box between>
        <Box full className="w-[160px]">
          <Text body color="lightText">
            {t('secpos.widgets.events-typologies-count.table.log_type')}
          </Text>
        </Box>
        <Box className={cls('w-fit', { 'pr-4': hasScrollBar })}>
          <Text body color="lightText">
            {t('secpos.widgets.events-typologies-count.table.ingested_events')}
          </Text>
        </Box>
      </Box>
      <Box
        ref={ref}
        column
        className={cls('gap-3 overflow-hidden overflow-y-auto', {
          'pr-3': hasScrollBar,
        })}
      >
        {data?.map((item) => (
          <Box between ycenter>
            <Box ycenter className="flex-grow gap-2">
              <Icon
                isBoxed
                name="events"
                bg="lightBlue"
                color="primary"
                className="rounded-[100%] w-[32px] h-[32px]"
              />
              <Text body className="break-normal sm:break-all">
                {item.type}
              </Text>
            </Box>

            <Box
              ycenter
              between
              className={cls('ml-4 gap-1', {
                'flex-col items-end w-[100px] lg:w-[135px]': !changeTable,
              })}
            >
              <Box
                ycenter
                className={cls('flex-row gap-1', {
                  ' items-end': !changeTable,
                })}
              >
                <Text body>
                  {item.total
                    .toString()
                    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.')}
                </Text>
                <Box className="min-w-[40px]">
                  <Text badgeBold color="lightText" className="ml-auto">
                    {(
                      (getPercentage(item.total, totalData) * 100) /
                      100
                    ).toFixed(2)}
                    %
                  </Text>
                </Box>
              </Box>

              <ProgressBar
                className={cls('w-[100px]', {
                  'w-full': !changeTable,
                })}
                completed={Math.round(getPercentage(item.total, totalData))}
                bg="primary"
              />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default TableView;
