import EmptyStateImage from 'assets/profile/emptyState.png';
import Box from 'components/Box';
import Button from 'components/Button';
import Text from 'components/Text';
import { useTranslation } from 'core/translations/useTranslation';

const EmptyState = ({ onClick }) => {
  const t = useTranslation();

  return (
    <Box column xcenter ycenter className="h-[540px] gap-5">
      <Box className="h-[180px]">
        <img width={280} src={EmptyStateImage} alt="Empty" />
      </Box>
      <Box column xcenter className="gap-2">
        <Text subheading>{t('common.create_group')}</Text>
        <Text body color="lightText">
          {t('common.description_group')}
        </Text>
      </Box>
      <Button onClick={onClick} icon="plus">
        {t('common.create_new_group')}
      </Button>
    </Box>
  );
};

export default EmptyState;
