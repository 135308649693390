import Box from 'components/Box';
import Card from 'components/Card';
import Icon from 'components/Icon';
import Skeleton from 'components/Skeleton';
import Text from 'components/Text';
import { useTranslation } from 'core/translations/useTranslation';

const IsSuspiciousBadge = ({ isSuspicious }) => {
  const t = useTranslation();
  return !isSuspicious ? (
    <Text subtitle>{t('common.not_suspicious')}</Text>
  ) : (
    <Text>{t('common.suspicious')}</Text>
  );
};

const LineSkeleton = () => <Skeleton width={150} height={16} />;

const StatCard = ({ title, value, renderValue, isLoading, icon, bg }) => (
  <Card>
    <Box between ycenter full>
      <Box column className="gap-2">
        <Text bodyBold color="lightText">
          {title}
        </Text>
        <Box ycenter className="flex-grow">
          {isLoading ? (
            <LineSkeleton />
          ) : renderValue ? (
            renderValue()
          ) : (
            <Text subheading>{value}</Text>
          )}
        </Box>
      </Box>
      <Box className="rounded-[100%] w-fit h-fit p-2 " bg={bg}>
        <Icon size="medium" name={icon} color="white" className="mb-auto" />
      </Box>
    </Box>
  </Card>
);

const Overview = ({ data, isLoading }) => {
  const t = useTranslation();
  const entity = data?.entity || {};
  const { total_cases, total_malicious_cases, type, isSuspicious } = entity;

  return (
    <Box className="gap-4 grid grid-cols-2 2xl:grid-cols-4">
      <StatCard
        bg="danger"
        icon="bug"
        title={t('details.overview.involved_incidents')}
        value={total_cases}
        isLoading={isLoading}
      />
      <StatCard
        bg="error"
        icon="threats"
        title={t('details.overview.malicious_incidents')}
        value={total_malicious_cases}
        isLoading={isLoading}
      />
      <StatCard
        bg="primary"
        icon="info"
        title={t('details.overview.entity_type')}
        value={type}
        isLoading={isLoading}
      />
      <StatCard
        bg="lightText"
        icon="fire"
        title={t('details.overview.exposure')}
        renderValue={() => <IsSuspiciousBadge isSuspicious={isSuspicious} />}
        isLoading={isLoading}
      />
    </Box>
  );
};

export default Overview;
