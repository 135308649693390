import theme from 'core/styles/theme';

export const getChartOptions = ({ categories, series }) => ({
  chart: {
    parallelCoordinates: true,
    type: 'line',
    polar: true,
  },
  title: {
    text: '',
  },
  credits: {
    enabled: false,
  },
  pane: {
    size: '80%',
  },
  legend: {
    align: 'center',
    verticalAlign: 'top',
    layout: 'horizontal',
    itemPaddingBottom: 10,
    itemMarginBottom: 10,
    itemStyle: {
      fontSize: theme.fontSize.base,
      color: theme.colors.text,
      fontFamily: 'roboto',
      fontWeight: theme.fontWeight.regular,
    },
  },
  xAxis: {
    categories: categories,
    tickmarkPlacement: 'on',
    lineWidth: 0,
  },
  yAxis: {
    gridLineInterpolation: 'polygon',
    lineWidth: 0,
    min: 0,
  },

  series: series,
});
