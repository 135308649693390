import React from 'react';

import EmptyStateWidgets from 'common/components/widgets/EmptyStateWidgets';
import WidgetProgressBars from 'components/WidgetProgressBars';
import { useTranslation } from 'core/translations/useTranslation';
import '../../styles/styles.css';

import { useTopDetectedHostsQuery } from '../../services';

const WidgetContent = ({ widgetData, isLoading }) => {
  const t = useTranslation();

  if (isLoading || !widgetData) {
    return <WidgetProgressBars.Skeleton />;
  }
  if (widgetData.totalCases === 0) {
    return <EmptyStateWidgets className="mt-[3px] mb-[50px]" />;
  }
  return widgetData.list?.map((item) => {
    return (
      <WidgetProgressBars.Item
        name={item.host}
        completion={item.completion}
        cases={item.cases}
        totalCases={widgetData.totalCases}
        textColor="lightText"
        barColor="primary"
        key={item.host}
        incidents={t('secpos.widgets.top_detected_hosts.counter')}
        linkTo={`/entities-details/${item.host}/ADDRESS`}
      />
    );
  });
};

const WidgetTopDetectedHosts = ({ id, title, isEditing, removeWidget }) => {
  const { data: widgetData, isLoading } = useTopDetectedHostsQuery();
  return (
    <WidgetProgressBars.Widget
      isEditing={isEditing}
      iconLeft="settings"
      iconRight="dots"
      subtitle={<span className="title-widget">{title}</span>}
      onDelete={() => removeWidget(id)}
    >
      <WidgetContent widgetData={widgetData} isLoading={isLoading} />
    </WidgetProgressBars.Widget>
  );
};

export default WidgetTopDetectedHosts;
