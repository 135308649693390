import EmptyComponent from 'common/components/tables/EmptyComponent';
import Box from 'components/Box';
import Table from 'components/Table';

import { useEventsStorageDataQuery } from '../../services/useEventsStorageDataQuery';
import { useColumns } from './table';

const EventsStorageTable = () => {
  const {
    data,
    isError: isTableError,
    isFetching: isTableFetching,
    refetch,
    isPreviousData,
  } = useEventsStorageDataQuery();

  return (
    <Box
      column
      className="flex gap-5 overflow-x-auto sm:overflow-x-hidden md:overflow-x-hidden"
    >
      <Table
        data={data}
        columns={useColumns()}
        EmptyComponent={EmptyComponent({ isTableError, refetch })}
        isError={isTableError}
        isFetching={isTableFetching}
        loading={isTableFetching || isPreviousData}
        loadingRows={5}
        loadingHeight={64}
        withMinWidth
      />
    </Box>
  );
};

export default EventsStorageTable;
