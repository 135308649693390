export const getChartOptions = ({ data }) => ({
  credits: {
    enabled: false,
  },
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie',
  },
  title: '',

  accessibility: {
    point: {
      valueSuffix: '%',
    },
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
    },
  },
  legend: {
    labelFormatter: function () {
      return this.name + ': </span><b>' + this.y + '<br/>';
    },
    verticalAlign: 'top',
  },
  series: [
    {
      name: 'Brands',
      colorByPoint: true,
      data,
    },
  ],
});
