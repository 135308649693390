import { CACHE_IDS } from 'common/constants/cache';
import { useQuery } from 'core/api/react-query';

import { getCurrentUser } from '../api';

export const useCurrentUserQuery = () => {
  const query = useQuery([CACHE_IDS.CURRENT_USER], getCurrentUser);

  return query;
};

export default useCurrentUserQuery;
