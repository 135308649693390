import { convertBytesToMegabytes } from 'common/utils/convertBytesToMegabytes';
import Text from 'components/Text';
import { useTranslation } from 'core/translations/useTranslation';

export const useColumns = () => {
  const t = useTranslation();
  return [
    {
      title: t('metrics_events.table.name'),
      dataIndex: 'name',
      key: 'name',
      render: (name) => <Text body>{name}</Text>,
    },
    {
      title: t('metrics_events.table.max_events'),
      dataIndex: 'max_events',
      key: 'max_events',
      render: (max_events) => <Text body>{max_events}</Text>,
    },
    {
      title: t('metrics_events.table.min_events'),
      dataIndex: 'min_events',
      key: 'min_events',
      render: (min_events) => <Text body>{min_events}</Text>,
    },
    {
      title: t('metrics_events.table.avg_events'),
      dataIndex: 'avg_events',
      key: 'avg_events',
      render: (avg_events) => <Text body>{avg_events.toFixed(2)}</Text>,
    },
    {
      title: t('metrics_events.table.percentage_events'),
      dataIndex: 'percentage_events',
      key: 'percentage_events',
      render: (percentage_events) => (
        <Text body>{percentage_events.toFixed(2)}</Text>
      ),
    },
    {
      title: t('metrics_events.table.max_size'),
      dataIndex: 'max_size',
      key: 'max_size',
      render: (max_size) => (
        <Text body>{convertBytesToMegabytes(max_size)}</Text>
      ),
    },
    {
      title: t('metrics_events.table.min_size'),
      dataIndex: 'min_size',
      key: 'min_size',
      render: (min_size) => (
        <Text body>{convertBytesToMegabytes(min_size)}</Text>
      ),
    },
    {
      title: t('metrics_events.table.avg_size'),
      dataIndex: 'avg_size',
      key: 'avg_size',
      render: (avg_size) => (
        <Text body>{convertBytesToMegabytes(avg_size)}</Text>
      ),
    },
    {
      title: t('metrics_events.table.percentage_size'),
      dataIndex: 'percentage_size',
      key: 'percentage_size',
      render: (percentage_size) => (
        <Text body>{percentage_size.toFixed(2)}</Text>
      ),
    },
  ];
};
