import { useState } from 'react';

import Image from 'assets/forgot-password/forgot-password.png';
import BlackLogo from 'assets/forgot-password/logos_vsoc_black-blue.png';
import Box from 'components/Box';

import NewPasswordForm from './NewPasswordForm';
import ResetPasswordForm from './ResetPasswordForm';

const ForgotPassword = () => {
  const [isEmail, setIsEmail] = useState();

  return (
    <Box xcenter className="h-screen ">
      <Box full column className="p-5">
        <a href="/">
          <img className="h-6" src={BlackLogo} alt="logo" />
        </a>
        <Box xcenter full>
          <img
            src={Image}
            className="lg:min-w-[400px] w-[600px]"
            alt="Welcome"
          />
        </Box>
      </Box>
      {!isEmail ? (
        <ResetPasswordForm setIsEmail={setIsEmail} />
      ) : (
        <NewPasswordForm setIsEmail={setIsEmail} />
      )}
    </Box>
  );
};

export default ForgotPassword;
