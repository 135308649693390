import React from 'react';

import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { useToast } from 'common/hooks/toasts';
import Box from 'components/Box';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Input from 'components/Input';
import Text from 'components/Text';
import { useTranslation } from 'core/translations/useTranslation';

import { useUpdateResetPasswordMutation } from '../../../services';

const NewPassword = ({ setIsEmail }) => {
  const t = useTranslation();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' });

  const { mutate: resetPassword } = useUpdateResetPasswordMutation({
    onSuccess: () => {
      navigate('/password-reset');
    },
    onError: (error) => {
      showToast(error.detail, 'error');
    },
  });

  const submit = (values) => {
    const { token, password, retypePassword } = values;

    resetPassword({ token, password, retypePassword });
  };

  const deleteEmailInLocalStorage = () => {
    localStorage.clear();
    setIsEmail(false);
  };
  return (
    <Box column ycenter full className="bg-[#1a2035] h-full p-8">
      <form onSubmit={handleSubmit(submit)} className="w-[400px]">
        <Box column className="gap-3 pb-6">
          <Box
            onClick={deleteEmailInLocalStorage}
            className="cursor-pointer pb-4"
          >
            <Icon color="white" size="small" name="arrow-left" />
            <Text color="white">{t('reset_password.back')}</Text>
          </Box>
          <Text logoBold color="white">
            {t('reset_password.title')}
          </Text>
          <Text body color="white">
            {t('reset_password.description')}
          </Text>
        </Box>
        <Input
          {...register('token', {
            required: t('validations.required'),
          })}
          error={errors.token?.message}
          placeholder={t('reset_password.verification_code')}
          iconL="key"
          iconColor="secondary"
          width="400px"
        />
        <Input
          {...register('password', {
            required: t('validations.required'),
            pattern: {
              value: /^[^\s]+(?:$|.*[^\s]+$)/,
              message: t('validations.blank_space'),
            },
            minLength: {
              value: 6,
              message: t('validations.password_length'),
            },
          })}
          type="password"
          error={errors.password?.message}
          placeholder={t('common.new_password')}
          iconL="password"
          iconColor="secondary"
          width="400px"
        />
        <Input
          {...register('retypePassword', {
            required: t('validations.required'),
            pattern: {
              value: /^[^\s]+(?:$|.*[^\s]+$)/,
              message: t('validations.blank_space'),
            },
            validate: {
              differentThanNewPassword: (value) =>
                value === getValues().password ||
                t('validations.password_match'),
            },
          })}
          type="password"
          error={errors.retypePassword?.message}
          placeholder={t('common.retype_password')}
          iconL="password"
          iconColor="secondary"
          width="400px"
        />
        <Button disabled={!isValid} type="submit" className="w-[150px] mt-3">
          {t('common.continue')}
        </Button>
      </form>
    </Box>
  );
};

export default NewPassword;
