import { get, put, post } from 'core/api/client';

export const getResetPassword = async (email) =>
  get('/users/reset_password', {
    email,
  });

export const resetPassword = async ({ token, password, retypePassword }) =>
  put(`/users/reset_password_2`, {
    token,
    email: localStorage.email,
    password,
    confirm_password: retypePassword,
  });

export const postLogin = ({ username, password }) =>
  post('/login/access-token', { username, password }, null, true);
