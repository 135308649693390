import Accordion from 'components/Accordion';
import Box from 'components/Box';
import Text from 'components/Text';
import { useTranslation } from 'core/translations/useTranslation';

import useDayScheduler from '../hooks/useDayScheduler';
import DayActionButtons from './DayActionButtons';
import DayTimePickerRow from './DayTimePickerRow';
import DayTitleHourRange from './DayTitleHourRange';

const weekdayKeys = {
  Monday: 'common.days.Monday',
  Tuesday: 'common.days.Tuesday',
  Wednesday: 'common.days.Wednesday',
  Thursday: 'common.days.Thursday',
  Friday: 'common.days.Friday',
  Saturday: 'common.days.Saturday',
  Sunday: 'common.days.Sunday',
};

const DayAccordion = ({
  day,
  idDay,
  initialHoursConfig,
  idAlert,
  onSubmit,
  ...props
}) => {
  const t = useTranslation();
  const {
    submit,
    addPicker,
    removePicker,
    selectHour,
    hoursConfig,
    errorIds,
    isButtonDisabled,
  } = useDayScheduler({ initialHoursConfig, onSubmit, idAlert, idDay });

  return (
    <Box
      {...props}
      className="border-gray border-b last:border-none overflow-hidden "
    >
      <Accordion
        className="w-full"
        noStatus
        render={() => (
          <Box ycenter className="gap-2">
            <Text bodyBold>{t(weekdayKeys[day])}</Text>
            <DayTitleHourRange hoursConfig={hoursConfig} />
          </Box>
        )}
      >
        <Box column className="gap-3 ">
          {hoursConfig?.map((hour) => (
            <DayTimePickerRow
              hasError={!!errorIds.includes(hour.id)}
              key={hour.id}
              hour={hour}
              selectHour={selectHour}
              removePicker={removePicker}
            />
          ))}
        </Box>
        <DayActionButtons
          hasHours={!!hoursConfig?.length}
          isButtonDisabled={isButtonDisabled}
          submit={submit}
          addPicker={addPicker}
        />
      </Accordion>
    </Box>
  );
};

export default DayAccordion;
