import { useState } from 'react';

import Image from 'assets/security-posture/not-found.png';
import Box from 'components/Box';
import Card from 'components/Card';
import Skeleton from 'components/Skeleton';
import Text from 'components/Text';
import { sortDateArray } from 'core/dates';
import { cls } from 'core/styles/cls';
import { useTranslation } from 'core/translations/useTranslation';

import Cases from './Cases';
import Pagination from './Pagination';

const CASESPERPAGE = 5;

const getCasesDateTransformed = (cases) => {
  return cases?.map((item) => {
    if (item === 'creationTime');
    return {
      id: item.id,
      creationTime: new Date(item.creationTime),
      title: item.title,
    };
  });
};

const InvolvedCases = ({ data, isLoading }) => {
  const t = useTranslation();
  const cases = data?.last_case_details;
  const dateTransformed = getCasesDateTransformed(cases);
  const sortCases = sortDateArray(dateTransformed);
  const [currentPage, setCurrentPage] = useState(1);

  // Get current cases
  const indexOfLastPost = currentPage * CASESPERPAGE;
  const indexOfFirstPost = indexOfLastPost - CASESPERPAGE;
  const currentPosts = sortCases?.slice(indexOfFirstPost, indexOfLastPost);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <Card size>
      <Box
        column
        className={cls('gap-3 h-full', {
          hidden: !cases?.length,
        })}
      >
        <Box between>
          <Text className="w-full" body color="lightText">
            {t('details.involved_cases.table.header.identifier')}
          </Text>
          <Text className="w-full" body color="lightText">
            {t('details.involved_cases.table.header.typology')}
          </Text>
          <Box full className="w-[200px]">
            <Text body color="lightText">
              {t('details.involved_cases.table.header.time')}
            </Text>
          </Box>
        </Box>
        <Cases cases={currentPosts} />
        <Pagination
          currentPage={currentPage}
          className="self-end"
          casesPerPage={CASESPERPAGE}
          totalCases={cases?.length}
          paginate={paginate}
        />
      </Box>
      {isLoading ? (
        <Skeleton height={200} />
      ) : (
        !cases?.length && (
          <Box full column xcenter ycenter className="h-full">
            <Box column className="w-[100px] gap-3">
              <img src={Image} alt="logo" />
              <Text bodyBold color="lightText" className="w-[200px]">
                {t('details.involved_cases.table.no_data')}
              </Text>
            </Box>
          </Box>
        )
      )}
    </Card>
  );
};

export default InvolvedCases;
