import { useQueryClient } from 'react-query';

import { useMutation } from 'core/api/react-query';

import { updateCurrentLanguage } from '../api';

export const useUpdateCurrentLanguageMutation = ({ onSuccess, onError }) => {
  const client = useQueryClient();
  const mutation = useMutation(updateCurrentLanguage, {
    onSuccess: (...args) => {
      client.resetQueries();

      if (onSuccess) onSuccess(...args);
    },
    onError,
  });

  return mutation;
};

export default useUpdateCurrentLanguageMutation;
