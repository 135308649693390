import { useToggler } from 'common/hooks';
import Box from 'components/Box';
import Icon from 'components/Icon';
import { useTranslation } from 'core/translations/useTranslation';

import { TABS } from '../constants/constants';
import { Item } from './Item';

export const ItemDropdown = ({
  icon,
  toggleCollapse,
  handleTabClick,
  children,
  items,
  isActive,
  ...props
}) => {
  const t = useTranslation();

  const { isShown: isDropdownShown, toggle: toggleDropdown } = useToggler();

  const showDropdown = () => {
    toggleDropdown();
  };

  return (
    <Box onClick={showDropdown} column {...props}>
      <Item
        isActive={
          isActive === TABS.GROUP_NOTIFICATIONS ||
          isActive === TABS.RULE_NOTIFICATIONS
        }
        icon={icon}
      >
        <Box ycenter className="gap-3">
          {t('settings.tabs.notifications.tab')}
          <Icon
            size="large"
            color={isDropdownShown ? 'primary' : null}
            name={isDropdownShown ? 'arrow-up' : 'arrow-down'}
          />
        </Box>
      </Item>

      {isDropdownShown && (
        <Box column className="pl-2">
          {items.map((item) => {
            return (
              <Item
                isActive={isActive === item.id}
                key={item.id}
                icon={item.icon}
                onClick={() => handleTabClick(item.id)}
              >
                {item.text}
              </Item>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default ItemDropdown;
