import React, { useEffect } from 'react';

import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

import { CACHE_IDS } from 'common/constants/cache';
import { WIDGET_TYPES_SLUGS } from 'common/constants/widgets';
import { useQuery } from 'core/api/react-query';
import { useTranslation } from 'core/translations/useTranslation';
import { selectDateRange } from 'redux/filter/selectors';

import { getSocEngineData } from '../api';

export const useSocEngineQuery = () => {
  const t = useTranslation();
  const { dateFrom, dateTo } = useSelector(selectDateRange);
  const queryClient = useQueryClient();

  useEffect(() => {
    return () => {
      queryClient.invalidateQueries([CACHE_IDS.WIDGET_DATA]);
    };
  }, [t]);

  const renamedKeys = React.useMemo(
    () => ({
      incidents: t('soc_status.funnel.incidents'),
      events: t('soc_status.funnel.events'),
      notified_cases: t('soc_status.funnel.notified_cases'),
    }),
    [t]
  );

  const mapper = (data) => {
    const keys = Object.keys(data);

    return keys.map((key) => ({
      id: key,
      label: renamedKeys[key],
      value: data[key],
    }));
  };

  const query = useQuery(
    [
      CACHE_IDS.WIDGET_DATA,
      { slug: WIDGET_TYPES_SLUGS.SOC_ENGINE, dateTo, dateFrom },
    ],
    () =>
      getSocEngineData({
        dateFrom,
        dateTo,
      }),
    {
      select: (result) => mapper(result),
    }
  );
  return query;
};

export default useSocEngineQuery;
