import Box from 'components/Box';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Text from 'components/Text';
import { useTranslation } from 'core/translations/useTranslation';

const ModalChangeTab = ({ toggle, commitSelection }) => {
  const t = useTranslation();

  return (
    <Modal toggle={toggle} withCancel={false} className="min-h-0 pb-6">
      <Box full column between className="gap-5">
        <Box full xcenter column className="gap-4">
          <Text subtitle>{t('secpos.dashboard.modal.change_tab.title')}</Text>
          <Box column xcenter className="gap-1">
            <Text body>
              {t('secpos.dashboard.modal.change_tab.description')}
            </Text>
          </Box>
        </Box>
        <Box full xcenter className="gap-5">
          <Button bg="gray" color="lightText" onClick={toggle}>
            {t('secpos.dashboard.modal.buttons.cancel')}
          </Button>
          <Button
            onClick={() => {
              commitSelection();
              toggle();
            }}
          >
            {t('secpos.dashboard.modal.change_tab.button')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
export default ModalChangeTab;
