import { useQuery } from 'react-query';

import { CACHE_IDS } from 'common/constants/cache';

import { getCaseDetailsBaseDetails } from '../../api';

const transformList = (data) => {
  const dataTransformed = data?.alert_info?.map((item) => {
    return item;
  });
  return dataTransformed[0];
};

export const useBasicDetailsDataQuery = ({ id }) => {
  const query = useQuery(
    [CACHE_IDS.BASIC_DETAILS, { id }],
    () => getCaseDetailsBaseDetails({ id }),
    {
      select: (result) => transformList(result),
    }
  );

  return query;
};
