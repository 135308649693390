import React from 'react';

import Chart from 'components/Chart';
import Skeleton from 'components/Skeleton';

import { getChartOptions } from './chart';

const WidgetIncidentTimeline = ({ data = {}, isLoading }) => {
  const { series, max_date, min_date } = data;

  const options = React.useMemo(
    () =>
      getChartOptions({
        maxDate: max_date,
        minDate: min_date,
        series: series,
      }),
    [series, min_date, max_date]
  );

  return (
    <>
      {isLoading ? (
        <Skeleton radius={'10px'} height="200px" />
      ) : (
        <Chart
          options={options}
          containerProps={{ style: { width: '100%', height: '200px' } }}
        />
      )}
    </>
  );
};

export default WidgetIncidentTimeline;
