import Box from 'components/Box';
import Icon from 'components/Icon';
import Pagination from 'components/Pagination';
import Tag from 'components/Tag';
import Text from 'components/Text';

const getIsSuspiciousTag = (value) => {
  if (value) {
    <Tag className="h-fit min-w-[64px] m-2" status="error">
      Yes
    </Tag>;
  }
  return (
    <Tag className="h-fit min-w-[64px] m-2" status="success">
      No
    </Tag>
  );
};

const TableView = ({ data, onPageChange }) => {
  const datatable = data?.malicious_urls;

  return (
    <Box full between column className="gap-3 overflow-hidden pr-3 pl-2">
      <Box between>
        <Box full className="w-[160px]">
          <Text body color="lightText">
            Identifier
          </Text>
        </Box>
        <Box className="w-fit pr-3">
          <Text body color="lightText">
            Suspicious
          </Text>
        </Box>
      </Box>
      <Box column className="overflow-hidden overflow-y-auto pr-3 ">
        {datatable.map((item) => (
          <Box between ycenter>
            <Box ycenter className="flex-grow gap-2">
              <Icon
                isBoxed
                name="link"
                bg="success"
                color="darkGreen"
                className="rounded-[100%] w-[32px] h-[32px]"
              />
              <Text className="break-normal sm:break-all">
                {item.identifier}
              </Text>
            </Box>
            <Box ycenter className="ml-4">
              {getIsSuspiciousTag(item.is_suspicious)}
            </Box>
          </Box>
        ))}
      </Box>
      <Pagination
        numPages={data?.total_pages}
        currentPage={data?.page_number}
        onPageChange={onPageChange}
      />
    </Box>
  );
};

export default TableView;
