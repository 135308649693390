import Box from 'components/Box';
import Card from 'components/Card';
import Text from 'components/Text';
import { useTranslation } from 'core/translations/useTranslation';

import { useCurrentTenantQuery, useCurrentUserQuery } from '../../../services';
import ContentLayout from '../../ContentLayout';

const SettingsPersonalInformation = () => {
  const t = useTranslation();
  const { data: currentUser } = useCurrentUserQuery();
  const { data: tenant } = useCurrentTenantQuery();

  return (
    <ContentLayout title={t('settings.personal_information.title')}>
      <Card className="h-fit w-full p-5 flex-wrap">
        <Box xcenter className="w-full flex-col gap-6 2xl:flex-row">
          <Box className="gap-3 h-fit 2xl:justify-center" ycenter>
            <img
              src={currentUser?.avatar}
              className="h-[80px] w-[80px] rounded-lg"
              alt="avatar"
            />
            <Box column>
              <Text subheading>
                {currentUser?.first_name} {currentUser?.last_name}
              </Text>
              {currentUser?.tenants && <Text>{tenant}</Text>}
            </Box>
          </Box>
          <Box className="flex w-full flex-col gap-4 2xl:max-w-[550px]">
            <Box between full className="border-b border-gray pb-3">
              <Text bodyBold>{t('common.name')}</Text>
              <Text>{currentUser?.first_name}</Text>
            </Box>
            <Box between full className="border-b border-gray pb-3">
              <Text bodyBold>{t('common.last_name')}</Text>
              <Text>{currentUser?.last_name}</Text>
            </Box>
            <Box between full className="border-b border-gray pb-3">
              <Text bodyBold>{t('common.email')}</Text>
              <Text>{currentUser?.email}</Text>
            </Box>
            <Box between full className="pb-3">
              {currentUser?.tenants?.length > 1 ? (
                <Text bodyBold>{t('common.tenants')}</Text>
              ) : (
                <Text bodyBold>{t('common.tenant')}</Text>
              )}
              <Box xcenter className="gap-2">
                {currentUser?.tenants?.map((tenant) => (
                  <Text>{tenant.name}</Text>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </Card>
    </ContentLayout>
  );
};

export default SettingsPersonalInformation;
