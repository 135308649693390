import { CACHE_IDS } from 'common/constants/cache';
import { useQuery } from 'core/api/react-query';

import { getCurrentLanguage } from '../api';

export const useCurrentLanguageQuery = () => {
  const query = useQuery([CACHE_IDS.CURRENT_LANGUAGE], getCurrentLanguage);

  return query;
};

export default useCurrentLanguageQuery;
