import React from 'react';

import Box from 'components/Box';
import Pagination from 'components/Pagination';
import Skeleton from 'components/Skeleton';
import Table from 'components/Table';
import Text from 'components/Text';
import { cls } from 'core/styles/cls';
import { useTranslation } from 'core/translations/useTranslation';

import EmptyComponent from '../EmptyComponent';

const PaginatedTable = ({
  columns,
  data,
  totalPages,
  pageSize,
  totalResults,
  currentPage,
  isShowingPreviousData,
  isFetching,
  isError,
  refetch,
  onPageChange,
  withMinWidth,
}) => {
  const t = useTranslation();

  const firstResultNumber = isError
    ? 0
    : data?.length
    ? (currentPage - 1) * pageSize + 1
    : 0;
  const lastResultNumber = isError
    ? 0
    : Math.min(currentPage * pageSize, totalResults);
  const totalResultsNumber = isError ? 0 : totalResults;

  const paginationText = t('table.pagination.show_results', {
    firstResultNumber,
    lastResultNumber,
    totalResultsNumber,
  });
  // Move back to page 1 when received data is empty
  React.useEffect(() => {
    if (!data?.length) {
      onPageChange(1, 'replaceIn');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Box column>
      <Box className="mb-4 overflow-x-auto">
        <Table
          columns={columns}
          data={data}
          EmptyComponent={EmptyComponent({ refetch, isError })}
          loading={isFetching || isShowingPreviousData}
          loadingRows={10}
          loadingHeight={72}
          className={cls({ 'min-w-[1100px]': withMinWidth })}
        />
      </Box>
      <Box between ycenter>
        {isFetching || isShowingPreviousData ? (
          <Skeleton width={260} height={24} />
        ) : (
          <>
            {totalPages >= 1 && (
              <Box>
                <Text color="lightText" input>
                  {paginationText}
                </Text>
              </Box>
            )}
          </>
        )}
        {isFetching ? (
          <Box ycenter className="gap-1">
            <Skeleton width={24} height={24} />
            <Skeleton width={120} height={24} />
            <Skeleton width={24} height={24} />
          </Box>
        ) : (
          <>
            {totalPages >= 1 && (
              <Pagination
                currentPage={currentPage}
                onPageChange={onPageChange}
                numPages={totalPages || 1}
              />
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default PaginatedTable;
