import React from 'react';

import { ToastContainer } from 'react-toastify';

import { FullScreenProvider } from 'common/providers/FullScreenProvider';
import { config as toastConfig } from 'core/toasts/config';
import { DashboardProvider } from 'features/dashboard/hooks/useDashboard';
import { Dashboard } from 'features/dashboard/view';

import Tabs from '../components/Tabs';
import Layout from '../layout';

const SecurityPosture = () => {
  const [selectedTab, setSelectedTab] = React.useState({});

  const selectTab = React.useCallback((selectedItem) => {
    setSelectedTab(selectedItem);
  }, []);

  return (
    <DashboardProvider dashboardId={selectedTab?.id}>
      <FullScreenProvider>
        <ToastContainer {...toastConfig} />
        <div id="dashboard-tab-dropdown"></div>
        <Layout
          renderTabs={() => (
            <Tabs selectedTabId={selectedTab?.id} selectTab={selectTab} />
          )}
        >
          <Dashboard />
        </Layout>
      </FullScreenProvider>
    </DashboardProvider>
  );
};

export default SecurityPosture;
