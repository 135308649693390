import React from 'react';

import EmptyStateWidgets from 'common/components/widgets/EmptyStateWidgets';
import Chart from 'components/Chart';
import Skeleton from 'components/Skeleton';
import Widget from 'components/Widget';
import { useTranslation } from 'core/translations/useTranslation';

import { useIncidentTimelineQuery } from '../../../services/widget-data';
import { useFilters } from '../../TableView/hooks';
import { getChartOptions } from './chart';

const WidgetIncidentTimeline = () => {
  const t = useTranslation();
  const { filters } = useFilters();
  const { data: widgetData, isLoading } = useIncidentTimelineQuery(filters);
  const options = React.useMemo(
    () =>
      getChartOptions({
        rangeDays: widgetData?.range_days,
        maxDate: widgetData?.max_date,
        minDate: widgetData?.min_date,
        series: widgetData?.series,
      }),
    [widgetData]
  );

  return (
    <Widget
      iconLeft="settings"
      iconRight="dots"
      subtitle={t('incidents.widgets.incident_timeline.title')}
      className="h-fit"
    >
      {isLoading ? (
        <Skeleton radius={'10px'} height={330} />
      ) : widgetData?.series?.length ? (
        <Chart
          options={options}
          containerProps={{ style: { width: '100%', height: '330px' } }}
        />
      ) : (
        <EmptyStateWidgets className="min-h-[400px]" />
      )}
    </Widget>
  );
};

export default WidgetIncidentTimeline;
