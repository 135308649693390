import moment from 'moment';
import { Link } from 'react-router-dom';

import SortIndicator from 'common/components/tables/SortIndicator';
import Box from 'components/Box';
import Icon from 'components/Icon';
import Tag from 'components/Tag';
import Text from 'components/Text';
import { getDaysFromNow } from 'core/dates';
import { useTranslation } from 'core/translations/useTranslation';

export const useColumns = (sortByDate, orderBy) => {
  const t = useTranslation();

  return [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 100,
      render: (id) => (
        <Link to={`/incidents/${id}`}>
          <Text cursor="pointer" color="primary" body>
            {id}
          </Text>
        </Link>
      ),
    },
    {
      title: t('incidents.table.headers.typology'),
      dataIndex: 'event_typology',
      key: 'event-typology',
      ellipsis: true,
      render: (eventTypology) => <Text body>{eventTypology}</Text>,
    },

    {
      title: t('incidents.table.headers.product'),
      dataIndex: 'product',
      key: 'product',
      render: (product) => <Text body>{product}</Text>,
    },

    {
      title: t('incidents.table.headers.criticality'),
      dataIndex: 'criticality',
      width: 200,
      key: 'criticality',
      render: (incident) => {
        if (incident === -1)
          return <Tag status="success">{t('common.informative')}</Tag>;
        if (incident === 60)
          return <Tag status="warning">{t('common.medium')}</Tag>;
        if (incident === 80) return <Tag status="high">{t('common.high')}</Tag>;
        if (incident === 100)
          return <Tag status="critical">{t('common.critical')}</Tag>;

        return <Tag status="success">{t('common.low')}</Tag>;
      },
    },

    {
      title: (
        <Box
          data-tip
          data-for="tooltipDate"
          ycenter
          className="gap-1 cursor-pointer"
          onClick={sortByDate}
        >
          <Text link bodyBold>
            {t('incidents.table.headers.date')}
          </Text>
          <SortIndicator
            id="tooltipDate"
            orderBy={orderBy}
            tooltipAsc={t('common.tooltip.date.asc')}
            tooltipDesc={t('common.tooltip.date.desc')}
          />
          <Icon size="small" name={orderBy} />
        </Box>
      ),
      dataIndex: 'date',
      key: 'date',
      ellipsis: true,
      width: 240,
      render: (date) => (
        <Box column>
          <Box column className="gap-1 w-fit items-end">
            <Text>{moment(date).format('DD-MM-YYYY HH:mm:ss')}</Text>
            <Text badgeBold color="lightText">
              {getDaysFromNow(date)}
            </Text>
          </Box>
        </Box>
      ),
    },
  ];
};
