import React from 'react';

import EmptyStateWidgets from 'common/components/widgets/EmptyStateWidgets';
import { getNumberAbbreviation } from 'common/utils/getNumberAbbreviation';
import Chart from 'components/Chart';
import Skeleton from 'components/Skeleton';
import Widget from 'components/Widget';
import '../../styles/styles.css';
import { useWidgetChart } from 'features/widgets/hooks';
import { useTotalEventsTypologiesQuery } from 'features/widgets/services';

import { useGetChartOptions } from './chart';

const WidgetTotalEventsTypologies = ({
  id,
  title,
  isEditing,
  removeWidget,
}) => {
  const { props: chartProps } = useWidgetChart(id);
  const { data: widgetData, isLoading } = useTotalEventsTypologiesQuery();
  const getChartOptions = useGetChartOptions();

  const totalEventsData = widgetData?.reduce((accumulator, object) => {
    return accumulator + object.y;
  }, 0);

  const options = React.useMemo(
    () =>
      getChartOptions({
        data: widgetData,
        allEventsData: getNumberAbbreviation(totalEventsData),
      }),
    [widgetData, totalEventsData, getChartOptions]
  );

  return (
    <Widget
      isEditing={isEditing}
      iconLeft="settings"
      iconRight="dots"
      onDelete={() => removeWidget(id)}
      subtitle={<span className="title-widget">{title}</span>}
    >
      {isLoading ? (
        <Skeleton radius={'10px'} />
      ) : widgetData && totalEventsData > 0 ? (
        <Chart options={options} {...chartProps} />
      ) : (
        <EmptyStateWidgets />
      )}
    </Widget>
  );
};

export default WidgetTotalEventsTypologies;
