import React from 'react';

import { useParams } from 'react-router';

import EmptyComponent from 'common/components/tables/EmptyComponent';
import { useToggler } from 'common/hooks';
import Box from 'components/Box';
import Table from 'components/Table';

import { useRawEventsDataQuery } from '../../../../services/incident-details';
import RawEventsModal from '../../RawEventsModal';
import { useColumns } from './table';

const RawEvents = () => {
  const { id } = useParams();
  const [modalData, setModalData] = React.useState({});
  const { isShown: isModalRawEventsShown, toggle: setIsModalRawEventsShown } =
    useToggler();

  const onOpenModalRawEvents = (rowData) => {
    setModalData(rowData);
    setIsModalRawEventsShown(!isModalRawEventsShown);
  };

  const {
    data,
    isError: isTableError,
    isFetching: isTableFetching,
    refetch,
    isPreviousData,
  } = useRawEventsDataQuery({ id });
  return (
    <Box column className="gap-5">
      {isModalRawEventsShown && (
        <RawEventsModal data={modalData} toggle={setIsModalRawEventsShown} />
      )}
      <Table
        data={data?.raw_events}
        columns={useColumns(onOpenModalRawEvents)}
        EmptyComponent={EmptyComponent({ isTableError, refetch })}
        isError={isTableError}
        isFetching={isTableFetching}
        loading={isTableFetching || isPreviousData}
        loadingRows={5}
        loadingHeight={64}
        withMinWidth
      />
    </Box>
  );
};

export default RawEvents;
