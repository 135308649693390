import { useQuery } from 'react-query';

import { CACHE_IDS } from 'common/constants/cache';

import { getCaseDetailsCorrelations } from '../../api';

export const useCorrelationsDataQuery = ({ id }) => {
  const query = useQuery([CACHE_IDS.CORRELATIONS, { id }], () =>
    getCaseDetailsCorrelations({ id })
  );

  return query;
};
