import React from 'react';

import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

import { CACHE_IDS } from 'common/constants/cache';
import { useQuery } from 'core/api/react-query';
import { selectDateRange } from 'redux/filter/selectors';

import { getIncidentsData } from '../../api';

export const useIncidentsDataQuery = ({
  page,
  caseTypes,
  casesId,
  sortBy,
  products,
  important,
  incident,
  criticality,
  orderBy,
}) => {
  const queryClient = useQueryClient();
  const { dateFrom, dateTo } = useSelector(selectDateRange);
  const query = useQuery(
    [
      CACHE_IDS.INCIDENTS_INCIDENTS_ALL,
      {
        page,
        dateFrom,
        dateTo,
        casesId,
        caseTypes,
        sortBy,
        orderBy,
        products,
        important,
        incident,
        criticality,
      },
    ],
    () =>
      getIncidentsData({
        dateFrom,
        dateTo,
        page,
        caseTypes,
        casesId,
        sortBy,
        orderBy,
        products,
        important,
        incident,
        criticality,
      }),
    {
      keepPreviousData: true,
    }
  );
  React.useEffect(() => {
    if (query?.data?.num_cases || query?.data?.num_pages) {
      queryClient.invalidateQueries([CACHE_IDS.INCIDENTS_INCIDENTS_ALL]);
    }
  }, [query?.data?.num_cases, query?.data?.num_pages, queryClient]);

  return query;
};
