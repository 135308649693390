import { useSelector } from 'react-redux';

import { CACHE_IDS } from 'common/constants/cache';
import { WIDGET_TYPES_SLUGS } from 'common/constants/widgets';
import { useQuery } from 'core/api/react-query';
import { selectDateRange } from 'redux/filter/selectors';

import { getIncidentTypologies } from '../../api';

const mapApiListToObject = (data) => {
  return {
    categories: data.map((item) => item[0]),
    data: data.map((item) => item[1]),
  };
};

export const useIncidentTypologiesQuery = (filters) => {
  const { dateFrom, dateTo } = useSelector(selectDateRange);

  const query = useQuery(
    [
      CACHE_IDS.WIDGET_DATA,
      {
        slug: WIDGET_TYPES_SLUGS.INCIDENT_TYPOLOGIES,
        dateFrom,
        dateTo,
        filters,
      },
    ],
    () =>
      getIncidentTypologies({
        dateFrom,
        dateTo,
        case_type: [],
        cases_id: filters?.cases || [],
      }),
    {
      select: (result) => mapApiListToObject(result),
    }
  );
  return query;
};
