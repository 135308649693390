import Account from 'components/Account';
import Box from 'components/Box';
import Dropdown from 'components/Dropdown';
import Text from 'components/Text';
import { cls } from 'core/styles/cls';
import { useTranslation } from 'core/translations/useTranslation';
import { useLogout } from 'features/auth/hooks/useLogout';

import { useVersionAndBuildNumberQuery } from '../../../services';

const DropdownUser = ({
  closeDropdown,
  className,
  user,
  tenant,
  toggleModalTenant,
}) => {
  const { logout } = useLogout();
  const { data: versionAndBuild } = useVersionAndBuildNumberQuery();
  const t = useTranslation();

  const onLogout = () => {
    logout();
  };
  return (
    <Dropdown
      closeDropdown={closeDropdown}
      className={cls(
        'fixed bottom-[80px] left-[60px] min-w-[280px] pb-5',
        className
      )}
      style={{ zIndex: 9999 }}
    >
      <Account
        avatar={user.avatar}
        name={user.first_name}
        lastName={user.last_name}
        company={tenant ? tenant : ''}
        className="mb-3"
      />
      {user?.tenants?.length > 1 && (
        <Dropdown.Item onClick={toggleModalTenant} top>
          {t('sidebar.user.change_tenant')}
        </Dropdown.Item>
      )}
      <Dropdown.Item to="/settings">{t('sidebar.user.settings')}</Dropdown.Item>
      <Dropdown.Item top onClick={onLogout}>
        {t('sidebar.user.log_out')}
      </Dropdown.Item>
      <Box className="mt-3 gap-1" column>
        <Text className="text-sm text-end absolute right-3" color="lightText">
          Version {versionAndBuild?.version} Build {versionAndBuild?.build}
        </Text>
      </Box>
    </Dropdown>
  );
};

export default DropdownUser;
