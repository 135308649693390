import RCTable from 'rc-table';

import Box from 'components/Box';
import Icon from 'components/Icon';
import Skeleton from 'components/Skeleton';
import Text from 'components/Text';
import { cls } from 'core/styles/cls';

const getComponents = (loading, showHeaders) => ({
  table: ({ className, ...props }) => {
    return <table {...props} className={cls('w-full', className)} />;
  },

  header: {
    wrapper: ({ className, ...props }) =>
      showHeaders && (
        <thead {...props} className={cls('bg-darkBackground', className)} />
      ),
    cell: ({ children, className, ...props }) => (
      <td {...props} className={cls('px-4 py-3', className)}>
        <Text bodyBold ellipsis>
          {children}
        </Text>
      </td>
    ),
  },
  body: {
    wrapper: ({ className, ...props }) => (
      <thead {...props} className={cls('bg-white', className)} />
    ),
    cell: ({ children, className, ...props }) =>
      loading ? (
        <td {...props} className={cls('py-2', className)}>
          {children}
        </td>
      ) : (
        <td {...props} className={cls('px-4 py-3 align-top', className)}>
          {children}
        </td>
      ),
    row: ({ className, ...props }) => (
      <tr
        {...props}
        className={cls('border-b-[1px] border-gray last:border-0 ', className)}
      />
    ),
  },
});

const DefaultExpandIcon = ({ expanded, onExpand, record }) =>
  expanded ? (
    <Icon
      cursor="pointer"
      color="primary"
      onClick={(e) => onExpand(record, e)}
      name="arrow-up"
    />
  ) : (
    <Icon
      color="lightText"
      cursor="pointer"
      onClick={(e) => onExpand(record, e)}
      name="arrow-down"
    />
  );

const LoadingComponent = ({ loadingRows = 2, loadingHeight = 32 }) => {
  return (
    <Box column className="gap-2 w-full">
      {[...Array(loadingRows)].map((_, i) => {
        return <Skeleton key={`skeleton-table-${i}`} height={loadingHeight} />;
      })}
    </Box>
  );
};

const Table = ({
  data,
  columns,
  EmptyComponent,
  className,
  loading,
  loadingRows = 2,
  loadingHeight = 32,
  expandable,
  expandIcon,
  showHeaders = true,
  tableType,
}) => {
  return (
    <RCTable
      data={data}
      columns={columns}
      components={getComponents(loading, showHeaders, tableType)}
      className={cls('w-full', className)}
      emptyText={
        loading ? (
          <LoadingComponent
            loadingRows={loadingRows}
            loadingHeight={loadingHeight}
          />
        ) : (
          EmptyComponent
        )
      }
      expandable={{
        ...expandable,
        expandIcon: expandIcon || DefaultExpandIcon,
      }}
    />
  );
};

export default Table;
