import { useToast } from 'common/hooks/toasts';

import { useUpdateDashboardMutation } from '../services';

const useRenameTab = () => {
  const { showToast } = useToast();

  const { mutate: updateTab } = useUpdateDashboardMutation({
    onError: (error) => {
      showToast(error.detail, 'error');
    },
  });

  const renameTab = ({ id, name, position }) => {
    updateTab({ id, name, tabPosition: position });
  };

  return { renameTab };
};

export default useRenameTab;
