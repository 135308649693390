import moment from 'moment';

import Box from 'components/Box';
import Icon from 'components/Icon';
import Skeleton from 'components/Skeleton';
import Tag from 'components/Tag';
import Text from 'components/Text';
import { useTranslation } from 'core/translations/useTranslation';
import useGetPriorityText, {
  getColorByPriorityName,
  getColorByPriorityValue,
} from 'features/incidents/utils/priorities';

const FilledIcon = ({ color, icon }) => (
  <Box xcenter ycenter className="w-fit h-fit p-1 rounded-[100%]" bg={color}>
    <Box
      xcenter
      bg={color}
      className="rounded-[100%] w-fit h-fit p-3 border-4 border-white"
    >
      <Icon className="text-[50px]" color="white" name={icon} />
    </Box>
  </Box>
);

const getPercentageColor = (percentage) => {
  if (percentage < 25) {
    return 'success';
  } else if (percentage >= 25 && percentage < 50) {
    return 'warning';
  } else if (percentage >= 50 && percentage < 75) {
    return 'danger';
  }
  return 'error';
};

const DetailsHeading = ({
  value,
  title,
  color,
  icon,
  events,
  priority,
  isLoading,
  time,
  percentage,
}) => {
  const t = useTranslation();
  const getPriorityText = useGetPriorityText();

  const timestamp = parseInt(time);
  return (
    <Box ycenter className="gap-3 pt-3">
      <FilledIcon color={color} icon={icon} />
      <Box column className="gap-1">
        {isLoading ? (
          <Skeleton height={30} width="500px" />
        ) : (
          <Box ycenter className="gap-2">
            <Box className="pr-2 border-r border-gray">
              <Text title>{title}</Text>
            </Box>
            <Box ycenter className="h-full">
              <Text className="h-fit leading-none mt-1" subtitle>
                ID {value}
              </Text>
            </Box>
          </Box>
        )}

        {isLoading ? (
          <Skeleton width="150px" height={24} />
        ) : (
          <Box ycenter className="gap-2 h-full relative">
            <Box ycenter className="pr-2 border-r border-gray gap-2">
              <Box
                className="rounded-lg w-2 h-2"
                bg={getColorByPriorityName(priority)}
              />
              <Text body>
                {events} {events === 1 ? t('common.event') : t('common.events')}
              </Text>
            </Box>
            <Tag status={getColorByPriorityValue(priority)}>
              <Text badgeBold>{getPriorityText(priority)}</Text>
            </Tag>
            <Tag className="w-fit" status={getPercentageColor(percentage)}>
              {percentage}% {t('common.suspicious')}
            </Tag>
          </Box>
        )}
        {isLoading ? (
          <Skeleton width="200px" />
        ) : (
          <Box ycenter className="gap-2">
            <Box ycenter xcenter className="gap-1">
              <Icon color="lightText" size="small" name="time" />
              <Text badgeBold color="lightText">
                {moment(timestamp).format('l')}
              </Text>
            </Box>
            <Text badgeBold color="lightText">
              {moment(timestamp).format('HH:mm:ss')}
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DetailsHeading;
