import { useQuery } from 'react-query';

import { CACHE_IDS } from 'common/constants/cache';

import { getIntegrationsTags } from '../api';

export const useIntegrationsTagsQuery = () => {
  const query = useQuery([CACHE_IDS.INTEGRATIONS_TAGS], getIntegrationsTags);
  return query;
};
