import { usePagination } from 'common/hooks';
import Box from 'components/Box';
import { useTranslation } from 'core/translations/useTranslation';
import Layout from 'features/layout';

import Response from '../../components/Response';
import Search from '../../components/Search';
import { useFilters } from '../../hooks/useFilters';
import { useVendorsDataQuery } from '../../services';

const AutomatedResponse = () => {
  const t = useTranslation();
  const { currentPage, onPageChange } = usePagination();
  const { filters, inputProps, onFiltersSubmit } = useFilters();
  const {
    data: vendors,
    isLoading: isVendorLoading,
    isError: isVendorError,
    isPreviousData,
    refetch,
  } = useVendorsDataQuery({
    page: currentPage,
    vendors: filters.vendors,
    tags: filters.tags,
  });
  return (
    <Layout icon="settings" title={t('sidebar.automated_response')}>
      <Box column full className="gap-5 h-fit">
        <Search
          isLoading={isVendorLoading}
          refetch={refetch}
          inputProps={inputProps}
          onFiltersSubmit={onFiltersSubmit}
          onPageChange={onPageChange}
        />
        <Response
          currentPage={currentPage}
          isLoading={isVendorLoading}
          isError={isVendorError}
          refetch={refetch}
          vendors={vendors}
          isPreviousData={isPreviousData}
          onPageChange={onPageChange}
        />
      </Box>
    </Layout>
  );
};

export default AutomatedResponse;
