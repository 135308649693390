import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from 'locales/en.json';
import es from 'locales/es.json';
import it from 'locales/it.json';

const resources = {
  en: {
    translation: en,
  },
  'es-ES': {
    translation: es,
  },
  it: {
    translation: it,
  },
};

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    detection: {
      order: ['querystring', 'navigator'],
      lookupQuerystring: 'lng',
    },
    nsSeparator: false,
    supportedLngs: ['en', 'es-ES', 'it'],
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
