import { QueryClientProvider as ReactQueryProvider } from 'react-query';
import { Provider as ReduxProvider } from 'react-redux';

import { client } from 'core/api/react-query';
import { SidebarProvider } from 'features/layout/hooks/useSidebar';

import store from './core/redux/store';

const Providers = ({ children }) => {
  return (
    <ReactQueryProvider client={client}>
      <ReduxProvider store={store}>
        <SidebarProvider>{children}</SidebarProvider>
      </ReduxProvider>
    </ReactQueryProvider>
  );
};

export default Providers;
