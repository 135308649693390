import EmptyStateWidgets from 'common/components/widgets/EmptyStateWidgets';
import { usePagination } from 'common/hooks';
import Skeleton from 'components/Skeleton';
import Widget from 'components/Widget';
import '../../styles/styles.css';

import { useEventsTypologiesCountQuery } from '../../services';
import TableView from './TableView';
const WidgetEventsTypologiesCount = ({
  id,
  title,
  removeWidget,
  isEditing,
}) => {
  const { currentPage, onPageChange } = usePagination('pageEventsTypologies');
  const { data: widgetData, isLoading } = useEventsTypologiesCountQuery({
    page: currentPage,
  });

  return (
    <Widget
      isEditing={isEditing}
      iconLeft="settings"
      iconRight="dots"
      onDelete={() => removeWidget(id)}
      subtitle={<span className="title-widget">{title}</span>}
    >
      {isLoading ? (
        <Skeleton radius={'10px'} />
      ) : widgetData?.length ? (
        <TableView data={widgetData} onPageChange={onPageChange} />
      ) : (
        <EmptyStateWidgets />
      )}
    </Widget>
  );
};

export default WidgetEventsTypologiesCount;
