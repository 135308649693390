import React from 'react';

import ReactDOM from 'react-dom';

import { useToggler } from 'common/hooks';
import useOnScroll from 'common/hooks/useOnScroll';
import Box from 'components/Box';
import Dropdown from 'components/Dropdown';
import Icon from 'components/Icon';
import { useTranslation } from 'core/translations/useTranslation';

const TabOptions = ({ totalTabs, isSelected, onEdit, onDelete }) => {
  const [threeDotsPosition, setThreeDotsPosition] = React.useState({});
  useOnScroll(() =>
    setThreeDotsPosition(threeDotsIconRef.current?.getBoundingClientRect())
  );

  React.useEffect(() => {
    setThreeDotsPosition(threeDotsIconRef.current?.getBoundingClientRect());
  }, []);

  const t = useTranslation();
  const { isShown: isDropdownShown, toggle: toggleDropdown } = useToggler();
  const threeDotsIconRef = React.useRef();
  const { y, x } = threeDotsPosition;

  return (
    <>
      <Box
        ref={threeDotsIconRef}
        className="px-1 py-[2px] h-full rounded-md hover:bg-gray"
        onClick={(e) => {
          e.stopPropagation();
          toggleDropdown();
        }}
      >
        <Icon
          className="text-lg"
          color={isSelected ? 'primary' : 'lightText'}
          name="options"
        />
      </Box>
      {isDropdownShown &&
        ReactDOM.createPortal(
          <Dropdown
            closeDropdown={toggleDropdown}
            id="tab-dropdown"
            className="fixed w-[100px] z-[10]"
            style={{ top: y - 10, left: x - -10 }}
          >
            <Box column>
              <Dropdown.Item
                top={totalTabs === 1}
                onClick={() => {
                  onEdit();
                  toggleDropdown();
                }}
              >
                {t('secpos.dashboard.options.rename')}
              </Dropdown.Item>
              {/*  <Dropdown.Item top={totalTabs === 1}>
                {t('secpos.dashboard.options.clone')}
              </Dropdown.Item> */}
              {totalTabs > 1 && (
                <Dropdown.Item top onClick={onDelete}>
                  {t('secpos.dashboard.options.delete')}
                </Dropdown.Item>
              )}
            </Box>
          </Dropdown>,
          document.getElementById('dashboard-tab-dropdown')
        )}
    </>
  );
};

export default TabOptions;
