import React, { useEffect, useState } from 'react';

import moment from 'moment';

import Box from 'components/Box';
import Icon from 'components/Icon';
import Skeleton from 'components/Skeleton';
import Text from 'components/Text';
import { useTranslation } from 'core/translations/useTranslation';

const LastReportedCell = ({ lastReport, isLoading }) => {
  const lastReported = moment(lastReport).format('DD/MM/YYYY HH:mm:ss');

  if (isLoading) return <Skeleton width={150} height={10} />;

  return <Text body>{lastReported}</Text>;
};

const StatusCell = ({ lastReport, receiptTime, isLoading }) => {
  const actualTime = parseInt(moment().unix() + '000');

  const timeSinceLastReport = actualTime - lastReport;

  const averageTimeInMs = receiptTime * 1000;

  const isLessThanAverage = timeSinceLastReport <= averageTimeInMs;

  if (isLoading) return <Skeleton width={28} height={28} radius={100} />;

  return (
    <Box>
      {!isLessThanAverage ? (
        <Icon name="close" color="error" />
      ) : (
        <Icon name="check" color="success" />
      )}
    </Box>
  );
};

export const useColumns = (newDevices) => {
  const t = useTranslation();
  const [devices, setDevices] = useState([]);

  useEffect(() => {
    setDevices(newDevices);
    return () => {
      setDevices(null);
    };
  }, [newDevices]);

  return [
    {
      title: t('soc_status.list_of_devices.table.product'),
      key: 'name',
      width: 700,
      render: (data) => {
        return data?.device_name ? (
          <Box column>
            <Text body>{data?.device_name}</Text>
            <Text badgeBold color="lightText">
              {data?.product}
            </Text>
          </Box>
        ) : (
          <Box column>
            <Text body>{t('common.unknown')}</Text>
            <Text badgeBold color="lightText">
              {data?.product}
            </Text>
          </Box>
        );
      },
    },
    {
      title: t('soc_status.list_of_devices.table.last_reported'),
      key: 'id',
      width: 900,
      render: (data) => {
        const newDevice = devices?.find((device) => device.id === data.id);
        const lastReport = newDevice?.last_report;
        return (
          <LastReportedCell
            lastReport={lastReport}
            isLoading={!newDevice?.last_report}
          />
        );
      },
    },
    {
      title: t('soc_status.list_of_devices.table.status'),
      dataIndex: '',
      key: 'time',
      width: 200,
      render: (data) => {
        const newDevice = devices?.find((device) => device.id === data.id);
        const receiptTime =
          newDevice?.avg_receipt_time || data?.avg_receipt_time;
        const lastReport = newDevice?.last_report;
        return (
          <StatusCell
            receiptTime={receiptTime}
            lastReport={lastReport}
            isLoading={!newDevice?.last_report}
          />
        );
      },
    },
  ];
};
