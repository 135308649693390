import Box from 'components/Box';
import PaginationComponent from 'components/Pagination';
import { cls } from 'core/styles/cls';
const Pagination = ({
  entitiesPerPage,
  totalEntities,
  currentPage,
  paginate,
  className,
}) => {
  const numPages = Math.ceil(totalEntities / entitiesPerPage);

  return (
    <Box className={cls('gap-3', className)}>
      <PaginationComponent
        currentPage={currentPage}
        numPages={numPages}
        onPageChange={paginate}
      />
    </Box>
  );
};

export default Pagination;
