import Card from 'components/Card';

import AllEntitiesView from '../AllEntitiesView';

const Tabs = () => {
  return (
    <Card size>
      <AllEntitiesView />
    </Card>
  );
};

export default Tabs;
