import cls from 'classnames';
import ReactDOM from 'react-dom';
import OutsideClickHandler from 'react-outside-click-handler';

import Box from 'components/Box';
import Button from 'components/Button';
import Card from 'components/Card';
import Icon from 'components/Icon';
import Text from 'components/Text';
import { useTranslation } from 'core/translations/useTranslation';

const Modal = ({
  children,
  className,
  toggle,
  title,
  icon,
  bg,
  color,
  withCancel = true,
}) => {
  const t = useTranslation();
  return ReactDOM.createPortal(
    <Box className="bg-blackOpacity flex items-center justify-center w-screen h-screen">
      <OutsideClickHandler onOutsideClick={toggle}>
        <Card
          className={cls(
            'min-w-[300px] w-[700px] relative h-fit bg-white flex py-4 px-5',
            className
          )}
        >
          <Box between className="gap-7">
            <Box ycenter className="gap-2">
              {icon && (
                <Icon
                  isBoxed
                  name={icon}
                  bg={bg}
                  color={color}
                  className="rounded-[100%] w-[32px] h-[32px]"
                />
              )}
              <Text subtitle>{title}</Text>
            </Box>
            <Icon
              cursor="pointer"
              name="close"
              onClick={toggle}
              className="hover:text-primary text-lightText"
            />
          </Box>
          {children}
          {withCancel && (
            <Button onClick={toggle} className="w-[120px] self-end mt-4">
              {t('common.close')}
            </Button>
          )}
        </Card>
      </OutsideClickHandler>
    </Box>,
    document.getElementById('modals')
  );
};

export default Modal;
