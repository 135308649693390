import IP from 'assets/svg/ip.svg';
import Box from 'components/Box';
import Icon from 'components/Icon';
import Tag from 'components/Tag';
import Text from 'components/Text';
import { useTranslation } from 'core/translations/useTranslation';

export const useColumns = () => {
  const t = useTranslation();
  return [
    {
      title: '',
      dataIndex: '',
      key: 'avatar',
      width: 64,
      render: (data) => {
        const type = data?.type;
        const renderTag = () => {
          if (type === 'HOSTNAME' || type === 'DestinationURL') {
            return <Icon className="text-xl" color="primary" name="host" />;
          } else if (type === 'USERUNIQNAME') {
            return <Icon className="text-xl" color="error" name="account" />;
          } else if (type === 'ADDRESS') {
            return <img className="w-4" src={IP} alt="ip" />;
          } else if (type === 'FILENAME') {
            return <Icon className="text-xl" name="file" color="7B61FF" />;
          } else if (type === 'THREATSIGNATURE') {
            return <Icon className="text-xl" name="threats" color="warning" />;
          } else if (type === 'FILEHASH') {
            return <Icon className="text-xl" name="hash" color="lightText" />;
          } else if (type === 'DOMAIN') {
            return <Icon className="text-xl" name="domain" color="primary" />;
          }
          return null;
        };

        return (
          <Box
            xcenter
            className="h-[40px] w-[40px] rounded-[100%] bg-darkBackground text-white"
          >
            {renderTag()}
          </Box>
        );
      },
    },

    {
      title: t('incidents.details.tabs.related_entities.table.headers.name'),
      dataIndex: 'name',
      key: 'name',
      render: (name) => <Text body>{name}</Text>,
    },
    {
      title: t('incidents.details.tabs.related_entities.table.headers.type'),
      dataIndex: 'type',
      key: 'type',
      render: (type) => <Text body>{type}</Text>,
    },
    {},
    {
      title: t(
        'incidents.details.tabs.related_entities.table.headers.suspicious'
      ),
      dataIndex: 'Suspicious',
      key: 'suspicious',
      render: (suspicious) => {
        const renderTag = () => {
          if (suspicious === 'No') {
            return <Tag status="success">{t('common.no')}</Tag>;
          }

          return <Tag status="error">{t('common.yes')}</Tag>;
        };

        return renderTag();
      },
    },
  ];
};
