import { useSelector } from 'react-redux';

import { CACHE_IDS } from 'common/constants/cache';
import { WIDGET_TYPES_SLUGS } from 'common/constants/widgets';
import { useQuery } from 'core/api/react-query';
import { selectDateRange } from 'redux/filter/selectors';

import { getEventsDataTypologies } from '../api';

const mapper = (data) => {
  const keys = Object.keys(data);
  return keys
    .map((key) => ({
      name: key,
      y: data[key],
    }))
    .sort((a, b) => b.y - a.y);
};

export const useEventsDataTypologiesQuery = () => {
  const { dateFrom, dateTo } = useSelector(selectDateRange);

  const query = useQuery(
    [
      CACHE_IDS.WIDGET_DATA,
      { slug: WIDGET_TYPES_SLUGS.EVENTS_TYPOLOGIES, dateTo, dateFrom },
    ],
    () =>
      getEventsDataTypologies({
        dateFrom,
        dateTo,
      }),
    {
      select: (result) => mapper(result),
    }
  );
  return query;
};

export default useEventsDataTypologiesQuery;
