import React from 'react';

import { useQueryClient } from 'react-query';

import { CACHE_IDS } from 'common/constants/cache';
import { useQuery } from 'core/api/react-query';

import { getVendors } from '../api';

export const useVendorsDataQuery = (page, vendors, tags) => {
  const queryClient = useQueryClient();
  const query = useQuery(
    [CACHE_IDS.AUTOMATED_RESPONSE, { page, vendors, tags }],
    () => getVendors(page, vendors, tags),
    {
      keepPreviousData: true,
    }
  );

  React.useEffect(() => {
    if (query?.data?.num_pages) {
      queryClient.invalidateQueries([CACHE_IDS.AUTOMATED_RESPONSE]);
    }
  }, [query?.data?.num_pages, queryClient]);

  return query;
};
