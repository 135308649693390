import { useToast } from 'common/hooks/toasts';

import { useDeleteDashboardMutationQuery } from '../services';

const useDeleteTab = () => {
  const { showToast } = useToast();

  const { mutate: deleteTab } = useDeleteDashboardMutationQuery({
    onError: (error) => {
      showToast(error.detail, 'error');
    },
  });

  return { deleteTab };
};

export default useDeleteTab;
