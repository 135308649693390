import useInputUrlFilter from 'common/hooks/filters/useInputUrlFilter';
import useSelectUrlFilter from 'common/hooks/filters/useSelectUrlFilter';

export const useFilters = () => {
  const {
    value: vendorsInput,
    filter: vendors,
    syncFilter: syncVendorsFilters,
    onChange: onVendorsInputChange,
    isSync: isVendorsFilterSync,
  } = useInputUrlFilter('vendors');
  const {
    value: tagsSelect,
    filter: tags,
    syncFilter: syncTagsFilter,
    onChange: onTagsChange,
    isSync: isTagsFilterSync,
  } = useSelectUrlFilter('tags');

  const onFiltersSubmit = (refetch) => {
    if (isTagsFilterSync && isVendorsFilterSync) {
      refetch();
    } else {
      syncTagsFilter();
      syncVendorsFilters();
    }
  };

  return {
    filters: {
      tags,
      vendors,
    },
    inputProps: {
      integrations: {
        value: vendorsInput,
        defaultValue: vendorsInput,
        onChange: onVendorsInputChange,
      },
      tags: {
        value: tagsSelect,
        defaultValue: tagsSelect,
        onChange: onTagsChange,
      },
    },
    onFiltersSubmit,
  };
};
