import { useParams } from 'react-router';

import Box from 'components/Box';
import Text from 'components/Text';
import { cls } from 'core/styles/cls';
import { useTranslation } from 'core/translations/useTranslation';

import { useOverviewDataQuery } from '../../../../services/incident-details';
import Card from '../../Card';
import Metadata from '../../Metadata';
import WidgetIncidentTimeline from '../../Widgets/WidgetIncidentTimeline';

export const Separator = ({ row, column, className }) => {
  const rowClasses = 'bg-gray h-[1px] w-full';
  const columnClasses = 'bg-gray w-[1px] h-full';
  return (
    <Box
      className={cls(className, {
        [rowClasses]: row,
        [columnClasses]: column,
      })}
    />
  );
};

const Section = ({ title, children }) => {
  return (
    <Box column className="gap-4">
      <Text subtitle>{title}</Text>
      {children}
    </Box>
  );
};

const Overview = () => {
  const t = useTranslation();

  const { id } = useParams();
  const { data, isLoading } = useOverviewDataQuery({ id });

  const entities = data?.relevant_entities;
  const metadata = data?.details;
  const events = data?.events_timechart;

  const firstPartMetadata = metadata?.slice(
    metadata?.length / 2,
    metadata?.length
  );
  const secondPartMetadata = metadata?.slice(0, metadata?.length / 2);

  const metadataNameTransformed = secondPartMetadata?.map((item) => {
    if (item.name === 'Device Vendor')
      return { name: 'Source', value: item.value };
    if (item.name === 'file_hash')
      return { name: 'File Hash', value: item.value };
    return { name: item.name, value: item.value };
  });

  return (
    <Box column className="gap-6">
      <Section title={t('incidents.details.tabs.overview.summary.title')}>
        <Box column className="gap-3 xl:gap-0 xl:flex-row">
          <Card.Base
            isLoading={isLoading}
            title={t(
              'incidents.details.tabs.overview.summary.card.internal_targets'
            )}
            value={entities?.hostnames?.length}
            bg="primary"
            icon="table"
            border="primary"
          >
            <Card.Heading
              columns={[t('common.hostname'), t('common.address')]}
            />
            {entities?.hostnames?.length ? (
              entities?.hostnames?.map((item) => (
                <Card.Row
                  key={item.identifier}
                  values={[item.identifier, item.ip]}
                />
              ))
            ) : (
              <Card.Row
                key="UNKNOWN"
                values={[t('common.unknown'), t('common.unknown')]}
              />
            )}
          </Card.Base>
          <Separator column />
          <Card.Base
            isLoading={isLoading}
            title={t(
              'incidents.details.tabs.overview.summary.card.involved_users'
            )}
            value={entities?.users?.length}
            bg="error"
            icon="users"
            border="error"
          >
            <Card.Heading columns={[t('common.user')]} />
            {entities?.users?.length ? (
              entities?.users?.map((item) => (
                <Card.Row key={item.identifier} values={[item.identifier]} />
              ))
            ) : (
              <Card.Row key="UNKNOWN" values={[t('common.unknown')]} />
            )}
          </Card.Base>
          <Separator column />
          <Card.Base
            isLoading={isLoading}
            title={t('incidents.details.tabs.overview.summary.card.addresses')}
            value={entities?.ips?.length}
            bg="danger"
            icon="ip"
            border="error"
          >
            <Card.Heading columns={[t('common.ip'), t('common.hostname')]} />
            {entities?.ips?.map((item) => (
              <Card.Row
                key={item.identifier}
                values={[item.identifier, item.hostname]}
              />
            ))}
          </Card.Base>
        </Box>
      </Section>
      <Separator row />
      <Section title={t('incidents.details.tabs.overview.details')}>
        <Box full column between className="xl:flex-row xl:gap-0 gap-4 ">
          <Metadata.Base isLoading={isLoading}>
            {firstPartMetadata?.map((item) => (
              <Metadata.Row title={item?.name} value={item?.value} />
            ))}
          </Metadata.Base>
          <Separator row className="xl:flex-col xl:w-[1px] xl:h-full" />
          <Metadata.Base isLoading={isLoading}>
            {metadataNameTransformed?.map((item) => (
              <Metadata.Row title={item?.name} value={item.value} />
            ))}
          </Metadata.Base>
        </Box>
      </Section>
      <Separator row />

      <Section title={t('incidents.details.tabs.overview.incident_timeline')}>
        <WidgetIncidentTimeline data={events} isLoading={isLoading} />
      </Section>
    </Box>
  );
};

export default Overview;
