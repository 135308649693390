import { useMutation } from 'core/api/react-query';

import { getResetPassword } from '../api';

export const useResetPasswordQuery = ({ onSuccess, onError }) => {
  const mutation = useMutation(getResetPassword, {
    onSuccess: () => {
      if (onSuccess) onSuccess();
    },
    onError,
  });

  return mutation;
};
