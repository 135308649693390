import React from 'react';

import Box from 'components/Box';
import Dropdown from 'components/Dropdown';
import { cls } from 'core/styles/cls';
import { useTranslation } from 'core/translations/useTranslation';

const DropdownCard = ({
  closeDropdown,
  handleClickDeleteGroup,
  handleClickEditGroup,
  className,
}) => {
  const t = useTranslation();
  return (
    <Dropdown
      {...{ closeDropdown }}
      className={cls('z-[10] w-[100px]', className)}
    >
      <Box column>
        <Dropdown.Item
          onClick={(e) => {
            e.stopPropagation();
            handleClickEditGroup();
          }}
        >
          {t('dropdown.edit')}
        </Dropdown.Item>
        <Dropdown.Item
          top
          onClick={(e) => {
            e.stopPropagation();
            handleClickDeleteGroup();
          }}
        >
          {t('dropdown.delete')}
        </Dropdown.Item>
      </Box>
    </Dropdown>
  );
};

export default DropdownCard;
