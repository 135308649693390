import moment from 'moment';

import Box from 'components/Box';
import Text from 'components/Text';
import { getDaysFromNow } from 'core/dates';
import { useTranslation } from 'core/translations/useTranslation';

export const useColumns = () => {
  const t = useTranslation();
  return [
    {
      title: t('incidents.details.tabs.incident_logs.table.header.comment'),
      dataIndex: 'comment',
      key: 'comment',
      render: (comment) => (
        <Box column className="flex max-w-[700px]">
          <Text className="break-words text-ellipsis">{comment}</Text>
        </Box>
      ),
    },
    {
      title: t('incidents.details.tabs.incident_logs.table.header.creator'),
      dataIndex: 'creatorUserId',
      key: 'creator',
      render: (creatorUserId) => <Text body>{creatorUserId}</Text>,
    },
    {
      title: t('incidents.details.tabs.incident_logs.table.header.time'),
      dataIndex: 'creationTimeUnixTimeInMs',
      key: 'time',
      render: (date) => (
        <Box column className="gap-1 w-fit items-end">
          <Text body>{moment(date).format('DD-MM-YYYY HH:mm:ss')} </Text>
          <Text badgeBold color="lightText">
            {getDaysFromNow(date)}
          </Text>
        </Box>
      ),
    },
  ];
};
