import { usePagination } from 'common/hooks';
import Box from 'components/Box';
import { useTranslation } from 'core/translations/useTranslation';
import Layout from 'features/layout';

import Integrations from '../../components/Integrations';
import Search from '../../components/Search';
import { useFilters } from '../../hooks/useFilters';
import { useIntegrationsDataQuery } from '../../services/useIntegrationsDataQuery';

const Catalogue = () => {
  const t = useTranslation();
  const { currentPage, onPageChange } = usePagination();
  const { filters, inputProps, onFiltersSubmit } = useFilters();
  const {
    data: integrations,
    isLoading: isIntegrationLoading,
    refetch,
  } = useIntegrationsDataQuery({
    page: currentPage,
    integrations: filters.integrations,
    tags: filters.tags,
  });

  return (
    <Layout icon="catalogue" title={t('sidebar.catalogue')}>
      <Box column full className="gap-5 h-fit">
        <Search
          isLoading={isIntegrationLoading}
          refetch={refetch}
          inputProps={inputProps}
          onFiltersSubmit={onFiltersSubmit}
          onPageChange={onPageChange}
        />
        <Integrations
          currentPage={currentPage}
          isLoading={isIntegrationLoading}
          integrations={integrations}
          onPageChange={onPageChange}
        />
      </Box>
    </Layout>
  );
};
export default Catalogue;
