import {
  QueryClient,
  useMutation as useRqMutation,
  useQuery as useRqQuery,
} from 'react-query';

import { useHandleError } from 'common/hooks/errors';

const FIVE_MINUTES = 1000 * 60 * 5;
const config = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: 1,
      staleTime: FIVE_MINUTES, // the cache lasts 5 minutes
    },
  },
};

export const client = new QueryClient(config);

export const useMutation = (fn, options) => {
  const { handleError } = useHandleError();
  const mutation = useRqMutation(fn, {
    ...options,
    onError: (error) => {
      if (options?.onError) {
        options.onError(error);
      }
      handleError(error);
    },
  });
  return mutation;
};

export const useQuery = (cache, fn, options, queryKey) => {
  const { handleError } = useHandleError();

  const query = useRqQuery(
    cache,
    fn,
    {
      ...options,
      onError: (error) => {
        if (options?.onError) {
          options.onError(error);
        }
        handleError(error);
      },
    },
    queryKey
  );

  return query;
};
