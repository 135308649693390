import { useQuery } from 'react-query';

import { CACHE_IDS } from 'common/constants/cache';

import { getCaseDetailsHasCorrelations } from '../../api';

export const useHasCorrelationsDataQuery = ({ id }) => {
  const query = useQuery([CACHE_IDS.CORRELATIONS, { id }], () =>
    getCaseDetailsHasCorrelations({ id })
  );

  return query;
};
