import React from 'react';

import { v4 as uuidv4 } from 'uuid';

import { useToast } from 'common/hooks/toasts';
import { useTranslation } from 'core/translations/useTranslation';

// getHoursArray(3,5) => [3,4,5]
const getHoursArray = (v1, v2) => {
  const start = Math.min(v1, v2);
  const end = Math.max(v1, v2);
  const result = [];
  for (let i = start; i <= end; i++) result.push(i);
  return result;
};

function areArrayEqual(a1, a2) {
  if (a1?.length !== a2?.length) return false;
  const areEqual = a1.every((obj1, index) => {
    const obj2 = a2[index];
    return (
      obj1.hour_to === obj2.hour_to &&
      obj1.hour_from === obj2.hour_from &&
      obj1.max_elapsed_minutes === obj2.max_elapsed_minutes
    );
  });

  return areEqual;
}

const getHourConfigWithId = (hourConfig) => {
  return hourConfig?.map((a) => ({ ...a, id: uuidv4() }));
};

const checkRepetitionBetweenTwoArrays = (arr, arr2) => {
  let repetitions = 0;
  arr.forEach((e) => {
    if (arr2.includes(e)) repetitions++;
  });
  if (repetitions >= 2) {
    return true;
  }
  return false;
};
const checkRepetition = (array, arrayIds) => {
  let errorIds = new Set([]);
  let result = false;
  for (const [indice, elemento] of array.entries()) {
    for (const [indice2, elemento2] of array.entries()) {
      if (indice === indice2) break;
      let check = checkRepetitionBetweenTwoArrays(elemento, elemento2);
      if (check) {
        result = true;
        errorIds.add(arrayIds[indice]);
        errorIds.add(arrayIds[indice2]);
      }
    }
  }
  return { result, errorIds: Array.from(errorIds) };
};

const getHourTo = (hourTo) => {
  return hourTo === 0 ? 24 : hourTo;
};

const useDayScheduler = ({ initialHoursConfig, onSubmit, idAlert, idDay }) => {
  const { showToast } = useToast();
  const [errorIds, setErrorIds] = React.useState([]);
  const t = useTranslation();

  const [hoursConfig, setHoursConfig] = React.useState(
    getHourConfigWithId(initialHoursConfig)
  );

  React.useEffect(() => {
    setHoursConfig(getHourConfigWithId(initialHoursConfig));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idAlert]);

  // Array of the selected hours taking into account all pickers of the current day
  // If user selects [3,5] and [8,12] the array contains [3,4,5,8,9,10,11,12]
  const selectedHoursList = React.useMemo(() => {
    if (!hoursConfig || !hoursConfig.length) return;
    return hoursConfig.map((h) =>
      getHoursArray(h.hour_from, getHourTo(h.hour_to))
    );
    // .flat() -> flattens an array -> [[]] --> []
  }, [hoursConfig]);

  const selectedIds = hoursConfig.map((h) => h.id);

  const isButtonDisabled = React.useMemo(() => {
    return areArrayEqual(initialHoursConfig, hoursConfig);
  }, [initialHoursConfig, hoursConfig]);

  const selectHour = ({ idHour, valueFrom, valueTo, inputValueMinutes }) => {
    const hours = hoursConfig.map((h) => {
      if (h.id !== idHour) return h;
      if (valueFrom)
        return {
          ...h,
          hour_from: valueFrom.value,
        };
      if (valueTo)
        return {
          ...h,
          hour_to: valueTo.value,
        };
      if (inputValueMinutes)
        return {
          ...h,
          max_elapsed_minutes: inputValueMinutes * 60,
        };
      return h;
    });

    setHoursConfig(hours);
  };

  const removePicker = ({ idHour }) => {
    const newHoursConfig = hoursConfig.filter((h) => h.id !== idHour);
    setHoursConfig(newHoursConfig);

    if (hoursConfig.length === 1) {
      onSubmit({
        idAlert,
        hours: newHoursConfig.map(({ id, ...h }) => h),
        day: idDay,
      });
    }
  };

  const addPicker = () => {
    const nextHour = hoursConfig[hoursConfig.length - 1]?.hour_to;
    const emptyHours = {
      id: uuidv4(),
      day: idDay,
      hour_from: Math.min(nextHour, 23) || 0,
      hour_to: nextHour === 23 ? 0 : Math.min(nextHour + 1, 24) || 1,
      max_elapsed_minutes: 60,
    };

    if (hoursConfig.length === 24) {
      showToast(t('toast.notification.hours_config.max_dates'), 'error');
      return;
    }

    setHoursConfig([...hoursConfig, emptyHours]);
  };

  const submit = () => {
    let newErrorIds = [];
    const configIdsWithInvalidRanges = hoursConfig
      ?.filter((h) => {
        const hourTo = getHourTo(h.hour_to);
        return h.hour_from >= hourTo;
      })
      .map((range) => range.id);

    const result = checkRepetition(selectedHoursList, selectedIds);

    const isThereInvalidRanges = !!configIdsWithInvalidRanges.length;
    if (isThereInvalidRanges) {
      showToast(t('toast.notification.hours_config.invalidRanges'), 'error');
      newErrorIds = configIdsWithInvalidRanges;
    }
    /*   if (!checkExtremes(selectedHoursList)) {
      showToast(t('toast.notification.hours_config.repeat'), 'error');
      newErrorIds = [...newErrorIds, hoursConfig.at(-1).id];
    } */

    if (result.result) {
      showToast(t('toast.notification.hours_config.repeat'), 'error');
      newErrorIds = result.errorIds;
    }

    if (!!newErrorIds.length) {
      setErrorIds(newErrorIds);
      return;
    }
    setErrorIds([]);

    onSubmit({
      idAlert,
      hours: hoursConfig.map(({ id, ...h }) => h),
      day: idDay,
    });
  };

  return {
    submit,
    addPicker,
    removePicker,
    selectHour,
    hoursConfig,
    isButtonDisabled,
    errorIds,
  };
};

export default useDayScheduler;
