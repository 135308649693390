import { useNavigate } from 'react-router';

import Image from 'assets/catalogue/not-found.png';
import Box from 'components/Box';
import Button from 'components/Button';
import Text from 'components/Text';
import { useTranslation } from 'core/translations/useTranslation';
const ErrorNotFound = () => {
  const t = useTranslation();
  const navigate = useNavigate();
  return (
    <Box column xcenter className="max-w-[400px] self-center" full>
      <img src={Image} alt="not-found" className="mb-[-16px]" />
      <Box column xcenter className="gap-3">
        <Text subtitle>{t('error.subtitle')}</Text>
        <Text bodyBold color="lightText" className="text-center">
          {t('error.content')}
        </Text>
        <Button onClick={() => navigate(-1)}>{t('common.back')}</Button>
      </Box>
    </Box>
  );
};

export default ErrorNotFound;
