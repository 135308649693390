import { useSelector } from 'react-redux';

import { CACHE_IDS } from 'common/constants/cache';
import { WIDGET_TYPES_SLUGS } from 'common/constants/widgets';
import { useQuery } from 'core/api/react-query';
import { selectDateRange } from 'redux/filter/selectors';

import { getTotalEventTimeline } from '../api';

export const useTotalEventTimelineQuery = () => {
  const { dateFrom, dateTo } = useSelector(selectDateRange);

  const query = useQuery(
    [
      CACHE_IDS.WIDGET_DATA,
      { slug: WIDGET_TYPES_SLUGS.TOTAL_EVENT_TIMELINE, dateTo, dateFrom },
    ],
    () =>
      getTotalEventTimeline({
        dateFrom,
        dateTo,
      })
  );
  return query;
};
