import React from 'react';

const useOnScroll = (fn) => {
  React.useEffect(() => {
    window.addEventListener('scroll', fn, true);
    return () => {
      window.removeEventListener('scroll', fn, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useOnScroll;
