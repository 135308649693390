import Box from 'components/Box';
import Icon from 'components/Icon';
import Skeleton from 'components/Skeleton';
import Text from 'components/Text';
import Tooltip from 'components/Tooltip';
import { cls } from 'core/styles/cls';
import { useTranslation } from 'core/translations/useTranslation';

const CardRow = ({ title, values, className }) => {
  const t = useTranslation();
  return (
    <Box between className={cls('w-full', className)}>
      {values?.map((value) => {
        const shouldShowTooltip = value.length > 15;
        if (title) {
          return (
            <Text subheading color="lightText">
              {value}
            </Text>
          );
        }
        return value ? (
          <Box
            data-tip
            data-for={value}
            className="max-w-[150px] overflow-hidden"
          >
            <Text ellipsis subheading>
              {value}
            </Text>
            {shouldShowTooltip && <Tooltip id={value}>{value}</Tooltip>}
          </Box>
        ) : (
          <Text subheading>{t('common.unknown')}</Text>
        );
      })}
    </Box>
  );
};

const CardHeading = ({ columns, ...props }) => (
  <CardRow {...props} title values={columns} />
);

const CardBase = ({ children, title, value, icon, bg, border, isLoading }) => {
  return (
    <Box column className="border-gray min-w-[260px] gap-3 xl:w-full xl:px-5">
      <Box full between className={`pb-3 border-b border-${border}`}>
        <Box column className="gap-2">
          <Text bodyBold color="lightText">
            {title}
          </Text>
          {isLoading ? <Skeleton /> : <Text title>{value}</Text>}
        </Box>
        <Box bg={bg} xcenter className="rounded-[100%] w-6 h-6">
          <Icon className="text-lg" name={icon} color="white" />
        </Box>
      </Box>
      <Box column className="gap-2">
        {isLoading ? (
          <>
            <Box className="grid grid-cols-2 gap-3">
              <Skeleton />
              <Skeleton />
            </Box>
            <Box className="grid grid-cols-2 gap-3">
              <Skeleton />
              <Skeleton />
            </Box>
          </>
        ) : (
          children
        )}
      </Box>
    </Box>
  );
};

const Card = {
  Base: CardBase,
  Row: CardRow,
  Heading: CardHeading,
};

export default Card;
