import FlagIT from 'assets/profile/languages/italy.png';
import FlagSP from 'assets/profile/languages/spain.png';
import FlagUK from 'assets/profile/languages/united-kingdom.png';
import Box from 'components/Box';
import Icon from 'components/Icon';
import Text from 'components/Text';
import { cls } from 'core/styles/cls';

const changeFlag = (lng) => {
  switch (lng) {
    case 'English':
      return <img src={FlagUK} alt="flag" className="w-[32px] h-[32px]" />;
    case 'Español':
      return <img src={FlagSP} alt="flag" className="w-[32px] h-[32px]" />;
    case 'Spanish':
      return <img src={FlagSP} alt="flag" className="w-[32px] h-[32px]" />;
    case 'Italian':
      return <img src={FlagIT} alt="flag" className="w-[32px] h-[32px]" />;
    default:
      return null;
  }
};

const SelectorCard = ({
  title,
  onClick,
  isActive,
  noCheck,
  children,
  className,
  status,
  render,
  ...props
}) => {
  return (
    <Box
      onClick={onClick}
      {...props}
      between
      xcenter
      ycenter
      className={cls('py-3 px-3 cursor-pointer bg-white', className)}
    >
      <Box className="gap-2 w-full" ycenter>
        {status && (
          <Box bg={status} className="w-[12px] h-[12px] rounded-[100%]" />
        )}
        {changeFlag(title)}
        <Text bodyBold>{title}</Text>
        {render && render()}
      </Box>
      {children ? (
        <Box ycenter className="gap-3">
          {children}
          {isActive ? (
            <Icon name="check" color="primary" />
          ) : (
            <Icon cursor="pointer" name="circle" color="lightText" />
          )}
        </Box>
      ) : !noCheck && isActive ? (
        <Icon name="check" color="primary" />
      ) : (
        !noCheck && <Icon cursor="pointer" name="circle" color="lightText" />
      )}
    </Box>
  );
};

export default SelectorCard;
