import { Link } from 'react-router-dom';

import Box from 'components/Box';
import Icon from 'components/Icon';
import Text from 'components/Text';

const Title = ({ titlePathname, title, icon, render }) => {
  if (titlePathname) {
    return (
      <Box ycenter className="gap-2">
        <Icon color="primary" name={icon} />
        <Link to={titlePathname}>
          <Text title link>
            {title}
          </Text>
        </Link>
        {render && render()}
      </Box>
    );
  }
  return (
    <Box ycenter className="gap-2">
      <Icon color="primary" name={icon} />
      <Text title>{title}</Text>
      {render && render()}
    </Box>
  );
};

export default Title;
