import React from 'react';

import { useParams } from 'react-router';

import EmptyComponent from 'common/components/tables/EmptyComponent';
import Box from 'components/Box';
import Table from 'components/Table';

import { useIncidentLogsDataQuery } from '../../../../services/incident-details';
import { useColumns } from './table';

const IncidentLogs = () => {
  const { id } = useParams();

  const {
    data,
    isError: isTableError,
    isFetching: isTableFetching,
    refetch,
    isPreviousData,
  } = useIncidentLogsDataQuery({ id });

  return (
    <Box column className="flex gap-5 overflow-x-auto">
      <Table
        data={data?.incident_logs}
        columns={useColumns()}
        EmptyComponent={EmptyComponent({ isTableError, refetch })}
        isError={isTableError}
        isFetching={isTableFetching}
        loading={isTableFetching || isPreviousData}
        loadingRows={5}
        loadingHeight={64}
        withMinWidth
      />
    </Box>
  );
};

export default IncidentLogs;
