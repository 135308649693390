import React from 'react';

import { useTranslation } from 'core/translations/useTranslation';

export const useGetChartOptions = () => {
  const t = useTranslation();
  return React.useCallback(
    ({ data, totalIncidents }) => ({
      credits: {
        enabled: false,
      },

      chart: {
        type: 'pie',
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
        events: {
          render: (incident) => {
            redrawTitle(incident.target, totalIncidents);
            redrawSubtitle(
              incident.target,
              t('incidents.widgets.incident_detection.label.incidents')
            );
          },
        },
      },

      plotOptions: {
        pie: {
          innerSize: '60%',
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
          cursor: 'pointer',
          allowPointSelect: true,
        },
      },

      legend: {
        itemMarginTop: 10,
        itemStyle: {
          lineHeight: '14px',
        },
      },

      title: totalIncidents,

      series: [
        {
          type: 'pie',
          name: 'Incidents',
          colorByPoint: true,
          innerSize: '80%',
          data,
        },
      ],

      colors: [
        '#D45A66',
        '#fbd360',
        '#4CDFE8',
        '#a3da8d',
        '#ffa974',
        '#dddddd',
      ],
    }),
    [t]
  );
};

const redrawTitle = (chart, text) => {
  if (chart.title) {
    chart.title.destroy();
  }
  const r = chart.renderer,
    x = chart.series[0].center[0] + chart.plotLeft,
    y = chart.series[0].center[1] + chart.plotTop;
  chart.title = r
    .text(text, 0, 0)
    .css({
      fontFamily: "'roboto', sans-serif",
      fontSize: '20px',
      fontWeight: 500,
    })
    .hide()
    .add();

  const bbox = chart.title.getBBox();
  chart.title
    .attr({
      x: x - bbox.width / 2,
      y: y,
    })
    .show();
};

const redrawSubtitle = (chart, text) => {
  if (chart.subtitle) {
    chart.subtitle.destroy();
  }
  let r = chart.renderer,
    x = chart.series[0].center[0] + chart.plotLeft,
    y = chart.series[0].center[1] + chart.plotTop;
  chart.subtitle = r
    .text(text, 0, 0)
    .css({
      color: '#1f3740',
      fontFamily: "'roboto', sans-serif",
      fontSize: '14px',
    })
    .hide()
    .add();

  var bbox = chart.subtitle.getBBox();
  chart.subtitle
    .attr({
      x: x - bbox.width / 2,
      y: y + 15,
    })
    .show();
};
