import React from 'react';

import EmptyStateWidgets from 'common/components/widgets/EmptyStateWidgets';
import { convertBytesToGigabytes } from 'common/utils/convertBytesToGigabytes';
import Chart from 'components/Chart';
import Skeleton from 'components/Skeleton';
import { useWidgetChart } from 'features/widgets/hooks';

import { useEventsVolumeQuery } from '../../services/useEventsVolumeQuery';
import { useGetChartOptions } from './chart';

const WidgetEventsVolume = ({ id, title, isEditing, removeWidget }) => {
  const { props: chartProps } = useWidgetChart(id);
  const { data: widgetData, isLoading } = useEventsVolumeQuery();
  const getChartOptions = useGetChartOptions();

  const totalEventsVolumeData = widgetData?.reduce((accumulator, object) => {
    return accumulator + object.y;
  }, 0);

  const options = React.useMemo(
    () =>
      getChartOptions({
        data: widgetData,
        allEventsVolumeData: convertBytesToGigabytes(totalEventsVolumeData),
      }),
    [widgetData, totalEventsVolumeData, getChartOptions]
  );

  return (
    <>
      {isLoading ? (
        <Skeleton radius={'10px'} />
      ) : widgetData && totalEventsVolumeData > 0 ? (
        <Chart options={options} {...chartProps} />
      ) : (
        <EmptyStateWidgets />
      )}
    </>
  );
};

export default WidgetEventsVolume;
