import { useParams } from 'react-router';

import EmptyComponent from 'common/components/tables/EmptyComponent';
import Box from 'components/Box';
import Table from 'components/Table';
import { useTranslation } from 'core/translations/useTranslation';

import { useRelatedEntitiesDataQuery } from '../../../../services/incident-details';
import { useColumns } from './table';

const RelatedEntities = () => {
  const { id } = useParams();

  const {
    data,
    isError: isTableError,
    isFetching: isTableFetching,
    refetch,
    isPreviousData,
  } = useRelatedEntitiesDataQuery({ id });

  const t = useTranslation();
  const noDataText = t('details.related_entities.table.no_data');

  return (
    <Box column className="gap-5 overflow-x-auto">
      <Table
        data={data?.related_entities}
        columns={useColumns()}
        EmptyComponent={EmptyComponent({ isTableError, refetch, noDataText })}
        isError={isTableError}
        isFetching={isTableFetching}
        loading={isTableFetching || isPreviousData}
        loadingRows={5}
        loadingHeight={64}
        withMinWidth
        showHeaders={data?.related_entities?.length > 0}
      />
    </Box>
  );
};

export default RelatedEntities;
