import { useNavigate } from 'react-router';

import ImageSidebar from 'assets/error/error-sidebar.png';
import Box from 'components/Box';
import Button from 'components/Button';
import Text from 'components/Text';
import { useTranslation } from 'core/translations/useTranslation';

const Error = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const t = useTranslation();

  return (
    <Box className="h-screen w-screen bg-[#0A2537]" xcenter column ycenter>
      <img src={ImageSidebar} className="mb-5" alt="Error 404" />
      <Box xcenter column className="gap-4 text-center">
        <Text color="white" title>
          {t('error_page.title')}
        </Text>
        <Text color="white">{t('error_page.description')}</Text>
        <Button className="w-[180px]" onClick={goBack}>
          {t('common.go_back')}
        </Button>
      </Box>
    </Box>
  );
};

export default Error;
