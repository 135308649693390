import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { CACHE_IDS } from 'common/constants/cache';
import { WIDGET_TYPES_SLUGS } from 'common/constants/widgets';
import { selectDateRange } from 'redux/filter/selectors';

import { getMalwareTypes } from '../api';

const mapper = (data) => {
  return Object.keys(data).map((itemName) => ({
    name: itemName,
    y: data[itemName],
  }));
};

export const useMalwareTypesQuery = () => {
  const { dateFrom, dateTo } = useSelector(selectDateRange);

  const query = useQuery(
    [
      CACHE_IDS.WIDGET_DATA,
      {
        slug: WIDGET_TYPES_SLUGS.MALWARE_TYPOLOGIES,
        dateFrom,
        dateTo,
      },
    ],
    () =>
      getMalwareTypes({
        dateFrom,
        dateTo,
        sort_by: 'count',
        order_by: 'desc',
      }),
    {
      select: (result) => mapper(result),
    }
  );
  return query;
};
