import { CACHE_IDS } from 'common/constants/cache';
import { useQuery } from 'core/api/react-query';

import { getEmailNotificationGroups } from '../api';

export const useGetEmailNotificationGroup = () => {
  const query = useQuery(
    [CACHE_IDS.EMAIL_NOTIFICATION_GROUP],
    getEmailNotificationGroups
  );

  return query;
};

export default useGetEmailNotificationGroup;
