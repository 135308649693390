import React from 'react';

export const useChipList = () => {
  const [activeChips, setActiveChips] = React.useState([]);

  const onClick = ({ id, isActive }) => {
    // Add chip to active list
    if (isActive) {
      setActiveChips([...activeChips, id]);
      // Remove chip from active list
    } else {
      setActiveChips(activeChips.filter((chip) => chip !== id));
    }
  };

  return { onClick, activeChips };
};

export default useChipList;
