import { useQueryClient } from 'react-query';

import { CACHE_IDS } from 'common/constants/cache';
import { useMutation } from 'core/api/react-query';

import { postEmailNotification } from '../api';

export const useCreateEmailNotificationGroupMutation = ({
  onSuccess,
  onError,
}) => {
  const client = useQueryClient();

  const mutation = useMutation(postEmailNotification, {
    onSuccess: () => {
      client.invalidateQueries([CACHE_IDS.EMAIL_NOTIFICATION_GROUP]);
      if (onSuccess) onSuccess();
    },
    onError,
  });

  return mutation;
};

export default useCreateEmailNotificationGroupMutation;
