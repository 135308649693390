import React from 'react';

import Image from 'assets/security-posture/not-found.png';
import Box from 'components/Box';
import Card from 'components/Card';
import Chart from 'components/Chart';
import Skeleton from 'components/Skeleton';
import Text from 'components/Text';
import { useTranslation } from 'core/translations/useTranslation';

import { getChartOptions } from './chart';
const WidgetCasesDistribution = ({ data, isLoading }) => {
  const t = useTranslation();
  const name = data && Object.keys(data.cases_distribution)[0];
  const y = data && Object.values(data.cases_distribution)[0];

  const options = React.useMemo(
    () =>
      getChartOptions({
        data: { name, y },
      }),
    [name, y]
  );

  return (
    <Card>
      <Box full xcenter className="max-h-[270px] overflow-hidden">
        {isLoading ? (
          <Box xcenter column full className="gap-5 my-4">
            <Skeleton width={230} height={230} circle />
            <Skeleton width={200} />
          </Box>
        ) : !y || y.length === 0 ? (
          <Box full column xcenter ycenter className="h-full">
            <Box xcenter column className="gap-3">
              <img className="w-[60px]" src={Image} alt="logo" />
              <Text bodyBold color="lightText">
                {t('details.involved_incident_widget.no_data')}
              </Text>
            </Box>
          </Box>
        ) : (
          <Chart
            options={options}
            containerProps={{ style: { width: '100%', height: '270px' } }}
          />
        )}
      </Box>
    </Card>
  );
};

export default WidgetCasesDistribution;
