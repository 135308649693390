import { useQuery } from 'react-query';

import { CACHE_IDS } from 'common/constants/cache';

import { getCaseDetailSimilarCases } from '../../api';

const transformData = (result) =>
  result?.similar_cases.map((similarCase, i) => ({
    ...similarCase,
    key: i * 10 + 99,
  }));

export const useSimilarEntitiesDataQuery = ({ id }) => {
  const query = useQuery(
    [CACHE_IDS.SIMILAR_CASES, { id }],
    () => getCaseDetailSimilarCases({ id }),
    {
      select: (result) => transformData(result),
    }
  );

  return query;
};
