import React from 'react';

import { useControlledInput } from 'common/hooks';
import { useToast } from 'common/hooks/toasts';
import Accordion from 'components/Accordion';
import Box from 'components/Box';
import Icon from 'components/Icon';
import Input from 'components/Input';
import Select from 'components/Select';
import Skeleton from 'components/Skeleton';
import Switch from 'components/Switch';
import Text from 'components/Text';
import { useTranslation } from 'core/translations/useTranslation';
import ContentLayout from 'features/profile/components/ContentLayout';
import {
  useGetAlertNotificationConfigQuery,
  useGetEmailNotificationGroup,
} from 'features/profile/services';
import useUpdateAlertNotificationMutation from 'features/profile/services/useUpdateAlertNotificationMutation';

export const RuleNotifications = () => {
  const t = useTranslation();
  const { showToast } = useToast();
  const { data, isLoading } = useGetAlertNotificationConfigQuery();
  const { data: emailGroupList, isLoading: isEmailGroupListLoading } =
    useGetEmailNotificationGroup();
  const {
    value: inputValue,
    setValue: setInputValue,
    onChange: onInputChange,
  } = useControlledInput();
  const { mutate: updateNotification } = useUpdateAlertNotificationMutation({
    onSuccess: () => {
      showToast(t('toast.notification.success'), 'success');
    },
    onError: () => {
      showToast(t('toast.error.error_500'), 'error');
    },
  });

  const emailOptions = React.useMemo(() => {
    if (emailGroupList && emailGroupList.length) {
      return emailGroupList.map((e) => ({
        value: e.id,
        label: e.name,
      }));
    }
  }, [emailGroupList]);

  const onToggleNotifications = async (id, isChecked) =>
    updateNotification({
      id,
      enabled: !isChecked,
    });

  const onThresholdSubmit = (id, currentValue) => {
    if (
      isNaN(inputValue) ||
      parseFloat(inputValue) !== Math.floor(parseFloat(inputValue))
    ) {
      showToast('Value must be a whole number', 'error');
      setInputValue(currentValue);
      return;
    }
    if (parseInt(currentValue) === parseInt(inputValue * 60)) return;
    return updateNotification({ id, throtling: parseInt(inputValue) * 60 });
  };

  const onSelectGroup = (id, value) => {
    return updateNotification({ id, emailGroupId: value || 0 });
  };

  if (isLoading) {
    return (
      <ContentLayout title={t('settings.tabs.rule_notifications.title')}>
        <Skeleton height={60} />
        <Skeleton height={60} />
        <Skeleton height={60} />
      </ContentLayout>
    );
  }

  return (
    <ContentLayout title={t('settings.tabs.rule_notifications.title')}>
      <Box column className="border border-gray rounded-[10px] overflow-hidden">
        {data?.map((alert) => (
          <Accordion
            key={alert?.id}
            status={alert?.email_group && alert?.enabled}
            isDropdown
            render={() => <Text bodyBold>{alert?.alert_type?.name}</Text>}
            id={alert?.id}
          >
            <Box between ycenter>
              <Text color="lightText">{t('common.target_group')}</Text>

              <Select
                isLoading={isEmailGroupListLoading}
                defaultValue={
                  alert?.email_group?.name && {
                    value: alert?.email_group?.id,
                    label: alert?.email_group?.name,
                  }
                }
                isClearable
                onChange={(value) => {
                  if (alert?.email_group?.id === value?.value) return;
                  onSelectGroup(alert?.id, value?.value);
                }}
                optionsData={emailOptions}
                className="w-[200px]"
                placeholder={t('settings.notificacion.select')}
              />
            </Box>
            <Box between ycenter>
              <Box column className="gap-1">
                <Text color="lightText">{t('common.threshold')} </Text>
                <Box className="gap-1 items-start lg:items-center">
                  <Icon name="info" color="lightText" size="small" />
                  <Text badge color="lightText" className="">
                    {t(
                      'settings.notificacion.rule_notification.threshold.description'
                    )}
                  </Text>
                </Box>
              </Box>
              <Input
                onKeyDown={(e) =>
                  e.key === 'Enter' &&
                  onThresholdSubmit(alert?.id, alert?.throtling)
                }
                onBlur={() => onThresholdSubmit(alert?.id, alert?.throtling)}
                width="200px"
                noValidation
                defaultValue={alert?.throtling / 60}
                onChange={onInputChange}
                placeholder="5, 10"
                className="z-0"
              />
            </Box>
            <Box between ycenter>
              <Box column className="gap-1">
                <Text color="lightText">{t('common.enable')}</Text>
                {!alert?.email_group && (
                  <Box ycenter className="gap-1">
                    <Icon name="info" color="lightText" size="small" />
                    <Text badge color="lightText">
                      {t(
                        'settings.notificacion.rule_notification.enable_notification.description'
                      )}
                    </Text>
                  </Box>
                )}
              </Box>
              <Switch
                disabled={!alert?.email_group}
                isEnabled={alert?.email_group && alert?.enabled}
                id={alert?.id}
                updateSwitch={onToggleNotifications}
              />
            </Box>
          </Accordion>
        ))}
      </Box>
    </ContentLayout>
  );
};

export default RuleNotifications;
