import Box from 'components/Box';
import Icon from 'components/Icon';
import Text from 'components/Text';
import { cls } from 'core/styles/cls';

export const Item = ({
  icon,
  children,
  isActive,
  className,
  color,
  ...props
}) => (
  <Box
    className={cls(
      'h-[56px] cursor-pointer justify-start',
      {
        'border-r-4 border-primary': isActive,
      },
      className
    )}
    ycenter
    {...props}
  >
    <Icon
      color={color || (isActive ? 'text' : 'lightText')}
      isBoxed
      name={icon}
    />
    <Text
      color={color || (isActive ? 'text' : 'lightText')}
      className="hidden xl:block"
      bodyBold
    >
      {children}
    </Text>
  </Box>
);

export default Item;
