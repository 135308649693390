import { useTranslation } from 'core/translations/useTranslation';

const Loading = () => {
  const t = useTranslation;
  return (
    <span>{t('secpos.loading')}</span> // TODO: Replace with spinner
  );
};

export default Loading;
