import { useSelector } from 'react-redux';

import { CACHE_IDS } from 'common/constants/cache';
import { useQuery } from 'core/api/react-query';
import { selectDateRange } from 'redux/filter/selectors';

import { getMetricsEvents } from '../api';

const mapper = (data) => {
  const keys = Object.keys(data);
  return keys.map((key) => ({
    name: key,
    max_events: data[key].max_events,
    min_events: data[key].min_events,
    avg_events: data[key].avg_events,
    percentage_events: data[key].percentage_events,
    max_size: data[key].max_size,
    min_size: data[key].min_size,
    avg_size: data[key].avg_size,
    percentage_size: data[key].percentage_size,
  }));
};

export const useMetricsEventsQuery = () => {
  const { dateFrom, dateTo } = useSelector(selectDateRange);

  const query = useQuery(
    [CACHE_IDS.METRICS, { slug: [CACHE_IDS.METRICS], dateTo, dateFrom }],
    () =>
      getMetricsEvents({
        dateFrom,
        dateTo,
      }),
    {
      select: (result) => mapper(result),
    }
  );
  return query;
};

export default useMetricsEventsQuery;
