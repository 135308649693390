import React from 'react';

import { useNavigate } from 'react-router';

import { useToast } from 'common/hooks/toasts';
import Box from 'components/Box';
import Modal from 'components/Modal';
import SelectorCard from 'components/SelectorCard';
import Text from 'components/Text';
import { useTranslation } from 'core/translations/useTranslation';
import { useUpdateCurrentTenantMutation } from 'features/profile/services';

const TenantCards = ({ arrayTenants, onClickTenant, currentTenant }) => {
  const sortedTenants = React.useMemo(
    () => arrayTenants?.sort((a, b) => a.localeCompare(b)),
    [arrayTenants]
  );
  return sortedTenants?.length ? (
    <Box
      column
      className="overflow-y-auto border border-gray rounded-lg w-full h-fit"
    >
      {sortedTenants.map((tenant) => (
        <SelectorCard
          className="border-b border-gray last:border-b-[0px]"
          key={tenant}
          title={tenant}
          isActive={tenant === currentTenant}
          onClick={() => onClickTenant(tenant)}
        />
      ))}
    </Box>
  ) : null;
};

const ModalChangeTenant = ({ closeModal, tenants, currentTenant }) => {
  const arrayTenants = tenants?.map((tenant) => tenant.name);
  const { showToast } = useToast();
  const navigate = useNavigate();
  const t = useTranslation();
  const { mutate: updateTenant } = useUpdateCurrentTenantMutation({
    onSuccess: () => {
      navigate('/');
      showToast(t('toast.tenant.success'), 'success');
    },
    onError: (error) => {
      showToast(error.detail, 'error');
    },
  });

  const onClickTenant = (tenant) => {
    updateTenant({ tenant });
  };

  return (
    <Modal
      title={t('common.tenant')}
      toggle={closeModal}
      icon="account"
      bg="lightRed"
      color="red"
      className="h-fit w-fit px-6"
    >
      <Box column className="gap-4 mt-4">
        <Box className="pb-2">
          <Text body>{t('change_tenant.description')}</Text>
        </Box>

        <Box column className="h-[185px] overflow-y-auto">
          <TenantCards
            {...{
              arrayTenants,
              onClickTenant,
              currentTenant,
            }}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalChangeTenant;
