import { useParams } from 'react-router';

import ErrorNotFound from 'common/components/errors/ErrorNotFound';
import Box from 'components/Box';
import Icon from 'components/Icon';
import Text from 'components/Text';
import { useTranslation } from 'core/translations/useTranslation';
import Layout from 'features/layout';

import DetailsHeading from '../../components/Details/DetailsHeading';
import TabsView from '../../components/Details/TabsView';
import { useBasicDetailsDataQuery } from '../../services/incident-details';

const DetailsIncident = () => {
  const t = useTranslation();
  const { id } = useParams();
  const { data, isError, isLoading } = useBasicDetailsDataQuery({ id });

  return (
    <Layout
      icon="incidents"
      title={t('sidebar.incidents')}
      titlePathname="/incidents"
      renderHeaderLeft={() => (
        <Box className="gap-2">
          <Icon name="arrow-right" />
          <Text title>{t('incidents.details.heading.title')}</Text>
        </Box>
      )}
    >
      <Box full column className="h-full gap-4">
        {isError ? (
          <ErrorNotFound />
        ) : (
          <>
            <DetailsHeading
              isLoading={isLoading}
              priority={data?.priority}
              events={data?.num_events}
              icon="incidents"
              color="danger"
              value={id}
              title={data?.incident}
              time={data?.start_time}
              percentage={data?.percentage_entities_suspicious}
            />
            <Box column className="gap-4 overflow-hidden">
              <TabsView />
            </Box>
          </>
        )}
      </Box>
    </Layout>
  );
};

export default DetailsIncident;
