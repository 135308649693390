import { useNavigate } from 'react-router';

import Box from 'components/Box';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Text from 'components/Text';

const PasswordReset = () => {
  const navigate = useNavigate();
  return (
    <Box column ycenter xcenter full className=" h-screen gap-3">
      <Box column xcenter className="gap-4">
        <Icon
          color="darkGreen"
          name="check"
          bg="success"
          className="h-6 w-6 rounded-[30px]"
        />
        <Text color="secondary" logoBold>
          Password Reset
        </Text>
      </Box>
      <Box column className="gap-4">
        <Box column xcenter className="gap-1">
          <Text bodyBold color="lightText">
            Your password has been successfully reset.
          </Text>
          <Text bodyBold color="lightText">
            Click below to go to login.
          </Text>
        </Box>

        <Button onClick={() => navigate('/login')}>Continue</Button>
      </Box>
    </Box>
  );
};

export default PasswordReset;
